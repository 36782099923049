import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SelazarLinkContainer from './SelazarLinkContainer';

const Failure = (props) => {
    return (
        <div>
            <h2>Failure!</h2>
            <Container>
                <Row>
                    <Col sm={12}>
                        <p>Something went wrong with your request. Please try again, or contact the support team for assistance.</p>
                        <SelazarLinkContainer to='/' activeClassName={null}>
                            <Button>Back to Home</Button>
                        </SelazarLinkContainer>                      
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

Failure.displayName = Failure.name;
export default Failure;