import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PreferencesSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import FormValidator from '../../../common/FormValidator';
import LoadingBar from '../../../common/LoadingBar';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import CommonEndpoints from '../../../common/Endpoints';
import { extractData, PUT, GET } from '../../../../Consumer';
import { isNullOrEmptyGuid } from '../../../../Utilities';
import { CustomPackageForm, CustomPackageFormActions, CancelModal } from './Snippets';

const GET_MAILING_BAG_ERROR = "No custom mailing bag found. Please try again.";
const UPDATE_MAILING_BAG_ERROR = "Unable to update custom mailing bag. Ensure another custom mailing bag does not have the same name and that it is disabled. Please try again.";

const breadcrumbs = [
    { text: "Packaging", link: "/retailer/preferences/packaging" },
    { text: "Custom Packaging", link: "/retailer/preferences/packaging/custompackaging" },
    { text: "Edit Custom Mailing Bag", link: null }
];

class EditCustomMailingBag extends Component {
    constructor(props) {
        super(props);

        this.mailingBagValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a package friendly name'
            },
            {
                field: 'height',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 height for the mailing bag'
            },
            {
                field: 'width',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 width for the mailing bag'
            },
            {
                field: 'maxWeight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Enter the weight limit of the mailing bag'
            },
        ]);

        this.state = {
            loading: true,
            id: props.match.params.id || null,
            showCancelModal: false,
            showSuccess: false,
            errorMessage: "",
            mailingBagValidation: this.mailingBagValidator.valid(),
            mailingBag: {
                name: "",
                height: 0.00,
                width: 0.00,
                maxWeight: 0.00
            }
        };
    }

    async componentDidMount() {
        await this.fetchCustomMailingBag();
        this.setState({ loading: false });
    }

    fetchCustomMailingBag = async () => {
        const { id } = this.state;
        if (!isNullOrEmptyGuid(id)) {
            return GET(CommonEndpoints.PACKAGING.GET.MAILING_BAG + id)
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    const data = extractData(result) || {};
                    this.setState({ mailingBag: data ? data : {}, errorMessage: data ? "" : GET_MAILING_BAG_ERROR });
                })
                .catch(e => {
                    console.log(e);
                    this.setState({ errorMessage: GET_MAILING_BAG_ERROR })
                });
        }
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            mailingBag: {
                ...prevState.mailingBag,
                ...{ [name]: value }
            }
        }));
    }

    handleShowHideCancelModal = () => this.setState(prevState => ({ showCancelModal: !prevState.showCancelModal }));

    handleUpdatePackage = async () => {
        this.setState({ loading: true });
        
        const { mailingBag } = this.state;
        const validation = this.mailingBagValidator.validate(mailingBag);
        this.setState({ mailingBagValidation: validation });

        if (validation.isValid) {
            return await PUT(CommonEndpoints.PACKAGING.PUT.MAILING_BAG, mailingBag)
                .then(response => {
                    if (response && response.ok) return response.json();
                })
                .then(results => {
                    const updated = extractData(results);
                    updated
                        ? this.setState({ showSuccess: true, errorMessage: "", loading: false })
                        : this.setState({ errorMessage: UPDATE_MAILING_BAG_ERROR, showSuccess: false, loading: false });
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ errorMessage: UPDATE_MAILING_BAG_ERROR, showSuccess: false, loading: false })
                });
        }
        this.setState({ loading: false });
    }

    render() {
        const { loading, mailingBag, mailingBagValidation, errorMessage, showSuccess, showCancelModal } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Packaging" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col sm={12} md={6}>
                            <h5 className="mb-3">Edit Custom Mailing Bag</h5>
                                {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
                                {showSuccess && <SuccessAlert successMessage="Successfully updated custom mailing bag." />}
                                <CustomPackageForm isBox={false} packageDetails={mailingBag} packageValidation={mailingBagValidation} handleInputChange={this.handleInputChange} />
                                <CustomPackageFormActions buttonText="Update Custom Mailing Bag" handleCancel={this.handleShowHideCancelModal} handleAction={this.handleUpdatePackage} />
                            </Col>
                        </Row>
                        <CancelModal isBox={false} isAdd={false} handleClose={this.handleShowHideCancelModal} showModal={showCancelModal} handleConfirm={() => this.props.history.push("/retailer/preferences/packaging/custompackaging/view/mailingbags")} />
                    </Container>}
            </React.Fragment>
        )
    }
}

export default EditCustomMailingBag;