import React, { Component } from 'react';
import { Button, Card, Image, Row, Col } from 'react-bootstrap';

import './Integrations.scss';

class SelectIntegration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            integrationSources: [
            {
                name: 'Shopify',
                imageUrl : 'Images/shopify-logo.png'
            },
            {
                name: 'WooCommerce',
                imageUrl : 'Images/woocommerce-logo.png'
            },
        ]
        }
    }

    componentDidMount() {
        this.props.setIntegrationSource(this.props.integrationSource);
    }

    addActiveClass = (selectedSource, currentSource) => {
        if(selectedSource === currentSource) return "integration-selected";
        else return "";
    }

    render() {
        const { integrationSources } = this.state;


        return (
            <React.Fragment>
                <p>Please select the platform you would like to add as an integration.</p>
                <Row>
                {integrationSources.map((source) => {
                    return ( 
                        <Col>
                            <Card id={`integration-source-${source.name}`} className={"integration-select-card " + this.addActiveClass(source.name, this.props.integrationSource)} 
                                onClick={() => this.props.setIntegrationSource(source.name)}>
                                    <Image src={source.imageUrl} className="mx-auto my-4 mx-5 integration-image" />
                            </Card>
                        </Col>
                    );
                })}
                </Row>
                <Button className="btn btn-primary float-right" type="submit" disabled={this.props.integrationSource === ''} onClick={this.props.setStep}>Next</Button>
                <Button variant="link" className="float-right" onClick={() => this.props.setStep('home')}>Cancel</Button>


            </React.Fragment>
        )
    }
}

export default SelectIntegration;