import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { checkPermission } from '../../../Utilities';
import { GET, extractData } from '../../../Consumer';
import MenuCard from '../../common/MenuCard';
import Endpoints from '../../common/Endpoints';

class Issues extends Component {
    constructor(props) {
        super(props);

        this.state = {
            company: {}
        };
    }

    componentDidMount() {
        this.getCompany();
    }

    getCompany() {
        let user = JSON.parse(localStorage.getItem('user'));

        GET(Endpoints.COMPANY.GET.COMPANY_BY_ID + user.companyID)
            .then(raw => raw.json())
            .then(result => {
                this.setState({ company: extractData(result) });
            });
    }

    render() {

        let { company } = this.state; 

        let raisedAgainstTitle = "Issues Raised Against " + company.name;
        let raisedByTitle = "Issues Raised By " + company.name;

        let raisedAgainstIssues = checkPermission("ViewIssue",
            <Col sm={6} md={3} className="margin-bottom">
                <MenuCard title={raisedAgainstTitle} link={{ pathname: '/company/issues/list/false', filter: { unresolved: false, resolved: false, read: false, title: '', startRecord: 0, endRecord: 9 }}} />
            </Col>);

        let raisedByIssues = checkPermission("ViewIssue",
            <Col sm={6} md={3} className="margin-bottom">
                <MenuCard title={raisedByTitle} link={{ pathname: '/company/issues/list/true', filter: {unresolved: false, resolved: false, read: false, title: '', startRecord: 0, endRecord: 9 }, raisedBy: true }} />
            </Col>);

        let unresolvedIssues = checkPermission("ViewIssue",
            <Col sm={6} md={3} className="margin-bottom">
                <MenuCard title="Unresolved Issues" link={{ pathname: '/company/issues/list/false', filter: {unresolved: true, resolved: false, read: false, title: '', startRecord: 0, endRecord: 9 } }} />
            </Col>);

        return (
            <div>
                <h2>Issues</h2>
                <Row className="margin-bottom display-flex">
                    {unresolvedIssues}
                    {raisedAgainstIssues}
                    {raisedByIssues}
                </Row>
            </div>
        );
    };
}
Issues.displayName = Issues.name;
export default Issues;