import React from 'react';
import { Button, Col, FormLabel, Form, FormGroup, Row, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


class UserFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: [],
        };
    }

    render() {
        const { name, sortBy, onSearch, onFilterChange, onClear } = this.props;

        return (
            <Form onSubmit={onSearch}>
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <InputGroup>
                                <FormControl id="nameField" className="input-filter" type="text" name="name" placeholder="Search by name" value={name} onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>                                    
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={1} className="pr-0">
                            <FormLabel htmlFor="sortBy" className="float-right mt-1">Sort By</FormLabel>
                        </Col>
                        <Col md={3}>
                            <FormControl id="sortBy" name="sortBy" as="select" value={sortBy} onChange={onFilterChange}>
                                <option key="" value="">Please Select...</option>;
                                <option value="EMAIL">Email</option>
                                <option value="FULL_NAME">Full Name</option>
                                <option value="LAST_LOGIN">Last Login</option>
                                <option value="DATE_CREATED">Date Created</option>
                                <option value="STATUS">Status</option>
                            </FormControl>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                    <Button variant="link" className="float-right" onClick={onClear}>Clear filter</Button>
                </FormGroup>
            </Form>
        );
    }
}

export default UserFilter;