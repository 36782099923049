import React from 'react';
import PropTypes from 'prop-types';
import SelazarLinkContainer from './SelazarLinkContainer';

const DashboardCard = ({ title,value, link }) => {
    return (
        <div className="card">
                <SelazarLinkContainer to={link}>
                    <a>
                        <h3>{title}</h3>
                        <h2>{value}</h2>
                    </a>
                </SelazarLinkContainer>
        </div>
    );
};

DashboardCard.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
};

export default DashboardCard;