import React, { Component } from 'react';
import { GET, extractData } from '../../Consumer';
import { toLocalTimeString } from '../../Utilities';
import { Container, Row, Col, Button } from 'react-bootstrap';
import LoadingBar from '../common/LoadingBar';
import Endpoints from '../common/Endpoints';


class InboundStock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stockConsignmentLabel: {},
            loading: true
        };
    }

    componentDidMount() {
        this.fetchLabels();
        setInterval(this.fetchLabels, 30000); // runs every 30 seconds.
    }

    goToURL = (url) => {
        window.open(url, '_blank');
    }

    fetchLabels = () => {
        return GET(Endpoints.FULFILMENT.GET.INBOUND_STOCK_LABELS)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({
                        stockConsignmentLabel: extractData(result),
                        loading: false,
                        error : false
                    });
                } else {
                    this.setState({ loading: false, error: true });
                }
            });
    }

    renderLabels() {
        const { stockConsignmentLabel, error } = this.state;

        return !error ? (
            <React.Fragment>
                <Row>
                    <Col sm="12" md="6" lg="3">
                    <section>
                        <h5 className="mt-4 integration-section-header">Label Details</h5>
                        <p className="mt-2">
                            <p className="title">Supplier Name</p>
                            <span className="d-block">{stockConsignmentLabel.supplierName}</span>
                        </p>
                        <p className="mt-2">
                            <p className="title">Date Created</p>
                            <span className="d-block">{toLocalTimeString(stockConsignmentLabel.createdDate)}</span>
                        </p>
                    </section>
                    <section>
                        <h5 className="mt-4 integration-section-header">Labels</h5>
                        <p className="mt-2">
                            <p className="title">Stock Box Labels</p>
                            <Button variant="secondary" onClick={() => { this.goToURL(stockConsignmentLabel.boxLabelsUrl) }} block>Download Stock Box Label(s)</Button>
                        </p>
                       {stockConsignmentLabel.itemLabelsUrl && 
                        <p className="mt-2">
                            <p className="title">Product Labels</p>
                            <Button variant="secondary" onClick={() => { this.goToURL(stockConsignmentLabel.itemLabelsUrl) }} block>Download Product Label(s)</Button>
                        </p>}
                    </section>
                    </Col>
                </Row>
            </React.Fragment>
        ) : <h5>No labels available</h5>;
    }


    render() {
        return (
            <Container fluid>
                <h2>Inbound Stock</h2>
                {this.state.loading ? <LoadingBar /> : this.renderLabels()}
            </Container>
        );
    }
}

export default InboundStock;