import React, { Component } from 'react';
import { Col, Row, Container, Button, Form, FormLabel, FormGroup, FormControl, Card, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GET, POST, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import SelazarLinkContainer from '../../../common/SelazarLinkContainer';
import Endpoints from '../../../common/Endpoints';
import { InventorySubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faFile, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { toLocalTimeString, defaultGuid } from '../../../../Utilities';


const QualityReportDetails = ({ quarantineDate, notes, photoLink }) => (
    <div className="section-consignment mt-3 mb-4">
        <p className="title"><strong>On Hold Date</strong></p>
        <p>{toLocalTimeString(quarantineDate)}</p>
        <p className="title"><strong>On Hold Reason</strong></p>
        <p>{notes}</p>
        <p className="title"><strong>Photo Evidence</strong></p>
        <img className="image-photo" src={photoLink} />
    </div>
);

class QualityReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id || null,
            qualityReport: {},
            qualityReportResponse: {
                id: defaultGuid,
                resolution: -1,
                resolutionNotes: "",
                qualityReportID : defaultGuid,
                createdByUserID : defaultGuid
            },
            loading: true,
            error: "",
            show: false,
            validationMessage:""
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchQualityReport()]);
        this.setState({ loading: false });
    }

    goToURL = (url) => {
        window.open(url, '_blank');
    }

    fetchQualityReport = async () => {
        const { id } = this.state;
        const url = new URL(Endpoints.STOCK.GET.QUALITY_REPORT + id);

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || {};
                this.setState({ qualityReport: data ? data : {}, error: data ? "" : "No quality report found" });
            });
    }

    renderResponseForm = () => {
        const { qualityReportResponse, qualityReport, loading, message, validationMessage } = this.state;
        return (<React.Fragment>
                    <Container className="mt-3" fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <h5>Quality Report</h5>
                                {qualityReport.qualityCheck.stockCSV.url && <div className="file-list consignment">
                                    <ul className>
                                        <li className="file-list-file" onClick={() => this.goToURL(qualityReport.qualityCheck.stockCSV.url)}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{qualityReport.qualityCheck.stockCSV.fileName}<div className="float-right"><FontAwesomeIcon className="form-fileicon" icon={faCheckCircle} /></div></li>
                                    </ul>
                                </div>}
                                <QualityReportDetails quarantineDate={qualityReport.qualityCheck.stockCSV.quarantineDate} notes={qualityReport.notes} photoLink={qualityReport.photoLink} />
                             
                                    <Form onSubmit={this.handleSubmit}>
                                    <div className="section-consignment mt-3 mb-4">
                                        <p className="title"><strong>Do you approve this stock to be processed for fulfilment?</strong></p>
                                        {this.state.error && <Card className="card-danger mb-3">
                                                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                                    <Card.Body>{message}</Card.Body>
                                                </Card>}
                                        <div className="form-input-description">
                                            <p>Once you make a decision on this stock it cannot be edited or undone. If you need more information contact us by emailing support@selazar.com or call 020 3950 7860</p>
                                        </div>
                                        <FormGroup className="custom-control custom-checkbox mb-2">
                                            <input disabled={loading} id="approve" className="custom-control-input" name="approve" type="checkbox" checked={qualityReportResponse.resolution == 0} onChange={this.handleInputChange} />
                                            <FormLabel className="custom-control-label" htmlFor="approve">Approve stock</FormLabel>
                                        </FormGroup>
                                        <FormGroup className="custom-control custom-checkbox mb-4">
                                            <input disabled={loading} id="deny" className="custom-control-input" name="deny" type="checkbox" checked={qualityReportResponse.resolution == 1} onChange={this.handleInputChange} />
                                            <FormLabel className="custom-control-label" htmlFor="deny">Do not approve stock</FormLabel>
                                        </FormGroup>
                                            {(qualityReportResponse.resolution == 0) && <div>
                                                <FormGroup>
                                                    <FormLabel>Comments <span className="form-label-optional">- optional</span></FormLabel>
                                                    <p>You can leave comments for the attention of the warehouse staff</p>
                                                    <FormControl disabled={loading} id="commentsField" type="text" as="textarea" rows="3" name="resolutionNotes" value={qualityReportResponse.resolutionNotes} onChange={this.handleInputChange} maxLength="550" />
                                                </FormGroup>
                                            </div>}
                                            {(qualityReportResponse.resolution == 1) && <div>
                                                <FormGroup>
                                                    <FormLabel>What do you want us to do with your stock?</FormLabel>
                                                    <p>We can destroy this stock on your behalf, or you can arrange for it to be returned yourself.</p>
                                                    <FormControl disabled={loading} id="commentsField" type="text" as="textarea" rows="3" name="resolutionNotes" value={qualityReportResponse.resolutionNotes} onChange={this.handleInputChange} maxLength="550" />
                                                    <span className="text-danger">{validationMessage}</span>
                                                </FormGroup>
                                            </div>}
                                        </div>
                                        <FormGroup className="float-right">
                                            <Button variant="link" onClick={this.handleShow} disabled={loading}>Cancel</Button>       
                                            <Button variant="primary"  type="submit" disabled={loading} value="Submit">Submit Decision</Button>
                                        </FormGroup>
                                    </Form>
                                
                            </Col>
                        </Row>
                </Container>
            </React.Fragment>);
    } 

    renderResponseComplete = () => {
        const { qualityReport } = this.state;
        return (<React.Fragment>
            <Container className="mt-3" fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <h5>Quality Report</h5>
                        <div className="section-consignment mt-3 mb-4">
                            <Card className="card-success mb-3">
                                <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
                                <Card.Body>Stock {qualityReport.qualityCheck.stockCSV.fileName} has been marked as approved</Card.Body>
                            </Card>
                            <p className="title"><strong>What happens now?</strong></p>
                            <p>We will notify the warehouse that you have approved this stock. Your stock will then be removed from on hold and processed as normal.</p>
                            <p>If you need any further information please contact us by emailing support@selazar.com or call 020 3950 7860</p>
                        </div>
                        <div className="float-right">
                            <Link className="btn btn-warning" to="/supplier/stockconsignments/quarantine" className="mr-3">Continue to On Hold Inventory</Link>
                        </div>
                    </Col>
                </Row>
        </Container>
    </React.Fragment>);
    } 

    handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            const { checked } = e.target;
            value = checked;

            if (name === "approve") {
                if (value === true) {
                    this.setState(prevState => ({
                        qualityReportResponse: {
                            ...prevState.qualityReportResponse,
                            resolution: 0
                        }
                    }));
                } else {
                    this.setState(prevState => ({
                        qualityReportResponse: {
                            ...prevState.qualityReportResponse,
                            resolution: -1
                        }
                    }));
                }
            }

            if (name === "deny") {
                if (value === true) {
                    this.setState(prevState => ({
                        qualityReportResponse: {
                            ...prevState.qualityReportResponse,
                            resolution: 1
                        }
                    }));
                } else {
                    this.setState(prevState => ({
                        qualityReportResponse: {
                            ...prevState.qualityReportResponse,
                            resolution: -1
                        }
                    }));
                }
            }
        } else {
            if (e.target.getAttribute('parent')) {
                let parent = e.target.getAttribute('parent');
                let parentValue = this.state.qualityReportResponse[parent];
                parentValue[name] = value;
    
                name = parent;
                value = parentValue;
            }
    
            this.setState(prevState => ({
                ...prevState,
                qualityReportResponse: {
                    ...prevState.qualityReportResponse,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { qualityReportResponse } = this.state;
        const { qualityReport } = this.state;

        qualityReportResponse.qualityReportID = qualityReport.id;

        if(qualityReportResponse.resolution >= 0) {
            if(qualityReportResponse.resolution == 1 && qualityReportResponse.resolutionNotes === "") {
                this.setState({ loading: false, validationMessage: "You need to inform us about what you want done with your stock" });
            } else {
                POST(Endpoints.STOCK.POST.QUALITY_REPORT_RESPONSE, qualityReportResponse)
                    .then(response => response.json()).then((result) => {
                        if (!result.error) {
                            this.setState({ qualityReportResponse: extractData(result),  loading: false }, this.fetchQualityReport);
                        } else {
                            this.setState({ loading: false, error: true, message: result.message });
                    }
                }); 
            }
        } else {
            this.setState({ loading: false, error: true, message: "You need to select a decision before you can continue" });
        }
    }
    
    renderModal = () => {
        return (
                <Modal centered show={this.state.show} onHide={this.handleClose} backdrop="static" animation={false}>
                    <Modal.Header closeButton className="px-4">
                        <Modal.Title>On Hold Review Process</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                    <p>Are you sure you want to cancel? This stock cannot come out of on hold without your decision.</p>    
                    </Modal.Body>
                    <Modal.Footer className="px-4">
                        <Button variant="link" onClick={this.handleClose}>Do not Cancel On Hold Review Process</Button>
                        <SelazarLinkContainer to={`/supplier/stockconsignments/quarantine`} exact>
                            <Button className="link-button" variant="danger" size="sm">Cancel On Hold Review Process</Button>
                        </SelazarLinkContainer>
                    </Modal.Footer>
                </Modal>
        );
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    render() {
        const { loading, qualityReport } = this.state;
        const contents = loading ? <LoadingBar /> : qualityReport.qualityReportResponse ? this.renderResponseComplete() : this.renderResponseForm();

        return (
            <React.Fragment>
                <Header title="Inventory" subNavList={InventorySubNavList} activeKey="On Hold Inventory" headerClass="mb-0" />
                <Link to='/supplier/stockconsignments/quarantine' className="link-button">
                    <Button variant="link" className="button-nav-return"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to On Hold Inventory</Button>
                </Link>
                {contents}        
                {this.renderModal()}
            </React.Fragment>
        );
    }
}

export default QualityReport;