import React, { Component } from 'react';
import { Col, Row, Container, Table, Card } from 'react-bootstrap';
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import { InvoicingSubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import { GET, extractData } from '../../../Consumer';
import InvoiceRow from './InvoiceRow';
import InvoiceFilter from './InvoiceFilter';
import Pagination from '../../common/Tables/Pagination';

class Invoices extends Component {
    constructor(props) {
        super(props);

        const { filter = { invoiceNumber: "", invoiceType: "", dateFrom: null, dateTo: null, orderDescending: true } } = this.props.location;

        this.state = {
            totalCount: 0,
            pageIndex: 1,
            pageCount: 0,
            invoices: [],
            loading: true,
            filter: filter
        };

        this.getInvoices = this.getInvoices.bind(this);
        this.renderInvoiceRows = this.renderInvoiceRows.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.onPageSelect = this.onPageSelect.bind(this);
    }

    componentDidMount() {
        this.getInvoices();
    }

    setLoading(loading) {
        this.setState({ loading: loading });
    }

    getInvoices() {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        const { invoiceNumber, invoiceType, dateFrom, dateTo, orderDescending } = this.state.filter;

        let url = new URL(Endpoints.INVOICES.GET.ALL);
        
        if (invoiceNumber || invoiceType || dateFrom || dateTo || orderDescending) {
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }
        url.searchParams.append("pageIndex", pageIndex)
        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    this.setState({ invoices: data.invoices, totalCount: data.total, pageCount: data.pageCount, loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }


    renderInvoiceRows() {
        const { invoices, pageIndex, pageCount } = this.state;
        return (
            <Card>
                <div className="table-responsive">
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th>Date and Time</th>
                                <th>Invoice Number</th>
                                <th>Invoice Type</th>
                                <th>Total (£)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.length ? invoices.map(invoice => <InvoiceRow key={invoice.id} invoice={invoice} />) : <p className="mx-1 my-1"><strong>No invoices available.</strong></p>}
                        </tbody>
                    </Table>
                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                </div>
            </Card>
        );
    }

    onFilterChange(e) {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear() {
        this.setState({
            invoices: [],
            filter: {
                invoiceNumber: "",
                invoiceType: "",
                dateFrom: null,
                dateTo: null, 
                orderDescending: true
            }
        }, this.getInvoices);
    }

    onSearch(event) {
        event.preventDefault();
        this.setState({ invoices: [], loading: true }, this.getInvoices);
    }

    onNext() {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.getInvoices);
        }
    }

    onPrevious() {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.getInvoices);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.getInvoices);
    }

    render() {
        return (
            <React.Fragment>
                <Header title="Invoicing" subNavList={InvoicingSubNavList} activeKey="Invoice" />
                <Container fluid>
                    <Row className="my-4">
                        <Col>
                            <InvoiceFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} loading={this.state.loading} onLoading={this.setLoading} />
                        </Col>
                    </Row>
                    {this.state.loading ? <LoadingBar /> : this.renderInvoiceRows()}
                </Container>
            </React.Fragment>
        );
    }
}

export default Invoices;