import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, FormLabel, FormControl, FormGroup, Card, Accordion, Row, FormCheck } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';

class ServiceFilter extends React.Component {
    displayName = ServiceFilter.name;

    constructor(props) {
        super(props);

        this.state = {
            serviceRegions: [],
            serviceTimes: []
        };
        this.getServiceRegions = this.getServiceRegions.bind(this);
        this.getServiceTimes = this.getServiceTimes.bind(this);
    }

    componentDidMount() {
        this.getServiceRegions();
        this.getServiceTimes();
    }

    getServiceRegions() {
        return GET(Endpoints.GET_ALL_SERVICE_REGIONS)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ serviceRegions: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    getServiceTimes() {
        return GET(Endpoints.GET_SERVICE_TIMES)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ serviceTimes: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    render() {
        let { fromServiceRegionID, toServiceRegionID, serviceTime, liquid, batteries, tracked, signed, onSearch, onFilterChange,loading  } = this.props;

        let { serviceRegions, serviceTimes } = this.state;

        return (<Card id="collapsible-panel-example-2">
            <Card.Heading style={{ textAlign: "center" }}><Card.Title toggle>Toggle Filter</Card.Title></Card.Heading>
            <Accordion.Collapse>
                <Card.Body>
                    <Form onSubmit={onSearch}> 
                            <FormGroup>
                                <FormLabel htmlFor="serviceTimeField">Service Time</FormLabel>

                                <FormControl id="serviceTimeField" name="serviceTime" componentClass="select" value={serviceTime} onChange={onFilterChange}>
                                    <option key={0} value={0}>Please Select...</option>;
                            {serviceTimes.map(serviceTime => {
                                        return <option key={serviceTime.key} value={serviceTime.key}>{serviceTime.value}</option>;
                                    })}
                                </FormControl>

                            </FormGroup>
                            <FormGroup>
                                <label>Service Region</label>
                                <Row>
                                    <Col md={1}>
                                        <FormLabel htmlFor="fromServiceRegionField">From</FormLabel>
                                    </Col>
                                    <Col md={5}>
                                        <FormControl id="fromServiceRegionField" name="fromServiceRegionID" componentClass="select" value={fromServiceRegionID} onChange={onFilterChange}>
                                            <option key="" value="">Please Select...</option>;
                            {serviceRegions.map(serviceRegion => {
                                                return <option key={serviceRegion.id} value={serviceRegion.id}>{serviceRegion.name}</option>;
                                            })}
                                        </FormControl>
                                    </Col>

                                    <Col md={1}>
                                        <FormLabel htmlFor="toServiceRegionField">To</FormLabel>
                                    </Col>
                                    <Col md={5}>
                                        <FormControl id="toServiceRegionField" name="toServiceRegionID" componentClass="select" value={toServiceRegionID} onChange={onFilterChange}>
                                            <option key="" value="">Please Select...</option>;
                            {serviceRegions.map(serviceRegion => {
                                                return <option key={serviceRegion.id} value={serviceRegion.id}>{serviceRegion.name}</option>;
                                            })}
                                        </FormControl>
                                    </Col>

                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row className="margin-bottom">
                                    <Col md={1}>
                                        <FormLabel htmlFor="batteriesField">Batteries</FormLabel>
                                    </Col>
                                    <Col md={3}>
                                        <FormCheck id="batteriesField" name="batteries" checked={batteries} onChange={onFilterChange} />
                                    </Col>
                                    <Col md={1}>
                                        <FormLabel htmlFor="liquidsField">Liquids</FormLabel>
                                    </Col>
                                    <Col md={3}>
                                        <FormCheck id="liquidsField" name="liquid" checked={liquid} onChange={onFilterChange} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={1}>
                                        <FormLabel htmlFor="signedField">Signed</FormLabel>
                                    </Col>
                                    <Col md={3}>
                                        <FormCheck id="signedField" name="signed" checked={signed} onChange={onFilterChange} />
                                    </Col>
                                    <Col md={1}>
                                        <FormLabel htmlFor="trackedField">Tracked</FormLabel>
                                    </Col>
                                    <Col md={3}>
                                        <FormCheck id="trackedField" name="tracked" checked={tracked} onChange={onFilterChange} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                            <div className="card-button-row">
                                <Button bsClass="btn btn-primary card-button" type="submit" disabled={loading}>Apply filter</Button>
                                <Button bsClass="btn btn-default card-button" onClick={this.props.onClear} disabled={loading}>Clear filter</Button>
                               </div>
                            </FormGroup>
                    </Form>
                </Card.Body>
            </Accordion.Collapse>
        </Card>);
    }
}

ServiceFilter.propTypes = {
    batteries: PropTypes.bool,
    fromServiceRegionID: PropTypes.string,
    liquid: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    serviceTime: PropTypes.number,
    signed: PropTypes.bool,
    toServiceRegionID: PropTypes.string,
    tracked: PropTypes.bool

};

export default ServiceFilter;