import React, { Component } from 'react';

export class InfiniteScroll extends Component
{
    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) - 1;
        const windowBottom = windowHeight + window.pageYOffset;

        if (windowBottom >= docHeight) {
            if (!this.props.loading) {
                this.props.onBottomDetected();
                console.log('bottom');
            }
        }
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}