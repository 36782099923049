import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import CommonEndpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import { toLocalDateString, hasPermission } from '../../../Utilities';
import { DefaultCard, ListCard, PieChartCard, BarCard } from '../../common/DashboardCards';

const chartColours = ['#F16750', '#3F4967', '#4DBCBE'];
const barChartColour = "#229BD8";

const getRegionLabel = (region) => region === "RestOfWorld" ? "Rest of World" : region === "EU" ? "Europe" : "UK";

const extractAreaData = (areaData) => {
    const labels = areaData.map(area => getRegionLabel(area.region));
    const data = areaData.map(area => area.total);
    const areaTotals = {
        labels: labels,
        data: data
    }
    return areaTotals;
}

const extractFulfilmentData = (fulfilmentData) => {
    const labels = fulfilmentData.map(fulfilment => fulfilment.status);
    const data = fulfilmentData.map(fulfilment => fulfilment.count);
    const fulfilmentTotals = {
        labels: labels,
        data: data
    }
    return fulfilmentTotals;
}

const extractOrderTotalsByDayData = (orderTotalData) => {
    const labels = orderTotalData.map(orderTotal => toLocalDateString(orderTotal.date));
    const data = orderTotalData.map(orderTotal => orderTotal.total);
    const orderTotalsByDay = {
        labels: labels,
        data: data
    }
    return orderTotalsByDay;
}

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            awaitingFulfilmentCount: 0,
            orderCount: {
                count: 0,
                status: "NO_CHANGE"
            },
            orderAverage: {
                averageCost: 0.00,
                status: "NO_CHANGE"
            },
            popularOrders: [],
            areaTotals: {
                labels: [],
                data: []
            },
            fulfilmentTotals: {
                labels: [],
                data: []
            },
            orderTotalsByDay: {
                labels: [],
                data: []
            }
        };
        this.getAwaitingFulfilmentCount = this.getAwaitingFulfilmentCount.bind(this);
        this.getOrderCount = this.getOrderCount.bind(this);
        this.getOrderAverage = this.getOrderAverage.bind(this);
        this.getPopularOrders = this.getPopularOrders.bind(this);
        this.getDeliveryDestinations = this.getDeliveryDestinations.bind(this);
        this.getOrderTotalsByDay = this.getOrderTotalsByDate.bind(this);
    }

    async componentDidMount() {
        await Promise.all([
            this.getAwaitingFulfilmentCount(),
            this.getOrderCount(),
            this.getOrderAverage(),
            this.getPopularOrders(),
            this.getDeliveryDestinations(),
            this.getFulfilmentTotals(),
            this.getOrderTotalsByDate()]);
        this.setState({ loading: false });
    }    

    getAwaitingFulfilmentCount() {
        return GET(CommonEndpoints.ORDERS.GET.AWAITING_FULFILMENT_ORDER_COUNT)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ awaitingFulfilmentCount: data });
                }
            }, err => {
                console.error(err);
            });
    }


    getOrderCount() {
        return GET(CommonEndpoints.ORDERS.GET.ORDER_COUNT)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ orderCount: data });
                }
            }, err => {
                console.error(err);
            });
    }

    getOrderAverage() {
        return GET(CommonEndpoints.ORDERS.GET.ORDER_AVERAGE)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ orderAverage: data });
                }
            }, err => {
                console.error(err);
            });
    }

    getPopularOrders() {
        return GET(CommonEndpoints.ORDERS.GET.POPULAR_ORDERS)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ popularOrders: data });
                }
            }, err => {
                console.error(err);
            });
    }

    getDeliveryDestinations() {
        return GET(CommonEndpoints.ORDERS.GET.TOTALS_BY_AREA)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const areaTotals = extractAreaData(data);
                    this.setState({ areaTotals: areaTotals });
                }
            }, err => {
                console.error(err);
            });
    }

    getFulfilmentTotals() {
        return GET(CommonEndpoints.ORDERS.GET.FULFILMENT_TOTALS)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const fulfilmentTotals = extractFulfilmentData(data);
                    this.setState({ fulfilmentTotals: fulfilmentTotals });
                }
            }, err => {
                console.error(err);
            });
    }

    getOrderTotalsByDate() {
        return GET(CommonEndpoints.ORDERS.GET.TOTALS_BY_DATE)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const orderTotalsByDay = extractOrderTotalsByDayData(data);
                    this.setState({ orderTotalsByDay: orderTotalsByDay });
                }
            }, err => {
                console.error(err);
            });
    }

    render() {
        const { loading, awaitingFulfilmentCount, orderCount, orderAverage, popularOrders, areaTotals, fulfilmentTotals, orderTotalsByDay } = this.state;

        const deliveryDestinationData = {
            labels: areaTotals.labels,
            datasets: [{
                data: areaTotals.data,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartColours
            }]
        };

        const fulfilmentData = {
            labels: fulfilmentTotals.labels,
            datasets: [{
                data: fulfilmentTotals.data,
                backgroundColor: chartColours,
                hoverBackgroundColor: chartColours
            }]
        };

        const orderData = {
            labels: orderTotalsByDay.labels,
            datasets: [
                {
                    backgroundColor: barChartColour,
                    data: orderTotalsByDay.data
                }
            ]
        };

        const showCosts = hasPermission("ViewInvoice");

        return (
            <Container fluid>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <h2>Dashboard</h2>
                        <Row>
                            <Col sm="12" md={showCosts ? 4 : 6} className="mt-4">
                                <DefaultCard title="Orders Awaiting Fulfilment" count={(awaitingFulfilmentCount).toLocaleString()} text="orders currently awaiting fulfilment" />
                            </Col>
                            <Col sm="12"  md={showCosts ? 4 : 6} className="mt-4">
                                <DefaultCard title="Total Number of Orders" count={(orderCount.count).toLocaleString()} text="in the past 28 days" status={orderCount.status} statusText="from the previous 28 days" />
                            </Col>
                            {showCosts && <Col sm="12" md="4" className="mt-4">
                                <DefaultCard title="Average Order Cost" count={`£${parseFloat(orderAverage.averageCost).toFixed(2)}`} text="in the past 28 days" status={orderAverage.status} statusText="from the previous 28 days" />
                            </Col>}
                        </Row>

                        <Row>
                            <Col sm="12" md="4" className="mt-4">
                                <ListCard title="Most Popular Products" items={popularOrders} />
                            </Col>
                            <Col sm="12" md="8">
                                <Row>
                                    <Col sm="12" md="6" className="mt-4">
                                        <PieChartCard title="Delivery Destinations" data={areaTotals.data.reduce((a, b) => a + b, 0) > 0 ? deliveryDestinationData : null} />
                                    </Col>
                                    <Col sm="12" md="6" className="mt-4">
                                        <PieChartCard title="Today's Fulfilment Process" data={fulfilmentTotals.data.reduce((a, b) => a + b, 0) > 0  ? fulfilmentData : null} />
                                    </Col>
                                    <Col md="12" className="mt-4">
                                        <BarCard title="Order Totals for the Past 28 Days" data={orderTotalsByDay.data.reduce((a, b) => a + b, 0) > 0 ? orderData : null} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>}
            </Container>
        );
    }
}

export default Dashboard;