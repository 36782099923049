import React, { Component } from 'react';
import { Button, Col, Form, FormControl, FormGroup, Card, Accordion } from 'react-bootstrap';
import { extractData, GET, POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';

class PromotionCreateByCategory extends Component {

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            promotion: {
                active: true,
                dateFrom: "",
                dateTo: "",
                description: "",
                categoryID: "",
                percentage: 0,
                companyID: user.companyID,
                promotionTypeID:""
            },
            categoryList: [],
            promotionTypes: [],
            errors:[]
        };

        this.initialPromotion = {
            active: true,
            dateFrom: "",
            dateTo: "",
            description: "",
            categoryID: "",
            percentage: 0,
            companyID: user.companyID,
            promotionTypeID: ""
        };

        this.fetchCategories = this.fetchCategories.bind(this);
        this.fetchPromotionTypes = this.fetchPromotionTypes.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.fetchCategories();
        this.fetchPromotionTypes();
    }

    handleInputChange(changeEvent) {
        let { name, value } = changeEvent.target;
        this.setState({ promotion: { ...this.state.promotion, [name]: value } });
    }

    handleSubmit(ev) {
        //ev.preventDefault();
        const { promotion } = this.state;
        const { promotionTypes } = this.state;

        let promotionType = promotionTypes.find(obj => obj.name === "Category");
        promotion.promotionTypeID = promotionType.id;

        POST(Endpoints.CREATE_PROMOTION, promotion)
            .then(r => r.json()).then(e => {
                if (typeof e === "number") {
                    this.setState({ promotion: this.initialPromotion });
                    this.props.refetchMe();
                    alert("Promotion Added");
                }
                if (typeof e === "object") {
                    let arrays = Object.keys(e).map(k => e[k]);
                    let errors = [].concat.apply([], arrays);
                    this.setState({ errors: errors });
                }
            });
    }

    fetchCategories() {
        return GET(Endpoints.ALL_CATEGORIES)
            .then(response => response.json())
            .then(data => {
                this.setState({ categoryList: extractData(data) || [] });
            });
    }

    fetchPromotionTypes() {
        return GET(Endpoints.ALL_PROMOTION_TYPES)
            .then(response => response.json())
            .then(data => {
                this.setState({ promotionTypes: extractData(data) || [] });
            });
    }

    render() {
        let categoryList = this.state.categoryList || [];
        let categoryOptions = categoryList.map((c) => <option key={c.id} value={c.id}>{c.name}</option>);
        return (
            <div>
                {this.state.errors.length > 0 && <div className="alert alert-danger">
                    <span>An error occurred while submitted your changes, please see below for further information:</span>
                    <ul>
                        {this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
                    </ul>
                </div>}
                <Card id="collapsible-panel" defaultExpanded>
                    <Card.Heading>
                        <Card.Title componentClass="h1" toggle>Add By Category</Card.Title>
                    </Card.Heading>
                    <Accordion.Collapse>
                        <Card.Body>
                            <Form horizontal onSubmit={(ev) => { this.handleSubmit(ev); }}>
                                <FormGroup>
                                    <Col sm={2}>Categories</Col>
                                    <Col sm={4}><FormControl componentClass="select" id="categoryID" name="categoryID" placeholder="Select Category" defaultValue="Please Select" onChange={this.handleInputChange} value={this.state.promotion.entityid}>
                                        <option key="" value="">Please Select...</option>;
                                        {categoryOptions}
                                    </FormControl></Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col sm={2}>Date from</Col>
                                    <Col sm={4}><FormControl type="date" id="dateFrom" name="dateFrom" onChange={this.handleInputChange} value={this.state.promotion.datefrom}/></Col>
                                    <Col sm={2}>to</Col>
                                    <Col sm={4}><FormControl type="date" id="dateTo" name="dateTo" onChange={this.handleInputChange} value={this.state.promotion.dateto}/></Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col sm={2}>Percentage</Col>
                                    <Col sm={4}><FormControl type="number" id="percentage" name="percentage" placeholder="Percentage" onChange={this.handleInputChange} value={this.state.promotion.percentage}/></Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col sm={2}>Description</Col>
                                    <Col sm={10}><FormControl type="text" id="description" name="description" placeholder="Description" onChange={this.handleInputChange} value={this.state.promotion.description}/></Col>
                                </FormGroup>
                                <FormGroup>
                                    <Col smOffset={2} sm={10}>
                                        <Button bsClass="btn btn-primary" type="submit">Add Promotion</Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </div>
        );
    }
}

export default PromotionCreateByCategory;