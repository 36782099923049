import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BulkOrderSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import BulkOrderTable from './BulkOrderTable';
import { GET, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { ErrorAlert } from '../../../common/Alert';
import BulkOrderListFilter from './BulkOrderListFilter';

const HEADER_KEY = "Unsuccessful Orders";

class UnsuccessfulBulkOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 1,
            pageIndex: 1,
            bulkOrders: [],
            loading: true,
            filter: {
                purchaseOrderNumber: "",
                dateFrom: undefined,
                dateTo: undefined,
                sortBy: ""
            }
        };
    }

    async componentDidMount() {
        await this.fetchUnsuccessfulBulkOrders();
        this.setState({ loading: false });
    }

    fetchUnsuccessfulBulkOrders = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RETAILER.GET.BULK_ORDER_UNSUCCESSFUL);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.bulkOrders === 0 ? "No unsuccessful bulk orders found" : null;
                this.setState({ bulkOrders: data.bulkOrders ? data.bulkOrders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            });
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchUnsuccessfulBulkOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchUnsuccessfulBulkOrders);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchUnsuccessfulBulkOrders);
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { purchaseOrderNumber: "", dateFrom: null, dateTo: null, sortBy: "" },
            loading: true
        }, await this.fetchUnsuccessfulBulkOrders);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchUnsuccessfulBulkOrders);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    handleViewBulkOrderClick = (id) => {
        this.props.history.push({ pathname: `/retailer/bulkorder/bulkorderdetails/${id}`, state: { headerKey: HEADER_KEY } });
    }

    render() {
        const { bulkOrders, loading, pageIndex, pageCount, warning } = this.state;

        return (
            <React.Fragment>
                <Header title="Bulk Order" subNavList={BulkOrderSubNavList} activeKey={HEADER_KEY} />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                        <Row className="my-4">
                            <Col>
                                <BulkOrderListFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                            </Col>
                        </Row>
                        <BulkOrderTable bulkOrders={bulkOrders} handleViewBulkOrderClick={this.handleViewBulkOrderClick} showCancel={false} onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                    </Container>}
            </React.Fragment>
        )
    }
}

export default UnsuccessfulBulkOrders;