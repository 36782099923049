import React, { Component } from 'react';
import Endpoints from '../../common/Endpoints';
import { GET, PUT, extractData } from '../../../Consumer';
import { Container, Col, Row, FormGroup, FormLabel, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingBar from '../../common/LoadingBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

class Permissions extends Component {
    displayName = Permissions.name;
    
    constructor(props) {
        super(props);
        this.state = {
            allPermissions: [],
            loading: true,
            errors: []
        };
        this.loadAllPermissions = this.loadAllPermissions.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.updatePermission = this.updatePermission.bind(this);
        this.handleParentInputChange = this.handleParentInputChange.bind(this);
    }

    componentDidMount() {
        this.loadAllPermissions();
    }

    loadAllPermissions() {
        const { id } = this.props.location.state.user;

        return GET(Endpoints.GET_FEATURE_BREAKDOWN_FOR_USER + id)
            .then(response => response.json())
            .then(data => {
                this.setState({ allPermissions: extractData(data) || [], loading: false });
            });
    }

    handleInputChange(e) {
        const { type, name } = e.target;
        const { id } = this.props.location.state.user;

        let access = false;

        if (type === "checkbox") {
            const { checked } = e.target;
            access = checked;
        }

        this.updatePermission(name, id, access)
    }

    handleParentInputChange(e) {
        const { name, checked } = e.target;
        const { id } = this.props.location.state.user;
        const { allPermissions } = this.state;
        const childPermissions = allPermissions.find(a => a.name === name);
        this.setState({ loading: true });

        childPermissions.permissions.map(permission => {
            this.updatePermission(permission.id, id, checked)
        });

        this.setState({ loading: false });
    }

    updatePermission(name, userId, access) {
        PUT(`${Endpoints.UPDATE_OR_CREATE_FEATUREUSER}${name}/${userId}/${String(access)}`)
            .then(r => r.json()).then(data => {
                if (!data.error) {
                    this.loadAllPermissions();
                }
                else {
                    let arrays = Object.keys(extractData(data)).map(k => extractData(data)[k]);
                    let errors = [].concat.apply([], arrays);
                    this.setState({ errors: errors });
                }
            });
    }

    render() {
        const { loading, errors, allPermissions } = this.state;
        const { forename, surname } = this.props.location.state.user;

        return (
            loading ?
                <LoadingBar /> :
                <Container fluid>
                    <h2>Edit Permissions : {forename} {surname}</h2>
                    <Link to='/company/users' className="link-button">
                        <Button variant="link" className="button-nav-link mb-3">{"< Back to Users"}</Button>
                    </Link>
                    <Row>
                        <Col sm={12} md={6}>
                            {errors.length > 0 && <Card className="card-danger my-3">
                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                <Card.Body>
                                    {errors.map((e, i) => e ? <p key={i}>{e}</p> : null)}
                                </Card.Body>
                            </Card>}
                            {allPermissions.length ? allPermissions.map((parent) =>
                                <div key={`${parent.name}-permissions`}>
                                    <FormGroup key={`${parent.name}-check`} className="custom-control custom-checkbox">
                                        <input id={parent.name} name={parent.name} className="custom-control-input" type="checkbox" onChange={this.handleParentInputChange} checked={Object.values(parent.permissions).every(p => p.enabled === true)} />
                                        <FormLabel className="custom-control-label" htmlFor={parent.name}><strong>{parent.name}</strong></FormLabel>
                                    </FormGroup>
                                    {parent.permissions.length ? parent.permissions.map((permission) =>
                                        <FormGroup key={`permission-${permission.id}`} className="custom-control custom-checkbox ml-4">
                                            <input id={permission.id} name={permission.id} className="custom-control-input" type="checkbox" onChange={this.handleInputChange} checked={permission.enabled} />
                                            <FormLabel className="custom-control-label" htmlFor={permission.id}>{permission.featureEnumName}</FormLabel>
                                        </FormGroup>
                                    ) : null}
                                </div>
                            ) : <p><strong>No permissions to show.</strong></p>}
                        </Col>
                    </Row>
                </Container>
        );
    }
}

export default Permissions;