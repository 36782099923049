import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { GET, extractData } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { CountryList } from '../../../common/Constants';
import Header from '../../../common/Header';
import { BulkOrderSubNavList } from '../../../common/Constants';
import { toLocalTimeString } from '../../../../Utilities';
import { ErrorAlert, WarningAlert } from '../../../common/Alert';
import Breadcrumbs from '../../../common/Breadcrumbs';

import './BulkOrderDetails.scss';

const BULK_ORDER_WARNING_TITLE = "Order Information";
const BULK_ORDER_WARNING_MESSAGE = "We are currently processing your order. Full order details will be available within 10 minutes, please check back later.";
const BULK_ORDER_ERROR_MESSAGE = "There was a problem retrieving the bulk order. Please try again.";

const breadcrumbs = (headerKey) => [
    { text: "Bulk Orders", link: headerKey === "Pending Orders" ? "/retailer/bulkorder" : headerKey === "Orders in Process" ? "/retailer/bulkorder/inprocessorders" : headerKey === "Processed Orders" ? "/retailer/bulkorder/processedorders" : "/retailer/bulkorder/unsuccessfulorders" },
    { text: "Bulk Order Details", link: null }
];

const findCountryName = (code) => CountryList.find(c => c.code === code).name;

const renderStatus = (status) => {
    switch (status) {
        case "Shipped":
        case "Awaiting Collection":
            return <span className="text-status-green">{status}</span>;
        case "Awaiting Fulfilment":
            return <span className="text-status-purple">{status}</span>;
        case "Fulfilment In Progress":
            return <span className="text-status-blue">{status}</span>;
        case "Unprocessed":
            return <span className="text-status-red">{status}</span>;
        default:
            return <span className="text-status-grey">{status}</span>;
    }
}

const createBulkSummary = (boxes) => {
    const summary = [];
    for (const box of boxes) {
        for (const item of box.items) {
            const foundIndex = summary.findIndex(fi => fi.companyItemID === item.companyItemID);
            if (foundIndex === -1) {
                summary.push({ companyItemID: item.companyItemID, name: item.name, quantity: item.quantity });
            } else {
                const currentItem = summary[foundIndex];
                summary[foundIndex] = { companyItemID: currentItem.companyItemID, name: currentItem.name, quantity: currentItem.quantity + item.quantity }
            }
        }
    }
    return summary;
}

const OrderBreakdown = ({ purchaseOrderNumber, createdDate, isBoxSplit, requireSeperateSKUs, requiresNoPackaging, boxSplit, totalBoxes, handleViewSplitDetailsClick, bulkOrderID, headerKey }) => {
    const summary = createBulkSummary(boxSplit.boxes);
    return (
        <section className="mt-3">
            <h6>Order Breakdown</h6>
            <p className="title">Purchase Order Number</p>
            <p>{purchaseOrderNumber}</p>
            <p className="title">Date Created</p>
            <p>{createdDate ? toLocalTimeString(createdDate) : "N/A"}</p>
            <p className="title">Bulk Order Products</p>
            <div className="mb-3">
                {summary.length ? summary.map(item =>
                    <p className="mb-1" key={item.companyItemID}>{`${item.name}: ${item.quantity}`}</p>) : null}
            </div>
            <p className="title">Split Details</p>
            <p>{requiresNoPackaging ? "No box packaging selected" : (isBoxSplit ? "Box Split" : (requireSeperateSKUs ? "SKU Split" : "Default Split"))}</p>
            <p className="title">Total Boxes</p>
            {requiresNoPackaging ? <p className="mb-0">0 Boxes</p> :
                <React.Fragment>
                    <p className="mb-0">{totalBoxes} Boxes</p>
                    <Button variant="link" className="p-0" onClick={() => handleViewSplitDetailsClick(purchaseOrderNumber, boxSplit, bulkOrderID, headerKey)}>View Split Details</Button>
                </React.Fragment>}
        </section>
    )
}

const ParcelDetails = ({ status, requireShipping, courierProvider, trackingInfo, shippingAddress, contactDetails, totalWeight }) => {
    return (
        <section className="mt-3">
            <h6>Parcel Details</h6>
            <p className="title">Status</p>
            <p>{renderStatus(status)}</p>
            <p className="title">Shipping Option</p>
            <p>{requireShipping ? "Selazar Fulfilled" : "Self Fulfilled"}</p>
            {requireShipping && <React.Fragment>
                {courierProvider &&
                    <React.Fragment>
                        <p className="title">Courier Provider</p>
                        <p>{courierProvider.courierName}, {courierProvider.service} delivery</p>
                        <p className="title">Tracking Number(s)</p>
                        <div className="btn-group-vertical mb-3">
                            {trackingInfo.map((ti, i) => <Button key={`${i}_tracking`} href={ti.url} target="_blank" rel="noopener noreferrer" variant="link">{ti.trackingNumber}</Button>)}
                        </div>
                    </React.Fragment>}
            </React.Fragment>}
            <p className="title">Shipping Address</p>
            <p className="mb-1">{shippingAddress.addressLine1}</p>
            {shippingAddress.addressLine2 && <p className="mb-1">{shippingAddress.addressLine2}</p>}
            <p className="mb-1">{shippingAddress.town}</p>
            <p className="mb-1">{shippingAddress.county}</p>
            <p className="mb-1">{findCountryName(shippingAddress.country)}</p>
            <p>{shippingAddress.postcode}</p>
            <p className="title">Contact Name</p>
            <p>{contactDetails.name}</p>
            <p className="title">Email Address</p>
            <p>{contactDetails.emailAddress}</p>
            <p className="title">Contact Number</p>
            <p>{contactDetails.contactNumber}</p>
            <p className="title">Weight</p>
            <p>{totalWeight}kg</p>
        </section>
    )
}

const CostDetails = ({ totalCost, invoiceLink }) => (
    <section className="mt-3">
        <h6>Cost Details</h6>
        <p className="title">Courier Total</p>
        <p>£{totalCost}</p>
        {invoiceLink && <Button href={invoiceLink} className="link-button" target="_blank" rel="noopener noreferrer" variant="secondary">View Invoice</Button>}
    </section>
)

class BulkOrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            warning: false,
            bulkOrderID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            headerKey: (props.location && props.location.state && props.location.state.headerKey) ? props.location.state.headerKey : "",
            bulkOrder: {}
        };
    }

    async componentDidMount() {
        await this.getBulkOrder();
        this.setState({ loading: false });
    }

    getBulkOrder = () => {
        const { bulkOrderID } = this.state;
        return GET(Endpoints.RETAILER.GET.BULK_ORDER_DETAILS + bulkOrderID)
            .then(response => response.json())
            .then(data => {
                const bulkOrder = extractData(data);
                const warningTitle = "Order Information";
                const warning = bulkOrder ? null : "We are currently processing your order. Full order details will be available within 10 minutes, please check back later.";
                this.setState({ bulkOrder: bulkOrder, warning: bulkOrder ? false : true, error: false });
            }).catch(error => {
                console.error(error);
                this.setState({ error: true, warning: false });
            });
    }

    handleViewSplitDetailsClick = (purchaseOrderNumber, boxSplit, bulkOrderID, headerKey) => {
        this.props.history.push({ pathname: "/retailer/bulkorder/splitdetails", state: { purchaseOrderNumber: purchaseOrderNumber, boxSplit: boxSplit, bulkOrderID: bulkOrderID, headerKey: headerKey } });
    }

    render() {
        const { bulkOrderID, bulkOrder, loading, warning, error, headerKey } = this.state;

        return (
            <React.Fragment>
                <Header title="Bulk Order" subNavList={BulkOrderSubNavList} activeKey={headerKey} headerClass="mb-2" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs(headerKey)} />
                        <h5>Bulk Order Details</h5>
                        <Row className="mb-4">
                            <Col sm={12} md={6}>
                                {error
                                    ? <ErrorAlert errorMessage={BULK_ORDER_ERROR_MESSAGE} />
                                    : warning
                                        ? <div className="mt-3">
                                            <WarningAlert warningTitle={BULK_ORDER_WARNING_TITLE} warningMessage={BULK_ORDER_WARNING_MESSAGE} />
                                        </div>
                                        : <React.Fragment>
                                            <OrderBreakdown {...bulkOrder} handleViewSplitDetailsClick={this.handleViewSplitDetailsClick} bulkOrderID={bulkOrderID} headerKey={headerKey} />
                                            <ParcelDetails {...bulkOrder} />
                                            <CostDetails totalCost={bulkOrder.totalCost} invoiceLink={bulkOrder.invoiceLink} />
                                        </React.Fragment>}
                            </Col>
                        </Row>
                    </Container>}
            </React.Fragment>
        )
    }
}

export default BulkOrderDetails;