import React from 'react';
import PropTypes from 'prop-types';
import { NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const MenuItem = ({ title, link, glyph, badges, spanClass = undefined }) => {
    return (
        <LinkContainer to={link} exact>
            <NavItem>
                <span className={spanClass}>{glyph}</span>{title}{badges}  
            </NavItem>
        </LinkContainer>
    );
};

MenuItem.propTypes = {
    glyph: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default MenuItem;