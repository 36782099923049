import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Endpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import { checkPermission } from '../../../Utilities';

class AdHocCollectionCard extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow  = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderButton = this.renderButton.bind(this);

        this.state = {
            show: false,
            collection: this.props.collection,
            senderAddress: { }
        };
    }

    fetchSenderAddress() {        
        return GET(`${Endpoints.GET_LOCATION}${this.props.collection.senderCompanyAddressID}/true`)
        .then(raw => raw.json())
        .then(result => {
            let data = extractData(result);
            this.setState({ senderAddress: data.address });
        }); 
    }

    renderButton() {
        let { collection } = this.state;
        return (
            <Link className="btn btn-warning" from={'/courier/collections/adhoc'} to={{ pathname: '/courier/collections/createissue', state: {collectionID: collection.id}  }}>(Pencil) Raise Issue</Link>);
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.fetchSenderAddress();
        this.setState({ show: true });
    }

    render() {
        let { collection } = this.state;
        let senderAddress = this.state.senderAddress;
        let button = checkPermission("AddIssue", this.renderButton());

        return (
            <div className="swimlane-card">
                <dl onClick={this.handleShow}>
                    <dt>Collection reference:</dt>
                    <dd onClick={this.handleShow}>{collection.courierReferenceNumber}</dd>
                    <dt>Date booked:</dt>
                    <dd>{collection.dateBookedDisplay}</dd>
                    <dt>Date collected:</dt>
                    <dd>{collection.dateCollectedDisplay}</dd>
                </dl>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Collection details: {collection.courierReferenceNumber}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <dl>
                            <dt>Sender Address:</dt>
                            <dd>{senderAddress.line1}</dd>
                            <dd>{senderAddress.line2}</dd>
                            <dd>{senderAddress.town}</dd>
                            <dd>{senderAddress.country}</dd>
                            <dd>{senderAddress.postcode}</dd>
                            <dt>Date booked:</dt>
                            <dd>{collection.dateBookedDisplay}</dd>
                            <dt>Date collected:</dt>
                            <dd>{collection.dateCollectedDisplay}</dd>
                            <dt>Status:</dt>
                            <dd>{collection.status}</dd>
                        </dl>
                        {button}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

AdHocCollectionCard.propTypes = {
    collection: PropTypes.object.isRequired
};

AdHocCollectionCard.defaultProps = {
    collection: {}
};

export default AdHocCollectionCard;