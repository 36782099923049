import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './Integrations.scss';

class EditWooCommerceIntegration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            secretInputType: 'password',
            secretInputIcon: faEye,
            secretError: '',
            storeUrlError: '',
            storeNameError: '',
            keyError: '',
            keyInputType: 'password',
            keyInputIcon: faEye
        }

        this.handleShowHideKey = this.handleShowHideKey.bind(this);
        this.handleShowHideSecret = this.handleShowHideSecret.bind(this);
        this.validate = this.validate.bind(this);
    }

    validate(integration) {
        let isValid = true;

        integration.integrationType = "WooCommerce";

        if (!integration.shopName || integration.shopName === '') {
            isValid = false;
            this.setState({ storeNameError: 'Store Name is required' });
        }
        if (!integration.storeUrl || integration.storeUrl === '') {
            isValid = false;
            this.setState({ storeUrlError: 'Store URL is required' });
        }
        if (!integration.key || integration.key === '') {
            isValid = false;
            this.setState({ keyError: 'Consumer Key is required' });
        }
        if (!integration.secret || integration.secret === '') {
            isValid = false;
            this.setState({ secretError: 'Consumer Secret is required' });
        }

        if (isValid) {
            this.props.handleSubmit(integration);
        }
    }

    handleShowHideSecret = () => {
        const { secretInputType } = this.state;

        secretInputType === "password" ?
            this.setState({ secretInputType: 'text', secretInputIcon: faEyeSlash }) :
            this.setState({ secretInputType: 'password', secretInputIcon: faEye });
    }

    handleShowHideKey = () => {
        const { keyInputType } = this.state;

        keyInputType === "password" ?
            this.setState({ keyInputType: 'text', keyInputIcon: faEyeSlash }) :
            this.setState({ keyInputType: 'password', keyInputIcon: faEye });
    }

    render() {
        const { keyInputType, keyInputIcon, keyError, secretError, secretInputType, secretInputIcon, storeUrlError, storeNameError } = this.state;
        const { integration, isError, isSuccess, showBack, setStep, handleInputChange } = this.props;

        return (
            <React.Fragment>
                {!isSuccess ? <React.Fragment>
                    <p><strong>WooCommerce Credentials</strong></p>
                    <p>Please enter the following information about your WooCommerce account to allow us to set up your integration.</p>

                    {isError &&
                        <Card className="card-danger mb-3">
                            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                                There seems to be a problem
                            </Card.Title>
                            <Card.Body>
                                We could not validate your credentials with your provider. Please check the details below and try again.
                            </Card.Body>
                        </Card>}

                    <Form onSubmit={this.handleSubmit}>
                        <fieldset>
                            <FormGroup>
                                <FormLabel htmlFor="shopName">Store Name</FormLabel>
                                <FormControl id="shopName" type="text" name="shopName" value={integration.shopName} onChange={handleInputChange} />
                                <span className="text-danger">{storeNameError}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="storeUrl">Store URL</FormLabel>
                                <FormControl id="storeUrl" type="text" name="storeUrl" value={integration.storeUrl} onChange={handleInputChange} />
                                <span className="text-danger">{storeUrlError}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="key">Consumer Key</FormLabel>
                                <FormControl id="key" type={keyInputType} name="key" value={integration.key} onChange={handleInputChange} />
                                <span className="form-password-icon" onClick={this.handleShowHideKey}><FontAwesomeIcon icon={keyInputIcon} /></span>
                                <span className="text-danger">{keyError}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="secret">Consumer Secret</FormLabel>
                                <FormControl id="secret" type={secretInputType} name="secret" value={integration.secret} onChange={handleInputChange} />
                                <span className="form-password-icon" onClick={this.handleShowHideSecret}><FontAwesomeIcon icon={secretInputIcon} /></span>
                                <span className="text-danger">{secretError}</span>
                            </FormGroup>
                            <p className="title">Do you want this integration to be connected now?</p>
                            <div className="form-input-description">
                                <p>You can connect and disconnect your integration at any stage. Please note until this integration is connected it will not work.</p>
                            </div>
                            <FormGroup className="custom-control custom-radio mb-2">
                                <input id="connected" className="custom-control-input" name="connected" type="radio" checked={integration.connected === true} onChange={handleInputChange} />
                                <FormLabel className="custom-control-label" htmlFor="connected">Yes, connect now</FormLabel>
                            </FormGroup>
                            <FormGroup className="custom-control custom-radio mb-4">
                                <input id="disconnected" className="custom-control-input" name="disconnected" type="radio" checked={integration.connected === false} onChange={handleInputChange} />
                                <FormLabel className="custom-control-label" htmlFor="disconnected">No, do not connect now</FormLabel>
                            </FormGroup>

                            {/* Inventory Management */}
                            {integration.connected ?
                                <FormGroup>
                                    <p className="title">Do you want to allow for WooCommerce to use inventory levels from Selazar?</p>
                                    <div className="form-input-description">
                                        <p>Selazar inventory levels will not be used by this WooCommerce store until you have selected it per product within WooCommerce.</p>
                                    </div>
                                    <FormGroup className="custom-control custom-radio mb-2">
                                        <input id="inventoryManaged" className="custom-control-input" name="inventoryManaged" type="radio" checked={integration.inventoryManagement} onChange={handleInputChange} />
                                        <FormLabel className="custom-control-label" htmlFor="inventoryManaged">Yes, allow Selazar inventory management</FormLabel>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-radio mb-4">
                                        <input id="inventoryUnmanaged" className="custom-control-input" name="inventoryUnmanaged" type="radio" checked={!integration.inventoryManagement} onChange={handleInputChange} />
                                        <FormLabel className="custom-control-label" htmlFor="inventoryUnmanaged">No, do not allow Selazar inventory management</FormLabel>
                                    </FormGroup>
                                </FormGroup> : null}

                        </fieldset>
                        {
                            showBack &&
                            <Button className="link-button float-left" variant="secondary" size="sm" onClick={() => setStep('back')}>Back</Button>
                        }

                        <Button variant="primary" className="float-right" onClick={() => this.validate(integration)}>Validate Credentials</Button>
                        <Button variant="link" className="float-right" onClick={() => setStep('home')}>Cancel</Button>
                    </Form>
                </React.Fragment>
                    :
                    <React.Fragment>
                        <Card className="card-success mb-3">
                            <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
                            <Card.Body>
                                {integration.connected ? "You have successfully connected your WooCommerce store to Selazar." : "You have successfully saved your integration."}
                            </Card.Body>
                        </Card>

                        <Button className="btn btn-primary float-right" onClick={() => this.props.setStep('finish')}>Return to Integrations</Button>
                    </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default EditWooCommerceIntegration;