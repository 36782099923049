import React from 'react';
import { Container, Col, Button, Card } from 'react-bootstrap';
import { checkPermission } from '../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const NO_BANK_ACCOUNT_MESSAGE = "You have not setup a direct debit. Please be aware that currently we can only accept UK bank accounts.";

const NoDirectDebit = ({ onLoadForm }) =>
    <Container fluid>
        <Col sm="12" md="6" lg="4" className="mt-4">
            <Card className="card-warning mb-3">
                <Card.Title><FontAwesomeIcon icon={faExclamationTriangle} />No Direct Debit Linked</Card.Title>
                <Card.Body><p>{NO_BANK_ACCOUNT_MESSAGE}</p></Card.Body>
            </Card>
            {checkPermission('NewCard',<Button variant="primary" className="float-right mt-3" type="submit" onClick={onLoadForm}>Setup Direct Debit</Button>)}
        </Col>
    </Container>

export default NoDirectDebit;
