import React from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';
import { CountryList } from '../../../../common/Constants';
import { WarningAlert } from '../../../../common/Alert';

const COMMODITY_CODE_DESC = <p>This information is required by customs, and should be 8-10 characters. If you do not know your commodity code you can find it at <a target="_blank" rel="noopener noreferrer" href="https://www.gov.uk/trade-tariff">gov.uk</a></p>;
const PFP_TITLE = "Product Size Information";
const PFP_INFO = "We have detected you are uploading a large product. These products will be kept on pallets and picked directly from them, providing you with more cost-effective storage.";

const ProductDetails = ({ companyItem, handleInputChange, productDetailsValidation, formatInput }) =>
    <React.Fragment>
        <h5>Product Details</h5>
        <FormGroup as={Row}>
            <Col sm={12} md={4}>
                <FormLabel htmlFor="heightField">Product Height (cm)</FormLabel>
                <FormControl id="heightField" type="number" parent="item" name="height" value={companyItem.item.height} onChange={handleInputChange} min="0" step="0.01" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{productDetailsValidation.height.message}</span>
            </Col>
        </FormGroup>
        <FormGroup as={Row}>
            <Col sm={12} md={4}>
                <FormLabel htmlFor="widthField">Product Width (cm)</FormLabel>
                <FormControl id="widthField" type="number" parent="item" name="width" value={companyItem.item.width} onChange={handleInputChange} min="0" step="0.01" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{productDetailsValidation.width.message}</span>
            </Col>
        </FormGroup>
        <FormGroup as={Row}>
            <Col sm={12} md={4}>
                <FormLabel htmlFor="depthField">Product Depth (cm)</FormLabel>
                <FormControl id="depthField" type="number" parent="item" name="depth" value={companyItem.item.depth} onChange={handleInputChange} min="0" step="0.01" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{productDetailsValidation.depth.message}</span>
            </Col>
        </FormGroup>
        <FormGroup as={Row}>
            <Col sm={12} md={4}>
                <FormLabel htmlFor="weightField">Product Weight (kg)</FormLabel>
                <FormControl id="weightField" type="number" parent="item" name="weight" value={companyItem.item.weight} onChange={handleInputChange} min="0" step="0.01" />
            </Col>
            <Col md={12}>
                <span className="text-danger">{productDetailsValidation.weight.message}</span>
            </Col>
        </FormGroup>
        {companyItem.pickFromPallet && <WarningAlert warningTitle={PFP_TITLE} warningMessage={PFP_INFO} />}
        <FormGroup>
            <FormLabel htmlFor="countryOfManufactureField">Country of Manufacturing</FormLabel>
            <FormControl id="countryOfManufactureField" name="countryCodeOfManufacture" parent="item" as="select" value={companyItem.item.countryCodeOfManufacture} onChange={handleInputChange}>
                <option key="" value="">Please select an option</option>;
                                    {CountryList.map((v, i) => <option key={i} value={v.code}>{v.name}</option>)}
            </FormControl>
            <span className="text-danger">{productDetailsValidation.countryCodeOfManufacture.message}</span>
        </FormGroup>
        <FormGroup as={Row}>
            <Col md={12}>
                <FormLabel htmlFor="commodityCodeField">Commodity Code</FormLabel>
                <div className="form-input-description">
                    {COMMODITY_CODE_DESC}
                </div>
            </Col>
            <Col sm={12} md={4}>
                <FormControl id="commodityCodeField" type="text" name="commodityCode" parent="item" value={companyItem.item.commodityCode} onChange={handleInputChange} onBlur={formatInput} />
            </Col>
            <Col md={12}>
                <span className="text-danger">{productDetailsValidation.commodityCode.message}</span>
            </Col>
        </FormGroup>
    </React.Fragment>

export default ProductDetails;