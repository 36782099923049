import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import DashboardCard from '../common/DashboardCard';
import { checkPermission } from '../../Utilities';
import Endpoints from '../common/Endpoints';
import { GET, extractData } from '../../Consumer';

export class AdminDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recent_issues: [],
            outstanding_issues: [],
            most_common_type:'',
            assigned_to_me:'Loading...',
            raised_by_count:'Loading...',
            resolved_count:'Loading...',
            pending_orders:'Loading...',
            total_orders:'Loading...',
            total_orders_this_month:'Loading...',
            total_orders_today:'Loading...',
            total_orders_value:'Loading...',
            total_orders_value_this_month:'Loading...',
            total_orders_value_today:'Loading...',
            loading:true
        };
    }

    componentDidMount() {

        checkPermission("ViewIssue",Promise.all([
            this.getMostRecentIssues(),
            this.getOutstandingIssues(),
            this.getMostCommonIssueType(),
            this.getIssuesAssignedToMeCount(),
            this.getIssuesRaisedCount(),
            this.getIssuesResolvedCount()      
        ]));

        checkPermission("ViewManagerDashboard",Promise.all([
            this.getTotalOrderCount(),
            this.getOrderCountThisMonth(),
            this.getOrderCountToday(),
            this.getPendingOrderCount()
        ]));

        checkPermission("ViewAdminDashboard",Promise.all([
            this.getTotalOrderCount(),
            this.getOrderCountThisMonth(),
            this.getOrderCountToday(),
            this.getPendingOrderCount(),
            this.getTotalOrderValue(),
            this.getOrderValueThisMonth(),
            this.getOrderValueToday(),
        ]));
    }

    getMostCommonIssueType = () => {
        const url = Endpoints.SELAZAR.ISSUES.GET.MOST_COMMON_TYPE;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ most_common_type: extractData(result), loading: false });
                }
                else {
                    this.setState({ most_common_type: "No Data", loading: false });
                }
            });
    }

    getMostRecentIssues = () => {
        let url = Endpoints.SELAZAR.ISSUES.GET.RAISED_AGAINST;

        const filter = {unresolved: true, startRecord: 0, endRecord: 4}

        if (filter) {
            url = new URL(url);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }
        
        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ recent_issues: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    getOutstandingIssues = () => {
        let url = Endpoints.SELAZAR.ISSUES.GET.RAISED_AGAINST;

        const filter = {unresolved: true, startRecord: 0, endRecord: 4, orderDescending: true}

        if (filter) {
            url = new URL(url);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }
        
        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ outstanding_issues: extractData(result), loading: false });
                }
                else {
                    this.setState({ outstanding_issues: 0, loading: false });
                }
            });
    }

    getIssuesAssignedToMeCount = () => {
        const url = Endpoints.SELAZAR.ISSUES.GET.ASSIGNED_TO_ME_COUNT;
        
        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ assigned_to_me: extractData(result), loading: false });
                }
                else {
                    this.setState({ assigned_to_me: 0, loading: false });
                }
            });
    }

    getIssuesRaisedCount = () => {
        const url = Endpoints.SELAZAR.ISSUES.GET.RAISED_BY_COUNT;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ raised_by_count: extractData(result), loading: false });
                }
                else {
                    this.setState({ raised_by_count: 0, loading: false });
                }
            });
    }

    getIssuesResolvedCount = () => {
        let url = Endpoints.SELAZAR.ISSUES.GET.RAISED_AGAINST_COUNT;

        const filter = {resolved: true}

        if (filter) {
            url = new URL(url);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ resolved_count: extractData(result), loading: false });
                }
                else {
                    this.setState({ resolved_count: 0, loading: false });
                }
            });
    }

    getPendingOrderCount = () => {
        const url = Endpoints.SELAZAR.ORDERS.GET.PENDING_ORDERS_COUNT;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ pending_orders: extractData(result), loading: false });
                }
                else {
                    this.setState({ pending_orders: 0, loading: false });
                }
            });
    }

    getTotalOrderCount = () => {
        const url = Endpoints.SELAZAR.ORDERS.GET.TOTAL_ORDERS_COUNT;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ total_orders: extractData(result), loading: false });
                }
                else {
                    this.setState({ total_orders: 0, loading: false });
                }
            });
    }

    getOrderCountThisMonth = () => {
        const url = Endpoints.SELAZAR.ORDERS.GET.ORDERS_COUNT_THIS_MONTH;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ total_orders_this_month: extractData(result), loading: false });
                }
                else {
                    this.setState({ total_orders_this_month: 0, loading: false });
                }
            });
    }

    getOrderCountToday = () => {
        const url = Endpoints.SELAZAR.ORDERS.GET.ORDERS_COUNT_TODAY;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ total_orders_today: extractData(result), loading: false });
                }
                else {
                    this.setState({ total_orders_today: 0, loading: false });
                }
            });
    }

    getTotalOrderValue = () => {
        const url = Endpoints.SELAZAR.ORDERS.GET.TOTAL_ORDERS_VALUE;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ total_orders_value: "£" + extractData(result), loading: false });
                }
                else {
                    this.setState({ total_orders_value: "£" + 0, loading: false });
                }
            });
    }

    getOrderValueThisMonth = () => {
        const url = Endpoints.SELAZAR.ORDERS.GET.ORDERS_VALUE_THIS_MONTH;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ total_orders_value_this_month: "£" + extractData(result), loading: false });
                }
                else {
                    this.setState({ total_orders_value_this_month: "£" + 0, loading: false });
                }
            });
    }

    getOrderValueToday = () => {
        const url = Endpoints.SELAZAR.ORDERS.GET.ORDERS_VALUE_TODAY;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ total_orders_value_today: "£" + extractData(result), loading: false });
                }
                else {
                    this.setState({ total_orders_value_today: "£" + 0, loading: false });
                }
            });
    }


    render() {

        const orders = checkPermission("ViewOrder",
            <React.Fragment>
            <h3>Orders</h3>
                <Container>
                    <Row>
                        <Col md={3}>
                                <DashboardCard title="Pending Orders" value={this.state.pending_orders} link={""} />
                        </Col>
                        <Col md={3}>
                                <DashboardCard title="Orders Today" value={this.state.total_orders_today} link={""} />
                        </Col>
                        <Col md={3}>
                            <DashboardCard title="Orders This Month" value={this.state.total_orders_this_month} link={""} />
                        </Col>
                        <Col md={3}>
                            <DashboardCard title="Total Orders" value={this.state.total_orders} link={""} />
                        </Col>
                    </Row>
            </Container>
     </React.Fragment>
     );

     const orders_value = checkPermission("ViewOrder",
     <React.Fragment>
     <h3>Orders Value</h3>
     <Container>
         <Row>
         <Col md={4}>
                 <DashboardCard title="Orders Value Today" value={this.state.total_orders_value_today} link={""} />
          </Col>
          <Col md={4}>
              <DashboardCard title="Orders Value This Month" value={this.state.total_orders_value_this_month} link={""} />
          </Col>
          <Col md={4}>
              <DashboardCard title="Total Orders Value" value={this.state.total_orders_value} link={""} />
          </Col>
      </Row>
  </Container>
</React.Fragment>
);

        const issues = checkPermission("ViewIssue",<React.Fragment>
            <h3>Issues</h3>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className="card">
                        <h3>Most Recent Issues</h3>
                        {this.state.recent_issues.length ? this.state.recent_issues.map(r => <div><p>{r.title}</p></div> ) : <div>No issues to show.</div>}
                        </div>
                    </Col>
                    <Col md={6}>
                    <div className="card">
                        <h3>Outstanding Issues</h3>
                        {this.state.outstanding_issues.length ? this.state.outstanding_issues.map(r => <div><p>{r.title}</p></div> ) : <div>No issues to show.</div>}
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                    <div className="card">
                        <h3>Most Common Issue Type</h3>
                        {this.state.most_common_type ? <p>{this.state.most_common_type.areaOfConcernType.name}-  {this.state.most_common_type.name}</p>: <div>No data</div>}
                    </div>
                    </Col>
                    <Col md={2}> <DashboardCard title="Raised" value={this.state.raised_by_count} link={"/issues"} /></Col>
                    <Col md={2}> <DashboardCard title="Resolved" value={this.state.resolved_count} link={"/issues"} /></Col>
                    <Col md={4}> <DashboardCard title="Assigned to Me" value={this.state.assigned_to_me} link={"/issues"} /></Col>
                    
                </Row>
            </Container>
        </React.Fragment>);     


        const common = <React.Fragment>
            <h2>Common</h2>
            {issues}
        </React.Fragment>;

        const customer = checkPermission("ViewCustomerCareDashboard", 
        <React.Fragment>
            <h2>Customer Care</h2>
            <Container>
                <Row>
                    <Col>
                        <DashboardCard title="Dashboard item" value={99} link={"/"} />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
        );

        const management = checkPermission("ViewManagerDashboard", 
        <React.Fragment>
            <h2>Management</h2>
            {orders}
        </React.Fragment>
        );

        const admin = checkPermission("ViewAdminDashboard", 
        <React.Fragment>
            <h2>Admin</h2>
            {orders}
            {orders_value}
        </React.Fragment>
        );

        return (
            <React.Fragment>
                <h1>Selazar Dashboard</h1>
                {common}
                {customer}
                {management}
                {admin}
            </React.Fragment>
        );
    }
}
