import React, { Component } from 'react';
import LoadingBar from '../../common/LoadingBar';
import { Form, FormGroup, FormLabel, FormControl, Col, FormCheck } from 'react-bootstrap';
import { GET, POST, PUT, extractData } from '../../../Consumer';
import { isNullOrEmptyGuid, defaultGuid } from '../../../Utilities';
import Endpoints from '../../common/Endpoints';
import update from 'immutability-helper';

class EditLocation extends Component {

    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            errors: [],            
            addressTypes: [],
            companyAddress:
            {
                id: defaultGuid,
                addressID: defaultGuid,
                companyID: user.companyID,
                address: {
                    id: defaultGuid,
                    line1: "",
                    line2: "",
                    town: "",
                    country: "",
                    postcode: "",
                    latitude: 0.0,
                    longitude: 0.0
                },
                addressTypeID: defaultGuid
            },
            openingHours: {
                0: {},
                1: {},
                2: {},
                3: {},
                4: {},
                5: {},
                6: {}
            },
            servicesOfferedOptions: [],
            servicesOffered: [],
            loading: false
        };

        this.handleSubmit                = this.handleSubmit.bind(this);
        this.handleInputChange           = this.handleInputChange.bind(this);
        this.getAddressTypes             = this.getAddressTypes.bind(this);
        this.getAddress                  = this.getAddress.bind(this);
        this.getServicesOfferedOptions   = this.getServicesOfferedOptions.bind(this);
        this.renderForm                  = this.renderForm.bind(this);
        this.handleOpeningTime           = this.handleOpeningTime.bind(this);
        this.handleClosingTime           = this.handleClosingTime.bind(this);
        this.handleServicesOfferedChange = this.handleServicesOfferedChange.bind(this);
    }

    componentDidMount() {
        this.getAddressTypes();
        this.getAddress();
        this.getServicesOfferedOptions();
    }

    getAddressTypes() {
        return GET(Endpoints.ALL_ADDRESS_TYPES)
            .then(response => response.json())
            .then(data => {
                this.setState({ addressTypes: extractData(data) });
            });
    }

    getServicesOfferedOptions() {
        return GET(Endpoints.GET_ADDRESS_SERVICES)
            .then(response => response.json())
            .then(({ data }) => {
                this.setState({ servicesOfferedOptions: data });
            });
    }

    getAddress() {
        const { companyAddressID } = this.props.location.state;

        if (!isNullOrEmptyGuid(companyAddressID)) {
            this.setState({ loading: true });
            return GET(Endpoints.GET_LOCATION + companyAddressID + "/" + true)
                .then(response => response.json())
                .then(data => {
                    let companyAddress = extractData(data);
                    
                    let openingHours  = companyAddress.operatingDetails.openingHours.reduce((obj, item) => {
                        obj[item.day] = item;
                        return obj;
                    }, {});

                    let servicesOffered  = companyAddress.operatingDetails.servicesOffered;

                    this.setState({ 
                        companyAddress: companyAddress, 
                        openingHours: openingHours,
                        servicesOffered: servicesOffered,
                        loading: false 
                    });
                });
        }
    }

    handleInputChange(e) {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.companyAddress[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            companyAddress: {
                ...prevState.companyAddress,
                ...{ [name]: value }
            }
        }));
    }

    handleServicesOfferedChange(e) {
        let { value } = e.target;
        
        let currService = this.state.servicesOffered.indexOf(value);
        if (currService > -1) { 
            this.setState(prevState => ({ 
                servicesOffered: [
                    ...prevState.servicesOffered.slice(0, currService), ...prevState.servicesOffered.slice(currService + 1)
                ] 
            }));
        }
        else {
            let newServices = this.state.servicesOffered;
            newServices.push(value);
            this.setState({ servicesOffered: newServices });
        }
    }

    handleOpeningTime(e) {
        let { value } = e.target;
        let { day } = e.target.attributes;

        let openingHours = this.state.openingHours;
        openingHours = update(openingHours, { [day.value]: { openingTime: { $set: value }} });

        this.setState(prevState => ({
            ...prevState,
            openingHours: openingHours
        }));
    }

    handleClosingTime(e) {
        let { value } = e.target;
        let { day } = e.target.attributes;

        let openingHours = this.state.openingHours;
        openingHours = update(openingHours, { [day.value]: { closingTime: { $set: value }} });

        this.setState(prevState => ({
            ...prevState,
            openingHours: openingHours
        }));
    }

    handleSubmit(event) {
        event.preventDefault();
        let { companyAddress, openingHours, servicesOffered } = this.state;

        this.setState({ loading: true });

        let mappedHours = Object.keys(openingHours).map((o, i) => ( { day: i, ...openingHours[o] }));
        companyAddress = Object.assign(companyAddress, { operatingDetails: { openingHours: mappedHours } });
        companyAddress.operatingDetails.servicesOffered = servicesOffered;

        if (!isNullOrEmptyGuid(companyAddress.id)) {
            return PUT(Endpoints.UPDATE_LOCATION, companyAddress)
                .then(response => response.json())
                .then(e => {
                    if (e.error === false) {
                        this.props.history.push('/company/locations');
                    }
                    else if (typeof e === "object") {
                        let arrays = Object.keys(e).map(k => e[k]);
                        let errors = [].concat.apply([], arrays);
                        this.setState({ errors:  errors, loading: false });
                    }
                });
        }
        else {
            return POST(Endpoints.CREATE_LOCATION, companyAddress)
                .then(response => response.json())
                .then(e => {
                    if (e.error === false) {
                        this.props.history.push('/company/locations');
                    }
                    else if (typeof e === "object") {
                        let arrays = Object.keys(e).map(k => e[k]);
                        let errors = [].concat.apply([], arrays);
                        this.setState({ errors:  errors, loading: false });
                    }
                });
        }
    }

    renderForm(companyAddress) {
        let { loading, errors } = this.state;
        const { addressTypes } = this.state;

        return (
            <div>
                {errors.length > 0 && <div className="alert alert-danger">
                        <span>An error occurred while submitted your changes, please see below for further information:</span>
                        <ul>
                            {errors.map((e, i) => <li key={i}>{e}</li>)}
                        </ul>
                </div>}
                <Form horizontal onSubmit={this.handleSubmit}>
                    <input type="hidden" name="id" value={companyAddress.id} />
                    <input type="hidden" name="addressID" value={companyAddress.addressID} />
                    <input type="hidden" name="companyID" value={companyAddress.companyID} />
                    <FormGroup>
                        <FormLabel htmlFor="addressTypeField">Address Type</FormLabel>
                        <FormControl disabled={loading} id="addressTypeField" name="addressTypeID" componentClass="select" value={companyAddress.addressTypeID} onChange={this.handleInputChange}>
                            <option key="" value="">Please Select...</option>;
                            {addressTypes.map(addressType => {
                                return <option key={addressType.id} value={addressType.id}>{addressType.type}</option>;
                            })}
                        </FormControl>
                    </FormGroup>

                    <h3>Address</h3>

                    <FormGroup>
                        <FormLabel htmlFor="line1Field">Line 1</FormLabel>
                        <FormControl disabled={loading} id="line1Field" type="text" name="line1" parent="address" value={companyAddress.address.line1} onChange={this.handleInputChange} />                        
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="line2Field">Line 2</FormLabel>
                        <FormControl disabled={loading} id="line2Field" type="text" name="line2" parent="address" value={companyAddress.address.line2} onChange={this.handleInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="townField">Town</FormLabel>
                        <FormControl disabled={loading} id="townField" type="text" name="town" parent="address" value={companyAddress.address.town} onChange={this.handleInputChange} />                        
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="countryField">Country</FormLabel>
                        <FormControl disabled={loading} id="countryField" type="text" name="country" parent="address" value={companyAddress.address.country} onChange={this.handleInputChange} />                        
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="postcodeField">Postcode</FormLabel>
                        <FormControl disabled={loading} id="postcodeField" type="text" name="postcode" parent="address" value={companyAddress.address.postcode} onChange={this.handleInputChange} />                        
                    </FormGroup>

                    <fieldset>
                        <legend>Opening hours</legend>
                        <OpeningHours label="Sunday" day="0" openHandler={this.handleOpeningTime} closeHandler={this.handleClosingTime} {...this.state.openingHours[0]} />  
                        <OpeningHours label="Monday" day="1" openHandler={this.handleOpeningTime} closeHandler={this.handleClosingTime} {...this.state.openingHours[1]} />
                        <OpeningHours label="Tuesday" day="2" openHandler={this.handleOpeningTime} closeHandler={this.handleClosingTime} {...this.state.openingHours[2]} />
                        <OpeningHours label="Wednesday" day="3" openHandler={this.handleOpeningTime} closeHandler={this.handleClosingTime} {...this.state.openingHours[3]} />
                        <OpeningHours label="Thursday" day="4" openHandler={this.handleOpeningTime} closeHandler={this.handleClosingTime} {...this.state.openingHours[4]} />
                        <OpeningHours label="Friday" day="5" openHandler={this.handleOpeningTime} closeHandler={this.handleClosingTime} {...this.state.openingHours[5]} />
                        <OpeningHours label="Saturday" day="6" openHandler={this.handleOpeningTime} closeHandler={this.handleClosingTime} {...this.state.openingHours[6]} />                                              
                    </fieldset>
                    {this.state.servicesOfferedOptions.length > 0 ?
                    <fieldset>
                        <legend>Services Offered</legend>
                        <FormGroup>
                            {this.state.servicesOfferedOptions.map((r) => 
                                <Col key={r} sm={10}>
                                    <FormCheck name="servicesOffered" checked={this.state.servicesOffered.indexOf(r) > -1} value={r} onChange={this.handleServicesOfferedChange}>{r}</FormCheck>
                                </Col>
                            )}
                        </FormGroup>
                    </fieldset> : null
                    }

                    <div className="margin-bottom">
                        <input className="btn btn-primary" type="submit" disabled={loading} value="Submit" />
                    </div>
                </Form>
            </div>
        );
    }

    render() {

        const { companyAddress, loading } = this.state;
        let contents = this.renderForm(companyAddress);

        return (
            <div>
                {loading && <LoadingBar />}
                <h1>Location</h1>
                {contents}
            </div>
        );
    }

}

export default EditLocation;

const OpeningHours = ({label, day, openHandler, closeHandler, openingTime, closingTime}) => {
    return (
    <FormGroup>
        <Col sm={2} componentClass={FormLabel}>{label}</Col>
        <Col sm={3}><FormControl placeholder="HH:MM" type="time" day={day} name="open" onChange={openHandler} value={openingTime} /></Col>
        <Col sm={1} componentClass={FormLabel}>to:</Col>
        <Col sm={3}><FormControl placeholder="HH:MM" type="time" day={day} name="close" onChange={closeHandler} value={closingTime} /></Col>
    </FormGroup>);
};