import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import { defaultGuid, INTERACTION_SOURCE } from '../../../Utilities';
import { GET, POST, extractData } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';

class CreateOrderIssue extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'title',
                method: 'isEmpty',
                validWhen: false,
                message: 'Title is required'
            },
            {
                field: 'issueTypeID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Issue type is required'
            }
        ]
        );

        let user = JSON.parse(localStorage.getItem('user'));

        let { orderID } = this.props.location.state;

        this.state = {
            issue: {
                id: defaultGuid,
                title: "",
                dateRead: null,
                issueTypeID: "",
                interactionSourceID: INTERACTION_SOURCE.WEB,
                raisedByID: user.companyID,
                raisedByUserID: user.id,
                raisedAgainstID: "",
                entityID: this.props.location.state.orderID
            },
            issueTypes: [],
            validation: this.validator.valid(),
            loading: true,
            orderID: orderID
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getIssueTypes = this.getIssueTypes.bind(this);
        this.getRaisedAgainstCompanyIDByOrderID = this.getRaisedAgainstCompanyIDByOrderID.bind(this);

    }

    componentDidMount() {
        Promise.all([this.getIssueTypes(), this.getRaisedAgainstCompanyIDByOrderID()]);
    }

    getIssueTypes() {
        return GET(Endpoints.ISSUE_TYPES.GET.ALL)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ issueTypes: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    getRaisedAgainstCompanyIDByOrderID() {
        let { orderID } = this.state;
        return GET(Endpoints.ORDERS.GET.ORDER_COLLECTION_PROVIDER_ID_BY_ORDER_ID + orderID)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState(prevState => ({
                        issue: {
                            ...prevState.issue,
                            raisedAgainstID: extractData(result)
                        },
                        loading: false
                    }));
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    handleInputChange(e) {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.issue[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            issue: {
                ...prevState.issue,
                ...{ [name]: value }
            }
        }));


    }

    handleSubmit(event) {
        event.preventDefault();
        const { issue } = this.state;

        const validation = this.validator.validate(issue);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (validation.isValid) {
            POST(Endpoints.ISSUES.POST.CREATE, issue)
                .then(response => response.json())
                .then(result => {
                    if (!result.error) {
                        this.setState({ loading: false });
                        this.props.history.push('/company/issues/list/true');
                    }
                    else {
                        alert("An error occurred: " + result.message);
                        this.setState({ loading: false });
                        this.props.history.push('/company/issues/list/true');
                    }
                });
        }
    }

  

    renderForm(issue) {
        let validation = this.submitted ?
            this.validator.validate(issue) :
            this.state.validation;

        let { loading } = this.state;
        const { issueTypes } = this.state;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>

                    <FormGroup>
                        <FormLabel htmlFor="titleField">Title</FormLabel>
                        <FormControl disabled={loading} id="titleField" type="text" name="title" value={issue.title} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.title.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="issueTypeField">Issue Types</FormLabel>
                        <FormControl disabled={loading} id="issueTypeField" name="issueTypeID" componentClass="select" value={issue.issueTypeID} onChange={this.handleInputChange}>
                            <option key={0} value={0}>Please Select...</option>;
                            {issueTypes.map(issueType => {
                                return <option key={issueType.id} value={issueType.id}>{issueType.name} [{issueType.areaOfConcernType.name}]</option>;
                            })}
                        </FormControl>
                        <span className="text-danger">{validation.issueTypeID.message}</span>
                    </FormGroup>

                    <div className="margin-bottom">
                        <input className="btn btn-primary" type="submit" disabled={loading} value="Submit" />
                    </div>
                </Form>
            </div>
        );
    }

    render() {
        const { issue, loading } = this.state;
        let contents = this.renderForm(issue);

        return (
            <div>
                {loading && <LoadingBar />}
                <h1>Create Issue</h1>
                {contents}
            </div>
        );
    }
}

export default CreateOrderIssue;