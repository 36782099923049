import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import { checkPermission } from '../../../Utilities';
import { GET, extractData } from '../../../Consumer';
import ServiceCard from './ServiceCard';
import { InfiniteScroll } from '../../common/InfiniteScroll';
import ServiceFilter from './ServiceFilter';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyServices: [],
            loading: true,
            filter: {
                fromServiceRegionID: null,
                toServiceRegionID: null,
                serviceTime: null,
                liquid: false,
                batteries: false,
                tracked: false,
                signed: false,
                startRecord: 0,
                endRecord: 10
            }
        };

        this.getCompanyServices = this.getCompanyServices.bind(this);
        this.renderCards = this.renderCards.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentDidMount() {
        this.getCompanyServices();
    }

    getCompanyServices() {
        let user = JSON.parse(localStorage.getItem('user'));

        let filter = { ...this.state.filter };

        let url = Endpoints.GET_ALL_SERVICES + user.companyID;
        let { startRecord, endRecord, fromServiceRegionID, toServiceRegionID, serviceTime, liquid, batteries, tracked, signed } = this.state.filter;


        if (startRecord || endRecord || fromServiceRegionID || toServiceRegionID || serviceTime || liquid || batteries || tracked || signed) {
            url = new URL(url);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState(prevState => ({
                        companyServices: prevState.companyServices.concat(extractData(result)),
                        filter: {
                            ...prevState.filter,
                            startRecord: startRecord + 10,
                            endRecord: endRecord + 10
                        },
                        loading: false
                    }));
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    renderCards(companyServices) {
        let containsItems = companyServices.length > 0;
        if (containsItems) {
            return (
                <Row className="margin-bottom display-flex">
                    {companyServices.map(companyService => {
                        return <Col sm={4} md={3} key={companyService.id}><ServiceCard reload={this.getCompanyServices} companyService={companyService} /></Col>;
                    })}
                </Row>
            );
        }
        else {
            return (
                <Row className="margin-bottom">
                    <p>No Services</p>
                </Row>
            );
        }
    }

    renderButton() {
        return (
                <Link className="btn btn-success" from={'/courier/services'} to={{ pathname: '/courier/services/edit', state: { companyServiceID: 0 } }}>(+) Add Service</Link>);
    }

    onFilterChange(e) {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));


    }

    onClear() {
        this.setState({
            companyServices: [],
            filter: {
                fromServiceRegionID: null,
                toServiceRegionID: null,
                serviceTime: null,
                liquid: false,
                batteries: false,
                tracked: false,
                signed: false,
                startRecord: 0,
                endRecord: 10
            }
        }, this.getCompanyServices);
    }

    onSearch(event) {
        event.preventDefault();
        this.setState(prevState => ({
            companyServices: [],
            filter: {
                ...prevState.filter,
                startRecord: 0,
                endRecord: 10
            },
            loading: true
        }), this.getCompanyServices);
    }

    render() {
        let contents = this.state.loading
            ? <LoadingBar />
            : this.renderCards(this.state.companyServices);

        let button = checkPermission("AddCompanyService", this.renderButton());

        return (
            <InfiniteScroll onBottomDetected={this.getCompanyServices} loading={this.state.loading}>
                <Container fluid>

                    <Row className="margin-bottom">
                        <Col md={10}> <h2>Services</h2></Col>
                        <Col md={2}> <h2>{button}</h2></Col>

                    </Row>
                    <Row>
                        <ServiceFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} loading={this.state.loading}/>
                    </Row>
                    {contents}


                </Container>
            </InfiniteScroll>
        );
    }
}

export default Services;