import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import './Confirm.scss';

class Confirm extends Component {

    render() {
        const { title, text, buttonText, buttonVariant, handleConfirmClose, handleConfirmAction, show = true, cancelText = "Cancel", size = "", showCloseButton = true } = this.props;

        return (
            <Modal size={size} centered show={show} onHide={handleConfirmClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{text}</p>
                </Modal.Body>
                <Modal.Footer className="pt-0">
                    {showCloseButton && <Button variant="link" onClick={handleConfirmClose}>{cancelText}</Button>}
                    <Button variant={buttonVariant} onClick={handleConfirmAction}>{buttonText}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default Confirm;