import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ErrorAlert = ({ errorMessage }) =>
    <Card className="card-danger mb-3">
        <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
        <Card.Body>{errorMessage}</Card.Body>
    </Card>

const SuccessAlert = ({ successMessage }) =>
    <Card className="card-success mb-3">
        <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
        <Card.Body>{successMessage}</Card.Body>
    </Card>

const WarningAlert = ({ warningTitle, warningMessage }) =>
    <Card className="card-warning mb-3">
        <Card.Title><FontAwesomeIcon icon={faExclamationTriangle} />{warningTitle}</Card.Title>
        <Card.Body>{warningMessage}</Card.Body>
    </Card>

export {
    ErrorAlert,
    SuccessAlert,
    WarningAlert
}