import React from 'react';
import { Row, Button, Col } from 'react-bootstrap';

import { toLocalTimeString } from '../../Utilities';

const goToURL = (url) => {
    window.open(url, '_blank');
}

const FulfilmentLabel = ({ label = {} }) => {
    return (
        <div className="my-4">
            {label.displayName && <h5 className="mb-2">{label.displayName}</h5>}
            <Row>
                <Col>
                    {label.createdDate && <p>Date Created: <br />{toLocalTimeString(label.createdDate)}</p>}
                </Col>
                <Col className="my-3">
                    {label.packingSlipUrl && <Button variant="secondary" onClick={() => { goToURL(label.packingSlipUrl) }}>Download Packing Slip</Button>} 
                    <br/>
                    {label.packingSlipUrl && label.packingSlipPrinted ? <span className="text-status-green px-3 py-1" >Printed</span> : label.packingSlipUrl && <span className="text-status-grey px-3 py-1" >Not Printed</span>}
                </Col>
               
                <Col className="my-3">
                    {label.url && <Button variant="secondary" onClick={() => { goToURL(label.url) }}>Download Labels</Button>}
                    <br/>
                    {label.printed ? <span className="text-status-green px-3 py-1" >Printed</span> : <span className="text-status-grey px-3 py-1" >Not Printed</span>}
                </Col>
                
            </Row>
            <hr className="mt-0"/>
        </div>
    );
}

export default FulfilmentLabel;