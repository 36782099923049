import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { hasProfile } from './Utilities';

import ForgotPassword from './components/account/password/ForgotPassword';
import ResetPassword from './components/account/password/ResetPassword';
import ResetResult from './components/account/password/ResetResult';
import NotFound from './components/common/NotFound';
import ProtectedRoute from './components/common/ProtectedRoute';
import Issues from './components/company/issues/Issues';
import IssuesList from './components/company/issues/IssuesList';
import ResolveIssue from './components/company/issues/ResolveIssue';
import EditLocation from './components/company/locations/EditLocation';
import Locations from './components/company/locations/Locations';
import EditUser from './components/company/users/EditUser';
import EditDetails from './components/company/users/EditDetails';
import Password from './components/company/users/Password';
import Account from './components/company/users/Account';
import Permissions from './components/company/users/Permissions';
import Users from './components/company/users/Users';
import AdHocCollections from './components/courier/adhoccollections/AdHocCollections';
import CreateCollectionIssue from './components/courier/issues/CreateCollectionIssue';
import RegularCollections from './components/courier/regularcollections/RegularCollections';
import EditService from './components/courier/services/EditService';
import Services from './components/courier/services/Services';
import Fulfilment from './components/fulfilment/FulfilmentLabels';
import Dashboard from './components/company/dashboard/Dashboard';
import { Layout } from './components/Layout';
import { Login } from './components/Login';
import NewCard from './components/retailer/finance/NewCard';
import NewDirectDebit from './components/retailer/finance/NewDirectDebit';
import CreateRetailerIssue from './components/retailer/orders/CreateRetailerIssue';
import RetailerDashboard from './components/retailer/orders/RetailerDashboard';
import PendingOrder from './components/retailer/orders/PendingOrders';
import InProcessOrders from './components/retailer/orders/InProcessOrders';
import ProcessedOrders from './components/retailer/orders/ProcessedOrders';
import UnsuccessfulOrders from './components/retailer/orders/UnsuccessfulOrders';
import OrderDetails from './components/retailer/orders/OrderDetails';
import RetailerReturns from './components/retailer/returns/RetailerReturnsList';
import UploadOrderCSV from './components/retailer/orders/UploadOrderCsv';
import ConfirmEmail from './components/retailer/registration/ConfirmEmail';
import RegistrationResult from './components/retailer/registration/RegistrationResult';
import RetailerRegistration from './components/retailer/registration/RetailerRegistration';
import CreateOrderIssue from './components/supplier/issues/CreateOrderIssue';
import OrderList from './components/supplier/orders/OrderList';
import Orders from './components/supplier/orders/Orders';
import Promotions from './components/supplier/promotions/Promotions';
import EditProduct from './components/supplier/stock/product/EditProduct';
import EditProductDetails from './components/supplier/stock/product/edit/EditProductDetails';
import EditProductOverview from './components/supplier/stock/product/edit/EditProductOverview';
import EditPackagingPreferences from './components/supplier/stock/product/edit/EditPackagingPreferences';
import EditPricing from './components/supplier/stock/product/edit/EditPricing';
import EditUniqueIdentifiers from './components/supplier/stock/product/edit/EditUniqueIdentifiers';
import EditSKU from './components/supplier/stock/product/edit/EditSKU';
import AddProduct from './components/supplier/stock/product/AddProduct';
import StockConsignmentList from './components/supplier/stock/consignments/StockConsignmentList';
import StockConsignment from './components/supplier/stock/consignments/StockConsignment';
import StockConsignmentItems from './components/supplier/stock/consignments/StockConsignmentItems';
import StockList from './components/supplier/stock/StockList';
import UploadCompanyItemCSV from './components/supplier/stock/UploadCompanyItemCsv';
import UploadSingleStock from './components/supplier/stock/UploadSingleStock';
import UploadStockCsv from './components/supplier/stock/UploadStockCsv';
import { SelazarLogin } from './components/SelazarLogin';
import { SelazarLayout } from './components/selazar/SelazarLayout';
import { AdminDashboard } from './components/selazar/AdminDashboard';
import SelazarIssues from './components/selazar/SelazarIssues';
import SelazarIssuesList from './components/selazar/SelazarIssuesList';
import ResolveSelazarIssue from './components/selazar/ResolveSelazarIssue';
import Success from './components/common/Success';
import Failure from './components/common/Failure';
import Invoices from './components/company/invoices/Invoices';
import CollectionAdvice from './components/company/invoices/CollectionAdvice';
import RemittanceAdvice from './components/company/invoices/RemittanceAdvice';
import Courier from './components/retailer/preferences/Courier';
import Region from './components/retailer/preferences/Region';
import Pricing from './components/retailer/preferences/Pricing';
import Security from './components/retailer/preferences/Security';
import Taxes from './components/retailer/preferences/Taxes';
import CustomerSupportAccess from './components/retailer/preferences/CustomerSupportAccess';
import Packaging from './components/retailer/preferences/packaging/Packaging';
import CustomPackaging from './components/retailer/preferences/packaging/CustomPackaging';
import AddCustomBox from './components/retailer/preferences/packaging/AddCustomBox';
import AddCustomMailingBag from './components/retailer/preferences/packaging/AddCustomMailingBag';
import EditCustomBox from './components/retailer/preferences/packaging/EditCustomBox';
import EditCustomMailingBag from './components/retailer/preferences/packaging/EditCustomMailingBag';
import ViewCustomBox from './components/retailer/preferences/packaging/ViewCustomBox';
import ViewCustomMailingBag from './components/retailer/preferences/packaging/ViewCustomMailingBag';
import Integrations from './components/retailer/integrations/Integrations';
import EditIntegration from './components/retailer/integrations/EditIntegration';
import CreateBulkOrder from './components/retailer/bulkorder/createbulkorder/CreateBulkOrder';
import BulkOrderWizard from './components/retailer/bulkorder/createbulkorder/BulkOrderWizard';
import PendingBulkOrders from './components/retailer/bulkorder/bulkorders/PendingBulkOrders';
import InProcessBulkOrders from './components/retailer/bulkorder/bulkorders/InProcessBulkOrders';
import ProcessedBulkOrders from './components/retailer/bulkorder/bulkorders/ProcessedBulkOrders';
import UnsuccessfulBulkOrders from './components/retailer/bulkorder/bulkorders/UnsuccessfulBulkOrders';
import BulkOrderDetails from './components/retailer/bulkorder/bulkorders/BulkOrderDetails';
import BulkOrderSplitDetails from './components/retailer/bulkorder/bulkorders/BulkOrderSplitDetails';
import PickAndPackStats from './components/selazar/PickAndPackStats';
import QuarantineList from './components/supplier/stock/consignments/QuarantineList';
import QualityReport from './components/supplier/stock/consignments/QualityReport';
import TrackingReference from './components/retailer/orders/TrackingReference';
import InboundStock from './components/fulfilment/InboundStock';
import Bundles from './components/supplier/stock/bundles/Bundles';
import Bundle from './components/supplier/stock/bundles/Bundle';
import BundleWizard from './components/supplier/stock/bundles/bundlewizard/BundleWizard';

class App extends Component {

    state = {
        loggedIn: false
    };

    isLoggedIn = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user ? true : false;
    }

    isSelazar = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.profiles[0] === "System" ? true : false;
    }

    onLogin = (data) => {
        if (data.token) {
            localStorage.setItem('user', JSON.stringify(data));
            this.setState({
                loggedIn: true
            });
        }
    }

    onSelazarLogin = (data) => {
        this.props.history.push('/');
        if (data.token) {
            localStorage.setItem('user', JSON.stringify(data));
            this.setState({
                loggedIn: true,
                loading: false
            });
        }
    }

    onLogout = () => {
        this.setState({ loggedIn: false });
        localStorage.clear();
        this.props.history.push('/');
    }

    renderLogin = () =>
        <React.Fragment>
            <Switch>
                <Route exact path='/' render={() => (<Login onLogin={this.onLogin} />)} />
                <Route path='/selazar/login' render={() => (<SelazarLogin onLogin={this.onSelazarLogin} />)} />
                <Route path='/register/retailer' component={RetailerRegistration} exact />
                <Route path='/register/result' component={RegistrationResult} exact />
                <Route path='/register/email/confirm/:id' component={ConfirmEmail} exact />
                <Route path='/account/password/forgot' component={ForgotPassword} exact />
                <Route path='/account/password/result' component={ResetResult} exact />
                <Route path='/account/password/reset/:id' component={ResetPassword} exact />
                <Route path='/jacque/pp' component={PickAndPackStats} exact />
            </Switch>
        </React.Fragment>

    renderSelazar = () =>
        <SelazarLayout onLogout={this.onLogout} >
            <Switch>
                <Route exact path='/' render={() => (<AdminDashboard />)} />
                <ProtectedRoute feature='ViewIssue' path='/issues' component={SelazarIssues} exact />
                <ProtectedRoute feature='ViewIssue' path='/issues/raisedBy' component={SelazarIssuesList} exact />
                <ProtectedRoute feature='ActionIssue' path='/issues/resolve' component={ResolveSelazarIssue} exact />
                <Route component={NotFound} />
            </Switch>
        </SelazarLayout>

    renderHome = () => {
        const isFulfilmentUser = hasProfile("Fulfilment");
        return (
            <Layout onLogout={this.onLogout}>
                <Switch>
                    <Route exact path={'/'} render={() => (isFulfilmentUser ? <Fulfilment /> : <Dashboard />)} />
                    <Route path='/success' component={Success} exact />
                    <Route path='/failure' component={Failure} exact />
                    <ProtectedRoute feature='ViewUser' path='/company/users' component={Users} exact />
                    <ProtectedRoute feature='AddUser' path='/company/users/new' component={EditUser} exact />
                    <ProtectedRoute feature='EditUser' path='/company/users/edit/:id' component={EditUser} exact />
                    <ProtectedRoute feature='EditUser' path='/company/users/editdetails/:id' component={EditDetails} exact />
                    <ProtectedRoute feature='EditUser' path='/company/users/password' component={Password} exact />
                    <ProtectedRoute feature='ViewUser' path='/company/users/account/:id' component={Account} exact />
                    <ProtectedRoute feature='ViewCompanyAddress' path='/company/locations' component={Locations} exact />
                    <ProtectedRoute feature='EditCompanyAddress' path='/company/locations/edit' component={EditLocation} exact />
                    <ProtectedRoute feature='ViewInvoice' path='/company/invoices' component={Invoices} exact />
                    <ProtectedRoute feature='ViewInvoice' path='/company/invoices/collectionadvice' component={CollectionAdvice} exact />
                    <ProtectedRoute feature='ViewInvoice' path='/company/invoices/remittanceadvice' component={RemittanceAdvice} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewOrder' path='/supplier/orders' component={Orders} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewOrder' path='/supplier/orders/all' component={OrderList} exact />
                    <ProtectedRoute profile='Supplier' feature='AddIssue' path='/supplier/orders/createissue' component={CreateOrderIssue} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewPromotion' path='/supplier/promotions' component={Promotions} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products' component={StockList} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products/list/:pageIndex' component={StockList} exact />
                    <ProtectedRoute profile='Supplier' feature='AddStock' path='/supplier/products/add' component={AddProduct} exact />

                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/:id' component={EditProduct} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/:id/:pageIndex' component={EditProduct} exact />

                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/details/:id/:pageIndex' component={EditProductDetails} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/overview/:id/:pageIndex' component={EditProductOverview} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/packagingpreferences/:id/:pageIndex' component={EditPackagingPreferences} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/pricing/:id/:pageIndex' component={EditPricing} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/uniqueidentifiers/:id/:pageIndex' component={EditUniqueIdentifiers} exact />
                    <ProtectedRoute profile='Supplier' feature='EditStock' path='/supplier/products/edit/uniqueidentifiers/sku/:id/:pageIndex/:skuIndex' component={EditSKU} exact />

                    <ProtectedRoute profile='Supplier' feature='UploadCSV' path='/supplier/products/upload' component={UploadCompanyItemCSV} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products/bundles' component={Bundles} exact />
                    <ProtectedRoute profile='Supplier' feature='AddStock' path='/supplier/products/bundles/create' component={BundleWizard} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/products/bundles/:id' component={Bundle} exact />

                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments' component={StockConsignmentList} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/consignment/:id' component={StockConsignment} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/consignmentitems/:id' component={StockConsignmentItems} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/quarantine' component={QuarantineList} exact />
                    <ProtectedRoute profile='Supplier' feature='ViewStock' path='/supplier/stockconsignments/quarantine/qualityreport/:id' component={QualityReport} exact />
                    <ProtectedRoute profile='Supplier' feature='AddStock' path='/supplier/stockconsignments/create' component={UploadSingleStock} exact />
                    <ProtectedRoute profile='Supplier' feature='UploadCSV' path='/supplier/stockconsignments/upload' component={UploadStockCsv} exact />

                    <ProtectedRoute profile='Courier' feature='ViewCompanyService' path='/courier/services' component={Services} exact />
                    <ProtectedRoute profile='Courier' feature='EditCompanyService' path='/courier/services/edit' component={EditService} exact />
                    <ProtectedRoute feature='EditPermission' path='/users/permissions' component={Permissions} exact />
                    <ProtectedRoute profile='Courier' feature='ViewRegularCollection' path='/courier/collections/regular' component={RegularCollections} exact />
                    <ProtectedRoute feature='ViewIssue' path='/company/issues' component={Issues} exact />
                    <ProtectedRoute feature='ViewIssue' path='/company/issues/list/:raisedBy' component={IssuesList} exact />
                    <ProtectedRoute feature='ActionIssue' path='/company/issues/resolve' component={ResolveIssue} exact />
                    <ProtectedRoute profile='Courier' feature='AddIssue' path='/courier/collections/createissue' component={CreateCollectionIssue} exact />
                    <ProtectedRoute profile='Courier' feature='ViewCollection' path='/courier/collections/adhoc' component={AdHocCollections} exact />
                    <ProtectedRoute profile='Retailer' feature='UploadOrderCSV' path='/retailer/orders/upload' component={UploadOrderCSV} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders' component={RetailerDashboard} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/pendingorders' component={PendingOrder} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/inprocessorders' component={InProcessOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/processedorders' component={ProcessedOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/unsuccessfulorders' component={UnsuccessfulOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/orderdetails' component={OrderDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewOrder' path='/retailer/orders/tracking' component={TrackingReference} exact />
                    <ProtectedRoute profile='Retailer' feature='AddIssue' path='/retailer/orders/createissue' component={CreateRetailerIssue} exact />
                    <ProtectedRoute profile='Retailer' feature='ViewReturn' path='/retailer/returns' component={RetailerReturns} exact />

                    <ProtectedRoute profile='Retailer' feature='ManageCourierPreferences' path='/retailer/preferences' component={Courier} exact />
                    <ProtectedRoute profile='Retailer' feature='ManageCourierPreferences' path='/retailer/preferences/region' component={Region} exact />
                    <ProtectedRoute profile='Retailer' feature='ManageCourierPreferences' path='/retailer/preferences/pricing' component={Pricing} exact />

                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/security' component={Security} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/customersupportaccess' component={CustomerSupportAccess} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/taxes' component={Taxes} exact />

                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging' component={Packaging} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging' component={CustomPackaging} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/add/box' component={AddCustomBox} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/add/mailingbag' component={AddCustomMailingBag} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/edit/box/:id' component={EditCustomBox} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/edit/mailingbag/:id' component={EditCustomMailingBag} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/view/boxes' component={ViewCustomBox} exact />
                    <ProtectedRoute profile='Retailer' feature='ManagePreferences' path='/retailer/preferences/packaging/custompackaging/view/mailingbags' component={ViewCustomMailingBag} exact />

                    <ProtectedRoute profile='Retailer' feature='ManageIntegrations' path='/retailer/integrations' component={Integrations} exact />
                    <ProtectedRoute profile='Retailer' feature='ManageIntegrations' path='/retailer/integrations/add' component={EditIntegration} exact />

                    <ProtectedRoute feature='ViewCard' path='/retailer/finance/newcard' component={NewCard} exact />
                    <ProtectedRoute feature='ViewCard' path='/retailer/finance/newdirectdebit/' component={NewDirectDebit} exact />
                    <ProtectedRoute feature='ViewCard' path='/retailer/finance/newdirectdebit/:formCancelled' component={NewDirectDebit} exact />
                    <ProtectedRoute profile='Fulfilment' feature='AddIssue' path='/' component={Fulfilment} exact />
                    <ProtectedRoute profile='Fulfilment' feature='ViewCheckinRun' path='/inboundstock' component={InboundStock} exact />

                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder' component={PendingBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/inprocessorders' component={InProcessBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/processedorders' component={ProcessedBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/unsuccessfulorders' component={UnsuccessfulBulkOrders} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/bulkorderdetails/:id' component={BulkOrderDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/splitdetails' component={BulkOrderSplitDetails} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/info' component={CreateBulkOrder} exact />
                    <ProtectedRoute profile='Retailer' feature='BulkPick' path='/retailer/bulkorder/create' component={BulkOrderWizard} exact />

                    <Route component={NotFound} />
                </Switch>
            </Layout>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.isLoggedIn() ? this.isSelazar() ? this.renderSelazar() : this.renderHome() : this.renderLogin()}
            </React.Fragment>
        );
    }
}

export default withRouter(App);


