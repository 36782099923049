import React from 'react';
import { Form, FormControl, FormLabel, FormGroup, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ErrorAlert } from '../../../common/Alert';
import LoadingBar from '../../../common/LoadingBar';

const createBoxBreakdown = (box, i) => (
    (box.items.length > 0) ?
        <div className="mb-3">
            <p className="mb-1"><strong>Box {i + 1}</strong></p>
            {box.items.map(item => <div key={`box_${i}_item_${item.companyItemID}`} className="mb-1">
                {item.quantity > 0 ? item.sku ? <React.Fragment><p className="mb-1">{`${item.name}: ${item.quantity}`}</p><p className="small">{item.sku}</p></React.Fragment> : <p>{`${item.name}: ${item.quantity}`}</p> : null}
            </div>)}
        </div> 
    : null
);

const createBulkSummary = (boxes) => {
    const summary = [];
    for (const box of boxes) {
        for (const item of box.items) {
            const foundIndex = summary.findIndex(fi => fi.companyItemID === item.companyItemID);
            if (foundIndex === -1) {
                summary.push({ companyItemID: item.companyItemID, name: item.name, quantity: item.quantity });
            } else {
                const currentItem = summary[foundIndex];
                summary[foundIndex] = { companyItemID: currentItem.companyItemID, name: currentItem.name, quantity: currentItem.quantity + item.quantity }
            }
        }
    }
    return summary;
}

const BoxSplitDetails = ({ boxes }) => (
    <div className="mt-4">
        <h5 className="mb-3">Split Details</h5>
        {boxes.map((box, i) => createBoxBreakdown(box, i))}
    </div>
);

const BoxSplitSummary = ({ boxes }) => {
    const summary = createBulkSummary(boxes);

    return (
        <React.Fragment>
            <h5 className="mb-3">Bulk Order Summary</h5>
            <p><strong>Bulk Order Products</strong></p>
            {summary.length ? 
                summary.map(item => (item.quantity > 0) 
                    ? <p key={item.companyItemID}>{`${item.name}: ${item.quantity}`}</p> 
                    : null )
                : null
            }
        </React.Fragment>
    );
};

const BoxSplit = ({ boxes }) => (
    boxes.length ?
        <React.Fragment>
            <BoxSplitSummary boxes={boxes} />
            <BoxSplitDetails boxes={boxes} />
        </React.Fragment>
        : null
);

class BulkOrderBoxSplit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            boxQuantityErrors: [],
            loading: true,
            warning: '',
        };
    }

    async componentDidMount() {
        const { options, clearBoxSplit } = this.props;
        this.setState({ loading: false });

        if (options.ownBoxSplit === 'false') await clearBoxSplit();
    }

    validateBoxSplit = async () => {
        const { totalAddedCount, options, boxSplit } = this.props;
        let validation = true;
        const noOfBoxes = boxSplit.numberOfBoxes;

        if (options.ownBoxSplit === 'true') {
            if (noOfBoxes === '' || noOfBoxes === 0) {
                validation = false;
                await this.promisedSetState({ boxQuantityErrors: ["Total number of boxes is required"] });
            }
            else if (noOfBoxes <= 0 || noOfBoxes % 1 !== 0) {
                validation = false;
                await this.promisedSetState({ boxQuantityErrors: ["Invalid number of boxes"] });
            }
            else if (noOfBoxes > totalAddedCount) {
                validation = false;
                await this.promisedSetState({ boxQuantityErrors: ["There are more boxes requested than items picked"] });
            }
        }
        return validation;
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    handleClick = async () => {
        const { handleBoxQuantityRequest, calculateAssignments } = this.props;
        const isValid = await this.validateBoxSplit();
        if (isValid) {
            this.setState({ boxQuantityErrors: [] });
            await handleBoxQuantityRequest(true);
            await calculateAssignments();
        }
        else await handleBoxQuantityRequest(false);
    }

    generateListing = () => {

        const { boxSplit, selectedItems, recalculateAssignments } = this.props;

        let boxAndItemArray = [];
        for (let boxNumber = 1; boxNumber <= boxSplit.numberOfBoxes; boxNumber++) {
            const box = boxSplit.all.boxes[boxNumber - 1];
            boxAndItemArray.push(<div className="mb-3" key={"Box-" + boxNumber}>
                <h5>Box {boxNumber}</h5>
                {Object.entries(selectedItems.items).map(([key, value], i) => {
                    if (value.quantity !== 0) {
                        let selectedQuantity = "";
                        const identifier = (value.sku !== undefined && value.sku !== '' && value.sku !== null) ? value.companyItemID + "_" + value.sku : value.companyItemID;
                        const fieldIdentifier = `${identifier}_${i}_${boxNumber}`;
                        const sku = value.sku ? ` (${value.sku})` : '';
                        if (box !== undefined) {
                            const item = box.items.filter(item => item.identifier === identifier)[0];
                            if (item !== undefined) selectedQuantity = item.quantity;
                        }
                        return (
                            <FormGroup key={value.identifier}>
                                <FormLabel htmlFor={fieldIdentifier}>How many {value.name}{sku} would you like to add to box {boxNumber}?</FormLabel>
                                <FormControl id={fieldIdentifier} type="number" min="0" step="1" value={selectedQuantity} maxLength="50" name={identifier} onChange={(e) => recalculateAssignments(e.target.value, boxNumber - 1 , value.identifier, value.companyItemID, value.name, value.sku)} required />
                            </FormGroup>
                        )
                    }
                })}

                {boxSplit.showServerValidation ? (box && (box.companyPackagingID || box.isStockBox === true)) ? <span className="text-primary">This box has been successfully validated</span> : <span className="text-danger">Too many products to fit within box</span> : null}
            </div>);
        }
        return boxAndItemArray;
    }

    render() {
        const { options, boxSplit, handleBoxTotalChange, handleBack } = this.props;
        const { boxQuantityErrors, loading } = this.state;
        const errorMessage = boxQuantityErrors.length > 0 ? boxQuantityErrors.map((e, i) => e && e.length ? <p key={i} className="mb-0">{e}</p> : null) : null;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <h5 className="mb-4">{options.ownBoxSplit === "true" ? "Box Split" : "Split"}</h5>
                        {options.ownBoxSplit === "true" ?
                            boxSplit.showManualSplitSummary ?
                                <BoxSplit boxes={boxSplit.all.boxes} /> :
                                <React.Fragment>
                                    {boxSplit.showTotalBoxQuestion ?
                                        <Form>
                                            <fieldset>
                                                <FormGroup>
                                                    {boxQuantityErrors.length > 0 && <Row><Col md={12}><ErrorAlert errorMessage={errorMessage}></ErrorAlert></Col></Row>}
                                                    <FormLabel htmlFor="numberOfBoxes">Total number of boxes required for splitting this bulk order?</FormLabel>
                                                    <FormControl id="numberOfBoxes" name="numberOfBoxes" value={boxSplit.numberOfBoxes} onChange={(e) => handleBoxTotalChange(e)} required type="number" min="0" step="1" />
                                                    <br />
                                                    <div className="float-left">
                                                        <Button variant="secondary" onClick={handleBack}>Back</Button>
                                                    </div>
                                                    <div className="float-right">
                                                        <Link to='/retailer/bulkorder/info' className="link-button">
                                                            <Button variant="link" className="button-nav-link mr-3">Cancel</Button>
                                                        </Link>
                                                        <Button variant="primary" onClick={this.handleClick}>Confirm</Button>
                                                    </div>
                                                </FormGroup>
                                            </fieldset>
                                        </Form>
                                        :
                                        <React.Fragment>
                                            {boxSplit.numberOfBoxes === 0 ? null :
                                                <Form>
                                                    <fieldset>
                                                        <FormGroup>
                                                            {this.generateListing()}
                                                        </FormGroup>
                                                    </fieldset>
                                                </Form>
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment> : options.separateSKUs === "true" ? <BoxSplit boxes={boxSplit.all.boxes} /> : (options.packagingRequired === 'true' ? 
                                <p>You have not selected SKU Split or custom Box Split. Box allocation will be automatically generated on your behalf.</p> : 
                                <p>You have chosen for the items in your order to not be boxed, therefore no box split is required.</p>)}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default BulkOrderBoxSplit;


