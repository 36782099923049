import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import './Header.scss';

class Header extends Component {

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    logout() {
        this.props.onLogout();
    }

    render() {
        return (
            <Navbar className="shadow-sm fixed-top">
                <Navbar.Brand href="/" className="mx-4">
                    <img alt="Selazar Logo" src="/Images/logo.png" className="d-inline-block align-top" />
                </Navbar.Brand>
                <Nav className="mr-auto">
                    <Nav.Link href="https://selazar.freshdesk.com/support/solutions" target="_blank">FAQs</Nav.Link>
                </Nav>
                <Nav className="ml-auto">
                    <Nav.Link onClick={() => { if (window.confirm('Are you sure you wish to log out?')) this.logout(); }}>Sign Out <FontAwesomeIcon className="ml-1" icon={faSignOutAlt} /></Nav.Link>
                </Nav>
            </Navbar>
        );
    }
}

export default Header;