import React from 'react';
import { GoogleMap, withScriptjs, withGoogleMap } from 'react-google-maps';
import PropTypes from 'prop-types';

const SelazarMap = withScriptjs(withGoogleMap((props) => {
    let { centre, zoom } = props.settings;
    zoom = zoom || 5;
    let markers = props.children;

    return (
        <GoogleMap defaultZoom={zoom}            
            defaultCenter={centre}
        >
                {props.isMarkerShown ? markers : null}
        </GoogleMap>
    );
}));

SelazarMap.propTypes = {
    containerElement: PropTypes.element.isRequired,
    googleMapURL: PropTypes.string.isRequired,
    isMarkerShown: PropTypes.bool,    
    loadingElement: PropTypes.element.isRequired,
    mapElement: PropTypes.element.isRequired 
};

SelazarMap.defaultProps = {
    settings: { defaultZoom: 5 },
    isMarkerShown: true
};

export default SelazarMap;