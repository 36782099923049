import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PreferencesSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import FormValidator from '../../../common/FormValidator';
import LoadingBar from '../../../common/LoadingBar';
import { ErrorAlert } from '../../../common/Alert';
import CommonEndpoints from '../../../common/Endpoints';
import { extractData, POST } from '../../../../Consumer';
import { CustomPackageForm, CustomPackageFormActions, CancelModal, SuccessfulAddPackage } from './Snippets';

const ERROR_MESSAGE = "There was a problem adding your custom mailing bag. Ensure another custom mailing bag does not have the same name. Please try again."

const breadcrumbs = [
    { text: "Packaging", link: "/retailer/preferences/packaging" },
    { text: "Custom Packaging", link: "/retailer/preferences/packaging/custompackaging" },
    { text: "Add Custom Mailing Bag", link: null }
];

class AddCustomMailingBag extends Component {
    constructor(props) {
        super(props);

        this.mailingBagValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a package friendly name'
            },
            {
                field: 'height',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 height for the mailing bag'
            },
            {
                field: 'width',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 width for the mailing bag'
            },
            {
                field: 'maxWeight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Enter the weight limit of the mailing bag'
            },
        ]);

        this.state = {
            loading: true,
            showCancelModal: false,
            showSuccess: false,
            showError: false,
            mailingBagValidation: this.mailingBagValidator.valid(),
            returnToMain: (this.props.location && this.props.location.state) ? this.props.location.state.returnToMain : true,
            mailingBag: {
                name: "",
                height: 0.00,
                width: 0.00,
                maxWeight: 0.00
            }
        };
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            mailingBag: {
                ...prevState.mailingBag,
                ...{ [name]: value }
            }
        }));
    }

    handleShowHideCancelModal = () => this.setState(prevState => ({ showCancelModal: !prevState.showCancelModal }));

    handleAddPackage = async () => {
        this.setState({ loading: true });

        const { mailingBag } = this.state;
        const validation = this.mailingBagValidator.validate(mailingBag);
        this.setState({ mailingBagValidation: validation });
        if (validation.isValid) {
            return await POST(CommonEndpoints.PACKAGING.POST.MAILING_BAG, mailingBag)
                .then(response => {
                    if (response && response.ok) return response.json();
                })
                .then(results => {
                    const addedMailingBag = extractData(results);
                    addedMailingBag !== null
                        ? this.setState({ showSuccess: true, loading: false })
                        : this.setState({ showError: true, loading: false });
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ showError: true, loading: false })
                });
        }
        this.setState({ loading: false });
    }

    render() {
        const { loading, mailingBag, mailingBagValidation, showError, showSuccess, showCancelModal, returnToMain } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Packaging" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="mb-3">Add Custom Mailing Bag</h5>
                                {showSuccess
                                    ? <React.Fragment>
                                        <SuccessfulAddPackage name={mailingBag.name} />
                                        <Button variant="primary" className="float-right mt-3" onClick={() => this.props.history.push("/retailer/preferences/packaging/custompackaging/view/mailingbags")}>Continue to Custom Mailing Bags</Button>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        {showError && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
                                        <CustomPackageForm isBox={false} packageDetails={mailingBag} packageValidation={mailingBagValidation} handleInputChange={this.handleInputChange} />
                                        <CustomPackageFormActions buttonText="Add Custom Mailing Bag" handleCancel={this.handleShowHideCancelModal} handleAction={this.handleAddPackage} />
                                    </React.Fragment>}
                            </Col>
                        </Row>
                        <CancelModal isBox={false} isAdd={true} handleClose={this.handleShowHideCancelModal} showModal={showCancelModal} handleConfirm={() => this.props.history.push(`/retailer/preferences/packaging/${returnToMain ? "custompackaging" : "custompackaging/view/mailingbags"}`)} />
                    </Container>}
            </React.Fragment>
        )
    }
}

export default AddCustomMailingBag;