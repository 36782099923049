import React from 'react';

import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <a className="mx-2" href='https://www.selazar.com' target='_blank' rel="noopener noreferrer">Selazar Website</a>&nbsp;|&nbsp;
            <a className="mx-2" href='https://returns.selazar.com' target='_blank' rel="noopener noreferrer">Selazar Returns</a>&nbsp;|&nbsp;
            <a className="mx-2" href='http://support.selazar.com' target='_blank' rel="noopener noreferrer">Selazar Support</a>
        </footer>
    );
};

export default Footer;