import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import CommonEndpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import { toLocalDateString, checkPermission } from '../../../Utilities';
import { DefaultCard, OrderListCard, BarCard } from '../../common/DashboardCards';
import { RetailerOrderSubNavList } from '../../common/Constants'
import Header from '../../common/Header';

const barChartColour = "#229BD8";

const extractOrderByDateData = (totalsByDate) => {
    const labels = totalsByDate.map(orderTotal => toLocalDateString(orderTotal.date));
    const data = totalsByDate.map(orderTotal => orderTotal.total);
    const orderDataByDay = {
        labels: labels,
        data: data
    }
    return orderDataByDay;
}

class RetailerDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            unprocessedCount: 0,
            awaitingFulfilmentCount: 0,
            completedCount: 0,
            recentUnsuccessfulOrders: [],
            costsByDate: {
                labels: [],
                data: []
            },
            totalsByDate: {
                labels: [],
                data: []
            }
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.getUnproccesedCount(),
            this.getAwaitingFulfilmentCount(),
            this.getCompletedCount(),
            this.getRecentUnsuccessfulOrders(),
            this.getOrderCostsByDate(),
            this.getOrderTotalsByDate()]);
        this.setState({ loading: false });
    }

    getUnproccesedCount = () => {
        return GET(CommonEndpoints.ORDERS.GET.UNPROCESSED_TODAY)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ unprocessedCount: data });
                }
            }, err => {
                console.error(err);
            });
    }

    getAwaitingFulfilmentCount = () => {
        return GET(CommonEndpoints.ORDERS.GET.AWAITING_FULFILMENT_TODAY)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ awaitingFulfilmentCount: data });
                }
            }, err => {
                console.error(err);
            });
    }

    getCompletedCount = () => {
        return GET(CommonEndpoints.ORDERS.GET.COMPLETED_TODAY)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ completedCount: data });
                }
            }, err => {
                console.error(err);
            });
    }

    getRecentUnsuccessfulOrders = () => {
        return GET(CommonEndpoints.ORDERS.GET.RECENT_FAILED)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ recentUnsuccessfulOrders: data });
                }
            }, err => {
                console.error(err);
            });
    }

    getOrderTotalsByDate = () => {
        return GET(CommonEndpoints.ORDERS.GET.TOTALS_BY_DATE)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const totalsByDate = extractOrderByDateData(data);
                    this.setState({ totalsByDate: totalsByDate });
                }
            }, err => {
                console.error(err);
            });
    }

    getOrderCostsByDate = () => {
        return GET(CommonEndpoints.ORDERS.GET.COSTS_BY_DATE)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    const costsByDate = extractOrderByDateData(data, true);
                    this.setState({ costsByDate: costsByDate });
                }
            }, err => {
                console.error(err);
            });
    }

    render() {
        const { loading, unprocessedCount, awaitingFulfilmentCount, completedCount, recentUnsuccessfulOrders, costsByDate, totalsByDate } = this.state;

        const orderCostsData = {
            labels: costsByDate.labels,
            datasets: [
                {
                    backgroundColor: barChartColour,
                    data: costsByDate.data
                }
            ]
        };
        const orderTotalsData = {
            labels: totalsByDate.labels,
            datasets: [
                {
                    backgroundColor: barChartColour,
                    data: totalsByDate.data
                }
            ]
        };

        return (
            <React.Fragment>
                <Header title="Orders" subNavList={RetailerOrderSubNavList} activeKey="Dashboard" headerClass={"mb-0"} />
                <Container fluid>
                    {loading ? <LoadingBar /> :
                        <React.Fragment>
                            <Row>
                                <Col sm="12" md="4" className="mt-3">
                                    <DefaultCard title="Number of Unprocessed Orders" count={unprocessedCount} text="orders today" />
                                </Col>
                                <Col sm="12" md="4" className="mt-3">
                                    <DefaultCard title="Number of Orders Awaiting Fulfilment" count={awaitingFulfilmentCount} text="orders today" />
                                </Col>
                                <Col sm="12" md="4" className="mt-3">
                                    <DefaultCard title="Orders Completed" count={completedCount} text="orders today" />
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="12" md="4" className="mt-4">
                                    <OrderListCard title="Recent Unsuccessful Orders" orders={recentUnsuccessfulOrders} />
                                </Col>
                                <Col sm="12" md="8">
                                    <Row className="h-100">
                                        {checkPermission('ViewInvoice',
                                        <Col md="12" className="mt-4">
                                            <BarCard title="Order Value for the Past 28 Days (£)" data={costsByDate.data.reduce((a, b) => a + b, 0) > 0 ? orderCostsData : null} />
                                        </Col>)}
                                        <Col md="12" className="mt-4">
                                            <BarCard title="Order Totals for the Past 28 Days" data={totalsByDate.data.reduce((a, b) => a + b, 0) > 0 ? orderTotalsData : null} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </React.Fragment>}
                </Container>
            </React.Fragment>
        );
    }
}

export default RetailerDashboard;