import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { toLocalTimeString } from '../../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const PASSWORD_MASK = '**************************';

const WooCommerceDetails = ({ integration, integration: { metadata }, editIntegrationClick, backToIntegrationsClick }) => {
    let credentials = JSON.parse(metadata);
    credentials.key = PASSWORD_MASK;
    credentials.secret = PASSWORD_MASK;

    return (
        <Container fluid>
            <Row>
                <Col sm="12" md="6">
                    <a className="link-button link-pointer" onClick={backToIntegrationsClick}>
                        <FontAwesomeIcon icon={faAngleLeft} />  Return to Integrations
                    </a>
                    <h2 className="mt-4">Integrations</h2>
                    <section>
                        <h5 className="mt-4 integration-section-header">WooCommerce Credentials</h5>
                        <p className="mt-2">
                            <p className="title">Store Name</p>
                            <span className="d-block">{credentials.shopName}</span>
                        </p>
                        <p className="mt-2">
                            <p className="title">Store URL</p>
                            <span className="d-block">{credentials.storeUrl}</span>
                        </p>
                        <p>
                            <p className="title">Consumer Key</p>
                            <span className="d-block">{credentials.key}</span>
                        </p>
                        <p>
                            <p className="title">Consumer Secret</p>
                            <span className="d-block">{credentials.secret}</span>
                        </p>
                    </section>
                    <section>
                        <h5 className="mt-4 integration-section-header">Credential Information</h5>
                        <p className="mt-2">
                            <p className="title">Date of Integration</p>
                            <span className="d-block">{toLocalTimeString(integration.dateOfIntegration)}</span>
                        </p>

                        <p>
                            <p className="title d-block">Status</p>
                            {integration.connected ? <span className="text-status-green">Connected</span>
                            : <span className="text-status-red">Not Connected</span>}
                        </p>
                    </section>
                </Col>
            </Row>
            <Row>    
                <Col sm={12} md={{ offset: 3 }}>
                    <Button variant="secondary" size="sm" onClick={editIntegrationClick}>Edit WooCommerce Credentials</Button>
                </Col>
            </Row>
        </Container >
    );
};

export default WooCommerceDetails;