import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Endpoints from "../../common/Endpoints";
import { defaultGuid, hasPermission, checkPermission, toLocalTimeString } from '../../../Utilities';
import Spinner from "../../common/Spinner";
import { PUT, GET, extractData } from '../../../Consumer';
import { Form, FormGroup, FormControl, FormLabel, Modal, Button } from 'react-bootstrap';

class IssueCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            user_name: "Unassigned",
            show: false,
            issue: this.props.issue,
            assignTo: this.props.issue.assignedTo,
            current_user: JSON.parse(localStorage.getItem('user')),
            loading: true,
            raisedByCompany: { name: "Not found" },
            issueActorCompanies: []

        };

        this.clear = this.clear.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderContent = this.renderContent.bind(this);
        this.renderAssignment = this.renderAssignment.bind(this);
    }

    componentDidMount() {
        let { issue } = this.props;
        Promise.all([this.fetchAssignedUser(issue.assignedTo), this.fetchRaisedByCompany(issue.raisedByID),  this.fetchUsers()]);
    }

    clear() {
        this.loading(true);
        this.props.onClear();
    }

    loading(loading) {
        return this.props.onLoading(loading);
    }

    handleClose() {
        this.clear();
        this.setState({ show: false });
    }

    markAsRead(issue) {
        if (this.isActor(issue)) {
            return PUT(Endpoints.ISSUES.PUT.MARK_AS_READ + issue.id)
                .then(raw => raw.json());
        }
    }

    isActor = (issue) => 
    {   
        let user = JSON.parse(localStorage.getItem('user'));
        for( let i = 0; i < issue.issueActorCompanies.length; i++)  
        {
            if(issue.issueActorCompanies[i].companyID === user.companyID)
            {
                return true;
            }
        }
        return false;
    }

    handleShow() {
        let { issue } = this.props;
   
        if (!issue.dateRead) {
            this.markAsRead(issue);
        }

        this.setState({ show: true });
    }

    fetchUsers() {
        let { current_user } = this.state;
        GET(Endpoints.GET_ALL_USERS)
            .then(raw => raw.json())
            .then(result => {
                let data = extractData(result);
                if (data) {
                    var removeIndex = data.map(function (item) { return item.id; })
                        .indexOf(current_user.id);

                    ~removeIndex && data.splice(removeIndex, 1);

                    this.setState({ users: data, loading: false });
                }
            });
    }

    fetchAssignedUser(userID) {
        var name = "Unassigned";

        if (userID) {
            if (userID !== defaultGuid) {
            GET(Endpoints.GET_USER + userID)
                .then(raw => raw.json())
                .then(result => {
                    let data = extractData(result);
                    if (data) {
                        name = data.forename + " " + data.surname;
                        this.setState({ user_name: name });
                    }
                });
        }
    }

        return name;
    }

    fetchRaisedByCompany(id) {
        GET(Endpoints.COMPANY.GET.COMPANY_BY_ID + id)
            .then(raw => raw.json())
            .then(result => {
                this.setState({ raisedByCompany: extractData(result) });
            });
    }

    onChange(e) {
        let { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    renderLabel(value) {
        if (value) {
            return <span className="label label-success" >Resolved</span>;
        }
        else {
            return <span className="label label-danger" >Unresolved</span>;
        }
    }

    renderReadLabel(value) {
        if (value) {
            return <span className="label label-success" >Read</span>;
        }
        else {
            return <span className="label label-danger" >Unread</span>;
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const { assignTo, issue, current_user } = this.state;

        if (assignTo === current_user.id) {
            PUT(Endpoints.ISSUES.PUT.ASSIGN_TO_ME + issue.id)
                .then(response => response.json())
                .then(result => {
                    let data = extractData(result);
                    if (data) {
                        this.setState({ assignTo: current_user.id });
                    }
                    this.handleClose();
                }
                );
        } else {
            PUT(Endpoints.ISSUES.PUT.ASSIGN_TO_USER + issue.id + '/' + assignTo)
                .then(response => response.json())
                .then(result => {
                    let data = extractData(result);
                    if (data) {
                        this.setState({ assignTo: assignTo });
                    }
                    this.handleClose();
                }
                );
        }
    }

    renderEntityID = (id) => {
        if(id && id !== defaultGuid){
            return "[ID: "+id +"]";
        }
    }

    renderAssignment() {
        let { user_name, users, assignTo, current_user, issue } = this.state;
        let assignPermission = hasPermission("AssignIssue");
        let reassignPermission = hasPermission("ReassignIssue");

        let assignment;

        if ((assignPermission || reassignPermission) && this.isActor(issue)) {
            assignment = (<FormGroup>
                <FormLabel htmlFor="assignField">Assigned To:</FormLabel>

                <FormControl id="assignField" name="assignTo" componentClass="select" value={assignTo} onChange={this.onChange}>
                    <option key={null} value={null}>Unassigned</option>;
                                    <option key={current_user.id} value={current_user.id}>Assign to Me</option>;
                            {users.map(u => {
                        return <option key={u.id} value={u.id}>{u.forename} {u.surname}</option>;
                    })}
                </FormControl>

            </FormGroup>);
        }
        else {
            assignment = <div><dt>Assigned To:</dt><dd>{user_name}</dd></div>;
        }


        return assignment;
    }

    handleShow = () => {
        let { issue } = this.props;
   
        if (!issue.dateRead) {
            this.markAsRead(issue);
        }

        this.setState({ show: true });
    }


    renderContent() {
        let { user_name, issue, raisedByCompany } = this.state;

        let resolved = issue.dateResolved ? <div><dt>Resolved On:</dt> <dd>{issue.resolvedOn}</dd><dt>Resolution:</dt> <dd>{issue.resolution.description}</dd></div> : "";

        let resolvedButton = !issue.dateResolved && this.isActor(issue) ? <Link className="btn btn-warning map-button" from={'/company/issues/list'} to={{ pathname: '/company/issues/resolve', state: { issueID: issue.id } }}>Resolve (Wrench)</Link> : "";

        resolvedButton = checkPermission("ActionIssue", resolvedButton);
        let saveButton = this.isActor(issue) ?  <Button className="btn btn-primary" type="submit">Save</Button> : "";
        return (
            <div>
                <div onClick={this.handleShow}>
                    <h4>{issue.title}</h4>
                    <div>{this.renderLabel(issue.dateResolved)}</div>
                    <div>{this.renderReadLabel(issue.dateRead)}</div>
                    <div className="card-body">
                        <div className="card-text">
                            <dl>
                                <dt>Issue Type:</dt><dd>{issue.issueType.name}</dd>
                                <dt>Area of Concern:</dt><dd>{issue.issueType.areaOfConcernType.name} {this.renderEntityID(issue.entityID)}</dd>
                                <dt>Raised On:</dt><dd>{toLocalTimeString(issue.createdDate)}</dd>
                                <dt>Raised By:</dt><dd>{raisedByCompany.name}</dd>
                                {resolved}

                                <dt>Assigned To:</dt><dd>{user_name}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Form onSubmit={this.onSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{issue.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <dl>
                                <dt>Issue Type:</dt><dd>{issue.issueType.name}</dd>
                                <dt>Area of Concern:</dt><dd>{issue.issueType.areaOfConcernType.name} [ID: {issue.entityID}]</dd>
                                <dt>Raised On:</dt><dd>{toLocalTimeString(issue.createdDate)}</dd>
                                <dt>Raised By:</dt><dd>{raisedByCompany.name}</dd>
                                {resolved}
                            </dl>

                            {this.renderAssignment()}

                            {resolvedButton}
                        </Modal.Body>
                        <Modal.Footer>
                            {saveButton}
                            <Button onClick={this.handleClose}>Close</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

            </div>
        );
    }

    render() {

        let content = this.state.loading ? <Spinner /> : this.renderContent();

        return (
            <div className="card">
                {content}
            </div>
        );
    }
}

export default IssueCard;