import React, { Component } from 'react';
import { ProductsSubNavList } from '../../../../common/Constants'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { POST, extractData, GET } from '../../../../../Consumer';
import Endpoints from '../../../../common/Endpoints';
import Header from '../../../../common/Header';
import Stepper from '../../../../common/Wizard/Stepper';
import LoadingBar from '../../../../common/LoadingBar';
import BundleDetails from './BundleDetails';
import FormValidator from '../../../../common/FormValidator';
import { ErrorAlert } from '../../../../common/Alert';
import ProductSelection from './ProductSelection';
import ConfirmBundle from './ConfirmBundle';
import Confirm from '../../../../common/Confirm';

const steps = ["Bundle Details", "Product Selection", "Confirm Bundle"];
const cancelLink = "/supplier/products/bundles";
const CANCEL_MODAL_TITLE = 'Cancel Create Bundle';
const CANCEL_MODAL_TEXT = 'Are you sure you want to cancel creating this bundle?';
const CANCEL_MODAL_CANCEL = 'No, do not cancel this bundle';
const CANCEL_MODAL_OK = 'Cancel Creating Bundle';
const NO_PRODUCTS = "Select the products for this bundle before you continue";
const INVALID_QUANTITY = "You need to add more than one item to create a bundle";
const MINIMUM_PRICE = 0;
const MAXIMUM_PRICE = 50000;

const StepperButtons = ({ handleCancel, handleBack, showBack = false, handleNext, showNext = false, nextButtonText = "Next Step" }) => 
    <div className="mt-4">
        {showBack && <div className="float-left">
            <Button variant="secondary" onClick={handleBack}>Back</Button>
        </div>}
        <div className="float-right">
            <Button variant="link" className="button-nav-link mr-3" onClick={handleCancel}>Cancel</Button>
            {showNext && <Button variant="primary" onClick={handleNext}>{nextButtonText}</Button>}
        </div>
    </div>

class BundleWizard extends Component {
    constructor(props) {
        super(props);

        this.bundleDetailsValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a name for your bundle'
            },
            {
                field: 'skuCode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a bundle SKU'
            },
            {
                field: 'price',
                method: 'isFloat',
                validWhen: true,
                optional: true,
                args: [{ min: MINIMUM_PRICE, max: MAXIMUM_PRICE }],
                message: `Enter a value between '${MINIMUM_PRICE}' and '${MAXIMUM_PRICE}'`
            }
        ]);

        this.state = {
            loading: true,
            items: [],
            unfilteredItems: [],
            pageIndex: 1,
            pageCount: 1,
            currentPage: [],
            bundleDetailsValidation: this.bundleDetailsValidator.valid(),
            itemSelectionValidation: { isValid: true },
            message: '',
            success: false,
            error: false,
            displaySummary: false,
            activeStep: 0,
            showCancelModal: false,
            bundleDetails: {
                name: "",
                description: "",
                skuCode: "",
                price: ""
            },
            selectedItems: { productBundleCompanyItems: [] },
            displaySummary: false,
            filter: {
                searchText: "",
                sortBy: "",
                pagingRequired: true
            }
        };
    }

    validateBundleDetails = async (bundleDetails) => {
        const validation = this.bundleDetailsValidator.validate(bundleDetails);
        this.setState({ bundleDetailsValidation: validation });
        return validation;
    }

    validateItemSelection = async (selectedItems) => {
        let validation = { isValid: true };
        const total = selectedItems.productBundleCompanyItems.flatMap(pb => pb.quantity).reduce((a, b) => a + b, 0);

        if (selectedItems.length === 0) {
            validation.isValid = false;
            this.setState({ itemSelectionValidation: validation, message: NO_PRODUCTS });
        }
        
        const invalid = selectedItems.productBundleCompanyItems.findIndex(i => i.quantity == 0) > -1;
        if (total <= 1 || invalid) {
            validation.isValid = false;
            this.setState({ itemSelectionValidation: validation, message: INVALID_QUANTITY });
        }

        this.setState({ itemSelectionValidation: validation });
        return validation;
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    handleNext = async () => {
        const { activeStep, bundleDetails, selectedItems } = this.state;
        let validate = { isValid: true };
        this.setState({ loading: true });

        if (activeStep === 0) {
            validate = await this.validateBundleDetails(bundleDetails);
            await this.fetchStockItems();
        }
        else if (activeStep === 1) {
            validate = await this.validateItemSelection(selectedItems);
        }
        else if (activeStep === 2) {
            await this.handleSubmit();
        }

        if (validate.isValid && activeStep < 2) {
            this.setState(state => ({
                activeStep: state.activeStep + 1,
                loading: false,
                error: false,
                message: ''
            }));
        } else {
            this.setState({ loading: false });
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
            itemSelectionValidation: { isValid: true }
        }));
    };

    handleBundleDetailsInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            bundleDetails: {
                ...prevState.bundleDetails,
                ...{ [name]: value }
            },
        }));
    }

    handleProductSelectionInputChange = (e) => {
        const { value, type } = e.target;
        const companyItemID = e.target.id.split(':').pop();
        const sku = e.target.name || null;
        const { items } = this.state;
        let { productBundleCompanyItems } = this.state.selectedItems;

        let index = productBundleCompanyItems.findIndex(pb => pb.companyItemID === companyItemID && pb.skuCode === sku);
        if (index > -1) {
            if (type === "checkbox") productBundleCompanyItems.splice(index, 1);
            else productBundleCompanyItems[index].quantity = parseInt(value);
        } else {
            const product = items.find(i => i.companyItemID === companyItemID && i.sku === sku);
            productBundleCompanyItems.push({ companyItemID: product.companyItemID, skuCode: product.sku, name: product.name, ean: product.ean, selazarUniqueID: product.selazarUniqueID, quantity: 0 });
        }

        this.setState(prevState => ({
            ...prevState,
            selectedItems: {
                productBundleCompanyItems: productBundleCompanyItems
            }
        }));
    }

    onReset = async (e) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ searchText: "", sortBy: "" },
            }
        }), await this.fetchStockItems);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchStockItems);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchStockItems);
    }

    onFilterChange = async (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    toggleSummary = async (show) => {
        await this.fetchStockItemsUnfiltered();
        this.setState({ displaySummary: show });
    }

    handleEditStep = (step) => {
        this.setState({ activeStep: step });
    };

    handleShowCancelModal = () => this.setState({ showCancelModal: true });

    handleCancelModalClose = () => this.setState({ showCancelModal: false });

    handleCancelModalConfirm = () => this.props.history.push(cancelLink);

    fetchStockItems = async () => {
        this.setState({ loading: true });
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.STOCK.GET.BY);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)
        
        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No products to show." : null;
                this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            });
    }

    fetchStockItemsUnfiltered = async () => {
        this.setState({ loading: true });
        let url = new URL(Endpoints.STOCK.GET.BY);
        url.searchParams.append("pagingRequired", false)

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No products to show." : null;
                this.setState({ unfilteredItems: data.items ? data.items : [], loading: false, warning: warning });
            });
    }

    handleSubmit = async () => {
        const { bundleDetails, selectedItems } = this.state;

        const productBundle = Object.assign(bundleDetails, selectedItems);

        return await POST(Endpoints.STOCK.POST.PRODUCT_BUNDLE, productBundle)
            .then(r => r.json())
            .then(result => {
                const data = extractData(result)
                if (data) {
                    this.setState({ loading: false, success: true, error: false, bundleDetails: data })
                } else this.setState({ showConfirmError: true, success: false, error: true, message: result.message });
            });
    }

    render() {
        const { bundleDetails, items, unfilteredItems, selectedItems, loading, error, success, activeStep, bundleDetailsValidation, itemSelectionValidation, pageIndex, pageCount, filter, message, displaySummary, showCancelModal } = this.state;

        return (<React.Fragment>
            <Header title="Products" subNavList={ProductsSubNavList} activeKey="Bundles" />
            <Container fluid>
                <h5 className="mt-2 mb-3">Create a Bundle</h5>
                <div className="form-input-description mb-3">
                    <p>Bundles allow you to group pre-existing products which are sold to your customers.</p>
                </div>
                <Stepper steps={steps} activeStep={activeStep} />
                <Confirm title={CANCEL_MODAL_TITLE} text={CANCEL_MODAL_TEXT} buttonText={CANCEL_MODAL_OK} handleConfirmClose={this.handleCancelModalClose} handleConfirmAction={this.handleCancelModalConfirm} show={showCancelModal} cancelText={CANCEL_MODAL_CANCEL} size="lg" buttonVariant="danger"/>
                {loading ? <LoadingBar /> :
                    <div className="pt-4">
                        {error && <Row><Col sm={12} md={6}><ErrorAlert errorMessage={message}></ErrorAlert></Col></Row>}
                        {activeStep === 0 ?
                            <Row>
                                <Col sm={12} md={6}>
                                    <BundleDetails bundleDetails={bundleDetails} bundleDetailsValidation={bundleDetailsValidation} handleBundleDetailsInputChange={this.handleBundleDetailsInputChange} isEdit={false} />
                                    <StepperButtons handleCancel={this.handleShowCancelModal} handleNext={this.handleNext} showNext={true} />
                                </Col>
                            </Row> : activeStep === 1 ?
                            <Row>
                                <Col>
                                    <ProductSelection items={items} unfilteredItems={unfilteredItems} selectedItems={selectedItems} handleProductSelectionInputChange={this.handleProductSelectionInputChange} onNext={this.onNext} onPrevious={this.onPrevious}
                                        onPageSelect={this.onPageSelect} onSearch={this.onSearch} onFilterChange={this.onFilterChange} onReset={this.onReset} pageIndex={pageIndex} pageCount={pageCount}
                                        displaySummary={displaySummary} toggleSummary={this.toggleSummary} itemSelectionValidation={itemSelectionValidation} message={message} isEdit={false} {...filter} />
                                        {displaySummary ?
                                            <div className="mt-4" /> :
                                            <StepperButtons handleCancel={this.handleShowCancelModal} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                        }
                                </Col>
                            </Row> : activeStep === 2 ?
                            <Row>
                                <Col sm={12} md={6}>
                                    <ConfirmBundle bundleDetails={bundleDetails} items={items} selectedItems={selectedItems} handleEditStep={this.handleEditStep} success={success} />
                                    {success ? 
                                    <div className="float-right">
                                        <Link to={cancelLink} className="link-button">
                                            <Button variant="primary">Continue to Bundles</Button>
                                        </Link>
                                    </div> :
                                    <StepperButtons handleCancel={this.handleShowCancelModal} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} nextButtonText="Confirm and Create Bundle" />
                                    }
                                </Col>
                            </Row> : 
                            null}
                    </div>}
            </Container>
        </React.Fragment>);
    }
}

export default BundleWizard;