import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import { GET, extractData } from '../../../Consumer';
import { InfiniteScroll } from '../../common/InfiniteScroll';
import IssueCard from './IssueCard';
import IssueFilter from './IssueFilter';
import { checkProfile, PARTY } from '../../../Utilities';

class IssuesList extends Component {
    constructor(props) {
        super(props);

        let { filter = { unresolved: false, resolved: false, read: false, title: "", startRecord: 0, endRecord: 9 }} = this.props.location;
        this.raisedBy = (props.match.params.raisedBy === 'true');
        this.state = {
            party: checkProfile("System", PARTY.SELAZAR),
            issues: [],
            loading: true,
            fetching:false,
            filter: filter   
        };

        this.getIssues = this.getIssues.bind(this);
        this.renderCards = this.renderCards.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentDidMount() {
       this.getIssues();
    }

    setLoading(loading) {
        this.setState({ loading: loading });
    }

    getIssues() {
       if(!this.state.fetching){

        this.setState({ fetching: true });

        let { issues, totalCount, party } = this.state;
        let { startRecord, endRecord } = this.state.filter;

        if (startRecord === 0 || issues.length < totalCount) {
            let user = JSON.parse(localStorage.getItem('user'));

            let filter = { ...this.state.filter };
            let { unresolved, resolved, read, title } = this.state.filter;

            let url = this.raisedBy ? Endpoints.ISSUES.GET.ALL_RAISED_BY_ISSUES_BY_COMPANY + user.companyID + "/" + party : Endpoints.ISSUES.GET.ALL_ACTOR_ISSUES_BY_COMPANY + user.companyID + "/" + party;

            if (unresolved || resolved || read || title || startRecord || endRecord) {
                url = new URL(url);
                Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
            }

            return GET(url)
                .then(response => response.json())
                .then(result => {
                    if (!result.error) {
                        this.setState(prevState => ({
                            issues: prevState.issues.concat(extractData(result)),
                            totalCount: result.totalCount,
                            filter: {
                                ...prevState.filter,
                                startRecord: startRecord + 10,
                                endRecord: endRecord + 10
                            },
                            loading: false,
                            fetching: false
                        }));
                    }
                    else {
                        this.setState({ loading: false, fetching: false });
                    }
                });
         }
       }
    }

    renderCards(issues) {
        let containsItems = issues.length > 0;
        if (containsItems) {
            return (
                <Row className="margin-bottom display-flex">
                    {issues.map(issue => {
                        return <Col sm={4} md={3} key={issue.id}><IssueCard issue={issue} onLoading={this.setLoading} onClear={this.onClear}/></Col>;
                    })}
                </Row>
            );
        }
        else {
            return (
                <Row className="margin-bottom">
                    <p>No Issues</p>
                </Row>
            );
        }
    }

    onFilterChange(e) {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));


    }

    onClear() {
        this.setState({
            issues: [],
            filter: {
                unresolved: false,
                resolved: false,
                read: false,
                title: "",
                startRecord: 0,
                endRecord: 9
            }
        }, this.getIssues);
    }

    onSearch(event) {
        event.preventDefault();
        this.setState(prevState => ({
            issues: [],
            filter: {
                ...prevState.filter,
                startRecord: 0,
                endRecord: 9
            },
            loading: true
        }), this.getIssues);
    }

    render() {
        let contents = this.state.loading
            ? <LoadingBar />
            : this.renderCards(this.state.issues);
      
        return (
            <InfiniteScroll onBottomDetected={this.getIssues} loading={this.state.loading} >
                <Container fluid>

                    <Row className="margin-bottom">
                        <Col md={10}><h2>Issues</h2></Col>
                    </Row>
                    <Row>
                        <Col>
                            <IssueFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} loading={this.state.loading} onLoading={this.setLoading} />
                        </Col>
                    </Row>
                    {contents}


                </Container>
            </InfiniteScroll>
        );
    }
}

export default IssuesList;