import React from 'react';
import SelazarLinkContainer from '../../../common/SelazarLinkContainer';
import { toLocalTimeString } from '../../../../Utilities';
import { Button } from 'react-bootstrap';

const renderReceived = (received) => received ? <span className="text-status-green" >Received</span> : <span className="text-status-grey">Not Received</span>;
const renderProcessed = (item) => {
    const qualityCheck = item.qualityChecks[0];

    if (qualityCheck) {
        if (qualityCheck.resolution !== null) {
            switch (qualityCheck.resolution) {
                case 0:
                    return <span className="text-status-green">Processed</span>;
                case 1:
                    return <span className="text-status-red">Destroyed</span>;
                case 2:
                    return <span className="text-status-yellow">Other</span>;
                default:
                    return <span className="text-status-grey">Unknown</span>;
            }
        } else {
            return <span className="text-status-yellow">On Hold</span>
        }
    } else {
        return item.isProcessed ? <span className="text-status-green">Processed</span> : <span className="text-status-grey" >Not Processed</span>;
    }
}
const renderStatus = (item) => {
    switch (item.status) {
        case "Processing":
            return <span className="text-status-yellow">{item.status}</span>;
        case "Processed":
            return <span className="text-status-green">{item.status}</span>;
        case "Received":
            return <span className="text-status-yellow">{item.status}</span>;
        case "Not Received":
            return <span className="text-status-yellow">{item.status}</span>;
        case "Quality Check Completed":
            return <span className="text-status-yellow">{item.status}</span>;
        case "On Hold":
            return <span className="text-status-red">{item.status}</span>;
        default:
            return <span className="text-status-grey">Unknown</span>;
    }
}

const renderOutcome = (item) => {
    switch (item.quarantineResolution) {
        case "No Decision Made":
            return <span className="text-status-grey">{item.quarantineResolution}</span>;
        case "Approved":
            return <span className="text-status-green">{item.quarantineResolution}</span>;
        case "Not Approved":
            return <span className="text-status-red">{item.quarantineResolution}</span>;
        case "Other":
            return <span className="text-status-yellow">{item.quarantineResolution}</span>;
        default:
            return <span className="text-status-grey">Unknown</span>;
    }
}

const QuarantineRow = ({ item }) => {
    return (
        <tr>
            <td>{item.fileName}</td>
            <td>{toLocalTimeString(item.quarantineDate)}</td>
            <td>{renderStatus(item)}</td>
            <td>{renderOutcome(item)}</td>
            <td className="text-center py-2">
                <SelazarLinkContainer to={`/supplier/stockconsignments/quarantine/qualityreport/${item.id}`} exact>
                    <Button className="link-button" variant="secondary" size="sm">Review</Button>
                </SelazarLinkContainer>
            </td>
        </tr>
    );
};

export default QuarantineRow;