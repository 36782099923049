import React, { Component } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CommonEndpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';

import './Integrations.scss';

const SFP = 'Self Fulfilled Package';
const SFP_VALUE = 'SFP';
const UK = 'UK';
const EU = 'EU';
const ROW = 'RestOfWorld';
const SERVICE_TIMES_ORDERED = ["TwentyFourPreTenThirty", "TwentyFourPreNoon", "TwentyFour", "FortyEight", "SeventyTwo", "OneToTwo", "TwoToThree", "ThreeToFive", "FiveToTen"];

class ShopifyShippingConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            integration: null,
            shopifyShippingRates: null,
            serviceTimeOptions: [],
            currentPreferences: [],
            selectedShippingRates: [],
            ukDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: UK,
                serviceTime: null
            },
            euDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: EU,
                serviceTime: null
            },
            rowDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: ROW,
                serviceTime: null
            }
        }
    }
    
    async componentDidMount() {
        await Promise.all([
            this.getRetailerIntegration(),
            this.fetchServiceTimes(),
            this.fetchCurrentPreferences(),
            this.getShippingRates()
        ]);
        this.setState({ loading: false });
    }

    getRetailerIntegration = async () => {
        const { integration } = this.props;
        const url = new URL(CommonEndpoints.INTEGRATIONS.GET.INTEGRATION_BY_ID + integration.id);

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const shopifyMetadata = JSON.parse(data.metadata);

                if (shopifyMetadata.shopifyShippingRatePreferences) {
                    const existingShippingRates = shopifyMetadata.shopifyShippingRatePreferences.filter(s => s.shippingRateID != null) || null;

                    this.setDefaults(shopifyMetadata.shopifyShippingRatePreferences);
                    this.setState({ integration: data, selectedShippingRates: existingShippingRates });
                } else {
                    this.setState({ integration: data })
                }
            });
    }

    fetchServiceTimes = async () => {
        return await GET(CommonEndpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ serviceTimeOptions: extractData(results) });
            });
    }

    fetchCurrentPreferences = async () => {
        return await GET(CommonEndpoints.RETAILER.GET.COURIER_REGIONAL_PREFERENCES)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const currentPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ currentPreferences: currentPreferences });
            });
    }

    getShippingRates = async () => {
        const { integration } = this.props;
        const url = new URL(CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_PREFIX + integration.id + CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_SUFFIX);

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                this.setState({ shopifyShippingRates: data });
            });
    }

    setDefaults = (shopifyShippingRatePreferences) => {
        const uk = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion === UK);
        const eu = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion === EU);
        const row = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion === ROW);

        if (uk) this.setState({ ukDefault: uk });
        if (eu) this.setState({ euDefault: eu });
        if (row) this.setState({ rowDefault: row });     
    }

    getShippingRateName = (shippingRateID, i) => {
        const { shopifyShippingRates } = this.state;
        const shippingRate = shopifyShippingRates.find(s => s.shippingRateID === shippingRateID);
        return shippingRate ? <p key={i}>{shippingRate.name}-{shippingRate.shippingZoneName}-£{shippingRate.price.toFixed(2)}</p> : 
        <Card className="card-danger mb-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                A Shopify shipping rate has been removed
            </Card.Title>
            <Card.Body>
                Update your shipping rate configuration to resolve this.
            </Card.Body>
        </Card>;
    }

    getDefaultValue = (serviceTimes, availableServiceTimes, serviceTime) => {
        return availableServiceTimes.includes(serviceTime) ? <p>{serviceTimes[serviceTime]}</p> :
            <Card className="card-danger mb-3">
                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                    Service Time Preference Removed
                </Card.Title>
                <Card.Body>
                    Update your shipping rate configuration to set a new default service time.
                </Card.Body>
            </Card>;
    }

    sortServiceTimes = (serviceTimes) => {
        serviceTimes.sort( function (a, b) {
            if (SERVICE_TIMES_ORDERED.indexOf(a) > SERVICE_TIMES_ORDERED.indexOf(b)) return 1;
            else return -1;
          });  
          return serviceTimes;
    }

    render() {
        const { loading, serviceTimeOptions, currentPreferences, selectedShippingRates, ukDefault, euDefault, rowDefault } = this.state;
        const { showEditShippingRates, backToIntegrationsClick, showSuccess } = this.props;
        
        return (
            loading ? (<LoadingBar />) : <Container fluid>
                <a className="link-button link-pointer" onClick={backToIntegrationsClick}>
                    <FontAwesomeIcon icon={faAngleLeft} />  Return to Integrations
                </a>
                <h2 className="mt-4">Integrations</h2>
                <Row>
                    <Col sm="12" md="6" className="mt-4">
                        <h6>Shopify Shipping Configuration</h6>

                        {showSuccess &&
                            <Card className="card-success mb-3">
                                <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
                                <Card.Body>
                                    Your Shopify shipping configuration has been saved successfully.
                            </Card.Body>
                            </Card>}

                        {serviceTimeOptions.map((st, wi) => {
                            const serviceTimesList = st.availableServiceTimeOptions.filter(s => s.companyName != SFP).flatMap(m => m.serviceTimes),
                                serviceTimes = Object.assign({}, ...serviceTimesList);

                            let availableServiceTimes;
                            let isSFP = false;
                            const currentRegionPreference = currentPreferences.find(c => c.countryRegion === st.countryRegion);
                            if (currentRegionPreference) { 
                                availableServiceTimes = currentRegionPreference.preferences.flatMap(p => p.ServiceTimes);
                                isSFP = currentRegionPreference.preferences.flatMap(p => p.ServiceTimes).findIndex(s => s == SFP_VALUE) > -1;
                            }

                            let uniqueServiceTimes = Object.keys(serviceTimes);

                            if (availableServiceTimes) uniqueServiceTimes = uniqueServiceTimes.filter(u => availableServiceTimes.includes(u));

                            uniqueServiceTimes = this.sortServiceTimes(uniqueServiceTimes);

                            let defaultValue = {};
                            switch (st.countryRegion) {
                                case UK:
                                    defaultValue = ukDefault;
                                    break;
                                case EU:
                                    defaultValue = euDefault;
                                    break;
                                case ROW:
                                    defaultValue = rowDefault;
                                    break;
                            }

                            return (
                                !isSFP && 
                                <React.Fragment key={`world-area-${wi}`}>
                                    <h5>{st.countryRegionName}</h5>
                                    <p className="title">Default {st.countryRegionName} Shipping</p>
                                    {this.getDefaultValue(serviceTimes, uniqueServiceTimes, defaultValue.serviceTime)}
                                    {uniqueServiceTimes.map((serviceTime, i) => {
                                        const shippingRates = selectedShippingRates.filter(s => s.countryRegion == st.countryRegion && s.serviceTime == serviceTime);
                                        return (
                                            <React.Fragment>
                                                <p className="title">{serviceTimes[serviceTime]}</p>
                                                {(shippingRates && shippingRates.length > 0) ? shippingRates.map((shippingRate, i) =>
                                                    this.getShippingRateName(shippingRate.shippingRateID, i)
                                                ) :
                                                <p>No shipping rates selected</p>}
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )
                        })}
                        <Button variant="link" className="button-nav-link mr-3" onClick={() => showEditShippingRates()}>Edit Shopify Shipping Configuration</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ShopifyShippingConfiguration;