import React, { Component } from 'react';
import { POST, extractData } from '../Consumer';
import { Form, FormGroup, FormLabel, FormControl, Container, Row, Card, Button, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Endpoints from './common/Endpoints';
import Footer from './common/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './Login.scss';

export class Login extends Component {
    displayName = Login.name

    constructor(props) {
        super(props);
        this.state = { email: '', password: '', loginError: '', passwordInputType: 'password', passwordInputIcon: faEye};
        this.validateForm = this.validateForm.bind(this);
        this.tryLogin = this.tryLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.tryLogin();
    }

    handleRegister = () => {
        window.location = '/register/retailer';
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ? 
            this.setState({ passwordInputType: 'text', passwordInputIcon : faEyeSlash }) : 
            this.setState({ passwordInputType: 'password', passwordInputIcon : faEye });
    }

    tryLogin() {
        return POST(Endpoints.LOGIN, { email: this.state.email, password: this.state.password })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                if (result.error) {
                    throw result.message ? new Error(result.message) : new Error("Login failed");
                } else {
                    this.props.onLogin(extractData(result));
                }
            })
            .catch(e => this.setState({ loginError: e.message, email: '', password: '' }));
    }

    render() {
        return (
            <Container className="h-100">
                <Row className="justify-content-center row-form-adjustedheight">
                    <Col md={6} className="align-self-center">
                        <Card className="p-5">
                            <Image src='Images/logo.png' className="mx-auto mb-4 image-logo" />
                            <Form onSubmit={this.handleSubmit}>
                                {this.state.loginError && <Card className="card-danger mb-3">
                                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                    <Card.Body>{this.state.loginError}</Card.Body>
                                </Card>}
                                <FormGroup>
                                    <FormLabel className="font-weight-bold">Email</FormLabel>
                                    <FormControl autoFocus type="email" id="email" value={this.state.email} onChange={this.handleChange} />
                                </FormGroup>
                                <FormGroup className="mb-1">
                                    <FormLabel className="font-weight-bold">Password</FormLabel>
                                    <FormControl type={this.state.passwordInputType} id="password" value={this.state.password} onChange={this.handleChange} />
                                    <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={this.state.passwordInputIcon} /></span>
                                </FormGroup>
                                <FormGroup>
                                    <Link to='/account/password/forgot'>Forgot Password?</Link>
                                </FormGroup>
                                <Button size="lg" type="submit" block>Login</Button>
                                <p className="decorated mb-3 mt-3"><span>Not registered yet?</span></p>
                            </Form>
                            <Link to='/register/retailer' className="link-button">
                                <Button variant="secondary" size="lg" type="submit" block>Register Now</Button>
                            </Link>
                        </Card>
                    </Col>
                </Row>
                <Footer />
            </Container>
        );
    }
}