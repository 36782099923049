import React from 'react';
import { checkPermission, toLocalTimeString } from '../../../Utilities';
import { Button } from 'react-bootstrap';

const OrderRow = ({ order, showCancellationConfirmationModal }) => {
    return (
        <tr>
            <td>{order.identifier}</td>
            <td>{order.source}</td>
            <td>{toLocalTimeString(order.createdDate)}</td>
            <td>{!order.cancelled ? <span className="text-status-purple">{order.status}</span> : <span className="text-status-red">{order.status}</span>}</td>
            {!order.cancelled && <td className="text-center py-2">{checkPermission("CancelOrder", <Button variant="danger" size="sm" onClick={showCancellationConfirmationModal}>Cancel</Button>)}</td>}
        </tr>
    );
};

export default OrderRow;