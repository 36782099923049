import React, { Component } from 'react';
import { Container, Row, Col, Alert, Form, FormControl, FormLabel, FormGroup, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingBar from '../../common/LoadingBar';
import { GET, extractData, POST, PUT } from '../../../Consumer';
import CommonEndpoints from '../../common/Endpoints';
import FormValidator from '../../common/FormValidator';
import { isNullOrEmptyGuid } from '../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const strongPasswordPattern = /^(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>]).{6,}/;
const numberPattern = /^(?=.*[0-9])/;
const specialCharPattern = /^(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>])/;
const lengthPattern = /^.{6,}/;

class EditUser extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: 'Forename is required'
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: 'Surname is required'
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required'
            },
            {
                field: 'password',
                method: 'matches',
                args: [strongPasswordPattern],
                validWhen: true,
                message: 'Password must contain at least one number, one special character and at least 6 characters long'
            },
            {
                field: 'confirmPassword',
                method: 'isEmpty',
                validWhen: false,
                message: 'Confirm password is required'
            }]
        );

        this.state = {
            loading: true,
            validation: this.validator.valid(),
            errors: [],
            roleOptions: {},
            userID: props.match.params.id || null,
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                password: '',
                confirmPassword: '',
                roles: []
            },
            validationMessages: {
                roles: false,
                passwords: false
            }
        };

        this.fetchUser = this.fetchUser.bind(this);
        this.fetchRoleOptions = this.fetchRoleOptions.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
    }

    componentDidMount() {
        this.fetchRoleOptions();
        this.fetchUser();
    }

    validate(user) {
        let validation = this.validator.validate(user);

        if (!user.password) {
            validation.isValid = false;
            validation.password.isInvalid = true;
        }
        if (!user.confirmPassword) {
            validation.isValid = false;
            validation.confirmPassword.isInvalid = true;
        }

        return validation;
    }

    fetchUser() {
        let { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {
            return GET(CommonEndpoints.GET_USER_WITH_ROLES + userID)
                .catch(error => console.log(error))
                .then(res => {
                    if (res && res.ok) {
                        return res.json();
                    }
                    else {
                        throw new Error('Error fetching data');
                    }
                })
                .then(result => {
                    if (result) {
                        let data = extractData(result) || [];
                        let warning = result.error ? result.message : null;
                        let user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                        this.setState({ user: user, loading: false, warning: warning });
                    }
                }, err => {
                    console.error(err);
                    this.setState({ loading: false, warning: err.toString() });
                });
        }
    }

    fetchRoleOptions() {
        let companyID = JSON.parse(localStorage.getItem('user')).companyID;
        return GET(CommonEndpoints.GET_ROLE_OPTIONS_FOR_COMPANY + companyID)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    let data = extractData(result) || [];
                    let warning = result.error ? result.message : null;
                    this.setState({ roleOptions: data, loading: false, warning: warning });
                }
            }, err => {
                console.error(err);
                this.setState({ loading: false, warning: err.toString() });
            });
    }

    handleInputChange(e) {
        let { name, value, type } = e.target;

        if (name === "password") {
            var numberPass = numberPattern.test(value);
            var specialCharPass = specialCharPattern.test(value);
            var lengthPass = lengthPattern.test(value);

            this.setState(prevState => ({
                passwordNumberCheck: numberPass,
                passwordSpecialCharCheck: specialCharPass,
                passwordLengthCheck: lengthPass,
                user: {
                    ...prevState.user,
                },
                validationMessages: {
                    ...prevState.validationMessage
                }
            }));
        }

        if (type === 'checkbox') {
            let currRole = this.state.user.roles.indexOf(name);
            if (currRole > -1) {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: [...prevState.user.roles.slice(0, currRole), ...prevState.user.roles.slice(currRole + 1)] }
                    }
                }));
            }
            else {
                let newRoles = this.state.user.roles;
                newRoles.push(name);
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: newRoles }
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let { user } = this.state;

        let userID = JSON.parse(localStorage.getItem('user')).id;

        if (!user.password)
            user.password = '';

        if (!user.confirmPassword)
            user.confirmPassword = '';

        const validation = this.validator.validate(user);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (userID !== user.ID) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                this.setState({ validationMessages: { roles: true } });
            }
        }

        if (user.password.trim() !== user.confirmPassword.trim()) {
            validation.isValid = false;
            this.setState({ validationMessages: { passwords: true } });
        }

        if (validation.isValid) {
            if (isNullOrEmptyGuid(user.id)) {
                POST(CommonEndpoints.ADD_COMPANY_USER, user)
                    .then(res => res.json())
                    .then(e => {
                        if (!e.error) {
                            this.props.history.push('/company/users');
                        } else {
                            let arrays = Object.keys(e).map(k => e[k]);
                            let errors = [].concat.apply([], arrays);
                            this.setState({ errors: errors, loading: false });
                        }
                    });
            } else {
                PUT(CommonEndpoints.EDIT_COMPANY_USER, user)
                    .then(res => res.json())
                    .then(e => {
                        if (!e.error) {
                            this.props.history.push('/company/users');
                        } else {
                            let arrays = Object.keys(e).map(k => e[k]);
                            let errors = [].concat.apply([], arrays);
                            this.setState({ errors: errors, loading: false });
                        }
                    });
            }
        } else {
            this.setState({ loading: validation.isValid });
        }
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    render() {
        const { loading, warning, errors, user, validationMessages, passwordNumberCheck, passwordSpecialCharCheck, passwordLengthCheck, passwordInputType, passwordInputIcon } = this.state;

        const loggedInUser = JSON.parse(localStorage.getItem('user'));

        const validation = this.submitted ?
            this.validate(user) :
            this.state.validation;

        const userID = JSON.parse(localStorage.getItem('user')).id;

        if (userID !== user.ID && this.submitted) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                validationMessages.roles = true;
            }
        }

        return (
            <Container fluid>
                <h2>{user.id ? 'Edit User' : 'Add User'}</h2>
                <Link to='/company/users' className="link-button">
                    <Button variant="link" className="button-nav-link mb-3">{"< Back to Users"}</Button>
                </Link>
                {warning && <Alert bsStyle="warning">{warning}</Alert>}
                {loading ? <LoadingBar /> : null}
                {
                    <Row>
                        <Col sm={12} md={6}>
                            {errors.length > 0 && <Card className="card-danger my-3">
                            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                <Card.Body>
                                    {errors.map((e, i) =>  e ? <p key={i}>{e}</p> : null)}
                                </Card.Body>
                            </Card>}
                            <Form onSubmit={this.handleSubmit} className="mt-4">
                                <input type="hidden" name="id" value={user.id} />

                                <fieldset>
                                    <h5>Personal Information</h5>
                                    <FormGroup>
                                        <FormLabel htmlFor="forename">Forename</FormLabel>
                                        <FormControl id="forename" type="text" name="forename" value={user.forename} onChange={this.handleInputChange} />
                                        <span className="text-danger">{validation.forename.message}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="surname">Surname</FormLabel>
                                        <FormControl id="surname" type="text" name="surname" value={user.surname} onChange={this.handleInputChange} />
                                        <span className="text-danger">{validation.surname.message}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="email">Email</FormLabel>
                                        <FormControl id="email" type="email" name="email" autoComplete="off" value={user.email} onChange={this.handleInputChange} />
                                        <span className="text-danger">{validation.email.message}</span>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="password">Password</FormLabel>
                                        <FormControl id="password" type={passwordInputType} name="password" autocomplete="new-password" value={user.password} onChange={this.handleInputChange} />
                                        <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                                        <span className="text-danger">{validation.password.message}</span>
                                    </FormGroup>
                                    <div className="form-password-checklist">
                                        <ul>
                                            <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                                            <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                                            <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                                        </ul>
                                    </div>
                                    <FormGroup>
                                        <FormLabel htmlFor="confirmPassword">Confirm password</FormLabel>
                                        <FormControl id="confirmPassword" type="password" name="confirmPassword" value={user.confirmPassword} onChange={this.handleInputChange} />
                                        <span className="text-danger">{validation.confirmPassword.message}</span>
                                        {validationMessages.passwords && <span className="text-danger">Passwords do not match</span>}
                                    </FormGroup>
                                </fieldset>
                                {loggedInUser.id !== user.id && Object.keys(this.state.roleOptions).map((k) =>
                                    <div key={k} className="py-2">
                                        <h5 className="pb-2">{k} Roles</h5>
                                        {this.state.roleOptions[k].map((r) =>
                                            <FormGroup key={r.id} className="custom-control custom-checkbox mb-1">
                                                <input className="custom-control-input" id={r.id} type="checkbox" name={r.id} onChange={this.handleInputChange} checked={user.roles.indexOf(r.id) > -1} />
                                                <FormLabel className="custom-control-label" htmlFor={r.id}>{r.name}</FormLabel>
                                            </FormGroup>
                                        )}
                                    </div>
                                )}
                                {validationMessages.roles && <span className="text-danger">Select a Role</span>}
                                <FormGroup>
                                    <Button className="btn btn-primary float-right" type="submit">Submit</Button>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

export default EditUser;