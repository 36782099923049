import React from 'react';
import { Form, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap';
import { CountryList } from '../../../common/Constants';
import { ErrorAlert } from '../../../common/Alert';

const NO_SHIPPING_TEXT = "You have selected that this order does not require shipping therefore you do not need to complete this section.";
const NO_PREFERENCE_HELP_TEXT = "Selecting 'No preference' will use your general courier preferences.";
const PREFERENCE_HELP_TEXT = "Selecting a service time will overrule the service times in your general courier preferences for this bulk order only. If we cannot find an option for the service selection you have made, we will return the quickest option we have.";
const findCountryName = (code) => CountryList.find(c => c.code === code).name;
const NO_SHIPPING_AVAILABLE_TEXT = "Sorry, no shipping results have been found for this order. You can return to previous steps and alter the bulk pick, or contact our customer service team on 020 39507860 for alternative options.";

const NoShipping = () => (
    <React.Fragment>
        <p>{NO_SHIPPING_TEXT}</p>
    </React.Fragment>
);

const ShippingResults = ({ shippingService, toggleShippingService, validation, showDifferentServiceMessage, originalServiceTimePreference }) => (
    <div>
        <h6>Shipping Results</h6>
        <React.Fragment>
            {showDifferentServiceMessage ? <div><p>Sorry, we could not find a {originalServiceTimePreference} shipping option, however we can provide the following shipping option:</p></div> : ''}
            <div className="form-check-detailed mb-3">
                <FormGroup className="custom-control-center custom-checkbox mb-2">
                    <input id={shippingService.id} className="custom-control-input" type="checkbox" onChange={toggleShippingService} checked={shippingService.selected} />
                    <FormLabel className="custom-control-label" htmlFor={shippingService.id}><span>{`${shippingService.name}, ${shippingService.service}, £${shippingService.cost}`}</span></FormLabel>
                </FormGroup>
            </div>
            <span className="text-danger">{validation.selected.message}</span>
        </React.Fragment>
    </div>
);

const ShippingOptions = ({ shippingServiceTime, handleInputChange, fetchShippingOption }) => (
    <div>
        <h6>Shipping Options</h6>
        <p><strong>Please select a service time preference</strong></p>
        <div className="form-input-description">
            <p>{NO_PREFERENCE_HELP_TEXT}</p>
            <p>{PREFERENCE_HELP_TEXT}</p>
        </div>
        <FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-noPreference' name='shippingServiceTime' value={'noPreference'}
                    checked={shippingServiceTime === 'noPreference'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-noPreference">No preference, cheapest option</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-TwentyFourPreTenThirty' name='shippingServiceTime' value={'TwentyFourPreTenThirty'}
                    checked={shippingServiceTime === 'TwentyFourPreTenThirty'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-TwentyFourPreTenThirty">24 hours (pre-10:30)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-TwentyFourPreNoon' name='shippingServiceTime' value={'TwentyFourPreNoon'}
                    checked={shippingServiceTime === 'TwentyFourPreNoon'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-TwentyFourPreNoon">24 hours (pre-noon)</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-TwentyFour' name='shippingServiceTime' value={'TwentyFour'}
                    checked={shippingServiceTime === 'TwentyFour'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-TwentyFour">24 hours</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-OneToTwo' name='shippingServiceTime' value={'OneToTwo'}
                    checked={shippingServiceTime === 'OneToTwo'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-OneToTwo">1-2 days</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-FortyEight' name='shippingServiceTime' value={'FortyEight'}
                    checked={shippingServiceTime === 'FortyEight'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-FortyEight">48 hours</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-TwoToThree' name='shippingServiceTime' value={'TwoToThree'}
                    checked={shippingServiceTime === 'TwoToThree'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-TwoToThree">2-3 days</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-SeventyTwo' name='shippingServiceTime' value={'SeventyTwo'}
                    checked={shippingServiceTime === 'SeventyTwo'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-SeventyTwo">72 hours</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-ThreeToFive' name='shippingServiceTime' value={'ThreeToFive'}
                    checked={shippingServiceTime === 'ThreeToFive'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-ThreeToFive">3-5 days</FormLabel>
            </FormGroup>
            <FormGroup className="custom-control custom-radio mb-0">
                <FormControl className="custom-control-input" type='radio' id='shippingServiceTime-FiveToTen' name='shippingServiceTime' value={'FiveToTen'}
                    checked={shippingServiceTime === 'FiveToTen'} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="shippingServiceTime-FiveToTen">5-10 days</FormLabel>
            </FormGroup>
        </FormGroup>
        <FormGroup>
            <Button variant="secondary" onClick={fetchShippingOption}>Find Shipping Options</Button>
        </FormGroup>
    </div>

);

const ShippingAddress = ({ destinationAddress }) => (
    <div className="mb-4">
        <h6>Shipping Address</h6>
        <p className="mb-1">{destinationAddress.addressLine1}</p>
        {destinationAddress.addressLine2 ? <p className="mb-1">{destinationAddress.addressLine2}</p> : null}
        <p className="mb-1">{destinationAddress.town}</p>
        <p className="mb-1">{destinationAddress.county}</p>
        <p className="mb-1">{findCountryName(destinationAddress.country)}</p>
        <p>{destinationAddress.postcode}</p>
    </div>
);

const Shipping = ({ destinationAddress, shippingServiceTime, handleInputChange, fetchShippingOption, shippingService, toggleShippingService, validation, showDifferentServiceMessage, originalServiceTimePreference, noCourierError }) => (
    <React.Fragment>
        <ShippingAddress destinationAddress={destinationAddress} />
        <ShippingOptions shippingServiceTime={shippingServiceTime} handleInputChange={handleInputChange} fetchShippingOption={fetchShippingOption} />
        {shippingService && shippingService.id !== "" ? <ShippingResults shippingService={shippingService} toggleShippingService={toggleShippingService} validation={validation} showDifferentServiceMessage={showDifferentServiceMessage} originalServiceTimePreference={originalServiceTimePreference} /> : null}
        {noCourierError && <React.Fragment><h6>Shipping Results</h6><ErrorAlert errorMessage={NO_SHIPPING_AVAILABLE_TEXT} /></React.Fragment>}
    </React.Fragment>
);


class BulkOrderShippingResults extends React.Component {
    render() {
        const { shippingRequired, destinationAddress, shippingServiceTime, handleInputChange, fetchShippingOption, shippingService, toggleShippingService, validation, showDifferentServiceMessage, originalServiceTimePreference, noCourierError } = this.props;
        return (
            <Form>
                <fieldset>
                    <h5 className="mb-4">Shipping Results</h5>
                    {shippingRequired === "true" ? <Shipping destinationAddress={destinationAddress} shippingServiceTime={shippingServiceTime} handleInputChange={handleInputChange} fetchShippingOption={fetchShippingOption} shippingService={shippingService} toggleShippingService={toggleShippingService} validation={validation} showDifferentServiceMessage={showDifferentServiceMessage} originalServiceTimePreference={originalServiceTimePreference} noCourierError={noCourierError}/> : <NoShipping />}
                </fieldset>
            </Form>
        );
    }
}

export default BulkOrderShippingResults;