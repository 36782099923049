import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SubNav from './SubNav';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: this.props.title,
            subNavList: this.props.subNavList,
            activeKey: this.props.activeKey,
            headerClass: this.props.headerClass
        }
    }

    render() {
        const { title, subNavList, activeKey, headerClass = null } = this.state

        return (
            <Container className={headerClass ? headerClass : "mb-4"} fluid>
                <Row>
                    <Col md={12}>
                        <h2 className="mb-4">{title}</h2>
                        <SubNav breadcrumbs={subNavList} activeKey={activeKey} />       
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Header;