import React, { Component } from 'react';
import { InfoWindow, Marker } from 'react-google-maps';
import PropTypes from 'prop-types';

export class LocationMarker extends Component {
    displayName = LocationMarker.name;
    constructor(props) {
        super(props);
        this.onToggleInfo = this.onToggleInfo.bind(this);
        this.state = { show: false };
    }
    
    onToggleInfo() {
        this.setState((prevState) => ({ show: !prevState.show }));
    }

    getIconUri(location) {                
        var dot = 'green-dot.png';
        
        if (!location.active) {
            dot = 'yellow-dot.png';
        }

        return `https://maps.google.com/mapfiles/ms/icons/${dot}`;
    }

    render() {
        let location = this.props.location;       

        return (
            <Marker icon={this.getIconUri(location)} position={{ lat: location.address.latitude, lng: location.address.longitude }} onClick={this.onToggleInfo} visible={true}>
                {
                    this.state.show &&
                    <InfoWindow>
                        <dl>
                            <div>
                                <dt>Location Name:</dt>
                                <dd>{location.addressType.type}</dd>
                            </div>
                            <div>
                                <dt>Location Address:</dt>
                                <dd>{location.address.line1}</dd>
                                <dd>{location.address.line2}</dd>
                                <dd>{location.address.town}</dd>
                                <dd>{location.address.country}</dd>
                                <dd>{location.address.postcode}</dd>
                            </div>
                            <div>
                                <dt>Active</dt>
                                <dd>{location.active ? 'Yes': 'No'}</dd>
                            </div>
                            {location.operatingDetails !== null ? 
                            <div>
                                <dt>Opening Hours</dt>
                                {location.operatingDetails.openingHours.map(o => <dd key={o.day}>{o.display}</dd>)}
                                <dt>Services offered</dt>
                                {location.operatingDetails.servicesOffered.map(o => <dd key={o}>{o}</dd>)}
                            </div> : null}
                        </dl>
                    </InfoWindow>
                }
            </Marker>
        );
    }
}

LocationMarker.propTypes = {
    location: PropTypes.object.isRequired
};
