import JwPagination from 'jw-react-pagination';
import React, { Component } from 'react';
import { Alert, Col, Container, Table } from 'react-bootstrap';
import { sortValuesByDirection, toggleSortDirection } from '../../Utilities';
import { GET, extractData } from '../../Consumer';
import LoadingBar from '../common/LoadingBar';
import Endpoints from '../common/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import SelazarIssueTableRow from './SelazarIssueTableRow';


class SelazarIssuesList extends Component {
    displayName = SelazarIssuesList.name;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            currentPage: [],
            raisedBy: this.props.raisedBy,
            assignedToMe: this.props.assignedToMe,
            issues:[],
            warning:'',
            currentSort: { key: '', direction: 1 }
        };

        this.getIssues = this.getIssues.bind(this);
    }

    componentDidMount() {
       this.getIssues();
    }

    getIssues() {
        let url = this.state.raisedBy ? Endpoints.SELAZAR.ISSUES.GET.RAISED_BY :Endpoints.SELAZAR.ISSUES.GET.RAISED_AGAINST ;

        if(this.state.assignedToMe){
            url = Endpoints.ISSUES.GET.ASSIGNED_TO_ME;
        }

        return GET(url)
        .then(response => response.json())
        .then(result => {
            if (!result.error) {
                this.setState({ issues: extractData(result), loading: false });
            }
            else {
                this.setState({ loading: false });
            }
        });
        }
    
 
    sortIssues = (event) => {
        event.preventDefault();
        let col = event.target.getAttribute("col");
        if (col) {
            const currentSort = toggleSortDirection(this.state.currentSort.key, this.state.currentSort.direction, col);
            const issues = this.state.issues;
            this.setState({
                currentSort: currentSort,
                items: sortValuesByDirection(issues, col, currentSort.direction)
            });
        }
    }

    onChangePage = (page) => {
        this.setState({ currentPage: page });
    }

    render() {
     
        let {issues, currentPage} = this.state;

        return (
            <Container fluid>
                <Col md={12}>
                    {this.state.warning && <Alert bsStyle="warning">{this.state.warning}</Alert>}
       
                    {this.state.loading ? <LoadingBar /> :
                        <Table id="issueTable" className="stock-table" responsive>
                            <thead>
                                <tr>
                                    <th><a onClick={this.sortIssues} col="title" className="sortlink">Title<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="raisedOn" className="sortlink">Raised On<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="raisedBy" className="sortlink">Raised By<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="user" className="sortlink">User<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="issueType" className="sortlink">Issue Type<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="areaOfConcernType" className="sortlink">Area of Concern<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="entityID" className="sortlink">Entity ID<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="interactionSource" className="sortlink">Interaction Source<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="actorCompanies" className="sortlink">Actor Companies<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="notifyCompanies" className="sortlink">Notify Companies<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="assignedUsed" className="sortlink">Assigned User<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="resolvedOn" className="sortlink">Resolved On<FontAwesomeIcon icon={faSort} /></a></th>
                                    <th><a onClick={this.sortIssues} col="resolution" className="sortlink">Resolution<FontAwesomeIcon icon={faSort} /></a></th>
                                </tr>
                            </thead>
                            <tbody>
                                {issues.length ? currentPage.map(r => <SelazarIssueTableRow issue={r}/> ) : <tr><td colSpan={10}>No issues to show.</td></tr>}
                            </tbody>
                        </Table>
                    }
                </Col>
                <Col md={12}>
                    <JwPagination items={issues} onChangePage={this.onChangePage} pageSize={25} />
                </Col>
            </Container>
        );
    }
}
export default SelazarIssuesList;