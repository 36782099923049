import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import PromotionCard from './PromotionCard';
import PromotionCreateByCategory from './PromotionCreateByCategory';

class Promotions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            promotions: [],
            loading: true
        };

        this.refetchMe = this.refetchMe.bind(this);
        this.ListPromotions = this.ListPromotions.bind(this);

        GET(Endpoints.ALL_PROMOTIONS)
            .then(response => response.json())
            .then(data => {
                this.setState({ promotions: extractData(data) || [], loading: false });
            });
    }

    refetchMe() {
        GET(Endpoints.ALL_PROMOTIONS)
            .then(response => response.json())
            .then(data => {
                this.setState({ promotions: extractData(data) || [] });
            });
    }

    ListPromotions(prom) {

        let promos = prom || [];
        if (promos !== []) {
            return promos.map((p, i) => <PromotionCard promotion={p} key={i} refetchMe={this.refetchMe} />);
        }
        else { return <PromotionCard />; }
    }

//    <Col sm={12} md={0}>
//    <PromotionCreateByCompany refetchMe={this.refetchMe} />
//    </Col>

    renderPromotion() {
        let ListOfPromotions = this.ListPromotions(this.state.promotions);
        return (
            <div>
                <h2>Promotion</h2>
                <Row className="margin-bottom">
                    <Col sm={12} md={0}>
                        <PromotionCreateByCategory refetchMe={this.refetchMe} />
                    </Col>    
                </Row>
                <Row className="margin-bottom">
                    <Col sm={12} md={0}>
                        <h2>Active Promotions</h2>
                        {ListOfPromotions}
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <LoadingBar />
            : this.renderPromotion();
        return (
            <div>
                {contents}
            </div>
        );
    }
}

export default Promotions;