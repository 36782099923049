import React, { Component, } from 'react';
import { Container, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { SuccessAlert } from '../../common/Alert';
import Confirm from '../../common/Confirm';
import { checkPermission } from '../../../Utilities';
import { PaymentsSubNavList } from '../../common/Constants';
import Header from '../../common/Header';

import './Payments.scss';

const CONFIRMATION_TITLE = "Remove Card Confirmation";
const CONFIRMATION_MESSAGE = "Are you sure you would like to remove your payment card?"; 
const CONFIRMATION_BUTTON_TEXT = "Remove";

class Payments extends Component {
    render() {
        const { success, showRemoveCardConfirmation, handleRemoveShowHide, handleRemoveAction, onLoadForm, latestDetails, disableCardSuccess } = this.props;

        return (
            <Container fluid>
                <React.Fragment>
                    {success && <Row><Col sm={12} md={6} className="px-2"><SuccessAlert successMessage="New card added successfully"></SuccessAlert></Col></Row>}
                    {disableCardSuccess && <Row><Col sm={12} md={6} className="px-2"><SuccessAlert successMessage="Card successfully disabled"></SuccessAlert></Col></Row>}
                    <Row className="mb-4">
                        <Col sm={12} md={12}>
                            <div className={`payment-card ${latestDetails.active ? "active" : "inactive"}`}>
                                <p className="payment-card-title">Your Card Details</p>
                                <div className="payment-card-mark"></div>
                                <p className="payment-card-number">**** **** **** {latestDetails.cardSummary}</p>
                                <p className="payment-card-name">{latestDetails.name}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col sm={12} md={6}>
                            <h5>Card Status</h5>
                            {latestDetails.active  ? <span className="text-status-green"><strong>Active</strong></span> : <span className="text-status-red"><strong>Inactive</strong></span>}
                            <FormGroup className="float-right mt-5">
                                {latestDetails.active && checkPermission('NewCard', <Button variant="link" className="mr-2" onClick={handleRemoveShowHide}>Remove Card</Button>)}
                                {checkPermission('NewCard', <Button variant="secondary" type="submit" onClick={onLoadForm}>Replace Card</Button>)}
                            </FormGroup>
                        </Col>
                    </Row>
                    {showRemoveCardConfirmation && <Confirm title={CONFIRMATION_TITLE} text={CONFIRMATION_MESSAGE} buttonText={CONFIRMATION_BUTTON_TEXT} buttonVariant="danger" handleConfirmClose={handleRemoveShowHide} handleConfirmAction={handleRemoveAction} />}
                </React.Fragment>
            </Container>
        )
    }
}

export default Payments;
