import React, { Component } from 'react';
import { Button, Col, Row, Container, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { POST_FILE, extractData, GET } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Stepper from '../../common/Wizard/Stepper';
import StepperButtons from '../../common/Wizard/StepperButtons';
import Endpoints from '../../common/Endpoints';
import FormValidator from '../../common/FormValidator';
import { defaultGuid } from '../../../Utilities';
import Header from '../../common/Header';
import { InventorySubNavList } from '../../common/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTruckMonster } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert } from '../../common/Alert';
import ConsignmentDetails from './StockUploadWizard/ConsignmentDetails';
import FileUpload from './StockUploadWizard/FileUpload';
import CsvConfirmation from './StockUploadWizard/CsvConfirmation';
import Confirm from '../../common/Confirm';
import { ErrorAlert } from '../../common/Alert';

const steps = ["Inventory Details", "Shipping & Labelling", "Confirmation"];
const cancelLink = "/supplier/stockconsignments"

const MODAL_TITLE = "Confirm Stock Box Quantity";
const MODAL_CANCEL = "No, return and edit stock details";

const STOCK_BOX_MODAL_LIMIT = 150;
const BOX_CONFIGURATION_MODAL_CONFIRM = "Yes";

const PFP_MODAL_TITLE = "Pick from Pallet Alert";
const PFP_MODAL_CANCEL = "No, return and edit stock details";

const ITEM_COUNT_KEY = "totalItemCount";
const STOCK_BOX_COUNT_KEY = "stockBoxCount";

const getBoxConfigurationModalText = (isPallet, boxQuantity, totalItemCount) => `You have indicated that this ${isPallet === "true" ? "pallet" : "shelf consignment"} will have ${parseInt(boxQuantity, 10)} stock boxes with ${parseInt(totalItemCount, 10)} items in total, is this correct?`;
const getPickFromPalletBoxConfigModalText = () => "You have indicated that your stock box size is above our shelf storage restrictions (200cm x 60cm x 65cm x 70kg). This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?";
const getPickFromPalletItemModalText = () => "You have selected to upload 1 or more pick from pallet items. This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?";

class UploadStockCsv extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'supplierPrintedLabels',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please choose a label printing method'
            }
        ]);

        this.isPalletValidator = new FormValidator([
            {
                field: 'isPallet',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please confirm if consignment is a pallet'
            }
        ]);

        this.state = {
            data: {
                id: defaultGuid,
                companyID: defaultGuid,
                fileName: '',
                labelsAffixed: false,
                processedBy: null,
                dateReceived: null,
                dateProcessed: null,
                supplierPrintedLabels: '',
                shippingReference: '',
                isPallet: 'true',
                boxHeight: 0.00,
                boxWidth: 0.00,
                boxDepth: 0.00,
                boxWeight: 0.00,
                pickFromPallet: false,
                containsMixed: false,
                stockBoxIsPfp: false
            },
            activeStep: 0,
            files: [],
            loading: true,
            error: false,
            permitted: false,
            results: [],
            message: '',
            validation: this.validator.valid(),
            validationMessages: {
                boxHeightError: false,
                boxWidthError: false,
                boxDepthError: false,
                boxWeightError: false
            },
            isPalletValidation: this.isPalletValidator.valid(),
            labelChoice: {
                stockBox: false,
                product: false
            },
            boxConfigurations: [],
            pfpValidation: [],
            showPfpValidation: false,
            showNoInputValidation: false,
            showBoxConfigurationModal: false,
            showPickFromPalletItemModal: false,
            showPickFromPalletBoxModal: false,
            palletCount: 0,
            errors: []
        };
    }

    validate = (data) => this.validator.validate(data);
    validatePallet = (data) => this.isPalletValidator.validate(data);

    componentDidMount() {
        this.validateAccount();
    }

    updateNextStep = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1
        }));
    }

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }));
    };

    handleEditStep = (step) => {
        this.setState({ activeStep: step });
    };

    validateAccount = () => {
        return GET(Endpoints.FINANCE.CARD.HAS_VALID_DETAILS)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    console.log(res.message);
                    this.setState({ loading: false });
                }
                else {
                    const existing = extractData(res);
                    existing ? this.setState({ loading: false, permitted: true }) : this.setState({ loading: false });
                }
            })
            .catch(e => console.log(e));
    }

    validateBox = (stock) => {
        const { validationMessages } = this.state;

        if (stock.isPallet === 'false') {
            validationMessages.boxHeightError = (stock.boxHeight <= 0 || stock.boxHeight === '' || stock.boxHeight === null);
            validationMessages.boxWidthError = (stock.boxWidth <= 0 || stock.boxWidth === '' || stock.boxWidth === null);
            validationMessages.boxDepthError = (stock.boxDepth <= 0 || stock.boxDepth === '' || stock.boxDepth === null);
            validationMessages.boxWeightError = (stock.boxWeight <= 0 || stock.boxWeight === '' || stock.boxWeight === null);
        } else {
            validationMessages.boxHeightError = false;
            validationMessages.boxWidthError = false;
            validationMessages.boxDepthError = false;
            validationMessages.boxWeightError = false;
        }

        this.setState({ validationMessages: validationMessages, showNoInputValidation: false, message: "" })
        return validationMessages;
    }

    removeFile = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const idx = e.target.getAttribute('index');
        const files = [
            ...this.state.files.slice(0, idx),
            ...this.state.files.slice(idx + 1)
        ];

        this.setState({ files: files });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            this.setState({ results: [], error: true, message: "File could not be uploaded. Please ensure the file type is CSV and the file size is under 1MB." });
        }

        this.setState(prevState => ({
            files: prevState.files.concat(acceptedFiles)
        }));
    }

    handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            const { checked } = e.target;
            value = checked;

            this.setState(prevState => ({
                ...prevState,
                labelChoice: {
                    ...prevState.labelChoice,
                    ...{ [name]: value }
                }
            }));
        } else {
            if (e.target.getAttribute('parent')) {
                const parent = e.target.getAttribute('parent');
                const parentValue = this.state.data[parent];
                parentValue[name] = value;

                name = parent;
                value = parentValue;
            }

            this.setState(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleBoxConfigurationModalClose = () => this.setState({ showBoxConfigurationModal: false, loading: false });
    handleBoxConfigurationModalConfirm = () => {
        this.setState({ showBoxConfigurationModal: false, loading: false });
        this.updateNextStep();
    }

    handlePickFromPalletBoxModalClose = () => this.setState({ showPickFromPalletBoxModal: false, loading: false });
    handlePickFromPalletBoxModalConfirm = () => {
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                isPallet: "true",
                pickFromPallet: true
            },
            showPickFromPalletBoxModal: false
        }));

        this.boxValidation();
    }

    handlePickFromPalletItemModalClose = () => this.setState({ showPickFromPalletItemModal: false, loading: false });
    handlePickFromPalletItemModalConfirm = () => {
        this.setState(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                isPallet: "true",
                pickFromPallet: true
            },
            showPickFromPalletItemModal: false
        }));

        this.boxValidation();
    }

    renderForbidden = () => {
        return (<Card className="card-danger mb-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>No card details found, please add card details to your account to upload stock.</Card.Body>
        </Card>);
    }

    handleNext = async () => {
        const { activeStep, data } = this.state;

        if (activeStep === 0) {
            if (this.state.files.length && parseInt(this.state.palletCount) > 0) {
                let isValid = true;

                if (data.isPallet === "false") {
                    const validation = this.isPalletValidator.validate(data);
                    const validationMessages = this.validateBox(this.state.data);

                    if (validationMessages.boxHeightError || validationMessages.boxWidthError || validationMessages.boxDepthError || validationMessages.boxWeightError) {
                        validation.isValid = false;
                        isValid = false;
                    }
                }

                // Validate SUID item upload quantity 
                const suidItemCountIsValid = await this.validateSuidUploadQuantity();
                if (typeof suidItemCountIsValid !== "boolean") {
                    this.setState({
                        loading: false,
                        message: suidItemCountIsValid.message,
                        showNoInputValidation: false,
                        showSuidItemCountValidation: false,
                        showStockCSVFormattingValidation: true
                    });
                } else if (suidItemCountIsValid && isValid) {
                    this.pfpValidation();
                } else if (!suidItemCountIsValid) {
                    this.setState({
                        loading: false,
                        message: "",
                        showNoInputValidation: false,
                        showStockCSVFormattingValidation: false,
                        showSuidItemCountValidation: true
                    });
                } else {
                    this.setState({ loading: false });
                }

            } else if (!this.state.files.length) {
                this.setState({
                    results: [],
                    message: "No file selected, ensure a file is selected for upload.",
                    showStockCSVFormattingValidation: false,
                    showSuidItemCountValidation: false,
                    showNoInputValidation: true,
                });
            } else if (parseInt(this.state.palletCount) < 1) {
                this.setState({
                    results: [],
                    message: "No quantity of consignments selected.",
                    showStockCSVFormattingValidation: false,
                    showSuidItemCountValidation: false,
                    showNoInputValidation: true
                });
            }
        } else if (activeStep === 1) {
            const validation = this.validator.validate(data);
            this.setState({ validation: validation });
            validation.isValid && this.updateNextStep();
        } else if (activeStep === 2) {
            this.setState({ loading: true });
            await this.onSubmit();
            const { error, results, errors } = this.state;
            (errors.length === 0) && !error && this.updateNextStep();
        }
    };

    displayNoInputValidation = () => {
        return <Card className="card-danger mb-3 mt-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>
                <p>{this.state.message}</p>
            </Card.Body>
        </Card>;
    }

    displayModelValidation = () => <ErrorAlert errorMessage={this.state.message} />
    

    displaySuidItemCountValidation = () => {
        return <Card className="card-danger mb-3 mt-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>
                <p>You've attempted to upload more than 1000 items with SUIDs, requiring unique labels to be generated. We currently do not support this functionality. Please reduce the quantity of repeated consignments, or split your CSV into smaller uploads and try again.</p>
            </Card.Body>
        </Card>;
    }

    validateSuidUploadQuantity = async () => {
        const { data, palletCount } = this.state;

        if (this.state.files.length) {
            this.setState({ loading: true });

            let formData = new FormData();
            this.state.files.forEach((f, i) => formData.append(`files`, this.state.files[i], f.name));
            formData.append('data', JSON.stringify(data));
            formData.append('numberToLoop', parseInt(palletCount));

            return await POST_FILE(Endpoints.STOCK.POST.VALIDATE_CSV, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    if (!response.error) {
                        const data = extractData(response);
                        if (data) {
                            return true;
                        } else {
                            return false;
                        }
                    } else return { message: response.message };
                });
        }
    }

    separateErrors = (validationErrors, message) => {
        message.split(',').forEach((f, i) => validationErrors.push(f));
        return validationErrors;
    }

    onSubmit = async () => {
        const { data, palletCount } = this.state;

        if (this.state.files.length) {
            this.setState({ loading: true, errors: [] });

            let formData = new FormData();
            this.state.files.forEach((f, i) => formData.append(`files`, this.state.files[i], f.name));
            formData.append('data', JSON.stringify(data));
            formData.append('numberToLoop', parseInt(palletCount));

            return await POST_FILE(Endpoints.STOCK.POST.UPLOAD_CSV, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    const data = extractData(response);
                    if (!response.error) {
                        let validationErrors = [];
                        data.forEach((f, i) => (f.isError === true) ? validationErrors = this.separateErrors(validationErrors, f.message) : "");

                        if(validationErrors.length > 0) this.setState({ loading: false, error: true, errors: validationErrors, message: 'An error occurred, please try again later.'});
                        else this.setState({ loading: false, error: false, files: [], message: 'Stock records successfully uploaded.', results: data, palletCount: 0 });
                    } 
                    else this.setState({ loading: false, error: true, errors: [], files: [], message: 'An error occurred, please try again later.' });                 
                });
        }

        !this.state.files.length && this.setState({ results: [], error: true, errors: [], message: "No file selected, Ensure a file is selected for upload." });
    }

    onPalletCountChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            ...{ [name]: value }
        }));
    }

    generatePalletCountOptions = () => {
        const optionsArray = [<option key="0" value="0">Please select the quantity of consignments...</option>];
        for (let i = 1; i <= 30; i++) optionsArray.push(<option key={i} value={i}>{i.toString()}</option>);
        return optionsArray;
    }

    boxValidation = async () => {
        const { files } = this.state;
        if (files.length) {
            const formData = new FormData();
            files.forEach(f => formData.append("files", f, f.name));
            return await POST_FILE(Endpoints.STOCK.POST.STOCK_BOX_VALIDATION, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    const data = extractData(response);
                    if (!response.error) {
                        if (data.length) {
                            var stockBoxCount = data.reduce((a, b) => a + (b[STOCK_BOX_COUNT_KEY] || 0), 0);
                            if (stockBoxCount > STOCK_BOX_MODAL_LIMIT) {
                                this.setState({
                                    boxConfigurations: data,
                                    showBoxConfigurationModal: true
                                });
                            } else {
                                this.setState({
                                    boxConfigurations: data,
                                    loading: false
                                });
                                this.updateNextStep();
                            }
                        } else {
                            this.setState({ loading: false });
                        }
                    } else {
                        this.setState({ loading: false });
                    }
                });
        }
    }

    pfpValidation = async () => {
        const { files, data } = this.state;
        const isPallet = data.isPallet;

        if (files.length) {
            this.setState({ loading: true, showSuidItemCountValidation: false, showNoInputValidation: false, showPfpValidation: false, showStockCSVFormattingValidation: false });
            const formData = new FormData();
            files.forEach(f => formData.append("files", f, f.name));

            return await POST_FILE(Endpoints.STOCK.POST.STOCK_PFP_VALIDATION, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    const data = extractData(response);

                    if (!response.error) {
                        if (data.length) {
                            const allPfp = data.every(p => p.pickFromPallet);
                            const containsMixed = data.some(p => p.mixedPallet);
                            const stockBoxIsPfp = data.some(p => p.stockBoxIsPFP);
                            const stockBoxCount = data.reduce((a, b) => a + (b[STOCK_BOX_COUNT_KEY] || 0), 0);
                            const itemCount = data.reduce((a, b) => a + (b[ITEM_COUNT_KEY] || 0), 0);

                            if (containsMixed) {
                                this.setState(prevState => ({
                                    ...prevState,
                                    pfpValidation: data,
                                    stockBoxCount: stockBoxCount,
                                    totalItemCount: itemCount,
                                    showPfpValidation: true,
                                    loading: false
                                }));
                            } else if (allPfp && !containsMixed && isPallet === "true") {
                                this.setState({
                                    pfpValidation: { ...data },
                                    stockBoxCount: stockBoxCount,
                                    totalItemCount: itemCount,
                                    showPickFromPalletItemModal: true
                                });
                            } else if (allPfp && !containsMixed && isPallet === "false") {
                                this.setState({
                                    pfpValidation: { ...data },
                                    stockBoxCount: stockBoxCount,
                                    totalItemCount: itemCount
                                });
                                this.boxValidation();
                            } else if (stockBoxIsPfp && !containsMixed) {
                                this.setState({
                                    pfpValidation: { ...data },
                                    stockBoxCount: stockBoxCount,
                                    totalItemCount: itemCount,
                                    showPickFromPalletBoxModal: true
                                });
                            } else if (!stockBoxIsPfp && !containsMixed) {
                                this.setState({
                                    pickFromPallet: false,
                                    stockBoxCount: stockBoxCount,
                                    totalItemCount: itemCount
                                });
                                this.boxValidation();
                            }
                        }
                    } else {
                        this.setState({ showPfpValidation: false });
                        this.boxValidation();
                    }
                });
        }
    }

    handlePickFromPalletModalClose = () => this.setState({ showPickFromPalletModal: false });
    handlePickFromPalletModalConfirm = () => {
        this.setState(prevState => ({
            ...prevState,
            stock: {
                ...prevState.stock,
                isPallet: "true",
                pickFromPallet: true
            },
            showPickFromPalletModal: false
        }));
    }

    displayPfpValidation = () => {
        const { pfpValidation } = this.state;

        return (<Card className="card-danger mb-3 mt-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>
                <p>The following products qualify for pick from pallet. This could be due to the product or the stock box configuration.</p>
                {pfpValidation.map(p => p.pfpItems.length ?
                    <React.Fragment>
                        <ul>
                            {p.pfpItems.map((item, i) => <li key={`${p.fileName}-${item.name}-${i}`}>{item.skuCode ? `${item.name} - SKU: ${item.skuCode}` : item.name}</li>)}
                        </ul>
                    </React.Fragment> : null)}
                <p>Pick from pallet qualifiers are as follows:</p>
                <ul>
                    <li>The product is too big, exceeding a weight of 5 kg, or a volume of 20,250 cmᶾ. You can calculate volume by multiplying height x width x depth</li>
                    <li>1 item per stock box</li>
                    <li>The stock box is too big, exceeding 200cm x 60cm x 65cm and 70kg</li>
                </ul>
                <p>Please remove the product(s) from your CSV and upload them to a separate consignment.</p>
            </Card.Body>
        </Card>)
    }

    renderUploadForm = () => {
        const { data, loading, message, activeStep, validation, validationMessages, results, files, labelChoice, showNoInputValidation, showPfpValidation, showSuidItemCountValidation, showStockCSVFormattingValidation, error, palletCount, errors } = this.state;

        const pickFromPalletAlert = showPfpValidation ? this.displayPfpValidation() : null;
        const suidItemCountAlert = showSuidItemCountValidation ? this.displaySuidItemCountValidation() : null;
        const noInputAlert = showNoInputValidation ? this.displayNoInputValidation() : null;

        return (<React.Fragment>
            {activeStep === 0 ?
                <React.Fragment>
                    <FileUpload
                        files={files}
                        validationMessages={validationMessages}
                        onDrop={this.onDrop}
                        removeFile={this.removeFile}
                        error={error}
                        message={message}
                        noInputAlert={noInputAlert}
                        pickFromPalletAlert={pickFromPalletAlert}
                        suidItemCountAlert={suidItemCountAlert}
                        showStockCSVFormattingValidation={showStockCSVFormattingValidation}
                        palletCount={palletCount}
                        onPalletCountChange={this.onPalletCountChange}
                        generatePalletCountOptions={this.generatePalletCountOptions}
                        isPallet={data.isPallet}
                        handleInputChange={this.handleInputChange}
                        data={data}
                        updateNextStep={this.updateNextStep} />
                    <Row>
                        <Col sm={12} md={6}>
                            <StepperButtons cancelLink={cancelLink} handleNext={this.handleNext} showNext={true} />
                        </Col>
                    </Row>
                </React.Fragment>
                : activeStep === 1 ?
                    <React.Fragment>
                        <Row>
                            <Col sm={12} md={6}>
                                <ConsignmentDetails data={data} handleInputChange={this.handleInputChange} validation={validation} labelChoice={labelChoice} loading={loading} validationMessages={validationMessages} isSingleUpload={false} />
                                <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                            </Col>
                        </Row>
                    </React.Fragment>
                    : activeStep === 2 ?
                        <Row>
                            <Col sm={12} md={6}>
                            
                                <CsvConfirmation errors={errors} data={data} results={results} files={files} handleEditStep={this.handleEditStep} palletCount={palletCount} />
                                <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} nextButtonText="Confirm CSV Upload" />
                            </Col>
                        </Row>
                        : <Row>
                            <Col sm={12} md={6}>
                                <div className="mt-4">
                                    <SuccessAlert successMessage={message}></SuccessAlert>
                                </div>
                                <Link to={"/supplier/stockconsignments"} className="link-button">
                                    <Button variant="primary" className="float-right">Continue to Inventory</Button>
                                </Link>
                                <Button variant="secondary" className="float-right mr-2" onClick={() => window.location.reload()} >Upload Another CSV</Button>
                            </Col>
                        </Row>
            }
        </React.Fragment>);
    }

    render() {
        const { pfpValidation, loading, permitted, activeStep, showBoxConfigurationModal, showPickFromPalletBoxModal, showPickFromPalletItemModal, totalItemCount, stockBoxCount } = this.state;

        return (<React.Fragment>
            <Header title="Inventory" subNavList={InventorySubNavList} activeKey="Send Mixed Products" />
            <Container fluid>
                <Stepper steps={steps} activeStep={activeStep} />
                {loading ? <LoadingBar /> :
                    permitted ? this.renderUploadForm() : this.renderForbidden()
                }
            </Container>

            <Confirm title={MODAL_TITLE} text={getBoxConfigurationModalText(pfpValidation.isPallet, stockBoxCount, totalItemCount)} buttonText={BOX_CONFIGURATION_MODAL_CONFIRM} handleConfirmClose={this.handleBoxConfigurationModalClose} handleConfirmAction={this.handleBoxConfigurationModalConfirm} show={showBoxConfigurationModal} cancelText={MODAL_CANCEL} />
            <Confirm title={PFP_MODAL_TITLE} text={getPickFromPalletBoxConfigModalText()} buttonText="Yes" handleConfirmClose={this.handlePickFromPalletBoxModalClose} handleConfirmAction={this.handlePickFromPalletBoxModalConfirm} show={showPickFromPalletBoxModal} cancelText={PFP_MODAL_CANCEL} size="lg" />
            <Confirm title={PFP_MODAL_TITLE} text={getPickFromPalletItemModalText()} buttonText="Yes" handleConfirmClose={this.handlePickFromPalletItemModalClose} handleConfirmAction={this.handlePickFromPalletItemModalConfirm} show={showPickFromPalletItemModal} cancelText={PFP_MODAL_CANCEL} size="lg" />
        </React.Fragment>
        );
    }
}

export default UploadStockCsv;