import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isNullOrEmptyGuid, checkPermission } from '../../../Utilities';
import { GET, PUT, DELETE, extractData } from '../../../Consumer';
import CommonEndpoints from '../../common/Endpoints';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { toLocalTimeString } from '../../../Utilities';
import LoadingBar from '../../common/LoadingBar';
import Confirm from '../../common/Confirm';
import { ErrorAlert } from '../../common/Alert';

import './Account.scss';

const USER_ERROR_MESSAGE = "There was a problem retrieving the user. Please try again.";

const UserDetails = ({ userId, forename, surname, email, roleOptions, roles, createdDate }) => (
    <Card>
        <Card.Header><strong>User Details</strong></Card.Header>
        <Card.Body>
            <Card.Text><strong>Forename</strong></Card.Text>
            <Card.Text className="mb-2">{forename}</Card.Text>

            <Card.Text><strong>Surname</strong></Card.Text>
            <Card.Text className="mb-2">{surname}</Card.Text>

            <Card.Text><strong>Email</strong></Card.Text>
            <Card.Text className="mb-2">{email}</Card.Text>
            {Object.keys(roleOptions).map((o, i) =>
                <div key={o} className="mb-2">
                    <Card.Text key={`${o}-${i}`}><strong>{o} Roles</strong></Card.Text>
                    {roleOptions[o].map((r, i) =>
                        roles.indexOf(r.id) > -1 && <Card.Text key={`${r.name}-${i}`}>{r.name}</Card.Text>
                    )}
                </div>
            )}
            {createdDate && <React.Fragment>
                <Card.Text><strong>Account Created</strong></Card.Text>
                <Card.Text className="mb-2">{toLocalTimeString(createdDate)}</Card.Text>
            </React.Fragment>}
            {checkPermission('EditUser', <SelazarLinkContainer to={{ pathname: `/company/users/editdetails/${userId}`, state: { returnToAccount: true } }}><Button variant="link" className="float-right p-0">Edit User Details</Button></SelazarLinkContainer>)}
        </Card.Body>
    </Card>
);

const UserPermissions = ({ userPermissions, editLink }) => (
    <Card>
        <Card.Header><strong>Permissions</strong></Card.Header>
        <Card.Body>
            {userPermissions.length ? userPermissions.map((parent) =>
                <React.Fragment>
                    <Card.Text key={`${parent.name}-permissions`}><strong>{parent.name}</strong></Card.Text>
                    {parent.permissions.length ? parent.permissions.filter((permission) => permission.enabled === true).map((permission) =>
                        <Card.Text>{permission.featureEnumName}</Card.Text>
                    ) : null}
                </React.Fragment>
            ) : null}
            {editLink && editLink}
        </Card.Body>
    </Card>
);

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            userID: props.match.params.id || null,
            roleOptions: {},
            userPermissions: [],
            warning: null,
            error: false,
            redirect: false,
            showDeleteUserConfirmation: false,
            showToggleUserConfirmation: false,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                active: true,
                roles: [],
                createdDate: null
            }
        };

        this.fetchUser = this.fetchUser.bind(this);
        this.fetchRoleOptions = this.fetchRoleOptions.bind(this);
        this.fetchUserPermissions = this.fetchUserPermissions.bind(this);
        this.handleToggleShowHide = this.handleToggleShowHide.bind(this);
        this.handleDeleteShowHide = this.handleDeleteShowHide.bind(this);
        this.handleToggleAction = this.handleToggleAction.bind(this);
        this.handleDeleteAction = this.handleDeleteAction.bind(this);
    }

    componentDidMount() {
        this.fetchUser();
        this.fetchRoleOptions();
        this.fetchUserPermissions();
    }

    fetchUser() {
        const { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {
            return GET(CommonEndpoints.GET_USER_WITH_ROLES + userID)
                .then(res => {
                    if (res && res.ok) {
                        return res.json();
                    }
                    else {
                        throw new Error('Error fetching data');
                    }
                })
                .then(result => {
                    if (result) {
                        const data = extractData(result) || [];
                        const error = result.error ? true : false;
                        const user = { ...data, roles: data.userRoles.map((ur) => ur.roleID) };
                        this.setState({ user: user, loading: false, error: error });
                    }
                }).catch(error => {
                    console.error(error);
                    this.setState({ user: [], loading: false, error: true });
                });
        }
    }

    fetchRoleOptions() {
        const companyID = JSON.parse(localStorage.getItem('user')).companyID;
        return GET(CommonEndpoints.GET_ROLE_OPTIONS_FOR_COMPANY + companyID)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result) || [];
                    const warning = result.error ? result.message : null;
                    this.setState({ roleOptions: data, loading: false, warning: warning });
                }
            }, err => {
                console.error(err);
                this.setState({ loading: false, warning: err.toString() });
            });
    }

    fetchUserPermissions() {
        const { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {

            return GET(CommonEndpoints.GET_FEATURE_BREAKDOWN_FOR_USER + userID)
                .then(response => response.json())
                .then(data => {
                    this.setState({ userPermissions: extractData(data) || [], loading: false });
                });
        }
    }

    deleteUser(user) {
        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to delete user' });
            return;
        }

        if (window.confirm(`Are you sure you want to delete ${user.forename} ${user.surname}?`)) {
            this.setState({ loading: true });

            return DELETE(CommonEndpoints.DELETE_USER + user.id)
                .then(r => r.json())
                .then(({ data, error, message }) => {
                    if (data === true) {
                        this.setState({ loading: false, redirect: true });
                    }
                    if (error) {
                        this.setState({ warning: message });
                    }
                });
        }
    }

    handleToggleShowHide = () => this.setState((prevState) => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation }));
    handleDeleteShowHide = () => this.setState((prevState) => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation }));

    handleToggleAction() {
        const { user } = this.state;
        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to toggle user' });
            return;
        }

        this.setState({ loading: true, showToggleUserConfirmation: false });

        return PUT(CommonEndpoints.TOGGLE_USER + user.id)
            .then(r => r.json())
            .then(({ data, error, message }) => {
                if (data) {
                    this.fetchUser();
                }
                if (error) {
                    this.setState({ warning: message });
                }
            });
    }

    handleDeleteAction() {
        const { user } = this.state;
        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to delete user' });
            return;
        }

        this.setState({ loading: true, showDeleteUserConfirmation: false });

        return DELETE(CommonEndpoints.DELETE_USER + user.id)
            .then(r => r.json())
            .then(({ data, error, message }) => {
                if (data === true) {
                    this.setState({ loading: false, redirect: true });
                }
                if (error) {
                    this.setState({ warning: message });
                }
            });
    }

    render() {
        const { user, warning, loading, redirect, roleOptions, userPermissions, showToggleUserConfirmation, showDeleteUserConfirmation, error } = this.state;
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const currentUserID = currentUser.id;
        const isSameUser = currentUserID === user.id;
        const toggleMessage = `Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${user.forename} ${user.surname}?`;
        const deleteMessage = `Are you sure you want to delete ${user.forename} ${user.surname}?`
        const editPermissions = checkPermission('EditPermission', <SelazarLinkContainer to={{ pathname: '/users/permissions', state: { user: user } }}><Button variant="link" className="float-right p-0">Edit User Permissions</Button></SelazarLinkContainer>);

        return (
            <Container fluid>
                <h2>User Account</h2>
                <Link to='/company/users' className="link-button">
                    <Button variant="link" className="button-nav-link">{"< Back to Users"}</Button>
                </Link>
                {loading ?
                    <LoadingBar /> :
                    redirect ?
                        <Redirect to='/company/users' /> :
                        <React.Fragment>
                            {error ?
                                <Row className="mt-4">
                                    <Col sm={12} md={6}>
                                        <ErrorAlert errorMessage={USER_ERROR_MESSAGE} />
                                    </Col>
                                </Row> :
                                <React.Fragment>
                                    <Row className="mt-4">
                                        <Col sm={12} md={6}>
                                            {warning && <Card className="card-danger mb-3">
                                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                                                <Card.Body>{warning}</Card.Body>
                                            </Card>}
                                            <UserDetails userId={user.id} forename={user.forename} surname={user.surname} email={user.email} roleOptions={roleOptions} roles={user.roles} createdDate={user.createdDate} />
                                        </Col>
                                        <Col sm={12} md={6}>
                                            {user.lastLogin && <p>Last Login <strong>{toLocalTimeString(user.lastLogin)}</strong></p>}
                                            <p><strong>Actions</strong></p>
                                            <div className="link-button">
                                                <SelazarLinkContainer to={{ pathname: '/company/users/password', state: { user: user } }}>
                                                    <Button variant="secondary" className="d-block account-button-action link-button mb-2">Change Password</Button>
                                                </SelazarLinkContainer>
                                            </div>
                                            {!isSameUser && checkPermission('ToggleUser', <Button variant={user.active ? "danger" : "secondary"} className="d-block account-button-action mb-2" onClick={this.handleToggleShowHide}>{user.active ? "Deactivate User" : "Activate User"}</Button>)}
                                            {!isSameUser && checkPermission('DeleteUser', <Button variant="danger" className="d-block account-button-action mb-2" onClick={this.handleDeleteShowHide}>Delete User</Button>)}
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col sm={12} md={6}>
                                            <UserPermissions userPermissions={userPermissions} editLink={editPermissions} />
                                        </Col>
                                    </Row>
                                    {showToggleUserConfirmation && <Confirm title={user.active ? "Confirm Deactivation" : "Confirm Activation"} text={toggleMessage} buttonText={user.active ? "Deactivate" : "Activate"} buttonVariant={user.active ? "danger" : "secondary"} handleConfirmClose={this.handleToggleShowHide} handleConfirmAction={this.handleToggleAction} />}
                                    {showDeleteUserConfirmation && <Confirm title={"Confirm Deletion"} text={deleteMessage} buttonText={"Delete"} buttonVariant="danger" handleConfirmClose={this.handleDeleteShowHide} handleConfirmAction={this.handleDeleteAction} />}
                                </React.Fragment>}
                        </React.Fragment>
                }

            </Container>
        );
    }
}

export default Account;