import React, { Component } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import { PreferencesSubNavList } from '../../common/Constants'
import Header from '../../common/Header';

class Security extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            customerSupportAccess: {}
        };
    }

    async componentDidMount() {
        await this.fetchSecurityPreference();
        this.setState({ loading: false });
    }

    async fetchSecurityPreference() {
        return await GET(Endpoints.RETAILER.GET.CUSTOMER_SERVICE_ACCESS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ customerSupportAccess: extractData(results) });
            });
    }

    handleViewChangePreferenceClick = (customerSupportAccess) => this.props.history.push({ pathname: "/retailer/preferences/customersupportaccess", state: { customerSupportAccess: customerSupportAccess } });

    render() {
        const { loading, customerSupportAccess } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Security" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="mb-3">Security</h5>
                                <p className="my-1"><strong>Customer Support Access</strong></p>
                                <p className="my-1">{customerSupportAccess.access ? "Enabled" : "Disabled"}</p>
                                <Button variant="link" className="p-0" onClick={() => this.handleViewChangePreferenceClick(customerSupportAccess)}>Change Preference</Button>
                            </Col>
                        </Row>
                    </Container>}
            </React.Fragment>
        )
    }
}

export default Security;