import React, { Component } from 'react';
import { ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { Marker } from 'react-google-maps';
import CommonEndpoints from '../../common/Endpoints';
import SelazarMap from '../../common/SelazarMap';
import PropTypes from 'prop-types';
import { PUT } from '../../../Consumer';
import { checkPermission } from '../../../Utilities';

export class RegularCollectionCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            regularCollection: this.props.regularCollection
        };
        
    }

    renderActive(value) {
        if (value === true) {
            return <span className="label label-success">Active</span>;
        }
        else if (value === false) {
            return <span className="label label-warning">Inactive</span>;
        }
        else {
            return <span className="label label-danger">Pending</span>;
        }
    }

    toggleRegularCollection() {
        const { regularCollection } = this.state;

        let endpoint = regularCollection.active === true ? CommonEndpoints.REGULAR_COLLECTIONS.PUT.DEACTIVATE : CommonEndpoints.REGULAR_COLLECTIONS.PUT.ACTIVATE;

        PUT(endpoint + regularCollection.id)
            .then(response => response.json())
            .then(() => this.props.reload())
            .catch(error => alert(error));
    }

    render() {
        let { regularCollection } = this.state;
        let companyAddress = regularCollection.companyAddress;

        let isMarkerShown = true;
        let centreCoords = { lat: companyAddress.address.latitude, lng: companyAddress.address.longitude };

        let settings = {
            centre: centreCoords,
            zoom: 15
        };

        let message = (regularCollection.active) ? "Are you sure you wish to deactivate this regular collection?" : "Are you sure you wish to activate this regular collection?";
        
        let toggleButton = checkPermission("EditRegularCollection", <button className="btn btn-default card-button" onClick={(e) => { if (window.confirm(message)) this.toggleRegularCollection(e); }}>(Off) Toggle</button>);

        return (
            <div className="map-card">
                <div className="map-card-top">
                    {
                        centreCoords.lat && centreCoords.lng ?
                        <SelazarMap settings={settings}
                            googleMapURL={CommonEndpoints.MAPS_URL}
                            loadingElement={<div style={{ height: "100%" }} />}
                            containerElement={<div style={{ height: "200px", min_height: "100%", width: "100%" }} />}
                            mapElement={<div style={{ height: "100%" }} />}
                            isMarkerShown={isMarkerShown}
                        >
                            <Marker position={settings.centre} />
                        </SelazarMap> :
                        <div style={{ height:'200px', min_height:'100%', width:'100%', textAlign:'center', verticalAlign:'center' }}>
                            <p><span>warning</span> Missing map information</p>
                        </div> 
                    }
                    
                </div>

                <div className="map-card-body">
                    <div className="map-card-text">                        
                        <h4>{this.renderActive(regularCollection.active)}</h4>
                        <dl>
                            <dd>{companyAddress.address.line1}</dd>
                            <dd>{companyAddress.address.line2}</dd>
                            <dd>{companyAddress.address.town}</dd>
                            <dd>{companyAddress.address.postcode}</dd>
                        </dl>
                        <ButtonToolbar>
                            <ButtonGroup bsSize="sm">                                
                                {toggleButton}
                            </ButtonGroup>
                        </ButtonToolbar>
                    </div>               
                </div>
            </div>
        );
    }

}

RegularCollectionCard.propTypes = {   
    regularCollection: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
};

export default RegularCollectionCard;