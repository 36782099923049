import React from 'react';
import PropTypes from 'prop-types';
import SelazarLinkContainer from './SelazarLinkContainer';

const MenuCard = ({ title, link, glyph }) => {
    return (
        <div className="card">
            <div className="card-body-menu">
                <SelazarLinkContainer to={link}>
                    <a>
                        <span>{glyph}</span>
                        <h3>{title}</h3>
                    </a>
                </SelazarLinkContainer>
        </div>
        </div>
    );
};

MenuCard.propTypes = {
    glyph: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default MenuCard;