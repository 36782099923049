import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Row, Col, FormCheck } from 'react-bootstrap';
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import { isNullOrEmptyGuid, defaultGuid } from '../../../Utilities';
import { GET, PUT, POST, extractData } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';

class EditService extends Component {
    constructor(props) {
        super(props);

        this.serviceValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Name is required'
            },
            {
                field: 'description',
                method: 'isEmpty',
                validWhen: false,
                message: 'Description is required'
            },
            {
                field: 'serviceCode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Service code is required'
            },
            {
                field: 'fromServiceRegionID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Service region is required'
            },
            {
                field: 'toServiceRegionID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Service region is required'
            },
            {
                field: 'serviceTime',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Service time is required'
            },
            {
                field: 'maxHeight',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Max height must be greater than 0'
            },
            {
                field: 'maxWidth',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Max width must be greater than 0'
            },
            {
                field: 'maxDepth',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Max depth must be greater than 0'
            },
            {
                field: 'maxWeight',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Max weight must be greater than 0'
            }
            //{
            //    field: 'price',
            //    method: 'isInt',
            //    validWhen: true,
            //    args: [{ min: 1 }],
            //    message: 'Price required'
            //}
        ]
        );

        this.state = {
            companyService: {
                id: defaultGuid,
                editable: false,
                active: false,
                companyID: defaultGuid,
                serviceID: defaultGuid,
                service: {
                    id: defaultGuid,
                    name: "",
                    description: "",
                    serviceCode: "",
                    toServiceRegionID: defaultGuid,
                    fromServiceRegionID: defaultGuid,
                    serviceTime: 0,
                    maxHeight: 0,
                    maxWidth: 0,
                    maxDepth: 0,
                    maxWeight: 0,
                    insuranceAmount: 0.00,
                    tracked: false,
                    signed: false,
                    liquid: false,
                    batteries: false,
                    active: false
                },
                currentPrice:
                {
                    id: defaultGuid,
                    companyServiceID: defaultGuid,
                    price: 0.00,
                    active:false
                }
            },
            serviceRegions: [],
            serviceTimes: [],
            serviceValidation: this.serviceValidator.valid(),
            loading: true
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCompanyService = this.getCompanyService.bind(this);
        this.getServiceRegions = this.getServiceRegions.bind(this);
        this.getServiceTimes = this.getServiceTimes.bind(this);
    }

    componentDidMount() {
        Promise.all([this.getCompanyService(), this.getServiceRegions(), this.getServiceTimes()]);
    }

    getCompanyService() {
        const { companyServiceID } = this.props.location.state;
        let user = JSON.parse(localStorage.getItem('user'));

        return GET(Endpoints.GET_SERVICE + companyServiceID)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ companyService: extractData(result), loading: false });
                }
                else {
                    this.setState(prevState => ({
                        ...prevState,
                        companyService: {
                            ...prevState.companyService,
                            ...{ companyID: user.companyID }
                        },
                        loading:false
                    }));
                }
            });
    }

    getServiceRegions() {
        return GET(Endpoints.GET_ALL_SERVICE_REGIONS)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ serviceRegions: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    getServiceTimes() {
        return GET(Endpoints.GET_SERVICE_TIMES)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ serviceTimes: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    handleInputChange(e) {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.companyService[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            companyService: {
                ...prevState.companyService,
                ...{ [name]: value }
            }
        }));


    }

    handleSubmit(event) {
        event.preventDefault();
        const { companyService } = this.state;

        const serviceValidation = this.serviceValidator.validate(companyService.service);
        this.setState({ serviceValidation: serviceValidation, loading: serviceValidation.isValid });
        this.submitted = true;

        if (serviceValidation.isValid) {
            if (!isNullOrEmptyGuid(companyService.id)) {
                PUT(Endpoints.UPDATE_SERVICE, companyService)
                    .then(response => response.json()).then(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/courier/services');
                    });
            }
            else {
                POST(Endpoints.CREATE_SERVICE, companyService)
                    .then(response => response.json()).then(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/courier/services');
                    });
            }
        }
    }

    renderForm(companyService) {
        let serviceValidation = this.submitted ?
            this.serviceValidator.validate(companyService.service) :
            this.state.serviceValidation;

        let { loading } = this.state;
        const { serviceRegions } = this.state;
        const { serviceTimes } = this.state;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <input type="hidden" name="id" value={companyService.id} />
                    <input type="hidden" name="companyID" value={companyService.companyID} />
                    <input type="hidden" name="active" value={true} />
                    <input type="hidden" parent="currentPrice" name="id" value={companyService.currentPrice.id} />
                    <input type="hidden" parent="currentPrice" name="companyServiceID" value={companyService.id} />
                    <input type="hidden" parent="currentPrice" name="active" value={true}/>
                    <FormGroup>
                        <FormLabel htmlFor="nameField">Name</FormLabel>
                        <FormControl disabled={loading} id="nameField" type="text" parent="service" name="name" value={companyService.service.name} onChange={this.handleInputChange} />
                        <span className="text-danger">{serviceValidation.name.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="descriptionField">Description</FormLabel>
                        <FormControl disabled={loading} id="descriptionField" type="text" parent="service" name="description" value={companyService.service.description} onChange={this.handleInputChange} />
                        <span className="text-danger">{serviceValidation.description.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="serviceCodeField">Service code</FormLabel>
                        <FormControl disabled={loading} id="serviceCodeField" type="text" parent="service" name="serviceCode" value={companyService.service.serviceCode} onChange={this.handleInputChange} />
                        <span className="text-danger">{serviceValidation.serviceCode.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="priceField">Price (£)</FormLabel>
                        <FormControl disabled={loading} id="priceField" type="number" parent="currentPrice" name="price" value={companyService.currentPrice.price} onChange={this.handleInputChange} min="0" step="0.01"/>
                        {/*<span className="text-danger">{serviceValidation.price.message}</span>*/}
                    </FormGroup>

                    <FormGroup>
                        <label>Service Region</label>
                        <Row>
                            <Col md={1}>
                                <FormLabel htmlFor="fromServiceRegionField">From</FormLabel>
                            </Col>
                            <Col md={5}>
                                <FormControl disabled={loading} id="fromServiceRegionField" parent="service" name="fromServiceRegionID" componentClass="select" value={companyService.service.fromServiceRegionID} onChange={this.handleInputChange}>
                                    <option key="" value="">Please Select...</option>;
                                    {serviceRegions.map(serviceRegion => {
                                        return <option key={serviceRegion.id} value={serviceRegion.id}>{serviceRegion.name}</option>;
                                    })}
                                </FormControl>
                                <span className="text-danger">{serviceValidation.fromServiceRegionID.message}</span>
                            </Col>

                            <Col md={1}>
                                <FormLabel htmlFor="toServiceRegionField">To</FormLabel>
                            </Col>
                            <Col md={5}>
                                <FormControl disabled={loading} id="toServiceRegionField" parent="service" name="toServiceRegionID" componentClass="select" value={companyService.service.toServiceRegionID} onChange={this.handleInputChange}>
                                    <option key="" value="">Please Select...</option>;
                            {serviceRegions.map(serviceRegion => {
                                        return <option key={serviceRegion.id} value={serviceRegion.id}>{serviceRegion.name}</option>;
                                    })}
                                </FormControl>
                                <span className="text-danger">{serviceValidation.toServiceRegionID.message}</span>
                            </Col>

                        </Row>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="serviceTimeField">Service Time</FormLabel>
                        <FormControl disabled={loading} id="serviceTimeField" parent="service" name="serviceTime" componentClass="select" value={companyService.service.serviceTime} onChange={this.handleInputChange}>
                            <option key={0} value={0}>Please Select...</option>;
                            {serviceTimes.map(serviceTime => {
                                return <option key={serviceTime.key} value={serviceTime.key}>{serviceTime.value}</option>;
                            })}
                        </FormControl>
                        <span className="text-danger">{serviceValidation.serviceTime.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <label>Max Size (cm)</label>
                        <Row>
                            <Col md={1}>
                                <FormLabel htmlFor="maxHeightField">Height:</FormLabel>
                            </Col>
                            <Col md={3}>
                                <FormControl disabled={loading} id="maxHeightField" type="number" parent="service" name="maxHeight" value={companyService.service.maxHeight} onChange={this.handleInputChange} min="0" />
                                <span className="text-danger">{serviceValidation.maxHeight.message}</span>
                            </Col>
                            <Col md={1}>
                                <FormLabel htmlFor="maxWidthField">Width:</FormLabel>
                            </Col>
                            <Col md={3}>
                                <FormControl disabled={loading} id="maxWidthField" type="number" parent="service" name="maxWidth" value={companyService.service.maxWidth} onChange={this.handleInputChange} min="0" />
                                <span className="text-danger">{serviceValidation.maxWidth.message}</span>
                            </Col>
                            <Col md={1}>
                                <FormLabel htmlFor="maxDepthField">Depth:</FormLabel>
                            </Col>
                            <Col md={3}>
                                <FormControl disabled={loading} id="maxDepthField" type="number" parent="service" name="maxDepth" value={companyService.service.maxDepth} onChange={this.handleInputChange} min="0" />
                                <span className="text-danger">{serviceValidation.maxDepth.message}</span>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="maxWeightField">Max Weight (kg)</FormLabel>
                        <FormControl disabled={loading} id="maxWeightField" type="number" parent="service" name="maxWeight" value={companyService.service.maxWeight} onChange={this.handleInputChange} min="0" />
                        <span className="text-danger">{serviceValidation.maxWeight.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="insuranceAmountField">Insurance Amount (£)</FormLabel>
                        <FormControl disabled={loading} id="insuranceAmountField" type="number" parent="service" name="insuranceAmount" value={companyService.service.insuranceAmount} onChange={this.handleInputChange} min="0.00" />
                    </FormGroup>
                    <FormGroup>
                        <Row className="margin-bottom">
                            <Col md={1}>
                                <FormLabel htmlFor="batteriesField">Batteries:</FormLabel>
                            </Col>
                            <Col md={3}>
                                <FormCheck disabled={loading} id="batteriesField" parent="service" name="batteries" checked={companyService.service.batteries} onChange={this.handleInputChange} />
                            </Col>
                            <Col md={1}>
                                <FormLabel htmlFor="liquidsField">Liquids:</FormLabel>
                            </Col>
                            <Col md={3}>
                                <FormCheck disabled={loading} id="liquidsField" parent="service" name="liquid" checked={companyService.service.liquid} onChange={this.handleInputChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}>
                                <FormLabel htmlFor="signedField">Signed:</FormLabel>
                            </Col>
                            <Col md={3}>
                                <FormCheck disabled={loading} id="signedField" parent="service" name="signed" checked={companyService.service.signed} onChange={this.handleInputChange} />
                            </Col>
                            <Col md={1}>
                                <FormLabel htmlFor="trackedField">Tracked:</FormLabel>
                            </Col>
                            <Col md={3}>
                                <FormCheck disabled={loading} id="trackedField" parent="service" name="tracked" checked={companyService.service.tracked} onChange={this.handleInputChange} />
                            </Col>
                        </Row>
                    </FormGroup>
                    <div className="margin-bottom">
                        <input className="btn btn-primary" type="submit" disabled={loading} value="Submit" />
                    </div>
                </Form>
            </div>
        );
    }

    render() {
        const { companyService, loading } = this.state;
        let contents = this.renderForm(companyService);

        return (
            <div>
                {loading && <LoadingBar />}
                <h1>Edit Service</h1>
                {contents}
            </div>
        );
    }
}

export default EditService;