import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './Integrations.scss';

class EditIntegration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordInputType: 'password',
            passwordInputIcon: faEye,
            passwordError: '',
            storeUrlError: '',
            storeNameError: '',
            usernameError: ''
        }

        this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
        this.validate = this.validate.bind(this);
    }

    validate(integration) {
        let isValid = true;

        integration.integrationType = "Shopify";

        if (!integration.shopName || integration.shopName === '') {
            isValid = false;
            this.setState({ storeNameError: 'Store Name is required' });
        }
        if (!integration.storeUrl || integration.storeUrl === '') {
            isValid = false;
            this.setState({ storeUrlError: 'Store URL is required' });
        }
        if (!integration.username || integration.username === '') {
            isValid = false;
            this.setState({ usernameError: 'Username is required' });
        }
        if (!integration.password || integration.password === '') {
            isValid = false;
            this.setState({ passwordError: 'Password is required' });
        }

        if (isValid) {
            this.props.handleSubmit(integration);
        }
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ?
            this.setState({ passwordInputType: 'text', passwordInputIcon: faEyeSlash }) :
            this.setState({ passwordInputType: 'password', passwordInputIcon: faEye });
    }

    render() {
        const { passwordInputType, passwordInputIcon } = this.state;
        const { integration, isError, isSuccess, showBack, handleInputChange } = this.props;

        return (
            <React.Fragment>
                {!isSuccess ? <React.Fragment>
                    <p><strong>Shopify Credentials</strong></p>
                    <p>Please enter the following information about your Shopify account to allow us to set up your integration.</p>

                    {isError &&
                        <Card className="card-danger mb-3">
                            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                                There seems to be a problem
                            </Card.Title>
                            <Card.Body>
                                We could not validate your credentials with your provider. Please check the details below and try again.
                            </Card.Body>
                        </Card>}

                    <Form onSubmit={this.handleSubmit}>
                        <fieldset>
                            <FormGroup>
                                <FormLabel htmlFor="shopName">Store Name</FormLabel>
                                <FormControl id="shopName" type="text" name="shopName" value={integration.shopName} onChange={handleInputChange} />
                                <span className="text-danger">{this.state.storeNameError}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="storeUrl">Store URL</FormLabel>
                                <FormControl id="storeUrl" type="text" name="storeUrl" value={integration.storeUrl} onChange={handleInputChange} />
                                <span className="text-danger">{this.state.storeUrlError}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="username">Username</FormLabel>
                                <FormControl id="username" type="text" name="username" value={integration.username} onChange={handleInputChange} />
                                <span className="text-danger">{this.state.usernameError}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <FormControl id="password" type={passwordInputType} name="password" value={integration.password} onChange={handleInputChange} />
                                <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                                <span className="text-danger">{this.state.passwordError}</span>
                            </FormGroup>

                            <p className="title">Do you want this integration to be connected now?</p>
                            <div className="form-input-description">
                                <p>You can connect and disconnect your integration at any stage. Please note until this integration is connected it will not work.</p>
                            </div>
                            <FormGroup className="custom-control custom-radio mb-2">
                                <input id="connected" className="custom-control-input" name="connected" type="radio" checked={integration.connected === true} onChange={handleInputChange} />
                                <FormLabel className="custom-control-label" htmlFor="connected">Yes, connect now</FormLabel>
                            </FormGroup>
                            <FormGroup className="custom-control custom-radio mb-4">
                                <input id="disconnected" className="custom-control-input" name="disconnected" type="radio" checked={integration.connected === false} onChange={handleInputChange} />
                                <FormLabel className="custom-control-label" htmlFor="disconnected">No, do not connect now</FormLabel>
                            </FormGroup>
                            
                            {/* Inventory Management */}
                            {integration.connected ?
                                <FormGroup>
                                    <p className="title">Do you want to allow for Shopify to use inventory levels from Selazar?</p>
                                    <div className="form-input-description">
                                        <p>Selazar inventory levels will not be used by this Shopify store until you have selected it per product within Shopify.</p>
                                    </div>
                                    <FormGroup className="custom-control custom-radio mb-2">
                                        <input id="inventoryManaged" className="custom-control-input" name="inventoryManaged" type="radio" checked={integration.inventoryManagement} onChange={handleInputChange} />
                                        <FormLabel className="custom-control-label" htmlFor="inventoryManaged">Yes, allow Selazar inventory management</FormLabel>
                                    </FormGroup>
                                    <FormGroup className="custom-control custom-radio mb-4">
                                        <input id="inventoryUnmanaged" className="custom-control-input" name="inventoryUnmanaged" type="radio" checked={!integration.inventoryManagement} onChange={handleInputChange} />
                                        <FormLabel className="custom-control-label" htmlFor="inventoryUnmanaged">No, do not allow Selazar inventory management</FormLabel>
                                    </FormGroup>
                                </FormGroup> : null}


                        </fieldset>
                        {
                            showBack &&
                            <Button className="link-button float-left" variant="secondary" size="sm" onClick={() => this.props.setStep('back')}>Back</Button>
                        }

                        <Button className="btn btn-primary float-right" onClick={() => this.validate(integration)}>Validate Credentials</Button>
                        <Button variant="link" className="float-right" onClick={() => this.props.setStep('home')}>Cancel</Button>
                    </Form>
                </React.Fragment>
                    :
                    <React.Fragment>
                        <Card className="card-success mb-3">
                            <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
                            <Card.Body>
                                {integration.connected ? "You have successfully connected your Shopify store to Selazar." : "You have successfully saved your integration."}
                            </Card.Body>
                        </Card>
                        {integration.connected &&
                            <React.Fragment>
                                <p className="title">Next Steps</p>
                                <div className="form-input-description">
                                    <p>Your Shopify orders will now be fulfilled through Selazar. In order to assure the desired service times of your Shopify shipping rates, you can configure these now. If these are not configured, we will attempt to send all orders on 24 hours as default.</p>
                                </div>
                            </React.Fragment>}
                        {integration.connected ?
                            <Button className="btn btn-primary float-right" onClick={() => this.props.showEditShippingRates()}>Configure Shopify Shipping</Button> :
                            <Button className="btn btn-primary float-right" onClick={() => this.props.setStep('finish')}>Return to Integrations</Button>}
                    </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default EditIntegration;