import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Swimlane from '../../common/Swimlane';
import AdHocCollectionCard from './AdHocCollectionCard';
import Endpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';

class AdHocCollections extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true, 
            collections: []
        };

        this.getPending            = this.getPending.bind(this);
        this.getCollected          = this.getCollected.bind(this);
        this.getFailed             = this.getFailed.bind(this);
        this.fetchAdHocCollections = this.fetchAdHocCollections.bind(this);
    }

    componentDidMount() {
        return this.fetchAdHocCollections();
    }

    fetchAdHocCollections() {
        this.setState({ loading: true });
        return GET(Endpoints.AD_HOC_COLLECTIONS.GET.FOR_CURRENT_COMPANY)
            .then(raw => raw.json())
            .then(result => {
                let data = extractData(result);
                this.setState({ collections: data, loading: false });
            });
    }

    getPending(collections) {
        collections = Array.isArray(collections) ? collections : [];        
        return collections.filter((r, i) => r.dateBooked !== null && r.dateCollected === null);
    }

    getCollected(collections) {
        collections = Array.isArray(collections) ? collections : [];        
        return collections.filter((r, i) => r.dateBooked !== null && r.dateCollected !== null);
    }

    getFailed(collections) {
        collections = Array.isArray(collections) ? collections : [];        
        return collections.filter((r, i) => r.failed === true);
    }

    collectionCardsFrom(collections) {        
        collections = Array.isArray(collections) ? collections : [];    
        return collections.map((c, i) => <AdHocCollectionCard key={c.id} collection={c} />)
    }

    render() {
        let { collections } = this.state;        
        let pending         = this.collectionCardsFrom(this.getPending(collections));
        let collected       = this.collectionCardsFrom(this.getCollected(collections));
        let failed          = this.collectionCardsFrom(this.getFailed(collections));

        return (
            <Container fluid>
                {this.state.loading && <LoadingBar />}
                <h2>Ad Hoc Collections</h2>
                <Row className="margin-bottom panel panel-default panel-padded">
                    <Col md={12} style={{ overflowY: "auto", maxHeight: "560px" }}>
                        <Col md={4}><Swimlane title="Awaiting collection">{pending}</Swimlane></Col>
                        <Col md={4}><Swimlane title="Collected">{collected}</Swimlane></Col>                        
                        <Col md={4}><Swimlane title="Failed">{failed}</Swimlane></Col>
                    </Col>                    
                </Row>
            </Container>
        );
    }
};

export default AdHocCollections;