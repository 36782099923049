import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import LocationCard from './LocationCard';
import LocationMap from './LocationMap';
import { checkPermission } from '../../../Utilities';
import { GET, extractData } from '../../../Consumer';

class Locations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companyAddresses: [], loading: true 
        };        
 
        this.fetchLocations = this.fetchLocations.bind(this);
    }

    componentDidMount() {
        this.fetchLocations();
    }

    fetchLocations() {
        this.setState({ loading: true });
        let user = JSON.parse(localStorage.getItem('user'));

        return GET(Endpoints.GET_LOCATIONS_BY_COMPANY + user.companyID)
            .then(response => response.json())
            .then(result => {
                this.setState({ companyAddresses: extractData(result), loading: false });
            });
    }

    renderButton() {
        return (
            <Col md={3} mdPush={9}>
                <Link className="btn btn-success" from={'/company/locations'} to={{ pathname: '/company/locations/edit', state: { companyAddressID: 0 } }}>(+) Add Location</Link>
        </Col>);
    }

    
    renderCards(companyAddresses) {

        let containsItems = companyAddresses.length > 0;

        if (containsItems) {
            return (
                <Row className="margin-bottom">
                    {companyAddresses.map(companyAddress => {
                        return <Col sm={6} md={4} key={companyAddress.id}><LocationCard reload={this.fetchLocations} location={companyAddress} /></Col>;
                    })}
                </Row>
            );
        }
        else {
            return (
                <Row className="margin-bottom">
                    <p>No Locations</p>
                </Row>
            );
        }
    }

    render() {

        let contents = this.state.loading
            ? <LoadingBar/>
            : this.renderCards(this.state.companyAddresses);

        let map = this.state.loading ? null : <div className="margin-bottom"><LocationMap companyAddresses={this.state.companyAddresses} /></div>;

        let button = checkPermission("AddCompanyAddress", this.renderButton());

        return (
            <div>
                <h2>Locations</h2>
                {map}
                {contents}

                <Row className="margin-bottom">
                    {button}
                </Row>
            </div>
        );
    }

}

export default Locations;