import React, { Component } from 'react';
import { Alert, Table, Container, Col, Row } from 'react-bootstrap';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import LoadingBar from '../../common/LoadingBar';
import CommonEndpoints from '../../common/Endpoints';
import OrderListFilter from './OrderListFilter';
import JwPagination from 'jw-react-pagination';
import OrderExport from './OrderExport';
import { GET, extractData } from '../../../Consumer';

class OrderList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            orders : [],
            currentPage: [],
            loading: true,
            warning: '',
            filter : { id: '', status: '', datefrom: '', dateto: '' }
        };

        this.fetchOrders    = this.fetchOrders.bind(this);
        this.onSearch       = this.onSearch.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onChangePage   = this.onChangePage.bind(this);
    }

    componentDidMount() {
        this.fetchOrders();
    }

    fetchOrders() {
        let url = CommonEndpoints.ALL_ORDERS;
        let { id, status, datefrom, dateto } = this.state.filter;

        if (id || status || datefrom || dateto) {
            url = new URL(url);
            Object.keys(this.state.filter).forEach(k => url.searchParams.append(k, this.state.filter[k]));
        }

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (!response.error) return response.json();
            })
            .then(results => {
                let orders = Array.isArray(extractData(results)) ? extractData(results) : [];
                let warning = orders.length === 0 ? "No orders found" : null;
                this.setState({ orders: orders, loading: false, warning: warning });
            }
        );
    }

    onFilterChange(event) {
        let filter = this.state.filter;
        filter[event.target.name] = event.target.value;
        this.setState({ filter: filter });
    }

    onSearch(event) {
        event.preventDefault();
        this.fetchOrders();
    }

    onChangePage(pageOfItems) {
        this.setState({currentPage: pageOfItems});
    }

    render() {
        return (<div>
            <h1>Order List</h1>            
            {this.state.warning && <Alert bsStyle="warning">{this.state.warning}</Alert>}
            {
                this.state.loading ? (<LoadingBar />) : 
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                <OrderListFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} {...this.state.filter} />
                            </Col>
                            <Col md={12}>
                                <Table striped bordered condensed hover>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Received</th>
                                            <th>Status</th>
                                            <th>Last modified</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.orders.length ? this.state.currentPage.map((o) => {
                                            return (<tr key={o.id}>
                                                <td>{o.id}</td>
                                                <td>{o.received}</td>
                                                <td>{o.status}</td>
                                                <td>{o.modified}</td>
                                            </tr>);
                                        }) : <tr><td colSpan={4}>No items to show.</td></tr>}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={12}>
                                <JwPagination items={this.state.orders} onChangePage={this.onChangePage} pageSize={25}  />
                            </Col>
                        </Row>
                </Container>
            }
            <Container fluid>
                <div className="margin-bottom">
                    <SelazarLinkContainer to='/supplier/orders' activeClassName={null}>
                        <a className='btn btn-default'>Back to Order Management</a>
                    </SelazarLinkContainer>
                    <OrderExport filter={this.state.filter} />
                </div>
            </Container>             
        </div>);
    }
}

export default OrderList;