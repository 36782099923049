import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col, Button, Container } from 'react-bootstrap';
import { CountryList, ProductsSubNavList } from '../../../../common/Constants';
import { Link } from 'react-router-dom';
import { GET, PUT, extractData } from '../../../../../Consumer';
import { defaultGuid } from '../../../../../Utilities';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import FormValidator from '../../../../common/FormValidator';
import Header from '../../../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../../../common/Alert';

const PFP_MAX_WEIGHT = 5;
const PFP_MAX_VOLUME = 20250;
const PFP_TITLE = "Product Size Information";
const PFP_INFO = "We have detected you are uploading a large product. These products will be kept on pallets and picked directly from them, providing you with more cost-effective storage.";

const COMMODITY_CODE_DESC = <p>This information is required by customs, and should be 8-10 characters. If you do not know your commodity code you can find it at <a target="_blank" rel="noopener noreferrer" href="https://www.gov.uk/trade-tariff">gov.uk</a></p>;

const getItemVolume = (depth, width, height) => parseFloat(depth) * parseFloat(width) * parseFloat(height);

class EditProductDetails extends Component {

    constructor(props) {
        super(props);

        this.itemDetailsValidator = new FormValidator([
            {
                field: 'height',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Height must be greater than 0'
            },
            {
                field: 'width',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Width must be greater than 0'
            },
            {
                field: 'depth',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Depth must be greater than 0'
            },
            {
                field: 'weight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Weight must be greater than 0'
            },
            {
                field: 'countryCodeOfManufacture',
                method: 'isEmpty',
                validWhen: false,
                message: 'Country of Manufacture is required'
            },
            {
                field: 'commodityCode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Commodity Code is required'
            }
        ]);

        const user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            loading: true,
            warning: null,
            companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            pageIndex: this.props.match.params.pageIndex || 1,
            categories: [],
            companyItem: {
                id: defaultGuid,
                editable: false,
                active: false,
                companyID: user.companyID,
                itemID: defaultGuid,
                expectedWeeklySales: 0,
                isPrePacked: false,
                requiresBoxPackaging: false,
                requiresBubbleWrapPackaging: false,
                requiresPaperPackaging: false,
                requiresEcoPackaging: false,
                isFragile: false,
                pickFromPack: false,
                stockKeepingUnits: [],
                item: {
                    id: defaultGuid,
                    name: '',
                    description: '',
                    brand: '',
                    height: 0.00,
                    width: 0.00,
                    depth: 0.00,
                    weight: 0.00,
                    categoryID: '',
                    ean: null,
                    selazarUniqueID: null,
                    jan: null,
                    upc: null,
                    isbn: null,
                    mpn: null,
                    countryCodeOfManufacture: '',
                    commodityCode: '',
                    itemImages: []
                },
                currentPrice:
                {
                    id: defaultGuid,
                    companyItemID: defaultGuid,
                    price: 0.00,
                    active: true
                },
                noBarcode: false,
                dropzoneEnabled: true
            },
            itemDetailsValidation: this.itemDetailsValidator.valid()
        };
    }

    async componentDidMount() {
        await this.getCompanyItem();
        this.setState({ loading: false });
    }

    getCompanyItem = () => {
        const { companyItemID } = this.state;
        return GET(Endpoints.STOCK.GET.BY_ID + companyItemID)
            .then(response => response.json())
            .then(data => {
                const companyItem = extractData(data);
                const warning = companyItem ? null : "No product available."
                this.setState({ companyItem: companyItem, warning: warning });
            });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        const { companyItem } = this.state;
        
        if (name === "weight") {
            if (value >= PFP_MAX_WEIGHT) {
                this.setPickFromPallet(true);
            } else {
                const itemVolume = getItemVolume(companyItem.item.depth, companyItem.item.width, companyItem.item.height);
                if (itemVolume < PFP_MAX_VOLUME) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (name === "height") {
            const itemVolume = getItemVolume(companyItem.item.depth, companyItem.item.width, value);
            if (itemVolume >= PFP_MAX_VOLUME) {
                this.setPickFromPallet(true);
            } else {
                if (companyItem.item.weight < PFP_MAX_WEIGHT) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (name === "width") {
            const itemVolume = getItemVolume(companyItem.item.depth, value, companyItem.item.height);
            if (itemVolume >= PFP_MAX_VOLUME) {
                this.setPickFromPallet(true);
            } else {
                if (companyItem.item.weight < PFP_MAX_WEIGHT) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (name === "depth") {
            const itemVolume = getItemVolume(value, companyItem.item.width, companyItem.item.height);
            if (itemVolume >= PFP_MAX_VOLUME) {
                this.setPickFromPallet(true);
            } else {
                if (companyItem.item.weight < PFP_MAX_WEIGHT) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (e.target.getAttribute('parent')) {
            const parent = e.target.getAttribute('parent');
            const parentValue = this.state.companyItem[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        if (name === "noBarcode") {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    noBarcode: value === "true" ? true : false,
                    item: {
                        ...prevState.companyItem.item,
                        ean: ''
                    }
                }
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                companyItem: {
                    ...prevState.companyItem,
                    ...{ [name]: value }
                }
            }));
        }
    };

    setPickFromPallet = (pickFromPallet) => {
        this.setState(prevState => ({
            companyItem: {
                ...prevState.companyItem,
                pickFromPallet: pickFromPallet,
            }
        }));
    };

    formatInput = (e) => {
        let { name, value } = e.target;

        value = value.trim();

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.companyItem[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            companyItem: {
                ...prevState.companyItem,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit = async () => {
        this.setState({ loading: true });
        const { companyItem } = this.state;
        this.submitted = true;

        if(companyItem.item.countryCodeOfManufacture === null) companyItem.item.countryCodeOfManufacture = '';
        if(companyItem.item.commodityCode === null) companyItem.item.commodityCode = '';

        const validation = this.itemDetailsValidator.validate(companyItem.item);
        this.setState({ itemDetailsValidation: validation });

        if (validation.isValid) {
            return await PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.setState({ loading: false, success: true, warning: null });
                    } else {
                        this.setState({ loading: false, success: false, warning: result.message });
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        const { companyItem, loading, warning, success, itemDetailsValidation, pageIndex } = this.state;

        return (
            <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Products" headerClass="mb-0" />
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link to={`/supplier/products/edit/${companyItem.id}/${pageIndex}`} className="link-button">
                                <Button variant="link" className="button-nav-return p-0 mb-3"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        {loading ? <LoadingBar /> :
                            <React.Fragment>
                                <Col sm={12} md={6}>
                                    <h5>Stock Items</h5>
                                    {warning && <ErrorAlert errorMessage={warning} />}
                                    {success && <SuccessAlert successMessage="Product successfully updated." />}
                                    <FormGroup as={Row}>
                                        <Col sm={12} md={4}>
                                            <FormLabel htmlFor="heightField">Product Height (cm)</FormLabel>
                                            <FormControl id="heightField" type="number" parent="item" name="height" value={companyItem.item.height} onChange={this.handleInputChange} min="0" step="0.01" />
                                        </Col>
                                        <Col md={12}>
                                            <span className="text-danger">{itemDetailsValidation.height.message}</span>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Col sm={12} md={4}>
                                            <FormLabel htmlFor="widthField">Product Width (cm)</FormLabel>
                                            <FormControl id="widthField" type="number" parent="item" name="width" value={companyItem.item.width} onChange={this.handleInputChange} min="0" step="0.01" />
                                        </Col>
                                        <Col md={12}>
                                            <span className="text-danger">{itemDetailsValidation.width.message}</span>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Col sm={12} md={4}>
                                            <FormLabel htmlFor="depthField">Product Depth (cm)</FormLabel>
                                            <FormControl id="depthField" type="number" parent="item" name="depth" value={companyItem.item.depth} onChange={this.handleInputChange} min="0" step="0.01" />
                                        </Col>
                                        <Col md={12}>
                                            <span className="text-danger">{itemDetailsValidation.depth.message}</span>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Col sm={12} md={4}>
                                            <FormLabel htmlFor="weightField">Product Weight (kg)</FormLabel>
                                            <FormControl id="weightField" type="number" parent="item" name="weight" value={companyItem.item.weight} onChange={this.handleInputChange} min="0" step="0.01" />
                                        </Col>
                                        <Col md={12}>
                                            <span className="text-danger">{itemDetailsValidation.weight.message}</span>
                                        </Col>
                                    </FormGroup>
                                    {companyItem.pickFromPallet && <WarningAlert warningTitle={PFP_TITLE} warningMessage={PFP_INFO} />}
                                    <FormGroup>
                                        <FormLabel htmlFor="countryOfManufactureField">Country of Manufacturing</FormLabel>
                                        <FormControl id="countryOfManufactureField" name="countryCodeOfManufacture" parent="item" as="select" value={companyItem.item.countryCodeOfManufacture} onChange={this.handleInputChange}>
                                            <option key="" value="">Please select an option</option>;
                                                    {CountryList.map((v, i) => <option key={i} value={v.code}>{v.name}</option>)}
                                        </FormControl>
                                        <span className="text-danger">{itemDetailsValidation.countryCodeOfManufacture.message}</span>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Col md={12}>
                                            <FormLabel htmlFor="commodityCodeField">Commodity Code</FormLabel>
                                            <div className="form-input-description">
                                                {COMMODITY_CODE_DESC}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <FormControl id="commodityCodeField" type="text" name="commodityCode" parent="item" value={companyItem.item.commodityCode} onChange={this.handleInputChange} onBlur={this.formatInput} />
                                        </Col>
                                        <Col md={12}>
                                            <span className="text-danger">{itemDetailsValidation.commodityCode.message}</span>
                                        </Col>
                                    </FormGroup>
                                    <Button variant="primary" className="float-right" onClick={this.handleSubmit}>Save</Button>
                                </Col>
                            </React.Fragment>}
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default EditProductDetails;