import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { checkPermission } from '../../../Utilities';

export class OrderCard extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderButton = this.renderButton.bind(this);

        this.state = {
            show: false,
            order: this.props.order
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    renderButton() {
        let { order } = this.state;
        return (
            <Link className="btn btn-warning" from={'/supplier/orders/all'} to={{ pathname: '/supplier/orders/createissue', state: { orderID: order.id } }}>(Pencil) Raise Issue</Link>);
    }

    render() {
        let { order } = this.state;
        let button = checkPermission("AddIssue", this.renderButton());

        return (
            <div className="swimlane-card">
                <dl>
                    <dt>Order ID:</dt>
                    <dd onClick={this.handleShow}>{order.id}</dd>
                    <dt>Received:</dt>
                    <dd>{order.received}</dd>
                </dl>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Order details: {order.id}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <dl>
                            <dt>Order ID:</dt>
                            <dd>{order.id}</dd>
                            <dt>Received:</dt>
                            <dd>{order.received}</dd>
                        </dl>
                        {button}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

OrderCard.propTypes = {
    order: PropTypes.object.isRequired
};

OrderCard.defaultProps = {
    order: {}
};