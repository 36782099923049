import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import SelazarLinkContainer from './SelazarLinkContainer';

const Success = (props) => {
    return (
        <div>
            <h2>Success!</h2>
            <Container>
                <Row>
                    <Col sm={12}>
                        <p>Your operation was completed successfully.</p>
                        <SelazarLinkContainer to='/' activeClassName={null}>
                            <Button>Back to Home</Button>
                        </SelazarLinkContainer>                       
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

Success.displayName = Success.name;
export default Success;