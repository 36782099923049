import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const Swimlane = ({ title, children }) => {
    return (
        <Card className="swimlane">
            <Card.Body>
                <h4>{title}</h4>
                {children}
            </Card.Body>
        </Card>
    );
};

Swimlane.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    title: PropTypes.string.isRequired
};

Swimlane.displayName = Swimlane.name;
export default Swimlane;