import React, { Component } from 'react';
import { NavItem } from 'react-bootstrap';
import { checkPermission, checkProfile, hasPermission } from './../Utilities';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faUsers, faFileInvoice, faShoppingCart, faClipboardList, faTasks, faCreditCard, faCog, faUndo, faPallet, faPlug, faBoxes } from '@fortawesome/free-solid-svg-icons';

import './NavMenu.scss';

export class NavMenu extends Component {
    render() {
        const SideNavItem = ({ link, icon, title, exactLink = true }) => {
            return (
                <LinkContainer to={link} exact={exactLink}>
                    <NavItem><FontAwesomeIcon className="mr-1" icon={icon} /> {title}</NavItem>
                </LinkContainer>
            );
        };

        const showSettings = (hasPermission('ViewCard') || hasPermission('ManageIntegrations') || hasPermission('ManagePreferences'));
        const showRetailer = (hasPermission('ViewOrder') || hasPermission('BulkPick') || hasPermission('ViewReturn'));
        const showStock = (hasPermission('ViewStock'));

        const CompanyNav =
            (<React.Fragment>
                <h5>Company</h5>
                <SideNavItem link="/" icon={faChartBar} title="Dashboard" />
                {checkPermission('ViewUser', <SideNavItem link="/company/users" icon={faUsers} title="Users" />)}
                {checkPermission("ViewInvoice", <SideNavItem link="/company/invoices" icon={faFileInvoice} title="Invoicing" />)}
            </React.Fragment>);

        const RetailerNav =
            (showRetailer && checkProfile("Retailer", <React.Fragment>
                <h5>Retailer</h5>
                {checkPermission('ViewOrder', <SideNavItem link="/retailer/orders" icon={faShoppingCart} title="Orders" exactLink={false} />)}
                {checkPermission('BulkPick', <SideNavItem link="/retailer/bulkorder" icon={faBoxes} title="Bulk Order" exactLink={false}/>)}
                {checkPermission('ViewReturn', <SideNavItem link="/retailer/returns" icon={faUndo} title="Returns" />)}
            </React.Fragment>));

        const StockNav =
            (showStock && checkProfile("Supplier", <React.Fragment>
                <h5>Stock</h5>
                {checkPermission('ViewStock', <SideNavItem link="/supplier/products" icon={faClipboardList} title="Products" exactLink={false} />)}
                {checkPermission('ViewStock', <SideNavItem link="/supplier/stockconsignments" icon={faTasks} title="Inventory" exactLink={false} />)}
            </React.Fragment>));

        const FulfilmentCompanyNav =
            (checkProfile("Fulfilment", <React.Fragment>
                <h5>Company</h5>
                {checkPermission('ViewUser', <SideNavItem link="/company/users" icon={faUsers} title="Users" />)}
            </React.Fragment>));

        const FulfilmentNav =
            (checkProfile("Fulfilment", <React.Fragment>
                <h5>Fulfilment</h5>
                <SideNavItem link="/" icon={faPallet} title="Fulfilment" />
                {checkPermission('ViewCheckinRun', <SideNavItem link="/inboundstock" icon={faBoxes} title="Inbound Stock" />)}
            </React.Fragment>));

        const SettingsNav =
            (<React.Fragment>
                {showSettings && <h5>Settings</h5>}
                {checkPermission('ViewCard', <SideNavItem link="/retailer/finance/newcard" icon={faCreditCard} title="Payments" />)}
                {checkPermission('ManageIntegrations', <SideNavItem link="/retailer/integrations" icon={faPlug} title="Integrations" />)}
                {checkPermission('ManagePreferences', <SideNavItem link="/retailer/preferences" icon={faCog} title="Preferences" exactLink={false} />)}
            </React.Fragment>);

        return (
            <div className="sidebar-wrapper py-4">
                {FulfilmentCompanyNav ? FulfilmentCompanyNav : CompanyNav}
                {FulfilmentNav}
                {RetailerNav}
                {StockNav}
                {!FulfilmentNav && SettingsNav}
            </div>
        );
    }
}