import React, { Component } from 'react';
import FormValidator from '../../common/FormValidator';
import LoadingBar from '../../common/LoadingBar';
import { Col, Row, Button, Container, Card } from 'react-bootstrap';
import { GET, POST, extractData } from '../../../Consumer';
import { defaultGuid } from '../../../Utilities';
import Endpoints from '../../common/Endpoints';
import { InventorySubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert } from '../../common/Alert';
import { Link } from 'react-router-dom';
import Stepper from '../../common/Wizard/Stepper';
import StepperButtons from '../../common/Wizard/StepperButtons';
import ItemSelection from './StockUploadWizard/ItemSelection';
import StockDetails from './StockUploadWizard/StockDetails';
import Shipping from './StockUploadWizard/Shipping';
import SingleConfirmation from './StockUploadWizard/SingleConfirmation';
import Confirm from '../../common/Confirm';

const steps = ["Item Selection", "Stock Details", "Shipping & Labelling", "Confirmation"];
const cancelLink = "/supplier/stockconsignments"

const MODAL_TITLE = "Confirm Stock Box Quantity";
const MODAL_CANCEL = "No, return and edit stock details";

const STOCK_BOX_MODAL_LIMIT = 150;
const BOX_CONFIGURATION_MODAL_CONFIRM = "Yes";

const PFP_ITEMS_PER_BOX_MODAL_LIMIT = 1;
const PFP_MODAL_CONFIRM = "Create consignment as pick from pallet";

const PFP_STOCK_BOX_HEIGHT = 200;
const PFP_STOCK_BOX_WIDTH = 60;
const PFP_STOCK_BOX_DEPHT = 65;
const PFP_STOCK_BOX_WEIGHT = 70;

const MINIMUM_GTIN_LENGTH = 8;

const PFP_MODAL_TITLE = "Pick from Pallet Alert";
const PFP_MODAL_CANCEL = "No, return and edit stock details";

const getBoxConfigurationModalText = (isPallet, boxQuantity, itemsPerBox, stockQuantity) => `You have indicated that this ${isPallet === "true" ? "pallet" : "shelf consignment"} will have ${parseInt(boxQuantity, 10)} stock boxes with ${itemsPerBox} items in each box. This is ${stockQuantity} items in total, is this correct?`;
const getPickFromPalletModelText = (itemsPerBox) => `You have indicated that you are sending in ${itemsPerBox} items per stock box. This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?`;
const getPickFromPalletBoxConfigModalText = () => "You have indicated that your stock box size is above our shelf storage restrictions (200cm x 60cm x 65cm x 70kg). This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?";
const getPickFromPalletItemModalText = () => "You have selected to upload a pick from pallet item. This qualifies the stock on this consignment to be picked from pallet and will require stock to be sent in on a pallet. Do you want this consignment to be pick from pallet?";

class UploadSingleStock extends Component {

    constructor(props) {
        super(props);

        this.itemValidator = new FormValidator([
            {
                field: 'companyItemID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Item is required'
            }
        ]);

        this.stockDetailsValidator = new FormValidator([
            {
                field: 'stockQuantity',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Stock quantity must be greater than 0'
            },
            {
                field: 'boxQuantity',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Box quantity must be greater than 0'
            },
            {
                field: 'itemsPerBox',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 1 }],
                message: 'Items per box must be greater than 0'
            },
            {
                field: 'batchNumber',
                method: 'isLength',
                validWhen: true,
                args: [{ max: 20 }],
                message: 'Batch Number must be 20 characters or less'
            },
            {
                field: 'stockBoxHeight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Stock box height must be greater than 0'
            },
            {
                field: 'stockBoxWidth',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Stock box width must be greater than 0'
            },
            {
                field: 'stockBoxDepth',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Stock box depth must be greater than 0'
            },
            {
                field: 'stockBoxWeight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Stock box weight must be greater than 0'
            },
            {
                field: 'isPallet',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please confirm if consignment is a pallet'
            },
            {
                field: 'requiresGTIN',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please choose a GTIN option'
            }
        ]);

        this.supplierLabelValidator = new FormValidator([
            {
                field: 'supplierPrintedLabels',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please choose a label printing method'
            }
        ]);

        this.defaultStock = {
            gtin: '',
            companyItemID: '',
            stockQuantity: 0,
            boxQuantity: 0,
            itemsPerBox: 0,
            batchNumber: '',
            expiryDate: '',
            id: defaultGuid,
            companyID: defaultGuid,
            fileName: '',
            labelsAffixed: false,
            processedBy: null,
            dateReceived: null,
            dateProcessed: null,
            supplierPrintedLabels: '',
            requiresGTIN: 'false',
            shippingReference: '',
            isPallet: 'true',
            boxHeight: 0,
            boxWidth: 0,
            boxDepth: 0,
            boxWeight: 0,
            stockBoxHeight: 0,
            stockBoxWidth: 0,
            stockBoxDepth: 0,
            stockBoxWeight: 0,
            skuCode: '',
            pickFromPallet: false,
            companyItemIsPFP: false
        };

        this.state = {
            stock: this.defaultStock,
            loading: true,
            permitted: false,
            companyItems: [],
            itemValidation: this.itemValidator.valid(),
            stockDetailsValidation: this.stockDetailsValidator.valid(),
            supplierLabelValidation: this.supplierLabelValidator.valid(),
            validationMessages: {
                boxHeightError: false,
                boxWidthError: false,
                boxDepthError: false,
                boxWeightError: false,
                gtinError: false,
                isPalletError: false,
                consignmentBoxHeightError: false,
                consignmentBoxWidthError: false,
                consignmentBoxDepthError: false,
                consignmentBoxWeightError: false,
            },
            errors: [],
            activeStep: 0,
            labelChoice: {
                stockBox: false,
                product: false
            },
            skuCodes: [],
            skuCodeEmptyMessage: "",
            showBoxConfigurationModal: false,
            showPickFromPalletModal: false,
            showPickFromPalletBoxModal: false,
            showPickFromPalletItemModal: false,
            showModal: false
        };
        this.renderForm = this.renderForm.bind(this);
    }

    async componentDidMount() {
        await this.validateAccount();
    }

    validateAccount = async () => {
        try {
            const res = await GET(Endpoints.FINANCE.CARD.HAS_VALID_DETAILS);
            const res_1 = await res.json();
            if (res_1.error) {
                console.log(res_1.message);
                this.setState({ loading: false });
            }
            else {
                const existing = extractData(res_1);
                if (existing) {
                    this.setState({ loading: false, permitted: true }, this.getStock);
                }
                else this.setState({ loading: false });
            }
        }
        catch (e) {
            return console.log(e);
        }
    }

    renderForbidden = () => {
        return (<Container fluid>
            <Row>
                <Col sm={12} md={6}>
                    <Card className="card-danger mb-3">
                        <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                        <Card.Body>No card details found, please add card details to your account to submit stock.</Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>);
    }

    updateNextStep = () => this.setState(state => ({ activeStep: state.activeStep + 1 }));
    handleBack = () => {
        const updatedStep = this.state.activeStep - 1;
        if (updatedStep === 1) {
                this.setState(prevState => ({
                    ...prevState,
                    stock: {
                        ...prevState.stock,
                        pickFromPallet: false
                    },
                    activeStep: updatedStep
                }));
        } else {
            this.setState({ activeStep: updatedStep });
        }
    }
    handleEditStep = (step) => this.setState({ activeStep: step });

    getStock = async () => {
        return GET(Endpoints.STOCK.GET.ALL)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const stock = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ companyItems: stock, loading: false });
            });
    }

    getSkuCodes = async (companyItemID) => {
        return GET(Endpoints.STOCK.GET.SKUS.replace(":id", companyItemID))
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const skus = extractData(results);
                this.setState({ skuCodes: skus });
            });
    }

    handleInputChange = (e) => {
        let { name, value, type } = e.target;
        const { companyItems } = this.state;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;

            this.setState(prevState => ({
                ...prevState,
                labelChoice: {
                    ...prevState.labelChoice,
                    ...{ [name]: value }
                }
            }));
        } else {
            if (e.target.getAttribute('parent')) {
                let parent = e.target.getAttribute('parent');
                let parentValue = this.state.stock[parent];
                parentValue[name] = value;

                name = parent;
                value = parentValue;
            }

            const stockQuantity = this.determineStockQuantity(name, value);

            if (name === "gtin" && value !== "") {
                value = value.replace(/[`~!@#£$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi, '');
            }

            this.setState(prevState => ({
                ...prevState,
                stock: {
                    ...prevState.stock,
                    ...{ [name]: value },
                    stockQuantity: stockQuantity
                }
            }));

            if (name === "companyItemID" && value !== "") {
                var companyItem = companyItems.find(ci => ci.companyItemID === value);
                if (companyItem !== null) {

                    if (companyItem.pickFromPallet) {
                        this.setState(prevState => ({
                            ...prevState,
                            stock: {
                                ...prevState.stock,
                                companyItemIsPFP: true
                            }
                        }));
                    } else {
                        this.setState(prevState => ({
                            ...prevState,
                            stock: {
                                ...prevState.stock,
                                companyItemIsPFP: false
                            }
                        }));
                    }

                    if (companyItem.selazarUniqueID === null) {
                        this.getSkuCodes(companyItem.companyItemID)
                            .then(() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    stock: {
                                        ...prevState.stock,
                                        ...{ [name]: value },
                                        skuCode: ''
                                    },
                                    skuCodeEmptyMessage: "",
                                    showLabelPrint: false,
                                    supplierPrintedLabels: false
                                }));
                            });
                    } else {
                        this.getSkuCodes(companyItem.companyItemID)
                            .then(() => {
                                this.setState(prevState => ({
                                    ...prevState,
                                    stock: {
                                        ...prevState.stock,
                                        ...{ [name]: value },
                                        skuCode: ''
                                    },
                                    skuCodeEmptyMessage: "",
                                    showLabelPrint: true
                                }));
                            });
                    }
                }
            }
        }
    }

    handlePickFromPalletModalClose = () => this.setState({ showPickFromPalletModal: false });
    handlePickFromPalletModalConfirm = () => {
        this.setState(prevState => ({
            ...prevState,
            stock: {
                ...prevState.stock,
                isPallet: "true",
                pickFromPallet: true
            },
            showPickFromPalletModal: false
        }));
        this.updateNextStep();
    }

    handleBoxConfigurationModalClose = () => this.setState({ showBoxConfigurationModal: false });
    handleBoxConfigurationModalConfirm = () => {
        this.setState({ showBoxConfigurationModal: false });
        this.updateNextStep();
    }

    handlePickFromPalletBoxModalClose = () => this.setState({ showPickFromPalletBoxModal: false });
    handlePickFromPalletBoxModalConfirm = () => {
        const { boxQuantity } = this.state.stock;
        this.setState(prevState => ({
            ...prevState,
            stock: {
                ...prevState.stock,
                isPallet: "true",
                pickFromPallet: true
            },
            showPickFromPalletBoxModal: false
        }));
        boxQuantity >= STOCK_BOX_MODAL_LIMIT ? this.setState({ showBoxConfigurationModal: true }) : this.updateNextStep();
    }

    handlePickFromPalletItemModalClose = () => this.setState({ showPickFromPalletItemModal: false });
    handlePickFromPalletItemModalConfirm = () => {
        this.setState(prevState => ({
            ...prevState,
            stock: {
                ...prevState.stock,
                isPallet: "true",
                pickFromPallet: true
            },
            showPickFromPalletItemModal: false
        }));
        this.updateNextStep();
    }

    handleClose = () => this.setState({ showModal: false });
    handleShow = () => this.setState({ showModal: true });

    determineStockQuantity = (inputName, newValue) => {
        let { stockQuantity } = this.state.stock;
        const { itemsPerBox: currentItemsPerBox, boxQuantity: currentBoxQuantity } = this.state.stock;

        if (inputName === 'boxQuantity') stockQuantity = newValue * currentItemsPerBox;
        if (inputName === 'itemsPerBox') stockQuantity = newValue * currentBoxQuantity;

        return stockQuantity;
    }

    handleNext = async () => {
        const { activeStep, stock, skuCodes } = this.state;

        if (activeStep === 0) {
            const itemValidation = this.itemValidator.validate(stock);
            this.setState({ itemValidation: itemValidation });

            if (stock.skuCode === '' && skuCodes.length > 0) {
                this.setState({ skuCodeEmptyMessage: "SKU is required" });
            } else if (itemValidation.isValid) {
                this.setState({ skuCodeEmptyMessage: "" });
                this.updateNextStep();
            }
        } else if (activeStep === 1) {
            const stockDetailsValidation = this.stockDetailsValidator.validate(stock);

            const gtinValidationMessages = this.validateGTIN(this.state.stock);
            if (gtinValidationMessages.gtinError) {
                stockDetailsValidation.isValid = false;
            }

            const validateConsignmentBoxMessages = this.validateConsignmentBox(stock);
            if (stock.isPallet === 'false' && (validateConsignmentBoxMessages.consignmentBoxHeightError || validateConsignmentBoxMessages.consignmentBoxHeightError || validateConsignmentBoxMessages.consignmentBoxHeightError || validateConsignmentBoxMessages.consignmentBoxHeightError)) {
                stockDetailsValidation.isValid = false;
            }

            const validationMessages = this.validateBox(this.state.stock);
            if (validationMessages.boxHeightError || validationMessages.boxWidthError || validationMessages.boxDepthError || validationMessages.boxWeightError) {
                stockDetailsValidation.isValid = false;
            }

            this.setState({ stockDetailsValidation: stockDetailsValidation });

            if (stockDetailsValidation.isValid) {
                if (stock.companyItemIsPFP && stock.isPallet === 'true') {
                    this.setState({ showPickFromPalletItemModal: true });
                } else if (stock.isPallet === 'true' && stock.itemsPerBox <= PFP_ITEMS_PER_BOX_MODAL_LIMIT) {
                    this.setState({ showPickFromPalletModal: true });
                } else if (stock.stockBoxHeight > PFP_STOCK_BOX_HEIGHT || stock.stockBoxWidth > PFP_STOCK_BOX_WIDTH || stock.stockBoxDepth > PFP_STOCK_BOX_DEPHT || stock.stockBoxWeight > PFP_STOCK_BOX_WEIGHT) {
                    this.setState({ showPickFromPalletBoxModal: true });
                } else if (stock.boxQuantity >= STOCK_BOX_MODAL_LIMIT) {
                    this.setState({ showBoxConfigurationModal: true });
                } else {
                    this.updateNextStep();
                }
            }
        } else if (activeStep === 2) {
            const supplierLabelValidation = this.supplierLabelValidator.validate(stock);
            this.setState({ supplierLabelValidation: supplierLabelValidation });
            if (supplierLabelValidation.isValid) this.updateNextStep();
        } else {
            this.setState({ loading: true });
            await this.handleSubmit();
            const { error } = this.state;
            !error && this.updateNextStep();
        }
    }

    handleSubmit = async () => {
        let { stock } = this.state;

        if (stock.skuCode === '') stock.skuCode = null;
        if (stock.gtin === '') stock.gtin = null;

        return POST(Endpoints.STOCK.PUT.ADD_STOCK, stock)
            .then(response => response.json()).then((result) => {
                if (!result.error) {
                    this.submitted = false;
                    this.setState({
                        loading: false,
                        error: false,
                        message: 'Stock record successfully uploaded.',
                        results: extractData(result),
                        stock: this.defaultStock
                    });
                }
                else this.setState({ loading: false, error: true, message: result.message });
            });
    }

    validateStock = (stock) => { return this.validator.validate(stock); }

    validateGTIN = (stock) => {
        const { validationMessages } = this.state;

        if (stock.requiresGTIN === 'true') {
            validationMessages.gtinError = (stock.gtin === '' || stock.gtin === null || stock.gtin.length < MINIMUM_GTIN_LENGTH);
        }
        else validationMessages.gtinError = false;

        this.setState(prevState => ({
            ...prevState,
            validationMessages: {
                ...prevState.validationMessages,
                gtinError: validationMessages.gtinError,
                isPallet: "true",
                pickFromPallet: true
            },
        }));

        return validationMessages;
    }

    validateConsignmentBox = (stock) => {
        const { validationMessages } = this.state;

        if (stock.isPallet === 'false') {
            validationMessages.consignmentBoxHeightError = (stock.boxHeight <= 0 || stock.boxHeight === '' || stock.boxHeight === null);
            validationMessages.consignmentBoxWidthError = (stock.boxHeight <= 0 || stock.boxHeight === '' || stock.boxHeight === null);
            validationMessages.consignmentBoxDepthError = (stock.boxHeight <= 0 || stock.boxHeight === '' || stock.boxHeight === null);
            validationMessages.consignmentBoxWeightError = (stock.boxWeight <= 0 || stock.boxHeight === '' || stock.boxHeight === null);
        }

        this.setState(prevState => ({
            ...prevState,
            validationMessages: {
                ...prevState.validationMessages,
                consignmentBoxHeightError: validationMessages.consignmentBoxHeightError,
                consignmentBoxWidthError: validationMessages.consignmentBoxWidthError,
                consignmentBoxDepthError: validationMessages.consignmentBoxDepthError,
                consignmentBoxWeightError: validationMessages.consignmentBoxWeightError
            },
        }));

        return validationMessages;
    }

    validateBox = (stock) => {
        const { validationMessages } = this.state;

        if (stock.isPallet === 'false') {
            validationMessages.boxHeightError = (stock.stockBoxHeight <= 0 || stock.stockBoxHeight === '' || stock.stockBoxHeight === null);
            validationMessages.boxWidthError = (stock.stockBoxWidth <= 0 || stock.stockBoxWidth === '' || stock.stockBoxWidth === null);
            validationMessages.boxDepthError = (stock.stockBoxDepth <= 0 || stock.stockBoxDepth === '' || stock.stockBoxDepth === null);
            validationMessages.boxWeightError = (stock.stockBoxWeight <= 0 || stock.stockBoxWeight === '' || stock.stockBoxWeight === null);
        } else {
            validationMessages.boxHeightError = false;
            validationMessages.boxWidthError = false;
            validationMessages.boxDepthError = false;
            validationMessages.boxWeightError = false;
        }

        this.setState(prevState => ({
            ...prevState,
            validationMessages: {
                ...prevState.validationMessages,
                boxHeightError: validationMessages.boxHeightError,
                boxWidthError: validationMessages.boxWidthError,
                boxDepthError: validationMessages.boxDepthError,
                boxWeightError: validationMessages.boxWeightError
            },
        }));

        return validationMessages;
    }

    renderForm(stock) {
        const { companyItems, loading, activeStep, message, validationMessages, labelChoice, skuCodes, skuCodeEmptyMessage, showPickFromPalletModal, showBoxConfigurationModal, showPickFromPalletBoxModal, showPickFromPalletItemModal, showModal } = this.state;
        const validation = this.submitted ? this.validateStock(stock) : this.state.validation;
        const itemValidation = this.submitted ? this.itemValidator.validate(stock) : this.state.itemValidation;
        const stockDetailsValidation = this.submitted ? this.stockDetailsValidator.validate(stock) : this.state.stockDetailsValidation;
        const supplierLabelValidation = this.submitted ? this.supplierLabelValidator.validate(stock) : this.state.supplierLabelValidation;

        return (
            <React.Fragment>
                <Row className="pt-3">
                    <Col sm={12} md={6}>
                        {activeStep === 0 ?
                            <React.Fragment>
                                <ItemSelection data={stock} handleInputChange={this.handleInputChange} loading={loading} itemValidation={itemValidation} companyItems={companyItems} skuCodes={skuCodes} skuCodeEmptyMessage={skuCodeEmptyMessage} />
                                <StepperButtons cancelLink={cancelLink} handleNext={this.handleNext} showNext={true} />
                            </React.Fragment>
                            : activeStep === 1 ?
                                <React.Fragment>
                                    <StockDetails data={stock} handleInputChange={this.handleInputChange} loading={loading} stockDetailsValidation={stockDetailsValidation} validation={validation} validationMessages={validationMessages} minGtinLength={MINIMUM_GTIN_LENGTH} showModal={showModal} />
                                    <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                </React.Fragment>
                                : activeStep === 2 ?
                                    <React.Fragment>
                                        <Shipping data={stock} handleInputChange={this.handleInputChange} labelChoice={labelChoice} supplierLabelValidation={supplierLabelValidation} />
                                        <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                    </React.Fragment>
                                    : activeStep === 3 ?
                                        <React.Fragment>
                                            <SingleConfirmation data={stock} companyItems={companyItems} handleEditStep={this.handleEditStep} />
                                            <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} nextButtonText="Confirm Stock Upload" />
                                        </React.Fragment>
                                        : <React.Fragment>
                                            <SuccessAlert successMessage={message}></SuccessAlert>
                                            <Link to={"/supplier/stockconsignments"} className="link-button">
                                                <Button variant="primary" className="float-right">Continue to Inventory</Button>
                                            </Link>
                                        </React.Fragment>
                        }
                    </Col>
                </Row>

                <Confirm title={MODAL_TITLE} text={getPickFromPalletModelText(stock.itemsPerBox)} buttonText={PFP_MODAL_CONFIRM} handleConfirmClose={this.handlePickFromPalletModalClose} handleConfirmAction={this.handlePickFromPalletModalConfirm} show={showPickFromPalletModal} cancelText={MODAL_CANCEL} size="lg" />
                <Confirm title={MODAL_TITLE} text={getBoxConfigurationModalText(stock.isPallet, stock.boxQuantity, stock.itemsPerBox, stock.stockQuantity)} buttonText={BOX_CONFIGURATION_MODAL_CONFIRM} handleConfirmClose={this.handleBoxConfigurationModalClose} handleConfirmAction={this.handleBoxConfigurationModalConfirm} show={showBoxConfigurationModal} cancelText={MODAL_CANCEL} />
                <Confirm title={PFP_MODAL_TITLE} text={getPickFromPalletBoxConfigModalText()} buttonText="Yes" handleConfirmClose={this.handlePickFromPalletBoxModalClose} handleConfirmAction={this.handlePickFromPalletBoxModalConfirm} show={showPickFromPalletBoxModal} cancelText={PFP_MODAL_CANCEL} size="lg" />
                <Confirm title={PFP_MODAL_TITLE} text={getPickFromPalletItemModalText()} buttonText="Yes" handleConfirmClose={this.handlePickFromPalletItemModalClose} handleConfirmAction={this.handlePickFromPalletItemModalConfirm} show={showPickFromPalletItemModal} cancelText={PFP_MODAL_CANCEL} size="lg" />
            </React.Fragment>
        );
    }

    render() {
        const { stock, loading, activeStep } = this.state;

        return (
            <React.Fragment>
                <Header title="Inventory" subNavList={InventorySubNavList} activeKey="Send Single Product" />
                <Container fluid>
                    <Stepper steps={steps} activeStep={activeStep} />
                    {loading ? <LoadingBar /> :
                        this.state.permitted ? this.renderForm(stock) : this.renderForbidden()
                    }
                </Container>
            </React.Fragment>
        );
    }
}

export default UploadSingleStock;