import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SelazarNavMenu } from './SelazarNavMenu';
import Header from '../Header';

import '../Layout.scss';

export class SelazarLayout extends Component {
  render() {
    return (
      <React.Fragment>
        <Header onLogout={this.props.onLogout} />
        <Container fluid>
          <Row className="layout-row">
            <Col sm={2} className="side-nav">
              <SelazarNavMenu />
            </Col>
            <Col sm={10} className="work-area">
              {this.props.children}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
