import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Container, Row, Card } from 'react-bootstrap';
import ReactDropzone from 'react-dropzone';
import { POST_FILE, extractData, GET } from '../../../Consumer';
import { ErrorAlert } from '../../common/Alert';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import OrderUploadPdf from '../Selazar-Order-Upload-Instructions.pdf'
import OrderExample from '../OrderExample.csv'
import Header from '../../common/Header';
import { RetailerOrderSubNavList } from '../../common/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faDownload, faFileUpload, faTrashAlt, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const NO_CARD_DETAILS_MESSAGE = "No card details found. Please add card details to your account to submit orders.";

class UploadOrderCsv extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            loading: true,
            permitted: false,
            error: false,
            results: [],
            message: ''
        };
        this.onDrop = this.onDrop.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderUploadResult = this.renderUploadResult.bind(this);
        this.renderUploadForm = this.renderUploadForm.bind(this);
    }

    componentDidMount() {
        this.validateAccount();
    }

    validateAccount() {
        return GET(Endpoints.FINANCE.CARD.HAS_VALID_DETAILS)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    console.log(res.message);
                    this.setState({ loading: false });
                }
                else extractData(res) ? this.setState({ loading: false, permitted: true }) : this.setState({ loading: false });
            })
            .catch(e => console.log(e));
    }

    removeFile(e) {
        e.preventDefault();
        e.stopPropagation();

        let idx = e.target.getAttribute('index');
        let files = [
            ...this.state.files.slice(0, idx),
            ...this.state.files.slice(idx + 1)
        ];

        this.setState({ files: files });
    }

    renderUploadResult(result) {
        if (result.isError) {
            const errors = result.message ? result.message.split(',') : [];

            if (result.isMalformed) {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>Formatting error, {result.fileName} error detected with formatting</Card.Body>
                </Card>);
            } else if (result.message && result.isError && errors.length > 0) {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>
                        <p>Upload Error</p>
                        {errors.map((e, i) => <p key={i}>{e}</p>)}
                    </Card.Body>
                </Card>);
            } else if (result.message && result.isError) {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>Upload error, {result.message}</Card.Body>
                </Card>);
            } else {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>
                        <p>Upload error</p>
                        <p>{result.fileName}: {result.successfulUploads} Items uploaded Successfully.</p>
                        <p>{result.errorUploads} Items could not be uploaded.</p>
                        {result.errorLines.length && <React.Fragment>
                            <p>Errors on lines:</p>
                            <ul>
                                {result.errorLines.map((e, i) => <li key={i}>{e}</li>)}
                            </ul>
                        </React.Fragment>}
                    </Card.Body>
                </Card>);
            }
        } else {
            return (<Card className="card-success mb-3">
                <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
                <Card.Body>{result.fileName} uploaded successfully.</Card.Body>
            </Card>);
        }
    }

    onDrop(acceptedFiles, rejectedFiles) {

        if (rejectedFiles.length > 0) {
            this.setState({ results: [], error: true, message: "File could not be uploaded. Please ensure the file type is CSV and the file size is under 1MB." });
        }

        this.setState(prevState => ({
            files: prevState.files.concat(acceptedFiles)
        }));
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.state.files.length) {
            this.setState({ loading: true });

            let formData = new FormData();
            this.state.files.forEach((f, i) => formData.append(`files`, this.state.files[i], f.name));

            return POST_FILE(Endpoints.ORDERS.POST.UPLOAD, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    if (!response.error) {
                        this.setState({ loading: false, error: false, files: [], message: 'Orders successfully uploaded.', results: extractData(response) });
                    }
                    else {
                        this.setState({ loading: false, error: true, files: [], message: response.message });
                    }
                });
        } else {
            this.setState({ results: [], error: true, message: "No file selected, Ensure a file is selected for upload." });
        }
    }

    renderUploadForm(uploadResult) {
        const { error, message } = this.state;

        return (<Form onSubmit={this.onSubmit}>
            <FormGroup>
                <ReactDropzone accept=".csv" onDrop={this.onDrop} maxSize={999999}>
                    {({ getRootProps, getInputProps }) => (
                        <React.Fragment>
                            <div {...getRootProps({ className: "file-upload text-center" })}>
                                <input {...getInputProps()} />
                                <FontAwesomeIcon icon={faFileUpload} className="file-upload-icon" />
                                <p className="file-upload-primarytext my-2">Drag and drop your CSV file(s)</p>
                                <p className="file-upload-secondarytext my-2">or</p>
                                <Button variant="secondary">Browse</Button>
                            </div>
                            <div className="file-list mt-4">
                                <ul>
                                    {this.state.files.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{f.name} - {f.size} bytes <FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" index={i} onClick={this.removeFile} /></li>)}
                                </ul>
                            </div>
                        </React.Fragment>
                    )}
                </ReactDropzone>
            </FormGroup>
            {uploadResult}
            {error && message && <Card className="card-danger mb-3">
                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                <Card.Body>{message}</Card.Body>
            </Card>}
            <FormGroup className="upload-buttons float-right">
                <Button variant="primary" type="submit">Upload File(s)</Button>
            </FormGroup>
        </Form>);
    }

    renderForbidden() {
        return (<Card className="card-danger mb-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>No card details found. Please add card details to your account to submit orders.</Card.Body>
        </Card>);
    }

    render() {
        const { results, loading, permitted } = this.state;
        const uploadResult = results.map((r, i) => this.renderUploadResult(r));

        return (<React.Fragment>
            <Header title="Orders" subNavList={RetailerOrderSubNavList} activeKey="Upload Order" />
            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <h4>CSV Example and Documentation</h4>
                        <p><strong>Before you upload please read the following information.</strong></p>
                        <p> Order Uploads should represent one customer order only but can contain multiple items in an order.</p>
                        <p>Our system will create labels for the order and these are unique to this order.</p>
                        <p> Please do not combine orders into an upload. Again, each order should be a separate upload. If in doubt or you have any questions about this please contact us via support@selazar.com.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <div className="file-list">
                            <ul className>
                                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Order CSV example<a href={OrderExample} target='_blank' rel="noopener noreferrer" className="float-right" alt='Order CSV example - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
                                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Order CSV instructions<a href={OrderUploadPdf} target='_blank' rel="noopener noreferrer" className="float-right" alt='Order CSV instructions - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        {loading ? <LoadingBar /> :
                            permitted ? this.renderUploadForm(uploadResult) : <ErrorAlert errorMessage={NO_CARD_DETAILS_MESSAGE} />}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
        );
    }
}

export default UploadOrderCsv;