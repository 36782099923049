import React, { Component } from 'react';
import { Modal, Table, Container, Col, Row, Button, Card } from 'react-bootstrap';
import Endpoints from '../../common/Endpoints';
import { GET, extractData } from '../../../Consumer';
import { toLocalTimeString } from '../../../Utilities';

const reasonDescription = (reason) => {
    switch (reason) {
        case 0:
            return "I no longer want/need the product"
        case 1:
            return "The product is not as described"
        case 2:
            return "I received a different product"
        case 3:
            return "I received the incorrect size or colour"
        case 4:
            return "The product does not work"
        case 5:
            return "The product is faulty or defective"
        case 6:
            return "The product arrived damaged"
        case 7:
            return "I ordered the wrong product"
        case 8:
            return "The product is faulty or defective"
        case 9:
            return "I found a better price elsewhere"
        default:
            return "Reason no found"
    }
}

class RetailerReturnsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            processed: [],
            unprocessed: [],
            loading: true,
            modalData: {},
            modalProcessed: false,
            show: false
        };

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    componentDidMount() {
        this.fetchProcessed();
        this.fetchUnprocessed();
    }

    fetchProcessed = () => {
        return GET(Endpoints.RETAILER.GET.PROCESSED_RETURNS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const processed = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ processed: processed, loading: false });
            });
    }

    fetchUnprocessed = () => {
        return GET(Endpoints.RETAILER.GET.UNPROCESSED_RETURNS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const unprocessed = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ unprocessed: unprocessed, loading: false });
            });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    handleShow = (modalReturn, modalProcessed) => {
        this.setState({
            modalData: modalReturn,
            modalProcessed: modalProcessed,
            show: true
        });
    }

    render() {
        return (<Container fluid>
            <Row>
                <Col md={12}>
                    <h2 className="mb-4">Returns</h2>
                </Col>
            </Row>
            <h5>Unprocessed Returns</h5>
            <Row className="my-4">
                <Col>
                    <Card>
                        <div className="table-responsive">
                            <Table striped bordered size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Return Reference</th>
                                        <th>Order Reference</th>
                                        <th>Status</th>
                                        <th>Check-in Start</th>
                                        <th>Check-in End</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.unprocessed.length ? this.state.unprocessed.map((u) => {
                                        return (<tr key={u.id}>
                                            <td><a className="table-id-link" onClick={() => this.handleShow(u, false)}>{u.reference}</a></td>
                                            <td>{u.orderReference}</td>
                                            <td>{u.status === 0 ? "Unprocessed" : "Checked-in"}</td>
                                            <td>{u.checkinStarted ? toLocalTimeString(u.checkinStarted) : "N/A"}</td>
                                            <td>{u.checkinEnded ? toLocalTimeString(u.checkinEnded) : "N/A"}</td>
                                        </tr>);
                                    }) : <tr><td colSpan={5}><b>No returns to show.</b></td></tr>}
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Col>
            </Row>

            <h5>Processed Returns</h5>
            <Row className="my-4">
                <Col>
                    <Card>
                        <div className="table-responsive">
                            <Table striped bordered size="sm" className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Return Reference</th>
                                        <th>Order Reference</th>
                                        <th>Check-in Start</th>
                                        <th>Check-in End</th>
                                        <th>Clean/Repack Start</th>
                                        <th>Clean/Repack End</th>
                                        <th>Processing Started</th>
                                        <th>Processing Ended</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.processed.length ? this.state.processed.map((p) => {
                                        return (<tr key={p.id}>
                                            <td><a className="table-id-link" onClick={() => this.handleShow(p, true)}>{p.reference}</a></td>
                                            <td>{p.orderReference}</td>
                                            <td>{p.checkinStarted ? toLocalTimeString(p.checkinStarted) : "N/A"}</td>
                                            <td>{p.checkinEnded ? toLocalTimeString(p.checkinEnded) : "N/A"}</td>
                                            <td>{p.cleaningRepackStarted ? toLocalTimeString(p.cleaningRepackStarted) : "N/A"}</td>
                                            <td>{p.cleaningRepackEnded ? toLocalTimeString(p.cleaningRepackEnded) : "N/A"}</td>
                                            <td>{p.processingStarted ? toLocalTimeString(p.processingStarted) : "N/A"}</td>
                                            <td>{p.processingEnded ? toLocalTimeString(p.processingEnded) : "N/A"}</td>
                                        </tr>);
                                    }) : <tr><td colSpan={8}><b>No returns to show.</b></td></tr>}
                                </tbody>
                            </Table>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Return Reference: {this.state.modalData.reference}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Return Summary</strong></p>
                    <p>Status: {this.state.modalData.status === 0 ? "Unprocessed" : this.state.modalData.status === 1 ? "Checked-in" : "Processed"}</p>
                    <p>Order Reference: {this.state.modalData.orderReference}</p>
                    {this.state.modalData && this.state.modalData.items ? this.state.modalData.items.map((i) => {
                        return (<React.Fragment>
                            <br></br>
                            <p><strong>Item Name: {i.name}</strong></p>
                            {i.skuCode &&  <p>SKU: {i.skuCode}</p>}
                            <p>Quantity: {i.quantity}</p>
                            {this.state.modalProcessed && <React.Fragment>
                                <p>Reason: {reasonDescription(i.reason)}</p>
                                {i.reasonComment ? <p>{i.reasonComment}</p> : null}
                                <p><strong>Item Quality Assurance</strong></p>
                                <p>Item is Present: {i.itemPresent ? "Yes" : "No"}</p>
                                <p>Return is Valid: {i.returnValid ? "Yes" : "No"}</p>
                                <p>Return Condition is Acceptable: {i.returnConditionAcceptable ? "Yes" : "No"}</p>
                                <p>No Other Issues: {i.noOtherIssues ? "Yes" : "No"}</p> </React.Fragment>}
                        </React.Fragment>);
                    }) : <p>No item details found.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" onClick={this.handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>

        </Container>
        );
    }
}

export default RetailerReturnsList;