import React, { Component } from 'react';
import { InfoWindow, Marker } from 'react-google-maps';
import PropTypes from 'prop-types';
import Orders from './Orders';

export class OrderMarker extends Component {
    displayName = OrderMarker.name;
    constructor(props) {
        super(props);
        this.onToggleInfo = this.onToggleInfo.bind(this);
        this.state = { show: false };
    }
    
    onToggleInfo() {
        this.setState((prevState) => ({ show: !prevState.show }));
    }

    getIcon(status) {
        let icon = 'blue-dot.png';
        switch (status.name.toUpperCase()) {
            case Orders.STATUSES.FAILED: icon = 'red-dot.png'; break;
            case Orders.STATUSES.DELIVERED: icon = 'green-dot.png'; break;            
            default: icon = 'blue-dot.png';
        }

        return icon;
    }

    getIconUri(status) {
        let uri = 'https://maps.google.com/mapfiles/ms/icons/';
        return uri + this.getIcon(status);
    }

    render() {
        let order = this.props.order;       

        return (
            <Marker icon={this.getIconUri(order.status)} position={{ lat: order.latitude, lng: order.longitude }} onClick={this.onToggleInfo} visible={true}>
                {
                    this.state.show &&
                    <InfoWindow>
                        <dl>
                            <dt>Order ID:</dt>
                            <dd>{order.id}</dd>
                            <dt>Received:</dt>
                            <dd>{order.received}</dd>
                            <dt>Status</dt>
                            <dd>{order.status}</dd>
                        </dl>
                    </InfoWindow>
                }
            </Marker>
        );
    }
}

OrderMarker.propTypes = {
    order: PropTypes.object.isRequired
};
