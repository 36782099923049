import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup, Card } from 'react-bootstrap';
import { GET, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

class StockListFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = { categoryOptions: [] };
    }

    componentDidMount() {
        Promise.all([this.fetchCategories()]);
    }

    fetchCategories = () => {
        return GET(Endpoints.CATEGORY.GET.ALL)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const categories = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ categoryOptions: categories });
            });
    }

    render() {
        const { name, category, onFilterChange, onReset, onSearch, onExport, exportError, message } = this.props;
        const categoryOptions = this.state.categoryOptions || [];

        return (<Form onSubmit={onSearch}>
            <FormGroup>
            {exportError &&
                <Row>
                    <Col>
                        <Card className="card-danger mb-3">
                            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                                There seems to be a problem
                            </Card.Title>
                            <Card.Body>
                                {message}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
                <Row>
                    <Col md={3}>
                        <InputGroup>
                            <FormControl type="text" id="name" name="name" value={name} onChange={onFilterChange} placeholder="Search by name" />
                            <InputGroup.Append>
                                <InputGroup.Text className="input-filter-icon">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={1} className="pr-0">
                        <FormLabel htmlFor="invoiceType" className="float-right mt-1">Category</FormLabel>
                    </Col>
                    <Col md={3}>
                        <FormControl id="category" name="category" as="select" value={category} onChange={onFilterChange}>
                            <option key="" value="">Please Select...</option>;
                            {categoryOptions.map((option) => <option key={option.id} value={option.name}>{option.name}</option>)}
                        </FormControl>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Button variant="primary" className="float-right ml-2" name="export" onClick={onExport}>Export CSV</Button>
                <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                <Button variant="link" className="float-right" name="filterReset" onClick={onReset}>Clear filter</Button>
            </FormGroup>
        </Form>);
    }
}

export default StockListFilter;