import React, { Component } from 'react';
import { InfoWindow, Marker } from 'react-google-maps';
import PropTypes from 'prop-types';

export class RegularCollectionMarker extends Component {
    displayName = RegularCollectionMarker.name;
    constructor(props) {
        super(props);
        this.onToggleInfo = this.onToggleInfo.bind(this);
        this.state = { show: false };
    }
    
    onToggleInfo() {
        this.setState((prevState) => ({ show: !prevState.show }));
    }

    getIconUri(regularCollection) {                
        var dot = 'green-dot.png';
        
        if (regularCollection.active === null) {
            dot = 'red-dot.png';
        }

        if (regularCollection.active === false) {
            dot = 'yellow-dot.png';
        }

        return `https://maps.google.com/mapfiles/ms/icons/${dot}`;
    }

    render() {
        let regularCollection = this.props.regularCollection;       
        let companyAddress = regularCollection.companyAddress;

        return (
            <Marker icon={this.getIconUri(regularCollection)} position={{ lat: companyAddress.address.latitude, lng: companyAddress.address.longitude }} onClick={this.onToggleInfo} visible={true}>
                {
                    this.state.show &&
                    <InfoWindow>
                        <dl>
                            <div>
                                <dt>Address:</dt>
                                <dd>{companyAddress.address.line1}</dd>
                                <dd>{companyAddress.address.line2}</dd>
                                <dd>{companyAddress.address.town}</dd>
                                <dd>{companyAddress.address.country}</dd>
                                <dd>{companyAddress.address.postcode}</dd>
                            </div>
                            <div>
                                <dt>Active</dt>
                                <dd>{companyAddress.active ? 'Yes': 'No'}</dd>
                            </div>
                            {companyAddress.operatingDetails !== null ? 
                            <div>
                                <dt>Opening Hours</dt>
                                {companyAddress.operatingDetails.openingHours.map(o => <dd key={o.day}>{o.display}</dd>)}
                                <dt>Services offered</dt>
                                {companyAddress.operatingDetails.servicesOffered.map(o => <dd key={o}>{o}</dd>)}
                            </div> : null}
                        </dl>
                    </InfoWindow>
                }
            </Marker>
        );
    }
}

RegularCollectionMarker.propTypes = {
    regularCollection: PropTypes.object.isRequired
};
