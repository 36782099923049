import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { toLocalTimeString } from '../../../Utilities';

class InvoiceRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invoice: this.props.invoice,
            show: false,
            loading: false
        };
    }

    goToURL = (url) => {
        window.open(url, '_blank');
    }

    getPDF = () => {
        const { invoice } = this.state;
        this.goToURL(invoice.link);
    }

    getType = (invoiceType) => {
        switch (invoiceType) {
            case "STOCK_HOLDING":
                return <span className="text-status-green">Stock Holding</span>
            case "STOCK_PROCESSING":
                return <span className="text-status-yellow">Stock Processing</span>
            case "STOCK_CONSIGNMENT_PROCESSING":
                return <span className="text-status-yellow">Stock Consignment Processing</span>
            case "STOCK_REPLENISHMENT":
                return <span className="text-status-grey">Stock Replenishment</span>
            case "ORDERS":
                return <span className="text-status-blue">Orders</span>
            case "RETURNS":
                return <span className="text-status-grey">Returns</span>
            case "AD_HOC_CHARGE":
                return <span className="text-status-grey">Additional Cost</span>
            case "CREDIT_NOTE":
                return <span className="text-status-grey">Credit Note</span>
            case "BULK_ORDER":
                return <span className="text-status-purple">Bulk Order</span>
            default:
                return <span className="text-status-grey">{invoiceType}</span>
        }
    }

    render() {
        const { invoice } = this.state;

        return (
            <tr>
                <td>{toLocalTimeString(invoice.createdDate)}</td>
                <td>{invoice.invoiceReferenceNumber}</td>
                <td>{this.getType(invoice.invoiceType)}</td>
                <td>{parseFloat(invoice.finalTotal).toFixed(2)}</td>
                <td className="text-center py-2"><Button variant="secondary" size="sm" onClick={this.getPDF}>View Invoice</Button></td>
            </tr>
        );
    }
}

export default InvoiceRow;