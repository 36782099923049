import React from 'react';
import ReactDropzone from 'react-dropzone';
import { FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFingerprint, faEdit, faImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { WarningAlert } from '../../../../common/Alert';

const NO_BARCODE_TITLE_VALIDATION = "No-Barcode Product";
const NO_BARCODE_MESSAGE_VALIDATION = "You are required to upload a product image.";

const NO_BARCODE_SKU_TITLE_VALIDATION = "No-Barcode Product SKU Variants";
const NO_BARCODE_SKU_MESSAGE_VALIDATION = "You are required to upload a product image for each SKU variant.";

const ImageUpload = ({ images = [], dropzoneEnabled, onDrop, deleteImage }) =>
    <FormGroup>
        <FormLabel className="mb-0">Product Image</FormLabel>
        <div className="form-input-description mb-3">
            <p>A product image is required for no-barcode products.</p>
        </div>
        {dropzoneEnabled &&
            <ReactDropzone accept="image/*" onDrop={onDrop} maxSize={999999} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="file-upload text-center">
                        <input {...getInputProps()} />
                        <p className="file-upload-primarytext my-2">Drag and drop your image</p>
                        <p className="file-upload-secondarytext my-2">or</p>
                        <Button variant="secondary">Browse</Button>
                    </div>
                )}
            </ReactDropzone>
        }
        {images.length > 0 && <div className="form-image-list mt-3">
            <ul>
                {images.map((image, i) =>
                    <li key={i}>
                        <FontAwesomeIcon icon={faImage} className="form-fileicon" />{image.name}<FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" id={image.id} onClick={() => deleteImage(i)} />
                    </li>)
                }
            </ul>
        </div>}
    </FormGroup>

const UniqueIdentifiers = ({ companyItem, handleInputChange, handleShowForNewSKU, handleShowForExistingSKU, eanValidation, showBarcodeWarning, images, dropzoneEnabled, onDrop, deleteImage }) =>
    <React.Fragment>
        <h5>Unique Identifiers</h5>
        <p className="my-3"><strong>Enter existing unique product identifiers here:</strong></p>
        <div className="mt-2">
            <FormGroup>
                <FormLabel htmlFor="eanField">EAN <span className="form-label-optional">- optional</span></FormLabel>
                <div className="form-input-description">
                    <p>This is the number that is below the barcode displayed on either the packaging or the product itself.</p>
                </div>
                <FormControl id="eanField" type="text" parent="item" name="ean" value={companyItem.item.ean} onChange={handleInputChange} />
                <span className="text-danger">{eanValidation.ean.message}</span>
            </FormGroup>
            <FormGroup>
                <FormLabel htmlFor="isbnField">ISBN <span className="form-label-optional">- optional</span></FormLabel>
                <FormControl id="isbnField" type="text" parent="item" name="isbn" value={companyItem.item.isbn} onChange={handleInputChange} />
            </FormGroup>
            <FormGroup className="mb-5">
                <div className="mb-4">
                    <FormLabel htmlFor="addSku">SKUs <span className="form-label-optional">- optional</span></FormLabel>
                    <Button variant="secondary" className="float-right" id="addSku" onClick={handleShowForNewSKU}>Add SKU</Button>
                </div>
                {(companyItem.stockKeepingUnits || []).length > 0 && <div className="identifier-list">
                    <ul>
                        {companyItem.stockKeepingUnits.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFingerprint} className="form-fileicon" />{f.code}<FontAwesomeIcon className="form-fileicon-edit float-right" onClick={() => handleShowForExistingSKU(i)} icon={faEdit} /></li>)}
                    </ul>
                </div>}
            </FormGroup>
            <FormGroup className="mb-med">
                <FormLabel className="mb-0">No-Barcode Product</FormLabel>
                <div className="form-input-description mb-3">
                    <p>We scan the product's physical barcode (EAN or SUID) throughout pick and pack for better quality control. If your product cannot have a physical barcode, please select below. This product will be manually confirmed using its image through pick and pack.</p>
                </div>
                <FormGroup className="custom-control custom-checkbox">
                    <input id="noScan" className="custom-control-input" type="checkbox" name="noScan" checked={companyItem.noScan} onChange={handleInputChange} />
                    <FormLabel className="custom-control-label" htmlFor="noScan">This is a no-barcode product</FormLabel>
                </FormGroup>
                {showBarcodeWarning &&
                    <WarningAlert
                        warningTitle={(companyItem.stockKeepingUnits || []).length > 0 ? NO_BARCODE_SKU_TITLE_VALIDATION : NO_BARCODE_TITLE_VALIDATION}
                        warningMessage={(companyItem.stockKeepingUnits || []).length > 0 ? NO_BARCODE_SKU_MESSAGE_VALIDATION : NO_BARCODE_MESSAGE_VALIDATION}
                    />
                }
            </FormGroup>
            {!companyItem.stockKeepingUnits.length > 0 && <ImageUpload images={images} dropzoneEnabled={dropzoneEnabled} onDrop={onDrop} deleteImage={deleteImage} />}
        </div>
    </React.Fragment>

export default UniqueIdentifiers;