import React from 'react';
import PropTypes from 'prop-types';

const OrderStats = ({received, inTransit, pickedAndPacked, failed}) => {
    return (
        <div className="ordercard">    
            <dl>
                <dt>Received:</dt>
                <dd id="receivedTotal">{received.length}</dd>
                <dt>Out for Delivery:</dt>
                <dd id="inTransitTotal">{inTransit.length}</dd>
                <dt>Picked and Packed:</dt>
                <dd id="pickedAndPackedTotal">{pickedAndPacked.length}</dd>
                <dt>Failed:</dt>
                <dd id="failedTotal">{failed.length}</dd>
                <dt>Total:</dt>
                <dd id="total">{received.length + failed.length + inTransit.length + pickedAndPacked.length}</dd>
            </dl>
        </div>
    );
};

OrderStats.propTypes = {
    received: PropTypes.array.isRequired,
    inTransit: PropTypes.array.isRequired,
    pickedAndPacked: PropTypes.array.isRequired,
    failed: PropTypes.array.isRequired,
};

export default OrderStats;