import React from 'react';
import { Button, Col, FormLabel, Form, FormGroup, Row, FormControl, InputGroup } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';


class InvoiceFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: [],
            loading: true,
        };
        this.clear = this.clear.bind(this);
    }

    componentDidMount() {
        this.getInvoiceTypes();
    }


    loading(loading) {
        return this.props.onLoading(loading);
    }

    clear() {
        this.loading(true);
        return this.props.onClear();
    }

    getInvoiceTypes = () => {
        const url = Endpoints.INVOICES.GET.TYPES;

        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ types: extractData(result), loading: false });
                } else {
                    this.setState({ loading: false });
                }
            });
    }

    render() {
        const { invoiceNumber, dateFrom, dateTo, invoiceType, onSearch, onFilterChange, loading } = this.props;
        const { types } = this.state;

        return (
            <Form onSubmit={onSearch}>
                <FormGroup>
                    <Row>
                        <Col md={3}>
                            <InputGroup>
                                <FormControl id="invoiceNumberField" className="input-filter" type="text" name="invoiceNumber" placeholder="Search by Invoice Number" value={invoiceNumber} onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Text>                                    
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={4}>
                            <InputGroup>
                                <FormControl id="dateFromField" className="input-filter" type="date" name="dateFrom" value={dateFrom} onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </InputGroup.Text>                                    
                                </InputGroup.Append>
                                                                
                                <FormControl id="dateToField" className="input-filter" type="date" name="dateTo" value={dateTo} onChange={onFilterChange} />
                                <InputGroup.Append>
                                    <InputGroup.Text className="input-filter-icon">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </InputGroup.Text>                                    
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <FormLabel htmlFor="invoiceType" className="float-right mt-1">Invoice Type</FormLabel>
                        </Col>
                        <Col md={3}>
                            <FormControl id="invoiceType" name="invoiceType" as="select" value={invoiceType} onChange={onFilterChange}>
                                <option key="" value="">Please Select...</option>;
                                        {types.map(type => (<option key={type.value} value={type.value}>{type.name}</option>))}
                            </FormControl>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Button variant="primary" className="float-right" type="submit" disabled={loading}>Apply filter</Button>
                    <Button variant="link" className="float-right" onClick={this.clear} disabled={loading}>Clear filter</Button>
                </FormGroup>
            </Form>
        );
    }
}

export default InvoiceFilter;