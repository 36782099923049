import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, FormControl, Container, Row, Card, InputGroup, FormLabel } from 'react-bootstrap';
import { GET, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import Header from '../../common/Header';
import { RetailerOrderSubNavList } from '../../common/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns'

class TrackingReference extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: false,
            companyName: '',
            filter: 
            {
                dateFrom: '',
                dateTo: ''
            },
            message: ''
        };
     
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getCompanyName = this.getCompanyName.bind(this);
        this.validate = this.validate.bind(this);
    }

    async componentDidMount() {
        await Promise.all([
            this.getCompanyName()]);
    }

    getCompanyName() {
        const user = JSON.parse(localStorage.getItem('user'));

        GET(Endpoints.COMPANY.GET.COMPANY_BY_ID + user.companyID)
            .then(res => {
                if (res && res.ok) return res.json();
            })
            .then(result => {
                if(result){
                    this.setState({ companyName: extractData(result).name });
                }            
            });
    }

    handleInputChange(e) {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    goToURL = (url) => {
        window.open(url, '_blank');
    }

    handleSubmit(e) {
        e.preventDefault();
        const filter = { ...this.state.filter };
        const { companyName } = this.state;
        let url = new URL(Endpoints.ORDERS.GET.TRACKING);

        if(this.validate(filter)) {

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.blob();
            })
            .then(result => {     
                    if(result){
                        const date = new Date();
                        const downloadLink = document.createElement("a");
                        const blob = new Blob(["\ufeff", result]);
                        const url = URL.createObjectURL(blob);
                        downloadLink.href = url;
                        downloadLink.download = companyName + "TrackingExport_" + format(date, 'DDMMYYYY') + ".csv";
                    
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        this.setState({ loading: false, error: false});
                    } else this.setState({ loading: false, error: true, message: "We could not find tracking information for the given date range."});
            });
        }
    }

    validate(filter) {
        let isValid = true;

        if(filter.dateTo && filter.dateFrom){
            if(filter.dateTo < filter.dateFrom){
                isValid = false;
                this.setState({ error: true, message: 'Date To must be after Date From.' });
            }
        }
        
        return isValid;
    }


    render() {
        const { error, message } = this.state;
        const { dateFrom, dateTo } = this.state.filter;
        return (<React.Fragment>
            <Header title="Orders" subNavList={RetailerOrderSubNavList} activeKey="Tracking Reference" />
            <Container fluid>
                <Row>
                    <Col sm="12" md="6">
                        <h4>Tracking Reference</h4>
                        <p><strong>What is tracking reference?</strong></p>
                        <p> This allows you to download a CSV export of the tracking numbers for each order between two dates. </p>
                    </Col>
                </Row>
                {error &&
                <Row>
                    <Col sm="12" md="6">
                        <Card className="card-danger mb-3">
                            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                                There seems to be a problem
                            </Card.Title>
                            <Card.Body>
                                {message}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
                <Row>
                    <Col sm="12" md="6">
                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                            <p><strong>Date Range</strong></p>
                            <p> Enter the date range that you require tracking references for </p>
                            <InputGroup>
                                <FormControl id="dateFromField" className="input-filter" type="date" name="dateFrom" value={dateFrom} onChange={this.handleInputChange}/>
                                    <InputGroup.Append>
                                        <InputGroup.Text className="input-filter-icon">
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                <FormControl id="dateToField" className="input-filter" type="date" name="dateTo" value={dateTo} onChange={this.handleInputChange} />
                                    <InputGroup.Append>
                                        <InputGroup.Text className="input-filter-icon">
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                            </InputGroup>

                            </FormGroup>
                            <FormGroup>
                               <Button variant="primary" className="float-right" type="submit">Export Tracking References</Button>
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
        );
    }
}

export default TrackingReference;