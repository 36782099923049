import React, { Component } from 'react';
import { Col, Container, Row, Button, Form, FormGroup, FormLabel, FormControl, Table, Modal } from 'react-bootstrap';
import LoadingBar from '../../common/LoadingBar';
import CommonEndpoints from '../../common/Endpoints';
import { GET, extractData, POST } from '../../../Consumer';
import { PreferencesSubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../common/Alert';
import FormValidator from '../../common/FormValidator';
import { EoriRegions, EUCountryList } from '../../common/Constants';
import Actions from '../../common/Tables/Actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import Confirm from './../../common/Confirm';

const GB_PLACEHOLDER = "Example: GB123456789";
const NI_PLACEHOLDER = "Example: X1123456789";
const EU_PLACEHOLDER = "Example: XX123456789";

const EORI_FORMATTING_ERROR = "EORI Number is formatted incorrectly for this region.";
const EORI_GENERIC_SAVE_ERROR = "There has been an issue saving this EORI.";
const EORI_GENERIC_UPDATE_ERROR = "There has been an issue updating this EORI.";
const EORI_ISSUE_RETRIEVING_ERROR = "There has been an issue retrieving EORI numbers.";
const EORI_ALL_ADDED_MESSAGE = "You have added EORI numbers for all regions. Please edit your EORI Number, or delete the existing EORI Number before trying to add a new one.";
const EORI_INFORMATION = "Enter your EORI number(s) here. You can get more information on EORI numbers and how to get one at";
const EORI_REMOVAL_MESSAGE = "Are you sure you want to remove the EORI number? Removing your EORI number will result in us being unable to process orders for ";
const EORI_GENERIC_REMOVE_ERROR = "There has been an issue removing this EORI.";

const VAT_INFORMATION = "Choose below with which EU countries you are VAT Registered. You must add an EU EORI number before making your selection. You can find out more information";
const VAT_UPDATE_MESSAGE = "Successfully saved your VAT Registered details";
const VAT_GENERIC_SAVE_ERROR = "There has been an issue saving your VAT Registered details.";
const VAT_GENERIC_RETRIEVE_ERROR = "There has been an issue retrieving your VAT Registered details.";
const VAT_UNSAVED_CHANGES = "You have not saved your changes to the VAT Registered details.";

const AddEoriNumberModal = ({ currentRegion, eoriUpdate, eoriListing, eoriSubmitted, eoriValidation, onSubmitEori, currentlySelectedEori, eoriPopupError, showEoriPopupError, showEoriModal, handleClose, handleEoriInputChange }) => {
    const identifier = currentlySelectedEori.identifier;
    const placeholder = identifier == "GB" ? GB_PLACEHOLDER : identifier == "NI" ? NI_PLACEHOLDER : identifier == "EU" ? EU_PLACEHOLDER : "";

    let availableRegions = [];
    EoriRegions.map(region => { if (!eoriListing.some(el => el.identifier == region.code)) availableRegions = availableRegions.concat(region); });

    return (
        <Modal show={showEoriModal} onHide={handleClose}>
            <Form onSubmit={e => { e.preventDefault(); }}>
                <Modal.Header closeButton>
                    <Modal.Title>EORI Number</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        {showEoriPopupError && <Row><Col><ErrorAlert errorMessage={eoriPopupError}></ErrorAlert></Col></Row>}
                        <FormGroup>
                            <Row>
                                <Col md={12}>
                                    <FormLabel htmlFor="identifier">Region</FormLabel>
                                    {eoriUpdate ? <FormControl type="text" id="identifier" name="identifier" value={currentRegion} /> :
                                        <FormControl as="select" id="identifier" name="identifier" value={currentlySelectedEori.identifier} onChange={handleEoriInputChange} required>
                                            <option value="">Select Region</option>
                                            {availableRegions.map((v, i) => <option key={i} value={v.code}>{v.name}</option>)}
                                        </FormControl>}
                                    {eoriSubmitted && <span className="text-danger">{eoriValidation.identifier.message}</span>}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col md={12}>
                                    <FormLabel htmlFor="number">EORI Number</FormLabel>
                                    <FormControl type="text" placeholder={placeholder} maxLength="15" id="number" name="number" onChange={handleEoriInputChange} value={currentlySelectedEori.number} required />
                                    {eoriSubmitted && <span className="text-danger">{eoriValidation.number.message}</span>}
                                </Col>
                            </Row>
                        </FormGroup>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" className="button-nav-link mr-3" onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" onClick={onSubmitEori}>Add EORI Number</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

class Taxes extends Component {

    eoriValidator = new FormValidator([
        {
            field: 'identifier',
            method: 'isEmpty',
            validWhen: false,
            message: 'Select a Region before continuing'
        },
        {
            field: 'number',
            method: 'isEmpty',
            validWhen: false,
            message: 'Enter your EORI number before continuing'
        }
    ]);

    state = {
        loading: true,
        eoriValidation: this.eoriValidator.valid(),
        showEoriError: false,
        showEoriSuccess: false,
        eoriErrorMessage: '',
        eoriSuccessMessage: '',
        showEoriModal: false,
        eoriSubmitted: false,
        currentlySelectedEori: {
            identifier: "",
            number: ""
        },
        eoriListing: [],
        showEoriPopupError: false,
        eoriPopupError: "",
        showEoriMaxModal: false,
        showEoriRemovalModal: false,
        currentRegion: "",
        eoriUpdate: false,
        showVatError: false,
        vatErrorMessage: "",
        showVatSuccess: false,
        vatSuccessMessage: "",
        vatCountries: [],
        showVatRetrievalError: false,
        euEoriPresent: false,
        unsavedVatChanges: false
    };

    async componentDidMount() {
        await this.fetchCurrentEoriDetails();
        await this.fetchCurrentVatDetails();
        this.setState({ loading: false });
    }

    async fetchCurrentEoriDetails() {
        return await GET(CommonEndpoints.RETAILER.GET.EORI_DETAILS)
            .catch(error => console.log(error))
            .then(response => { if (response.ok) return response.json(); })
            .then(results => {
                const result = extractData(results);
                if (result === null) this.setState({ showEoriError: true, eoriListing: [], eoriErrorMessage: EORI_ISSUE_RETRIEVING_ERROR });
                else {
                    var euEoriPresent = result.eoriNumbers.length > 0 ? result.eoriNumbers.filter(num => { return num.identifier == "EU"}) != "" ? true : false : false;
                    this.setState({ euEoriPresent: euEoriPresent, eoriListing: result.eoriNumbers, showEoriError: false, eoriErrorMessage: '' });
                }
            }).catch(() => this.setState({ showEoriError: true, eoriErrorMessage: EORI_ISSUE_RETRIEVING_ERROR }));;
    }

    async fetchCurrentVatDetails() {
        return await GET(CommonEndpoints.RETAILER.GET.VAT_DETAILS)
            .catch(error => console.log(error))
            .then(response => { if (response.ok) return response.json(); })
            .then(results => {
                const result = extractData(results);
                this.setState({ vatCountries: result.vatCountries, showVatError: false, vatErrorMessage: "", showVatRetrievalError: false });
            }).catch(() => this.setState({ showVatError: true, vatErrorMessage: VAT_GENERIC_RETRIEVE_ERROR, showVatRetrievalError: true }));;
    }


    handlePopupClose = () => this.setState({ showEoriModal: false, showEoriPopupError: false, eoriPopupError: "", eoriSubmitted: false, currentlySelectedEori: { identifier: "", number: "" } });

    handleMaxModalClose = () => this.setState({ showEoriMaxModal: false, currentlySelectedEori: { identifier: "", number: "" } });

    handleUpdateEoriModalShow = (eori) => this.setState({ eoriUpdate: true, showEoriModal: true, currentlySelectedEori: { identifier: eori.identifier, number: eori.eoriNumber }, currentRegion: eori.region });

    handleRemoveEoriModalShow = (eori) => this.setState({ showEoriRemovalModal: true, currentlySelectedEori: { identifier: eori.identifier, number: eori.eoriNumber }, currentRegion: eori.region });

    handleRemoveEoriModalClose = () => this.setState({ showEoriRemovalModal: false, currentlySelectedEori: { identifier: "", number: "" }, currentRegion: "" });


    onSubmitEori = () => {
        const { currentlySelectedEori, currentRegion, eoriUpdate } = this.state;
        const errorText = eoriUpdate ? EORI_GENERIC_UPDATE_ERROR : EORI_GENERIC_SAVE_ERROR;

        const eoriValidation = this.eoriValidator.validate(currentlySelectedEori);
        this.setState({ eoriValidation: eoriValidation, eoriSubmitted: true });

        if (eoriValidation.isValid) {
            this.setState({ loading: true });
            POST(CommonEndpoints.RETAILER.POST.UPDATE_EORI, currentlySelectedEori)
                .catch(error => console.log(error))
                .then(response => { if (response && response.ok) return response.json(); })
                .then(results => {
                    const result = extractData(results);
                    if (result.updated == true) {
                        const successText = eoriUpdate ? "Successfully edited your " + currentRegion + " EORI Number" : "Successfully added your " + result.region + " EORI Number";
                        this.setState({ loading: false, eoriSubmitted: false, eoriUpdate: false, showEoriPopupError: false, showEoriSuccess: true, eoriSuccessMessage: successText, showEoriModal: false, currentlySelectedEori: { identifier: "", number: "" } });
                        this.fetchCurrentEoriDetails();
                    }
                    else if (result.formattingError == true) this.setState({ loading: false, showEoriPopupError: true, showEoriSuccess: false, eoriPopupError: EORI_FORMATTING_ERROR, eoriSuccessMessage: "" });
                    else this.setState({ loading: false, showEoriPopupError: true, showEoriSuccess: false, eoriPopupError: errorText, eoriSuccessMessage: "" });
                }).catch(() => this.setState({ loading: false, showEoriPopupError: true, eoriPopupError: errorText, showEoriSuccess: false, eoriSuccessMessage: "" }));
        }
    }

    handleRemoveEori = () => {
        const { currentlySelectedEori, currentRegion } = this.state;
        const successText = "Successfully removed your " + currentRegion + " EORI Number";
        this.setState({ loading: true });
        POST(CommonEndpoints.RETAILER.POST.REMOVE_EORI + currentlySelectedEori.identifier)
            .catch(error => console.log(error))
            .then(response => { if (response && response.ok) return response.json(); })
            .then(results => {
                const result = extractData(results);
                if (result === true) {
                    this.setState({ loading: false, showEoriRemovalModal: false, showEoriSuccess: true, eoriSuccessMessage: successText, showEoriError: false, eoriErrorMessage: "", currentlySelectedEori: { identifier: "", number: "" } });
                    this.fetchCurrentEoriDetails();
                }
                else this.setState({ loading: false, showEoriRemovalModal: false, showEoriSuccess: false, eoriSuccessMessage: "", showEoriError: true, eoriErrorMessage: EORI_GENERIC_REMOVE_ERROR });
            }).catch(() => this.setState({ loading: false, showEoriRemovalModal: false, showEoriSuccess: false, eoriSuccessMessage: "", showEoriError: true, eoriErrorMessage: EORI_GENERIC_REMOVE_ERROR, }));
    }


    getEoriActions = (eori) => {
        const editDetails = <Button onClick={() => this.handleUpdateEoriModalShow(eori)} variant="link"><FontAwesomeIcon icon={faEdit} />Edit Details</Button>;
        const remove = <Button onClick={() => this.handleRemoveEoriModalShow(eori)} variant="link"><FontAwesomeIcon icon={faTrashAlt} />Remove</Button>;

        return [editDetails, remove];
    }

    handleEoriInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            currentlySelectedEori: {
                ...prevState.currentlySelectedEori,
                ...{ [name]: value }
            }
        }));
    }

    handleAddEoriClick = (eoriListing) => {
        if (eoriListing != undefined && eoriListing.length == 3) this.setState({ showEoriMaxModal: true });
        else this.setState({ showEoriModal: true, eoriPopupError: "" });
    }

    handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;

        this.setState(prevState => ({
            vatCountries: {
                ...prevState.vatCountries,
                ...{ [name]: checked }
            },
            unsavedVatChanges: true,
            showVatSuccess: false,
            showVatError: false
        }));
    }

    onSubmitVat = () => {
        const { vatCountries } = this.state;
        const data = { VatCountries: vatCountries };

        this.setState({ loading: true });
        POST(CommonEndpoints.RETAILER.POST.UPDATE_VAT, data)
            .catch(error => console.log(error))
            .then(response => { if (response && response.ok) return response.json(); })
            .then(results => {
                const result = extractData(results);
                if (result === true) {
                    this.setState({ unsavedVatChanges: false, vatCountries: [], loading: false, showVatError: false, vatErrorMessage: "", showVatSuccess: true, vatSuccessMessage: VAT_UPDATE_MESSAGE });
                    this.fetchCurrentVatDetails();
                }
                else this.setState({ unsavedVatChanges: false, loading: false, showVatError: true, showVatSuccess: false, vatErrorMessage: VAT_GENERIC_SAVE_ERROR, vatSuccessMessage: "" });
            }).catch(() => this.setState({ unsavedVatChanges: false, loading: false, showVatError: true, showVatSuccess: false, vatErrorMessage: VAT_GENERIC_SAVE_ERROR, vatSuccessMessage: "" }));
    }

    buildVatForm() {
        const { vatCountries, showVatRetrievalError, euEoriPresent } = this.state;
        return (
            <React.Fragment>
                { showVatRetrievalError ? null :
                    <Form onSubmit={this.onSubmitVat}>
                        {
                            EUCountryList.map((c, i) => {
                                const value = (vatCountries !== null || vatCountries === undefined || vatCountries === []) ? vatCountries[c.code] : c.code;
                                const checked = (vatCountries !== null || vatCountries === undefined || vatCountries === []) ? vatCountries[c.code] !== undefined ? vatCountries[c.code] : false : false;

                                return (
                                    <FormGroup key={c.code} className="custom-control custom-checkbox mb-2">
                                        <input id={c.code} name={c.code} value={value} className="custom-control-input" type="checkbox" onChange={this.handleCheckBoxChange} checked={checked} disabled={euEoriPresent ? false : true}/>
                                        <FormLabel className="custom-control-label" htmlFor={c.code}>{c.name}</FormLabel>
                                    </FormGroup>
                                )
                            })
                        }
                         { euEoriPresent ? <Button variant="primary" className="float-right mt-3" type='submit'>Save VAT Details</Button> : null }
                    </Form>
                }
            </React.Fragment>
        );
    }

    render() {
        const { unsavedVatChanges, showVatSuccess, vatErrorMessage, showVatError, vatSuccessMessage, eoriUpdate, eoriSuccessMessage, showEoriRemovalModal, currentRegion, eoriListing, showEoriMaxModal, loading, showEoriModal, currentlySelectedEori, eoriPopupError: eoriPopupError, showEoriError, showEoriSuccess, eoriErrorMessage, showEoriPopupError, eoriSubmitted } = this.state;
        const eoriValidation = this.eoriSubmitted ? this.eoriValidator.validate(currentlySelectedEori) : this.state.eoriValidation;

        return loading ? (<LoadingBar />) : (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Taxes" />
                <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            <h5 className="mb-3">EORI Number</h5>
                            <p>{EORI_INFORMATION} <a href="https://www.gov.uk/eori">gov.uk/eori</a>.</p>

                            {showEoriError && <ErrorAlert errorMessage={eoriErrorMessage} />}
                            {showEoriSuccess && <SuccessAlert successMessage={eoriSuccessMessage} />}

                            {eoriListing.length ?
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Region</th>
                                            <th>EORI Number</th>
                                            <th>Last Edited</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {eoriListing.map((e) => {
                                            return (
                                                <tr key={e.identifier}>
                                                    <td>{e.region}</td>
                                                    <td>{e.eoriNumber}</td>
                                                    <td>{e.lastEdited}</td>
                                                    <td><Actions actions={this.getEoriActions(e)} /></td>
                                                </tr>);
                                        })}
                                        <tr bordered className="table-light interactive-row">
                                            <td className="row-buffer"><FontAwesomeIcon icon={faPlus} /> <a className="table-link" onClick={() => this.handleAddEoriClick(eoriListing)}>Add EORI Number</a></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table> : !showEoriError && <Button className="mt-1" variant="secondary" onClick={() => this.handleAddEoriClick()}>Add EORI Number</Button>}

                            <div className="top-buffer">
                                <h5 className="mb-3">VAT Registered</h5>
                                <p>{VAT_INFORMATION} <a href="https://ec.europa.eu/taxation_customs/business/vat/eu-country-specific-information-vat_en">here</a>.</p>

                                {showVatError && <ErrorAlert errorMessage={vatErrorMessage} />}
                                {showVatSuccess && <SuccessAlert successMessage={vatSuccessMessage} />}
                                {unsavedVatChanges && <WarningAlert warningTitle="Unsaved Changes" warningMessage={VAT_UNSAVED_CHANGES} />}
                            </div>
                            {this.buildVatForm()}
                            <AddEoriNumberModal currentRegion={currentRegion} eoriUpdate={eoriUpdate} eoriListing={eoriListing} eoriSubmitted={eoriSubmitted} eoriValidation={eoriValidation} onSubmitEori={this.onSubmitEori} currentlySelectedEori={currentlySelectedEori} showEoriPopupError={showEoriPopupError} eoriPopupError={eoriPopupError} showEoriModal={showEoriModal} handleClose={this.handlePopupClose} handleEoriInputChange={this.handleEoriInputChange} addToBulkOrder={this.addToBulkOrder} />
                            <Confirm title="EORI Number Alert" text={EORI_ALL_ADDED_MESSAGE} buttonText="Ok" buttonVariant="primary" handleConfirmAction={this.handleMaxModalClose} handleConfirmClose={this.handleMaxModalClose} show={showEoriMaxModal} showCloseButton={false} />
                            <Confirm title="Remove EORI Number" text={EORI_REMOVAL_MESSAGE + currentRegion + "."} buttonText="Remove EORI Number" buttonVariant="danger" handleConfirmAction={this.handleRemoveEori} handleConfirmClose={this.handleRemoveEoriModalClose} show={showEoriRemovalModal} showCloseButton={true} />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

export default Taxes;