import React from 'react';
import { Button, Col, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class BundleFilter extends React.Component {
    
    render() {
        const { searchText, onFilterChange, onReset, onSearch } = this.props;

        return (<Form onSubmit={onSearch}>
            <FormGroup>
                <Row>
                    <Col md={6}>
                        <InputGroup>
                            <FormControl type="text" id="searchText" className="input-filter" name="searchText" value={searchText} onChange={onFilterChange} placeholder="Search by bundle name, bundle SKU or product SKU" />
                            <InputGroup.Append>
                                <InputGroup.Text className="input-filter-icon">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                <Button variant="link" className="float-right" name="filterReset" onClick={onReset}>Clear filter</Button>
            </FormGroup>
        </Form>);
    }
}

export default BundleFilter;