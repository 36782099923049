import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl, Row, Col, Button, Container } from 'react-bootstrap';
import { ProductsSubNavList } from '../../../../common/Constants';
import { Link } from 'react-router-dom';
import { GET, PUT, extractData } from '../../../../../Consumer';
import { defaultGuid } from '../../../../../Utilities';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import FormValidator from '../../../../common/FormValidator';
import Header from '../../../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert, ErrorAlert } from '../../../../common/Alert';

class EditPricing extends Component {

    constructor(props) {
        super(props);

        this.priceValidator = new FormValidator([
            {
                field: 'price',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Price must be greater than 0'
            }
        ]);

        this.expectedWeeklySalesValidator = new FormValidator([
            {
                field: 'expectedWeeklySales',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 0 }],
                message: 'Expected Weekly Sales is required'
            },
        ]);

        const user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            loading: true,
            warning: null,
            companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            pageIndex: this.props.match.params.pageIndex || 1,
            categories: [],
            companyItem: {
                id: defaultGuid,
                editable: false,
                active: false,
                companyID: user.companyID,
                itemID: defaultGuid,
                expectedWeeklySales: 0,
                isPrePacked: false,
                requiresBoxPackaging: false,
                requiresBubbleWrapPackaging: false,
                requiresPaperPackaging: false,
                requiresEcoPackaging: false,
                isFragile: false,
                stockKeepingUnits: [],
                item: {
                    id: defaultGuid,
                    name: '',
                    description: '',
                    brand: '',
                    height: 0.00,
                    width: 0.00,
                    depth: 0.00,
                    weight: 0.00,
                    categoryID: '',
                    ean: null,
                    selazarUniqueID: null,
                    jan: null,
                    upc: null,
                    isbn: null,
                    mpn: null,
                    countryCodeOfManufacture: '',
                    commodityCode: '',
                    itemImages: []
                },
                currentPrice:
                {
                    id: defaultGuid,
                    companyItemID: defaultGuid,
                    price: 0.00,
                    active: true
                },
                noBarcode: false,
                dropzoneEnabled: true
            },
            priceValidation: this.priceValidator.valid(),
            expectedWeeklySalesValidation: this.expectedWeeklySalesValidator.valid()
        };
    }

    async componentDidMount() {
        await this.getCompanyItem();
        this.setState({ loading: false });
    }

    getCompanyItem = () => {
        const { companyItemID } = this.state;
        return GET(Endpoints.STOCK.GET.BY_ID + companyItemID)
            .then(response => response.json())
            .then(data => {
                const companyItem = extractData(data);
                const warning = companyItem ? null : "No product available."
                this.setState({ companyItem: companyItem, warning: warning });
            });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            const parent = e.target.getAttribute('parent');
            const parentValue = this.state.companyItem[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }
        this.setState(prevState => ({
            ...prevState,
            companyItem: {
                ...prevState.companyItem,
                ...{ [name]: value }
            }
        }));
    };

    handleSubmit = async () => {
        this.setState({ loading: true });
        const { companyItem } = this.state;
        this.submitted = true;

        const priceValidation = this.priceValidator.validate(companyItem.currentPrice);
        const salesValidation = this.expectedWeeklySalesValidator.validate(companyItem);
        this.setState({ priceValidation: priceValidation, expectedWeeklySalesValidation: salesValidation });

        if (priceValidation.isValid && salesValidation.isValid) {
            return await PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.setState({ loading: false, success: true, warning: null });
                    } else {
                        this.setState({ loading: false, success: false, warning: result.message });
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        const { companyItem, loading, warning, success, priceValidation, expectedWeeklySalesValidation, pageIndex } = this.state;

        return (
            <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Products" headerClass="mb-0" />
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link to={`/supplier/products/edit/${companyItem.id}/${pageIndex}`} className="link-button">
                                <Button variant="link" className="button-nav-return p-0 mb-3"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        {loading ? <LoadingBar /> :
                            <React.Fragment>
                                <Col sm={12} md={6}>
                                    <h5>{"Sales & Pricing"}</h5>
                                    {warning && <ErrorAlert errorMessage={warning} />}
                                    {success && <SuccessAlert successMessage="Product successfully updated." />}
                                    <FormGroup as={Row}>
                                        <Col sm={12} md={4}>
                                            <FormLabel htmlFor="priceField">Product Price (£)</FormLabel>
                                            <FormControl id="priceField" type="number" parent="currentPrice" name="price" value={companyItem.currentPrice.price} onChange={this.handleInputChange} min="0" step="0.01" />
                                        </Col>
                                        <Col md={12}>
                                            <span className="text-danger">{priceValidation.price.message}</span>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Col md={12}>
                                            <FormLabel htmlFor="expectedWeeklySalesField" className="mb-0">Expected Weekly Sales</FormLabel>
                                            <div className="form-input-description">
                                                <p>We need to know your expected weekly sales to send you stock alerts. If your weekly sales is 0 then we will automatically add a default of 20% to the pallet total.</p>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <FormControl id="expectedWeeklySalesField" type="number" name="expectedWeeklySales" value={companyItem.expectedWeeklySales} onChange={this.handleInputChange} min="0" />
                                        </Col>
                                        <Col md={12}>
                                            <span className="text-danger">{expectedWeeklySalesValidation.expectedWeeklySales.message}</span>
                                        </Col>
                                    </FormGroup>
                                    <Button variant="primary" className="float-right" onClick={this.handleSubmit}>Save</Button>
                                </Col>
                            </React.Fragment>}
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default EditPricing;