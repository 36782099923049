import React, { Component } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { OrderCard } from './OrderCard';
import Swimlane from '../../common/Swimlane';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import OrderMap from './OrderMap';
import OrderStats from './OrderStats';
import OrderExport from './OrderExport';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import { GET, extractData } from '../../../Consumer';

class Orders extends Component {

    static get STATUSES() {
        return {
            RECEIVED: 'RECEIVED',
            PICKED_PACKED: 'PICKED AND PACKED',
            IN_TRANSIT: 'OUT FOR DELIVERY',
            FAILED: 'FAILED',
            DELIVERED: 'DELIVERED'
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            loading: true            
        };

        this.fetchOrders = this.fetchOrders.bind(this);
    }

    componentDidMount() {           
        this.fetchOrders();
    }

    fetchOrders() {
        let user = JSON.parse(localStorage.getItem('user'));

        return GET(Endpoints.CURRENT_ORDERS + user.companyID)
            .catch(error => console.log(error))
            .then(response =>  response.json())
            .then(results => {
                if (!results.error) {
                    this.setState({ orders: extractData(results), loading: false});
                } else {
                    let warning = "No orders found";
                    this.setState({ orders: [], loading: false, warning: warning });
                }
            }
        );   
    }
    
    getReceived(orders) {
 
        orders = Array.isArray(orders) ? orders : [];        
        return orders.filter((r, i) => r.status.name.toUpperCase() === Orders.STATUSES.RECEIVED);
    }

    getPickedAndPacked(orders) {
        orders = Array.isArray(orders) ? orders : [];
        return orders.filter((r, i) => r.status.name.toUpperCase() === Orders.STATUSES.PICKED_PACKED);
    }

    getInTransit(orders) {
        orders = Array.isArray(orders) ? orders : [];        
        return orders.filter((r, i) => r.status.name.toUpperCase() === Orders.STATUSES.IN_TRANSIT);
    }

    getFailed(orders) {
        orders = Array.isArray(orders) ? orders : [];        
        return orders.filter((r, i) => r.status.name.toUpperCase() === Orders.STATUSES.FAILED);
    }

    getTrackable(orders) {
        orders = Array.isArray(orders) ? orders : [];        
        return orders.filter((r, i) => r.latitude !== null && r.longitude !== null);
    }

    orderCardsFrom(orders) {
        orders = Array.isArray(orders) ? orders : [];        
        return orders.map((r, i) => <OrderCard order={r} key={r.id} />);
    }

    render() {
        let { orders }      = this.state;
        let received        = this.orderCardsFrom(this.getReceived(orders));
        let pickedAndPacked = this.orderCardsFrom(this.getPickedAndPacked(orders));
        let inTransit       = this.orderCardsFrom(this.getInTransit(orders));
        let failed          = this.orderCardsFrom(this.getFailed(orders));
        let trackableOrders = this.getTrackable(orders);

        return this.state.loading ? <LoadingBar /> :            
            <Container>
                <h1>Orders</h1>
                {this.state.warning && <Alert bsStyle="warning">{this.state.warning}</Alert>}                
                <Row className="margin-bottom panel panel-default panel-padded" style={{ minHeight: "300px" }}>
                    <Col md={8} style={{ overflowY: "auto", maxHeight: "560px" }}>
                        <Col md={3}><Swimlane title="Received">{received}</Swimlane></Col>
                        <Col md={3}><Swimlane title="Picked and Packed">{pickedAndPacked}</Swimlane></Col>
                        <Col md={3}><Swimlane title="Out for Delivery">{inTransit}</Swimlane></Col>
                        <Col md={3}><Swimlane title="Failed">{failed}</Swimlane></Col>
                    </Col>
                    <Col style={{ height: "100%" }} md={4}>                        
                        <Col md={12}><OrderExport /></Col>
                        <Col md={12}>
                            <SelazarLinkContainer to='/supplier/orders/all'>
                                <a className="btn btn-primary">View all orders</a>
                            </SelazarLinkContainer>
                        </Col>
                    </Col>
                </Row>
                <Row style={{ height: "auto", minHeight: "50vh" }} className={"panel panel-default panel-padded"}>
                    <Col md={8}>
                        <OrderMap orders={ trackableOrders } />
                    </Col>
                    <Col md={4}>                        
                        <OrderStats received={received} pickedAndPacked={pickedAndPacked} inTransit={inTransit} failed={failed} />                        
                    </Col>
                </Row>
            </Container>
        ;
    }
}

export default Orders;