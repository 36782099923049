import React, { Component } from 'react';
import { Col, Row, Container, Card, Table } from 'react-bootstrap';
import { GET, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { InventorySubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import { ErrorAlert } from '../../../common/Alert';
import Pagination from '../../../common/Tables/Pagination';
import QuarantineFilter from './QuarantineFilter';
import QuarantineRow from './QuarantineRow';

class QuarantineList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 1,
            pageCount: 1,
            items: [],
            loading: true,
            warning: "",
            filter: {
                name: "",
                quarantineDateFrom: undefined,
                quarantineDateTo: undefined,
                sortBy: ""
            }
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchQuarantineStock()]);
        this.setState({ loading: false });
    }

    fetchQuarantineStock = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.STOCK.GET.QUARANTINE);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No stock found" : null;
                this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            });
    }

    renderConsignmentRows() {
        const { items, pageIndex, pageCount } = this.state;
        return (
            <Card>
                <div className="table-responsive">
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th>Filename/Item Name</th>
                                <th>On Hold Date</th>
                                <th>Status</th>
                                <th>Outcome</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.length ? items.map(item => <QuarantineRow key={item.id} item={item} />) : <p className="mx-1 my-1"><strong>No consignments available.</strong></p>}
                        </tbody>
                    </Table>
                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                </div>
            </Card>
        );
    }

    onClear = async (e) => {
        e.preventDefault();

        this.setState({
            filter: { name: "", quarantineDateFrom: null, quarantineDateTo: null, sortBy: "" },
            loading: true
        }, await this.fetchQuarantineStock);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchQuarantineStock);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchQuarantineStock);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchQuarantineStock);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchQuarantineStock);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    render() {
        const { loading, warning } = this.state;

        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Header title="Inventory" subNavList={InventorySubNavList} activeKey="On Hold Inventory" />
                        <Container fluid>
                            {warning ?
                                <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row> :
                                <React.Fragment>
                                    <Row className="my-4">
                                        <Col>
                                            <QuarantineFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                                        </Col>
                                    </Row>
                                    {this.renderConsignmentRows()}
                                </React.Fragment>}
                        </Container>
                    </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default QuarantineList;