import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PreferencesSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import LoadingBar from '../../../common/LoadingBar';
import CommonEndpoints from '../../../common/Endpoints';
import { ErrorAlert } from '../../../common/Alert';
import { GET, extractData, POST } from '../../../../Consumer';

import './CustomPackaging.scss'

const EXTRAS_ERROR_MESSAGE = "There was a problem updating your extra packaging preference. Please try again.";
const ENFORCE_ERROR_MESSAGE = "There was a problem updating your enforce custom packaging. Please try again.";

const breadcrumbs = [
    { text: "Packaging", link: "/retailer/preferences/packaging" },
    { text: "Custom Packaging", link: null }
];

const CustomPackagingDescription = () => (
    <div className="section-packaging mt-2">
        <h5 className="mb-3">Custom Packaging</h5>
        <p className="mb-2">You can add your own custom boxes, mailing bags, and extras below.</p>
        <p className="mb-2">Before enabling any of these options, please confirm with customer service that your packaging is at the warehouse.</p>
        <p className="mb-2">When your packages are added and enabled, these will be in the usable pool of options during order processing.  Any extra that is enabled will be applied to all orders.</p>
        <p>Custom packaging can increase the pack time cap and can incur additional costs. Please see <a href="https://support.selazar.com/support/solutions/articles/48001165716-how-to-use-custom-packaging" target='_blank' rel="noopener noreferrer">here</a> for further details.</p>
    </div>
);

const CustomisedPackaging = ({ isBox, count, enforced, history, toggleEnforced }) => (
    <div className="section-packaging">
        <h5 className="mb-3">{isBox ? "Boxes" : "Mailing Bags"}</h5>
        {count > 0
            ? <React.Fragment>
                <p className="my-1"><strong>{`${isBox ? "Enforce Boxes" : "Enforce Mailing Bags"}`}</strong></p>
                <div className="form-input-description mb-2">
                    <p>{`When you enforce custom ${isBox ? "boxes" : "mailing bags"} we will only use your own ${isBox ? "boxes" : "bags"} and never Selazars.`}</p>
                </div>
                <label className="switch mb-4">
                    <input id={`slider-${isBox ? "box" : "bag"}`} type="checkbox" onChange={() => toggleEnforced(isBox)} checked={enforced} />
                    <span className="slider round"><span className="toggle-text">{enforced ? "Enforced" : "Not Enforced"}</span></span>
                </label>
                <p className="my-1"><strong>{`${isBox ? "Your Boxes" : "Your Mailing Bags"}`}</strong></p>
                <p className="my-1">{`You have added ${count} custom ${isBox ? "box(es)" : "mailing bag(s)"}`}</p>
                <Button variant="link" className="p-0 pr-1" onClick={() => history.push(`/retailer/preferences/packaging/custompackaging/${isBox ? "view/boxes" : "view/mailingbags"}`)}>{`View ${isBox ? "Box(es)" : "Mailing Bag(s)"}`}</Button>
                <span>|</span>
                <Button variant="link" className="p-0 pl-1" onClick={() => history.push({ pathname: `/retailer/preferences/packaging/custompackaging/${isBox ? "add/box" : "add/mailingbag"}`, state: { returnToMain: true }})}>{`Add Custom ${isBox ? "Box Package" : "Mailing Bag"}`}</Button>
            </React.Fragment>
            : <React.Fragment>
                <p className="my-1">{`No customised ${isBox ? "boxes" : "mailing bags"} have been added`}</p>
                <Button variant="link" className="p-0" onClick={() => history.push(`/retailer/preferences/packaging/custompackaging/${isBox ? "add/box" : "add/mailingbag"}`)}>{`Add Custom ${isBox ? "Box Package" : "Mailing Bag"}`}</Button>
            </React.Fragment>
        }
    </div>
)

const Extras = ({ extras, toggleExtra }) => (
    <div className="section-packaging">
        <h5 className="mb-3">Extras</h5>
        {extras.map((extra, i) => <div key={`extras_${i}`} className="mb-3">
            <p className="mb-2"><strong>{extra.typeName}</strong></p>
            <label className="switch">
                <input id={extra.type} type="checkbox" onChange={() => toggleExtra(extra.type)} checked={extra.enabled} />
                <span className="slider round"><span className="toggle-text">{extra.enabled ? "Enabled" : "Disabled"}</span></span>
            </label>
        </div>)}
    </div>
)

class CustomPackaging extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            customPackaging: {},
            extras: [],
            showExtrasError: false,
            showEnforceError: false
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchPackagingOverview(),
            this.fetchPackagingExtras()
        ]);
        this.setState({ loading: false });
    }

    fetchPackagingOverview = async () => {
        return await GET(CommonEndpoints.PACKAGING.GET.CUSTOM_OVERVIEW)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ customPackaging: extractData(results) });
            });
    }

    fetchPackagingExtras = async () => {
        return await GET(CommonEndpoints.PACKAGING.GET.EXTRAS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ extras: extractData(results) });
            });
    }

    toggleEnforced = (isBox) => {
        this.setState({ loading: true });
        const { customPackaging } = this.state;
        isBox
            ? customPackaging.boxes.enforced = !customPackaging.boxes.enforced
            : customPackaging.mailingBags.enforced = !customPackaging.mailingBags.enforced;

        const viewModel = { isBox: isBox, enforced: isBox ? customPackaging.boxes.enforced : customPackaging.mailingBags.enforced };

        POST(CommonEndpoints.PACKAGING.POST.ENFORCE, viewModel)
            .then(response => {
                if (response && response.ok) return response.json();
            })
            .then(results => {
                const enforce = extractData(results);
                if (enforce !== null) {
                    this.fetchPackagingOverview();
                    this.setState({ customPackaging: customPackaging, showEnforceError: false, showExtrasError: false, loading: false });
                } else this.setState({ showEnforceError: true, showExtrasError: false, loading: false });
            })
            .catch(error => {
                console.log(error)
                this.setState({ showEnforceError: true, showExtrasError: false, loading: false })
            });
    }

    toggleExtra = (type) => {
        this.setState({ loading: true })
        const { extras } = this.state;
        extras.find(e => e.type === type).enabled = !extras.find(e => e.type === type).enabled;
        const updatedExtra = extras.find(e => e.type === type);

        POST(CommonEndpoints.PACKAGING.POST.EXTRA, updatedExtra)
            .then(response => {
                if (response && response.ok) return response.json();
            })
            .then(results => {
                const extra = extractData(results);
                extra !== null
                    ? this.setState({ extras: extras, showExtrasError: false, loading: false })
                    : this.setState({ showExtrasError: true, loading: false });
            })
            .catch(error => {
                console.log(error)
                this.setState({ showExtrasError: true, loading: false })
            });
    }

    render() {
        const { loading, customPackaging, extras, showEnforceError, showExtrasError } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Packaging" headerClass="mb-2" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col sm={12} md={6}>
                                {showExtrasError && <ErrorAlert errorMessage={EXTRAS_ERROR_MESSAGE} />}
                                {showEnforceError && <ErrorAlert errorMessage={ENFORCE_ERROR_MESSAGE} />}
                                <CustomPackagingDescription />
                                <CustomisedPackaging isBox={true} count={customPackaging.boxes.count} enforced={customPackaging.boxes.enforced} history={this.props.history} toggleEnforced={this.toggleEnforced} />
                                <CustomisedPackaging isBox={false} count={customPackaging.mailingBags.count} enforced={customPackaging.mailingBags.enforced} history={this.props.history} toggleEnforced={this.toggleEnforced} />
                                {extras && <Extras extras={extras} toggleExtra={this.toggleExtra} />}
                            </Col>
                        </Row>
                    </Container>}
            </React.Fragment>
        )
    }
}

export default CustomPackaging;