import React, { Component } from 'react';
import { Container, Button, Table, Card, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InventorySubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { GET, extractData } from '../../../../Consumer';
import { isNullOrEmptyGuid } from '../../../../Utilities';

import './StockConsignmentItems.scss';

const renderIdentifier = (item) => item.selazarUniqueID ? item.selazarUniqueID : item.ean;

const ItemRow = ({ item }) => (
    <tr className="table-row-item">
        <td>{item.name}</td>
        <td>{renderIdentifier(item)}</td>
        <td>{item.skuCode}</td>
        <td>{item.stockCount}</td>
    </tr>
);

class StockConsignmentItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: "",
            id: props.match.params.id || null,
            stockItem: {}
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchStockItem()]);
        this.setState({ loading: false });
    }

    fetchStockItem = async () => {
        const { id } = this.state;
        if (!isNullOrEmptyGuid(id)) {
            return GET(Endpoints.STOCK.GET.BY_ID_WITH_ITEMS + id)
                .catch(error => console.log(error))
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    const data = extractData(result) || {};
                    this.setState({ stockItem: data ? data : {}, error: data ? "" : "No consignment items found" });
                });
        }
    }

    render() {
        const { loading, stockItem } = this.state;
        return (
            <React.Fragment>
                <Header title="Stock Consignments" subNavList={InventorySubNavList} activeKey="Consignments" headerClass="mb-0" />
                {stockItem && <Link to={`/supplier/stockconsignments/consignment/${stockItem.id}`} className="link-button">
                    <Button variant="link" className="button-nav-return"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Consignment</Button>
                </Link>}
                {loading ? <LoadingBar /> :
                    <Container className="mt-3" fluid>
                        <Row className="mb-4">
                            <Col>
                                <h5>Consignment Items</h5>
                            </Col>
                        </Row>
                        <Card>
                            <div className="table-responsive">
                                <Table striped bordered size="sm" className="mb-0">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Identifier</th>
                                            <th>SKU</th>
                                            <th>Stock Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {stockItem.lines.length ? stockItem.lines.map((line, i) => <ItemRow key={`${line.name}-${i}`} item={line} />) : <p className="mx-1 my-1"><strong>No items available.</strong></p>}
                                    </tbody>
                                </Table>
                            </div>
                        </Card>
                    </Container>}
            </React.Fragment>
        );
    }
}

export default StockConsignmentItems;