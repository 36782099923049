import React, { Component } from 'react';
import LoadingBar from '../../common/LoadingBar';
import { Container, Row, Col, Button, Table, Form, Modal, FormLabel, FormGroup, FormControl, Card } from 'react-bootstrap';
import CommonEndpoints from '../../common/Endpoints';
import { GET, extractData, POST, PUT, DELETE } from '../../../Consumer';
import Header from '../../common/Header';
import { sortValuesByDirection } from '../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { PreferencesSubNavList } from '../../common/Constants';

const SUCCESS_MESSAGE = 'Your courier preferences have been updated successfully.';
const ERROR_MESSAGE = 'There was a problem updating your courier preferences. Please try again.';
const COURIER_ERROR_MESSAGE = 'There are no approved couriers for this specified world area.';
const MAX_BANDS = 4;
const SLIDER_STEP = 1;
const MAX_VALUE = 5000;

class Pricing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showError: false,
            showSuccess: false,
            showCourierError: false,

            worldAreas: [],
            serviceTimes: [],
            existingPriceBands: [],
            approvedCouriers: [],

            currentID: null,
            currentCountryRegion: null,
            currentFrom: 0,
            currentTo: MAX_VALUE,
            currentCourier: '',
            currentServiceTime: '',
            currentProofOfDelivery: '',
            currentTracked: '',
            currentSliderValue: '',

            sliderDisabled: false
        };

        this.sliderChange = this.sliderChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fetchExistingPriceBands = this.fetchExistingPriceBands.bind(this);
        this.deleteBand = this.deleteBand.bind(this);
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchServiceTimes(),
            this.fetchWorldAreas(),
            this.fetchExistingPriceBands(),
            this.fetchCouriers()
        ]);
    }

    async fetchServiceTimes() {
        return await GET(CommonEndpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ serviceTimes: extractData(results) });
            });
    }

    async fetchWorldAreas() {
        return await GET(CommonEndpoints.RETAILER.GET.WORLD_AREA_OPTIONS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ worldAreas: extractData(results) });
            });
    }

    async fetchCouriers() {
        return await GET(CommonEndpoints.RETAILER.GET.APPROVED_COURIERS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const approvedCouriers = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ approvedCouriers: approvedCouriers });
            });
    }

    async fetchExistingPriceBands() {
        return await GET(CommonEndpoints.RETAILER.GET.COURIER_PRICE_BAND_PREFERENCES)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const existingPriceBands = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ existingPriceBands: existingPriceBands });
            });
    }

    async deleteBand(e) {
        e.preventDefault();
        const bandID = e.target.getAttribute('band-id');

        this.setState({
            loading: true
        });

        await DELETE(`${CommonEndpoints.RETAILER.DELETE.COURIER_PRICE_BAND_PREFERENCES}/${bandID}`)
            .then(this.fetchExistingPriceBands)
            .then(() => this.setState({
                loading: false
            }));
    }

    errorAlert = () => (
        <Card className="card-danger mb-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>{ERROR_MESSAGE}</Card.Body>
        </Card>
    );

    courierErrorAlert = () => (
        <Card className="card-danger mb-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>{COURIER_ERROR_MESSAGE}</Card.Body>
        </Card>
    );

    successAlert = () => (
        <Card className="card-success mb-3">
            <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
            <Card.Body>{SUCCESS_MESSAGE}</Card.Body>
        </Card>
    );

    boolAsString = (value) => {
        if (value === true) return 'Yes';
        if (value === false) return 'No';

        return 'No preference';
    };

    sliderChange(e) {
        this.setState({
            currentSliderValue: e.target.value,
            currentFrom: this.state.currentMin,
            currentTo: e.target.value
        })
    }

    displayPriceBands = (preferences = []) => {
        const { serviceTimes, approvedCouriers } = this.state;
        return (
            <Table striped hover condensed responsive>
                <thead>
                    <tr>
                        <th>From (£)</th>
                        <th>To (£)</th>
                        <th>Courier</th>
                        <th>Service Time</th>
                        {/* <th>Proof Of Delivery</th>
                        <th>Tracked</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {preferences.map((p, i) => {
                        const courierName = approvedCouriers.find((c => c.companyID === p.courierCompanyID));                        
                        return (
                        <tr key={i}>
                            <td>{p.from}</td>
                            <td>{p.to}</td>
                            <td>{(courierName && courierName.companyName) || 'No preference'}</td>
                            <td>{serviceTimes[p.serviceTime] || 'No preference'}</td>
                            {/* <td>{this.boolAsString(p.proofOfDelivery)}</td>
                            <td>{this.boolAsString(p.tracked)}</td> */}
                            <td>
                                {(i === preferences.length - 1 && <Button variant="secondary" className="mx-1" operation={'Edit'} area={p.countryRegion} band-id={p.id} onClick={this.showModalForm}>Edit</Button>)}
                                {(i === preferences.length - 1 && preferences.length > 0 && <Button variant="danger" className="mx-1" band-id={p.id} onClick={(e) => { if (window.confirm('Are you sure you wish to delete this price band?')) this.deleteBand(e) }}>Delete</Button>)}
                            </td>
                        </tr>
                    )})}
                </tbody>
            </Table>
        );
    }

    showModalForm = ({ target }) => {
        const area = target.getAttribute('area');
        const operation = target.getAttribute('operation');
        const bandID = target.getAttribute('band-id');
 
        const { existingPriceBands, worldAreas } = this.state;
        let { currentMin, currentFrom, currentSliderValue, sliderDisabled, currentCourier, currentServiceTime, currentTo } = this.state;
        let priceBandsByRegion = existingPriceBands.filter((f) => f.countryRegion === area);
        
        if (bandID) {
            const bandByID = existingPriceBands.find((f) => f.id === bandID);
            if (bandByID) {
                currentMin = currentFrom = bandByID.from;
                currentSliderValue = currentTo = bandByID.to;
                currentCourier = bandByID.courierCompanyID;
                currentServiceTime = bandByID.serviceTime;
                sliderDisabled = priceBandsByRegion.length === MAX_BANDS;
            }
        }
        else {            
            if (priceBandsByRegion.length > 0) {
                priceBandsByRegion = sortValuesByDirection(priceBandsByRegion, 'to', 1);
                currentMin = currentFrom = currentSliderValue = Math.max.apply(Math, priceBandsByRegion.map((o) => o.to)) + 1;             
            }
            else {
                currentMin = currentFrom = currentSliderValue = 0;
            }
            sliderDisabled = false;

            if (priceBandsByRegion.length === (MAX_BANDS - 1)) {
                currentFrom = currentMin;
                currentMin = currentSliderValue = MAX_VALUE;
                sliderDisabled = true;
            }
        }
        

        this.setState({
            showModal: true,
            modalTitle: `${operation} for ${worldAreas[area]}`,
            currentID: bandID,
            currentCountryRegion: area,
            currentMin: currentMin,
            currentFrom: currentFrom,
            currentTo: currentTo,
            currentSliderValue: currentSliderValue,
            currentCourier: currentCourier,
            currentServiceTime: currentServiceTime,
            sliderDisabled: sliderDisabled,
            operation: operation
        })
    };

    handleClose = () => {
        this.setState({
            showModal: false,
            currentID: null,
            currentCountryRegion: null,
            currentFrom: 0,
            currentTo: MAX_VALUE,
            currentCourier: '',
            currentServiceTime: '',
            currentProofOfDelivery: '',
            currentTracked: '',
            currentSliderValue: ''
        });
    };

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const data = {
            id: this.state.currentID,
            countryRegion: this.state.currentCountryRegion,
            from: this.state.currentFrom,
            to: this.state.currentTo,
            courierCompanyID: this.state.currentCourier,
            serviceTime: this.state.currentServiceTime,
            proofOfDelivery: this.state.currentProofOfDelivery,
            tracked: this.state.currentTracked
        };

        const { operation } = this.state;

        if (operation === 'Add') {
            POST(CommonEndpoints.RETAILER.POST.COURIER_PRICE_BAND_PREFERENCES, data)
                .catch(error => console.log(error))
                .then(response => {
                    if (response && response.ok) return response.json();
                })
                .then(results => {
                    const existingPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
                    this.setState({ existingPreferences: existingPreferences, loading: false, showError: results === undefined, showSuccess: results });
                })
                .then(this.handleClose)
                .then(this.fetchExistingPriceBands);
        }
        else if (operation === 'Edit') {
            PUT(CommonEndpoints.RETAILER.PUT.COURIER_PRICE_BAND_PREFERENCES, data)
                .catch(error => console.log(error))
                .then(response => {
                    if (response && response.ok) return response.json();
                })
                .then(results => {
                    const existingPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
                    this.setState({ existingPreferences: existingPreferences, loading: false, showError: results === undefined, showSuccess: results });
                })
                .then(this.handleClose)
                .then(this.fetchExistingPriceBands);
        }
    }

    onChange(e) {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    }

    buildModal() {
        const { modalTitle, showModal, serviceTimes, approvedCouriers, currentCountryRegion } = this.state;

        const suitableCouriers = approvedCouriers.filter((a, i) => a.countryRegion === currentCountryRegion);

        return (
            <Modal show={showModal} onHide={this.handleClose}>
                <Form onSubmit={this.onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup>
                            <FormLabel>Supported range</FormLabel>
                            <FormGroup>
                                <Slider id={this.state.currentID} min={this.state.currentFrom} max={MAX_VALUE} step={SLIDER_STEP} onChange={this.sliderChange} disabled={this.state.sliderDisabled} value={this.state.currentSliderValue} />
                                <label>Selected Value: </label>
                                <span>{this.state.currentSliderValue}</span>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Courier preference</FormLabel>
                            <FormGroup>
                                <FormControl as="select" name="currentCourier" value={this.state.currentCourier} onChange={this.onChange}>
                                    <option value={null}>No preference</option>
                                    {suitableCouriers.map((s, i) => <option key={i} value={s.companyID}>{s.companyName}</option>)}
                                </FormControl>
                            </FormGroup>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Service time</FormLabel>
                            <FormGroup>
                                <FormControl as="select" name="currentServiceTime" value={this.state.currentServiceTime} onChange={this.onChange}>
                                    <option value={null}>No preference</option>
                                    {Object.keys(serviceTimes).map((s, i) => <option key={i} value={s}>{serviceTimes[s]}</option>)}
                                </FormControl>
                            </FormGroup>
                        </FormGroup>
                        {/* <FormGroup>
                            <FormLabel>Require proof of delivery?</FormLabel>
                            <FormGroup>                                
                                <FormControl componentClass="select" name="currentProofOfDelivery" value={this.state.currentProofOfDelivery} onChange={this.onChange}>
                                    <option value={null}>No preference</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </FormControl>
                            </FormGroup>
                        </FormGroup> */}
                        {/* <FormGroup>
                            <FormLabel>Required tracked delivery?</FormLabel>
                            <FormGroup>                                
                                <FormControl componentClass="select" name="currentTracked" value={this.state.currentTracked} onChange={this.onChange}>
                                    <option value={null}>No preference</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </FormControl>
                            </FormGroup>
                        </FormGroup> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" type="submit">Save changes</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    buildDisplay() {
        const { worldAreas, existingPriceBands } = this.state;
        return (
            <React.Fragment>
                {Object.keys(worldAreas).map((wa, i) => {
                    const priceBandsByRegion = existingPriceBands.filter((f) => f.countryRegion === wa);
                    const maxValue = Math.max.apply(Math, priceBandsByRegion.map((o) => o.to));

                    return (
                        <Col className="mb-4" md={12} key={i}>
                            <h3>{worldAreas[wa]}</h3>
                            {priceBandsByRegion.length === 0 && <p>There are no price bands set for this region. Please click below to add one if you'd like.</p>}
                            {priceBandsByRegion.length > 0 && this.displayPriceBands(priceBandsByRegion)}
                            {priceBandsByRegion.length < MAX_BANDS && maxValue < MAX_VALUE && <Button operation={'Add'} area={wa} onClick={this.showModalForm}>Add for {worldAreas[wa]}</Button>}
                        </Col>)
                }
                )}
            </React.Fragment>
        );
    }

    render() {
        const { loading, showError, showCourierError, showSuccess } = this.state;
        return loading ? (<LoadingBar />) : (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Pricing" />
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12}>
                            <h3>Price Band Preferences</h3>
                            {showError && this.errorAlert()}
                            {showCourierError && this.courierErrorAlert()}
                            {showSuccess && this.successAlert()}
                            <p>Be aware that modifying these settings will override how the Selazar platform determines the best courier.</p>
                            <p>This can result in orders failing to be processed, in this situation, you will be notified.</p>
                            <p>
                                <strong>
                                    Be aware that the more restrictive your preferences are, the less likely an eligible courier is to be found for your order.
                            </strong>
                            </p>
                            <hr />
                        </Col>
                    </Row>
                    <Row className="margin-bottom">
                        {this.buildDisplay()}
                        {this.buildModal()}
                    </Row>
                </Container>
            </React.Fragment>);
    }
}


function Slider({ min, max, step, id, onChange, disabled, value }) {
    return (<React.Fragment>
        <span className="pull-left float-left">{min}</span>
        <span className="pull-right float-right">{max}</span>
        <input id={id} className="custom-range" type="range" min={min} max={max} step={step} disabled={disabled} onChange={onChange} value={value} />
    </React.Fragment>);
}

export default Pricing;