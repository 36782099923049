import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, FormGroup, FormLabel, FormControl, Col, Button, Container, Row, Card, Image } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import FormValidator from '../../common/FormValidator';
import { POST } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import Terms from '../terms.pdf';
import Privacy from '../privacy.pdf';
import Footer from '../../common/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const captchaKey = process.env.REACT_APP_CAPTCHA_TOKEN;
const strongPasswordPattern = /^(?=.*[a-z])(?=.*[0-9])(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>]).{6,}/;
const numberPattern = /^(?=.*[0-9])/;
const specialCharPattern = /^(?=.*[#$^+=!*()@%&"'[\]{},_\\/;:`\-~<>])/;
const lengthPattern = /^.{6,}/;

class RetailerRegistration extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'companyName',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your company name'
            },
            {
                field: 'title',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your title'
            },
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your forename'
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your surname'
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your email'
            },
            {
                field: 'phone',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your phone number'
            },
            {
                field: 'password',
                method: 'matches',
                args: [strongPasswordPattern],
                validWhen: true,
                message: 'Password must contain at least one number, one special character and at least 6 characters long'
            },
            {
                field: 'confirmPassword',
                method: 'isEmpty',
                validWhen: false,
                message: 'Confirm Password is required'
            },
            {
                field: 'line1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your address line 1'
            },
            {
                field: 'town',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your town'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your county'
            },
            {
                field: 'country',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your country'
            },
            {
                field: 'postcode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please enter your postcode'
            },
            {
                field: 'tsandcs',
                method: 'isBoolean',
                validWhen: true,
                message: 'Please confirm that you have read and agree to the Terms and Conditions and Privacy Policy'
            }
        ]);

        this.state = {
            registration: {
                companyName: '',
                line1: '',
                line2: '',
                town: '',
                county: '',
                country: '',
                postcode: '',
                longitude: 0,
                latitude: 0,
                title: '',
                forename: '',
                surname: '',
                email: '',
                phone: '',
                password: '',
                confirmPassword: '',
                tsandcs: false
            },
            passwordInputType: 'password', 
            passwordInputIcon: faEye,
            passwordNumberCheck: false,
            passwordSpecialCharCheck: false,
            passwordLengthCheck: false,
            recaptcha: false,
            validation: this.validator.valid(),
            recaptchaError: false,
            error: false,
            message: '',
            disableSubmit: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleShowHidePassword = this.handleShowHidePassword.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.validate = this.validate.bind(this);

    }

    validate(registration) {
        let validation = this.validator.validate(registration);
        if (!registration.tsandcs) {
            validation.isValid = false;
            validation.tsandcs.isInvalid = true;
            validation.tsandcs.message = "Please confirm that you have read and agree to the Terms and Conditions and Privacy Policy";
        }

        return validation;
    }

    handleRecaptcha = () => {
        this.setState({ recaptcha: true });
    }

    handleSubmit(e) {
        e.preventDefault();

        const { registration, recaptcha } = this.state;

        const validation = this.validate(registration);
        this.setState({ validation: validation, recaptchaError: !recaptcha });
        this.submitted = true;

        if (recaptcha && validation.isValid) {
            this.setState({ disableSubmit: true });
            return POST(Endpoints.REGISTRATION.POST.RETAILER.REGISTER, registration)
                .catch(error => console.log(error))
                .then(response => response.json())
                .then(result => {
                    result.error ? 
                        this.setState({ error: true, message: result.message, disableSubmit: false }) :
                        this.props.history.push("/register/result");
                })
                .catch(e => this.setState({ loginError: e.message, email: '', password: '' }));
        }
    }

    handleInputChange(e) {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        if ( name === "password") {
            var numberPass = numberPattern.test(value);
            var specialCharPass = specialCharPattern.test(value);
            var lengthPass = lengthPattern.test(value);

            this.setState(prevState => ({
                passwordNumberCheck: numberPass,
                passwordSpecialCharCheck: specialCharPass,
                passwordLengthCheck: lengthPass,
                registration: {
                    ...prevState.registration,
                }
            }));
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.registration[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            registration: {
                ...prevState.registration,
                ...{ [name]: value }
            }
        }));
    }

    handleShowHidePassword = () => {
        const { passwordInputType } = this.state;

        passwordInputType === "password" ? 
            this.setState({ passwordInputType: 'text', passwordInputIcon : faEyeSlash }) : 
            this.setState({ passwordInputType: 'password', passwordInputIcon : faEye });
    }

    goToURL = (e) => {
        const { link } = e.target;
        window.open(link, '_blank');
    }

    renderForm(registration) {
        const validation = this.submitted ?
            this.validate(registration) :
            this.state.validation;

        const legalLabel = <span>I agree to Selazars <a href={Terms} target='_blank' rel="noopener noreferrer" alt='Selazar Terms and Conditions - opens in a new window'>Terms and Conditions</a> and <a href={Privacy} target='_blank' rel="noopener noreferrer" alt='Selazar Privacy Policy - opens in a new window'>Privacy Policy</a></span>
        const { loading, error, message, passwordInputType, passwordInputIcon, passwordNumberCheck, passwordSpecialCharCheck, passwordLengthCheck, disableSubmit } = this.state;

        return (
            <Card className="p-5">
                <Image src='Images/logo.png' className="mx-auto mb-4 image-logo" />
                <p className="text-center">Register with Selazar and start selling with a straight forward and cost effective ecommerce platform.</p>
                <Form onSubmit={this.handleSubmit}>
                    {error && <Card className="card-danger mb-3">
                        <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                        <Card.Body>{message}</Card.Body>
                    </Card>}
                    
                    <h5 className="mt-2">Company Details</h5>
                    <FormGroup>
                        <FormLabel htmlFor="companyNameField">Company Name</FormLabel>
                        <FormControl disabled={loading} id="companyNameField" type="text" name="companyName" maxLength="50" value={registration.companyName} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.companyName.message}</span>
                    </FormGroup>             

                    <h5 className="mt-2">Personal Details</h5>
                    <FormGroup>
                        <FormLabel htmlFor="titleField">Title</FormLabel>
                        <FormControl disabled={loading} id="titleField" type="text" maxLength="10" name="title" value={registration.title} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.title.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="forenameField">Forename</FormLabel>
                        <FormControl disabled={loading} id="forenameField" type="text" maxLength="50" name="forename" value={registration.forename} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.forename.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="surnameField">Surname</FormLabel>
                        <FormControl disabled={loading} id="surnameField" type="text" maxLength="50" name="surname" value={registration.surname} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.surname.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="emailField">Email</FormLabel>
                        <FormControl disabled={loading} id="emailField" type="email" maxLength="50" name="email" value={registration.email} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.email.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="phoneField">Phone</FormLabel>
                        <FormControl disabled={loading} id="phoneField" type="tel" maxLength="50" name="phone" value={registration.phone} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.phone.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="passwordField">Password</FormLabel>                                    
                        <FormControl disabled={loading} id="passwordField" type={passwordInputType} maxLength="50" name="password" value={registration.password} onChange={this.handleInputChange} />
                        <span className="form-password-icon" onClick={this.handleShowHidePassword}><FontAwesomeIcon icon={passwordInputIcon} /></span>
                        <span className="text-danger">{validation.password.message}</span>
                    </FormGroup>

                    <div className="form-password-checklist">
                        <ul>
                            <li className={passwordNumberCheck ? "pass" : "fail"}>One number</li>
                            <li className={passwordSpecialCharCheck ? "pass" : "fail"}>One special character</li>
                            <li className={passwordLengthCheck ? "pass" : "fail"}>Six characters long</li>
                        </ul>
                    </div>

                    <FormGroup>
                        <FormLabel htmlFor="confirmPasswordField">Confirm Password</FormLabel>
                        <FormControl disabled={loading} id="confirmPasswordField" type="password" maxlength="50" name="confirmPassword" value={registration.confirmPassword} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.confirmPassword.message}</span>
                    </FormGroup>
                    
                    <h5 className="mt-2">Billing Address</h5>
                    <FormGroup>
                        <FormLabel htmlFor="line1Field">Address Line 1</FormLabel>
                        <FormControl disabled={loading} id="line1Field" type="text" name="line1" maxLength="50" value={registration.line1} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.line1.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="line2Field">Address Line 2 <span className="form-label-optional">- optional</span></FormLabel>
                        <FormControl disabled={loading} id="line2Field" type="text" name="line2" maxLength="50" value={registration.line2} onChange={this.handleInputChange} />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="townField">Town</FormLabel>
                        <FormControl disabled={loading} id="townField" type="text" name="town" maxLength="50" value={registration.town} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.town.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="countyField">County</FormLabel>
                        <FormControl disabled={loading} id="countyField" type="text" name="county" maxLength="50" value={registration.county} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.county.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="countryField">Country</FormLabel>
                        <FormControl disabled={loading} id="countryField" type="text" name="country" maxLength="50" value={registration.country} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.country.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="postcodeField">Postcode</FormLabel>
                        <FormControl disabled={loading} id="postcodeField" type="text" name="postcode" maxLength="50" value={registration.postcode} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.postcode.message}</span>
                    </FormGroup>

                    <h5 className="mt-2">Legals and Privacy</h5>

                    <FormGroup className="custom-control custom-checkbox mb-1">
                        <input disabled={loading} className="custom-control-input" id="tsandcsField" type="checkbox" name="tsandcs" value={registration.tsandcs} onChange={this.handleInputChange} />
                        <FormLabel className="custom-control-label" htmlFor="tsandcsField">{legalLabel}</FormLabel>
                    </FormGroup>
                    <span className="text-danger">{validation.tsandcs.message}</span>

                    <Container className="d-flex justify-content-center my-4">
                        <ReCAPTCHA sitekey={captchaKey} onChange={this.handleRecaptcha} />
                    </Container>
                    {this.state.recaptchaError && <p className="text-danger">Please complete captcha</p>}

                    <Button size="lg" disabled={loading || disableSubmit} type="submit" value="Register" block>Register</Button>

                    <Container className="d-flex justify-content-center mt-4">
                        <p>Already registered? <Link to='/'>Sign in?</Link></p> 
                    </Container>
                </Form>
            </Card>
        );
    }

    render() {
        const { registration } = this.state;
        const contents = this.renderForm(registration);

        return (
            <React.Fragment>
                <Container className="h-100">
                    <Row className="justify-content-center row-form-adjustedheight">
                        <Col md={6} className="align-self-center py-5">
                            {contents}
                        </Col>
                    </Row>
                    <Footer />
                </Container>
            </React.Fragment>
        );
    }
}
export default RetailerRegistration;