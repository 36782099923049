import React from 'react';
import { Container, Row, Col, Button, Form, FormControl, FormLabel, FormGroup } from 'react-bootstrap';
import Confirm from '../../common/Confirm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const CANCEL_POPUP_TITLE = "Cancel Adding Bank Account";
const CANCEL_POPUP_MESSAGE = "Are you sure you want to cancel adding your bank details? We will continue to charge your credit card until new bank details have been added and approved.";
const CANCEL_POPUP_ACTION_BUTTON_TEXT = "Cancel adding bank details";
const CANCEL_POPUP_CANCEL_BUTTON_TEXT = "Continue adding bank details";

const bankAccountTypes = [
    { id: "bacs_debit", name: "BACS" }
];

const AddDirectDebit = ({ showCancelAddModal, handleCancelAddShowHide, handleCancelAddAction, form, onChange, directDebitDetailsValidation, onNext, onBreadcrumbClick, paymentTerms }) =>
    <Container fluid>
        <Row>
            <Col md={12}>
                <Link className="link" to={'/retailer/finance/newdirectdebit'} onClick={onBreadcrumbClick}>Direct Debit</Link>&nbsp;
                <FontAwesomeIcon icon={faAngleRight}/> Add Bank account
            </Col>
        </Row>
        <Row>
            <Col sm={12} md={6}>
                <h5 className="mt-2 mb-3">Add Bank Account</h5>
                <div className="form-input-description mb-3">
                    <p>As previously agreed the direct debit will be collected automatically {paymentTerms} days after you receive your collection advice.</p>
                </div>
                <h6>Account Holder Details</h6>
                <FormGroup>
                    <FormLabel htmlFor="customerName">Name</FormLabel>
                    <FormControl type="text" size="50" maxLength="50" id="customerName" onChange={onChange} value={form.customerName} />
                    <span className="text-danger">{directDebitDetailsValidation.customerName.message}</span>
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <FormControl type="email" id="email" onChange={onChange} size="40" name="email" value={form.email} />
                    <span className="text-danger">{directDebitDetailsValidation.email.message}</span>
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="phone">Phone</FormLabel>
                    <FormControl type="text" size="20" maxLength="20" id="phone" onChange={onChange} name="phone" value={form.phone} />
                    <span className="text-danger">{directDebitDetailsValidation.phone.message}</span>
                </FormGroup>
                <h6 className="mt-4">Account Holder Address</h6>
                <FormGroup>
                    <FormLabel htmlFor="addressLine1">Address Line 1</FormLabel>
                    <FormControl type="text" size="50" maxLength="50" id="addressLine1" onChange={onChange} name="addressLine1" value={form.addressLine1} />
                    <span className="text-danger">{directDebitDetailsValidation.addressLine1.message}</span>
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="addressLine2">Address Line 2<span className="form-label-optional">- optional</span></FormLabel>
                    <FormControl type="text" size="50" maxLength="50" id="addressLine2" onChange={onChange} name="addressLine2" value={form.addressLine2} />
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="addressTown">Town</FormLabel>
                    <FormControl type="text" size="50" maxLength="50" id="addressTown" onChange={onChange} name="town" value={form.addressTown} />
                    <span className="text-danger">{directDebitDetailsValidation.addressTown.message}</span>
                </FormGroup>
                <FormGroup>
                    <FormLabel htmlFor="addressPostcode">Postcode</FormLabel>
                    <FormControl type="text" size="20" maxLength="20" id="addressPostcode" onChange={onChange} name="postcode" value={form.addressPostcode} />
                    <span className="text-danger">{directDebitDetailsValidation.addressPostcode.message}</span>
                </FormGroup>
                <h6 className="mt-4">Account Details</h6>
                <FormGroup>
                    <FormLabel htmlFor="bankAccountType">Bank Account Type</FormLabel>
                    <div className="form-input-description mb-3">
                        <p>Currently we are only able to accept UK bank accounts.</p>
                    </div>
                    <FormControl id="bankAccountType" name="bankAccountType" as="select" onChange={onChange} name="bankAccountType" value={form.bankAccountType}>
                        <option key="" value="">Select account type...</option>;
                        {bankAccountTypes.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)}
                    </FormControl>
                    <span className="text-danger">{directDebitDetailsValidation.bankAccountType.message}</span>
                </FormGroup>
                <Button variant="primary" className="float-right mt-4" onClick={onNext}>Next Step</Button>
                <Button variant="link" className="float-right mt-4 mr-2" onClick={handleCancelAddShowHide}>Cancel</Button>
            </Col>
        </Row>
        {showCancelAddModal && <Confirm title={CANCEL_POPUP_TITLE} text={CANCEL_POPUP_MESSAGE} buttonText={CANCEL_POPUP_ACTION_BUTTON_TEXT} cancelText={CANCEL_POPUP_CANCEL_BUTTON_TEXT}
            buttonVariant={"danger"} handleConfirmClose={handleCancelAddShowHide} handleConfirmAction={handleCancelAddAction} show={showCancelAddModal} />}
    </Container>

export default AddDirectDebit;
