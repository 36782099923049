import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { PreferencesSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import FormValidator from '../../../common/FormValidator';
import LoadingBar from '../../../common/LoadingBar';
import { ErrorAlert } from '../../../common/Alert';
import CommonEndpoints from '../../../common/Endpoints';
import { extractData, POST } from '../../../../Consumer';
import { CustomPackageForm, CustomPackageFormActions, CancelModal, SuccessfulAddPackage } from './Snippets';

const ERROR_MESSAGE = "There was a problem adding your custom box. Ensure another custom box does not have the same name. Please try again."

const breadcrumbs = [
    { text: "Packaging", link: "/retailer/preferences/packaging" },
    { text: "Custom Packaging", link: "/retailer/preferences/packaging/custompackaging" },
    { text: "Add Custom Box", link: null }
];

class AddCustomBox extends Component {
    constructor(props) {
        super(props);

        this.boxValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a package friendly name'
            },
            {
                field: 'height',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 height for the box'
            },
            {
                field: 'width',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 width for the box'
            },
            {
                field: 'depth',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 10.00 }],
                message: 'Enter a minimum of 10 depth for the box'
            },
            {
                field: 'maxWeight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Enter the weight limit of the box'
            },
        ]);

        this.state = {
            loading: true,
            showCancelModal: false,
            showSuccess: false,
            showError: false,
            boxValidation: this.boxValidator.valid(),
            returnToMain: (this.props.location && this.props.location.state) ? this.props.location.state.returnToMain : true,
            box: {
                name: "",
                height: 0.00,
                width: 0.00,
                depth: 0.00,
                maxWeight: 0.00
            }
        };
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            box: {
                ...prevState.box,
                ...{ [name]: value }
            }
        }));
    }

    handleShowHideCancelModal = () => this.setState(prevState => ({ showCancelModal: !prevState.showCancelModal }));

    handleAddPackage = async () => {
        this.setState({ loading: true });

        const { box } = this.state;
        const validation = this.boxValidator.validate(box);
        this.setState({ boxValidation: validation });

        if (validation.isValid) {
            return await POST(CommonEndpoints.PACKAGING.POST.BOX, box)
                .then(response => {
                    if (response && response.ok) return response.json();
                })
                .then(results => {
                    const addedBox = extractData(results);
                    addedBox !== null
                        ? this.setState({ showSuccess: true, loading: false })
                        : this.setState({ showError: true, loading: false });
                })
                .catch(error => {
                    console.log(error)
                    this.setState({ showError: true, loading: false })
                });
        }
        this.setState({ loading: false });
    }

    render() {
        const { loading, box, boxValidation, showError, showSuccess, showCancelModal, returnToMain } = this.state;
        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Packaging" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col sm={12} md={6}>
                                <h5 className="mb-3">Add Custom Box</h5>
                                {showSuccess
                                    ? <React.Fragment>
                                        <SuccessfulAddPackage name={box.name} />
                                        <Button variant="primary" className="float-right mt-3" onClick={() => this.props.history.push("/retailer/preferences/packaging/custompackaging/view/boxes")}>Continue to Custom Boxes</Button>
                                    </React.Fragment> 
                                    : <React.Fragment>
                                        {showError && <ErrorAlert errorMessage={ERROR_MESSAGE} />}
                                        <CustomPackageForm isBox={true} packageDetails={box} packageValidation={boxValidation} handleInputChange={this.handleInputChange} />
                                        <CustomPackageFormActions buttonText="Add Custom Box" handleCancel={this.handleShowHideCancelModal} handleAction={this.handleAddPackage} />
                                    </React.Fragment>}
                            </Col>
                        </Row>
                        <CancelModal isBox={true} isAdd={true} handleClose={this.handleShowHideCancelModal} showModal={showCancelModal} handleConfirm={() => this.props.history.push(`/retailer/preferences/packaging/${returnToMain ? "custompackaging" : "custompackaging/view/boxes"}`)} />
                    </Container>}
            </React.Fragment>
        )
    }
}

export default AddCustomBox;