import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { GET } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { isNullOrEmptyGuid, checkPermission } from '../../../Utilities';
import OrderBundleDetails from './OrderBundleDetails';

import './OrderDetails.scss';

const AWAITING_COLLECTION_STATUS = "Awaiting Collection";
const COLLECTION_BOOKED_STATUS = "Collection Booked";
const SHIPPED_STATUS = "Shipped";
const PROCESSED_LINK = "/retailer/orders/processedorders";
const INPROCESS_LINK = "/retailer/orders/inprocessorders";

const OrderBreakdown = ({ customerReference, internalReference, received, customerOrganisationPersonName }) => (
    <div className="section-item">
        <h6>Order Breakdown</h6>
        <p className="title">Order Number</p>
        <p>{customerReference}</p>
        <p className="title">Internal Reference</p>
        <p>{internalReference}</p>
        <p className="title">Order Received</p>
        <p>{received}</p>
        <p className="title">Customer Name</p>
        <p>{customerOrganisationPersonName}</p>
    </div>
);

const renderStatus = (status) => {
    switch (status) {
        case "Awaiting Collection":
        case "Collection Booked":
        case "Shipped":
            return <span className="text-status-green">{status}</span>;
        case "Received":
            return <span className="text-status-yellow">{status}</span>;
        case "Awaiting Fulfilment":
        case "Fulfilment In Progress":
        case "Picking In Progress":
        case "Packing In Progress":
            return <span className="text-status-blue">{status}</span>;
        default:
            return <span className="text-status-grey">{status}</span>;
    }
}

const ParcelDetails = ({ status, weight }) => (
    <div className="section-item">
        <h6>Parcel Details</h6>
        <p className="title">Weight</p>
        <p>{weight}</p>
        <p className="title">Status</p>
        <p>{renderStatus(status)}</p>
    </div>
);

const CostDetails = ({ orderCosts, totalCost, subtotal, adminRate, adminCharge, vatCharge }) => (
    <div className="section-item">
        <h6>Cost Details</h6>
        <p className="title">Grand Total</p>
        <p>£{totalCost}</p>
        <p className="title">Breakdown</p>
        <p className="mb-0">Cross Zonal Charge: £{orderCosts.crossZonalCharge.toFixed(2)}</p>
        <p className="mb-0">Fuel Charge: £{orderCosts.fuelCharge.toFixed(2)}</p>
        <p className="mb-0">Item Picking Charge: £{orderCosts.pickingCharge.toFixed(2)}</p>
        <p className="mb-0">Packing Charge: £{orderCosts.packingCharge.toFixed(2)}</p>
        <p className="mb-0">Packaging Charge: £{orderCosts.packagingCharge.toFixed(2)}</p>
        <p className="mb-0">Service Charge: £{(orderCosts.serviceCharge + orderCosts.selazarMarkup).toFixed(2)}</p>
        <p className="mb-0">Subtotal: £{subtotal}</p>
        <p className="mb-0">Selazar charge ({adminRate}%): £{adminCharge}</p>
        <p className="mb-0">VAT: £{vatCharge}</p>
    </div>
);

const DeliveryDetails = ({ customerAddressLine1, customerAddressLine2, customerAddressTown, customerAddressCounty, customerAddressPostcode, parcelReferences, trackingUrls, providerName }) => (
    <div className="section-item">
        <h6>Delivery Details</h6>
        <p className="title">Delivery Address</p>
        <p className="mb-0">{customerAddressLine1}</p>
        <p className="mb-0">{customerAddressLine2}</p>
        <p className="mb-0">{customerAddressTown}</p>
        <p className="mb-0">{customerAddressCounty}</p>
        <p>{customerAddressPostcode}</p>
        <p className="title">Parcel References/Tracking Number</p>
        {trackingUrls.length > 0 && parcelReferences.length > 0 ?
            trackingUrls.map((pr, i) => {
                return (pr.url !== null && pr.url !== "" && !pr.url.includes("null")) ?
                    <div className="mb-2">
                        <Button
                            key={i + "_tracking"}
                            href={pr.url}
                            target='_blank'
                            rel="noopener noreferrer"
                            variant="link">
                            {parcelReferences[i]}
                        </Button>
                    </div>
                    : <p>{parcelReferences[i]}</p>;
            }) : null}
        <p className="title">Courier Provider</p>
        <p>{providerName}</p>
    </div>
);

const ProductDetailsLegacyOrders = ({ products }) => (
    <div className="section-item">
        <h6>Product Details</h6>
        <p className="title">Total Products Ordered</p>
        <p className="mb-1">{products.totalProducts}</p>
        {products.items.length > 0 ?
            products.items.map((item, i) =>
                <dl key={i}>
                    <dt className="mb-0">{item.name}</dt>
                    <dd className="mb-1">Quantity: {item.quantity}</dd>
                    <dd className="mb-0">EAN: {item.ean ? item.ean : "N/A"}</dd>
                    <dd className="mb-0">SKU: {item.sku ? item.sku : "N/A"}</dd>
                    <dd className="mb-0">SUID: {item.selazarUniqueID ? item.selazarUniqueID : "N/A"}</dd>
                </dl>) 
                : null}
        {products.productBundles.length > 0 ?
            products.productBundles.map((productBundle, i) => 
                <dl key={i}>
                    <dt className="mb-0">{productBundle.name}</dt>
                    <dd className="mb-1">Quantity Ordered: {productBundle.quantityOrdered}</dd>
                    <dd className="mb-1">Total Items Included: {productBundle.totalOrderedProducts}</dd>
                    <dd className="mb-0">SKU: {productBundle.skuCode ? productBundle.skuCode : "N/A"}</dd>
                </dl>) 
                : null}

    </div>
);

const ProductDetails = ({ orderContents, showProductBundleDetails, totalProducts }) => (
    <div className="section-item">
        <h6>Product Details</h6>
        <p className="title">Total Products Ordered</p>
        <p className="mb-1">{totalProducts(orderContents)}</p>
        {orderContents.orderedCompanyItems.length > 0 ?
            orderContents.orderedCompanyItems.map((orderedCompanyItem, i) => 
                <dl key={i}>
                    <dt className="mb-0">{orderedCompanyItem.companyItem.item.name}</dt>
                    <dd className="mb-1">Quantity: {orderedCompanyItem.quantity}</dd>
                    <dd className="mb-0">EAN: {orderedCompanyItem.companyItem.item.ean ? orderedCompanyItem.companyItem.item.ean : "N/A"}</dd>
                    <dd className="mb-0">SKU: {orderedCompanyItem.skuCode ? orderedCompanyItem.skuCode : "N/A"}</dd>
                    <dd className="mb-0">SUID: {orderedCompanyItem.companyItem.item.selazarUniqueID ? orderedCompanyItem.companyItem.item.selazarUniqueID : "N/A"}</dd>
                </dl>) 
                : null}
        {orderContents.orderedProductBundles.length > 0 ?
            orderContents.orderedProductBundles.map((orderedProductBundle, i) => 
                <dl key={i}>
                    <dt className="mb-0">{orderedProductBundle.productBundle.name}</dt>
                    <dd className="mb-1">Quantity Ordered: {orderedProductBundle.quantity}</dd>
                    <dd className="mb-1">Total Items Included: {orderedProductBundle.productBundle.totalProducts * orderedProductBundle.quantity}</dd>
                    <dd className="mb-0">SKU: {orderedProductBundle.productBundle.skuCode ? orderedProductBundle.productBundle.skuCode : "N/A"}</dd>
                    <Button variant="link" className="p-0" onClick={() => showProductBundleDetails(orderedProductBundle.productBundle)}>View Bundle Details</Button>
                </dl>) 
                : null}

    </div>
);

class OrderDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order: props.location.state.order || {},
            orderCosts: {
                crossZonalCharge: 0,
                fuelCharge: 0,
                pickingCharge: 0,
                packagingCharge: 0,
                packingCharge: 0
            },
            loading: true,
            warning: null,
            subtotal: 0,
            totalCost: 0,
            vatCharge: 0,
            adminCharge: 0,
            adminRate: 0,
            trackingUrls: [],
            products: null,
            showProductBundle: false,
            selectedProductBundle: null
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchOrderCosts(),
            this.fetchTrackingRootUrls(),
            this.fetchItems()
        ]);
        this.setState({ loading: false });
    }

    fetchOrderCosts = async () => {
        const { order } = this.state;

        if (!isNullOrEmptyGuid(order.id)) {
            return GET(Endpoints.RETAILER.GET.ORDER_COST_BREAKDOWN + order.id)
                .catch(error => console.log(error))
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    this.setState(prevState => ({
                        orderCosts: {
                            ...prevState.orderCosts,
                            ...result.data
                        },
                        subtotal: result.data.subtotal.toFixed(2),
                        totalCost: result.data.total.toFixed(2),
                        vatCharge: result.data.vatCharge.toFixed(2),
                        adminCharge: result.data.adminCharge.toFixed(2),
                        adminRate: result.data.adminRate
                    }));
                });
        }
    }

    fetchTrackingRootUrls = async () => {
        const { order } = this.state;
        if (!isNullOrEmptyGuid(order.id)) {
            for (let i = 0; i < order.providersAndParcelReferences.length; i++) {
                const papr = order.providersAndParcelReferences[i];
                await this.fetchTrackingRootUrl(papr.providerId, papr.parcelReference);
            }
        }
    }

    fetchTrackingRootUrl = async (providerId, parcelReference) => {
        return GET(Endpoints.RETAILER.GET.ORDER_TRACKING_ROOT_URL + providerId)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                if (result !== undefined) {
                    const joinedTrackingUrls = this.state.trackingUrls.concat({ "url": result.data + parcelReference });
                    this.setState({ trackingUrls: joinedTrackingUrls });
                }
            });
    }

    fetchItems = async () => {
        const { order } = this.state;
        if(!order.orderContents){
            return GET(Endpoints.ORDERS.GET.ITEMS + order.id + '/items')
                .catch(error => console.log(error))
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    if (result !== undefined) {
                        this.setState({ products: result.data });
                    }
                });
        }
    }

    getTotalProducts = (orderContents) =>{
        let companyItems = 0;
        let productBundles = 0;

        if(orderContents){
            if(orderContents.orderedCompanyItems.length > 0) companyItems = orderContents.orderedCompanyItems.reduce((prev, cur) => prev + cur.quantity, 0)
            if(orderContents.orderedProductBundles.length > 0){
                orderContents.orderedProductBundles.map((orderedProductBundle) => {
                    productBundles += orderedProductBundle.productBundle.totalProducts * orderedProductBundle.quantity;
                });
            }
        }

        return companyItems + productBundles;
    }

    hideProductBundleDetails = async () => {
        this.setState({ showProductBundle: false });
    }
    
    showProductBundleDetails = async (productBundle) => {
        this.setState({ showProductBundle: true, selectedProductBundle: productBundle });
    }
    
    render() {
        const { order, orderCosts, loading, warning, totalCost, subtotal, adminRate, adminCharge, vatCharge, trackingUrls, products, showProductBundle, selectedProductBundle } = this.state;
        const weight = order.totalWeight.length > 0 ? `${order.totalWeight.reduce((a, b) => a + b)}kg` : "N/A"
        const returnLink = order.status.name === AWAITING_COLLECTION_STATUS || order.status.name === COLLECTION_BOOKED_STATUS || order.status.name === SHIPPED_STATUS ? PROCESSED_LINK : INPROCESS_LINK;
        
        if(showProductBundle){
            return (
                <Container fluid>
                    <Row>
                        <OrderBundleDetails productBundle={selectedProductBundle} hideDetails={this.hideProductBundleDetails}/>
                    </Row>
                </Container>
            );
        }

        return (
            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <Link to={returnLink} className="link-button">
                            <Button variant="link" className="button-nav-return p-0  mb-4"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Orders</Button>
                        </Link>
                        <h2 className="mb-4">Order Details</h2>
                        {loading ? <LoadingBar /> :
                            <React.Fragment>
                                {warning ? <ErrorAlert errorMessage={warning} /> :
                                    <React.Fragment>
                                        <OrderBreakdown customerReference={order.customerReference} internalReference={order.internalReference} received={order.received} customerOrganisationPersonName={order.customerOrganisationPersonName} />
                                        {order.orderContents ? 
                                            <ProductDetails orderContents={order.orderContents} showProductBundleDetails={this.showProductBundleDetails} totalProducts={this.getTotalProducts} /> :
                                            <ProductDetailsLegacyOrders products={products}/>
                                        }
                                        <ParcelDetails weight={weight} status={order.status.name} />
                                        {checkPermission('ViewInvoice' , <CostDetails orderCosts={orderCosts} totalCost={totalCost} subtotal={subtotal} adminRate={adminRate} adminCharge={adminCharge} vatCharge={vatCharge} />)}
                                        <DeliveryDetails customerAddressLine1={order.customerAddressLine1} customerAddressLine2={order.customerAddressLine2} customerAddressTown={order.customerAddressTown} customerAddressPostcode={order.customerAddressPostcode} parcelReferences={order.parcelReferences} trackingUrls={trackingUrls} providerName={order.providerNames.length > 0 ? order.providerNames.join(', ') : "N/A"} />
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default OrderDetails;