import React, { Component } from 'react';
import { GET, extractData } from '../../Consumer';
import { Container } from 'react-bootstrap';
import LoadingBar from '../common/LoadingBar';
import Endpoints from '../common/Endpoints';
import FulfilmentLabel from './FulfilmentLabel';

class FulfilmentLabels extends Component {
    displayName = FulfilmentLabels.name;

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            loading: true
        };

        this.fetchLabels = this.fetchLabels.bind(this);
    }

    componentDidMount() {
        this.fetchLabels();
        setInterval(this.fetchLabels, 30000); // runs every 30 seconds.
    }

    fetchLabels() {
        const url = Endpoints.FULFILMENT.GET.LABELS;
        return GET(url)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({
                        items: extractData(result),
                        loading: false
                    });
                } else {
                    this.setState({ loading: false });
                }
            });
    }

    renderLabels(items) {
        return items.length ? (
            <React.Fragment>
                {items.map(item => <FulfilmentLabel key={item.id} label={item} />)}
            </React.Fragment>
        ) : <h5>No labels available.</h5>;
    }


    render() {
        const contents = this.state.loading
            ? <LoadingBar />
            : this.renderLabels(this.state.items);

        return (
            <Container fluid>
                <h2>Fulfilment Labels</h2>
                {contents}
            </Container>
        );
    }
}

export default FulfilmentLabels;