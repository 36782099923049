import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PreferencesSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import Breadcrumbs from '../../../common/Breadcrumbs';
import LoadingBar from '../../../common/LoadingBar';
import { ViewCustomPackageCard, AddPackageCard, DeleteModal } from './Snippets';
import { ErrorAlert, SuccessAlert } from '../../../common/Alert';
import CommonEndpoints from '../../../common/Endpoints';
import { GET, extractData, PUT } from '../../../../Consumer';

const DELETE_ERROR_MESSAGE = "Unable to delete custom mailing bag. Please try again.";
const DELETE_SUCCESS_MESSAGE = "Successfully deleted custom mailing bag.";
const ENABLE_ERROR_MESSAGE = "Unable to update enable status of custom mailing bag. Please try again.";

const breadcrumbs = [
    { text: "Packaging", link: "/retailer/preferences/packaging" },
    { text: "Custom Packaging", link: "/retailer/preferences/packaging/custompackaging" },
    { text: "View Custom Mailing Bag", link: null }
];

class ViewCustomMailingBag extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showDeleteModal: false,
            showDeleteSuccess: false,
            showDeleteError: false,
            showEnableError: false,
            deletePackageName: "",
            deletePackageId: null,
            mailingBags: []
        };
    }

    async componentDidMount() {
        await this.fetchMailingBags();
        this.setState({ loading: false });
    }
  
    fetchMailingBags = async () => {
        return await GET(CommonEndpoints.PACKAGING.GET.MAILING_BAGS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ mailingBags: extractData(results) });
            });
    }

    handleAddPackageClick = () => {
        this.props.history.push({ pathname: "/retailer/preferences/packaging/custompackaging/add/mailingbag", state: { returnToMain: false }});
    }

    handleShowHideDeleteModal = (deletePackageName, deletePackageId) => this.setState(prevState => ({ showDeleteModal: !prevState.showDeleteModal, deletePackageName: deletePackageName, deletePackageId: deletePackageId }));
    
    handleDeletePackage = async () => {
        this.setState({ loading: true });
        const { deletePackageId, mailingBags } = this.state;
        this.handleShowHideDeleteModal("", null);

        return await PUT(CommonEndpoints.PACKAGING.PUT.ARCHIVE + deletePackageId)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result)
                if (data) {
                    const filteredMailingBags = mailingBags.filter(m => m.id !== deletePackageId);
                    this.setState({ mailingBags: filteredMailingBags, showDeleteSuccess: true, showDeleteError: false, showEnableError: false, loading: false });
                } else this.setState({ showDeleteSuccess: false, showDeleteError: true, showEnableError: false, loading: false });
            });
    }

    handleEnablePackage = async (id) => {
        this.setState({ loading: true });
        const { mailingBags } = this.state;

        return await PUT(CommonEndpoints.PACKAGING.PUT.ENABLE + id)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result)
                if (data) {
                    mailingBags.find(m => m.id = id).enabled = true;
                    this.setState({ mailingBags: mailingBags, showEnableError: false, showDeleteSuccess: false, showDeleteError: false, loading: false });
                } else this.setState({ showEnableError: true, showDeleteSuccess: false, showDeleteError: false, loading: false });
            });
    }

    handleDisablePackage = async (id) => {
        this.setState({ loading: true });
        const { mailingBags } = this.state;

        return await PUT(CommonEndpoints.PACKAGING.PUT.DISABLE + id)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result)
                if (data) {
                    mailingBags.find(m => m.id = id).enabled = false;
                    this.setState({ mailingBags: mailingBags, showEnableError: false, showDeleteSuccess: false, showDeleteError: false, loading: false });
                } else this.setState({ showEnableError: true, showDeleteSuccess: false, showDeleteError: false, loading: false });
            });
    }

    render() {
        const { loading, mailingBags, deletePackageName, deletePackageId, showDeleteModal, showDeleteError, showEnableError, showDeleteSuccess } = this.state;

        return (
            <React.Fragment>
                <Header title="Preferences" subNavList={PreferencesSubNavList} activeKey="Packaging" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        <Breadcrumbs breadcrumbs={breadcrumbs} />
                        <Row>
                            <Col>
                                <h5 className="mb-3">Custom Mailing Bags</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                {showDeleteError && <ErrorAlert errorMessage={DELETE_ERROR_MESSAGE} />}
                                {showEnableError && <ErrorAlert errorMessage={ENABLE_ERROR_MESSAGE} />}
                                {showDeleteSuccess && <SuccessAlert successMessage={DELETE_SUCCESS_MESSAGE} />}
                            </Col>
                        </Row>
                        <Row>
                            {mailingBags.map(bag => <ViewCustomPackageCard key={bag.id} isBox={false} customPackage={bag} handleDeleteClick={() => this.handleShowHideDeleteModal(bag.friendlyName, bag.id)} handleEditClick={() => this.props.history.push(`/retailer/preferences/packaging/custompackaging/edit/mailingbag/${bag.id}`)} handleEnableToggle={bag.enabled ? () => this.handleDisablePackage(bag.id) : () => this.handleEnablePackage(bag.id)} />)}
                            <AddPackageCard isBox={false} handleAddPackageClick={this.handleAddPackageClick} />
                        </Row>
                        <DeleteModal isBox={false} packageName={deletePackageName} deletePackageId={deletePackageId} handleClose={() => this.handleShowHideDeleteModal("", null)} handleDeletePackage={this.handleDeletePackage} showModal={showDeleteModal}/>
                    </Container>}
            </React.Fragment>
        )
    }
}

export default ViewCustomMailingBag;