import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { toLocalTimeString } from '../../../Utilities';

class RemittanceAdviceRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remittanceAdvice: this.props.remittanceAdvice,
            show: false,
            loading: false
        };
    }

    goToURL = (url) => {
        window.open(url, '_blank');
    }
    
    getPDF = () => {
        const { remittanceAdvice } = this.state;
        this.goToURL(remittanceAdvice.link);
    }

    renderLabel = (value) =>{
        if (value) return <span>{value}</span>;
        else return <span>Not Charged</span>;
    }

    render() {
        const { remittanceAdvice } = this.state;

        return (
            <tr>
                <td>{toLocalTimeString(remittanceAdvice.createdDate)}</td>
                <td>{this.renderLabel(remittanceAdvice.remittanceAdviceNumber)}</td>
                <td>{parseFloat(remittanceAdvice.collectionAdvice.totalCharge).toFixed(2)}</td>
                <td className="text-center py-2"><Button variant="secondary" size="sm" onClick={this.getPDF}>View</Button></td>
            </tr>
        );
    }
}

export default RemittanceAdviceRow;