import React, { Component } from 'react';
import { Col, Row, Container, Table, Card } from 'react-bootstrap';
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import { InvoicingSubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import { GET, extractData } from '../../../Consumer';
import CollectionAdviceRow from './CollectionAdviceRow';
import CollectionAdviceFilter from './CollectionAdviceFilter';
import Pagination from '../../common/Tables/Pagination';

class CollectionAdvice extends Component {
    constructor(props) {
        super(props);

        const { filter = { collectionAdviceNumber: "", dateFrom: null, dateTo: null, orderDescending: true } } = this.props.location;

        this.state = {
            totalCount: 0,
            pageIndex: 1,
            pageCount: 0,
            collectionAdvices: [],
            loading: true,
            filter: filter
        };
    }

    componentDidMount() {
        this.getCollectionAdvices();
    }

    setLoading = (loading) => {
        this.setState({ loading: loading });
    }

    getCollectionAdvices = () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        const { collectionAdviceNumber, dateFrom, dateTo, orderDescending } = this.state.filter;

        let url = new URL(Endpoints.COLLECTION_ADVICE.GET.ALL);
        
        if (collectionAdviceNumber || dateFrom || dateTo || orderDescending) {
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }
        url.searchParams.append("pageIndex", pageIndex)
        return GET(url)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    this.setState({ collectionAdvices: data.collectionAdvices, totalCount: data.total, pageCount: data.pageCount, loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    renderRows = () => {
        const { collectionAdvices, pageIndex, pageCount } = this.state;
        return (
            <Card>
                <div className="table-responsive">
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th>Date and Time</th>
                                <th>Reference</th>
                                <th>Total (£)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {collectionAdvices.length ? collectionAdvices.map(collectionAdvice => <CollectionAdviceRow key={collectionAdvice.id} collectionAdvice={collectionAdvice} />) : <p className="mx-1 my-1"><strong>No collections available.</strong></p>}
                        </tbody>
                    </Table>
                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                </div>
            </Card>
        );
    }

    onFilterChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear = () => {
        this.setState({
            collectionAdvices: [],
            filter: {
                collectionAdviceNumber: "",
                dateFrom: null,
                dateTo: null, 
                orderDescending: true
            }
        }, this.getCollectionAdvices);
    }

    onSearch = (event) => {
        event.preventDefault();
        this.setState({ collectionAdvices: [], loading: true }, this.getCollectionAdvices);
    }

    onNext = () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.getCollectionAdvices);
        }
    }

    onPrevious = () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.getCollectionAdvices);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.getCollectionAdvices);
    }

    render() {
        return (
            <React.Fragment>
                <Header title="Invoicing" subNavList={InvoicingSubNavList} activeKey="Collection" />
                <Container fluid>
                    <Row className="my-4">
                        <Col>
                            <CollectionAdviceFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} loading={this.state.loading} onLoading={this.setLoading} />
                        </Col>
                    </Row>
                    {this.state.loading ? <LoadingBar /> : this.renderRows()}
                </Container>
            </React.Fragment>
        );
    }
}

export default CollectionAdvice;