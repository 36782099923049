import React, { Component } from 'react';
import { Card, Table, Row, Col, Container } from 'react-bootstrap';
import { RetailerOrderSubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import OrderListFilter from './OrderListFilter';
import OrderRow from './OrderRow';
import { GET, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alert';
import Pagination from '../../common/Tables/Pagination';

class ProcessedOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 1,
            orders: [],
            loading: true,
            warning: "",
            filter: {
                orderNumber: "",
                dateFrom: undefined,
                dateTo: undefined,
                sortBy: ""
            }
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchProcessedOrders()]);
        this.setState({ loading: false });
    }

    fetchProcessedOrders = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RETAILER.GET.PROCESSED);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = null;
                this.setState({ orders: data.orders ? data.orders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            })
            .catch(e => this.setState({ warning: e.message }));
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { orderNumber: "", dateFrom: null, dateTo: null, sortBy: "" },
            loading: true
        }, await this.fetchProcessedOrders);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchProcessedOrders);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchProcessedOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchProcessedOrders);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchProcessedOrders);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    handleViewOrderClick = (order) => {
        this.props.history.push({ pathname: "/retailer/orders/orderdetails", state: { order: order } });
    }

    renderProcessedOrderTable() {
        const { orders, pageIndex, pageCount } = this.state;
        return (
            <Card>
                <div className="table-responsive">
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th>Order Number</th>
                                <th>Tracking Ref</th>
                                <th>Customer Name</th>
                                <th>Date Created</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.length ? orders.map(order => <OrderRow key={order.id} order={order} handleViewOrderClick={() => this.handleViewOrderClick(order)} />) : <p className="ml-3 my-3"><strong>No orders available.</strong></p>}
                        </tbody>
                    </Table>
                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                </div>
            </Card>
        );
    }

    render() {
        const { loading, warning } = this.state;

        return (
            <React.Fragment>
                <Header title="Orders" subNavList={RetailerOrderSubNavList} activeKey="Processed Orders" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                        <Row className="my-4">
                            <Col>
                                <OrderListFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                            </Col>
                        </Row>
                        {this.renderProcessedOrderTable()}
                    </Container>}
            </React.Fragment>
        )
    }
}

export default ProcessedOrders;