import React, { Component } from 'react';
import ReactDropzone from 'react-dropzone';
import { FormGroup, FormLabel, FormControl, Row, Col, Button, Container } from 'react-bootstrap';
import { ProductsSubNavList } from '../../../../common/Constants';
import { Link } from 'react-router-dom';
import { GET, PUT, DELETE, extractData, POST_FILE } from '../../../../../Consumer';
import { defaultGuid } from '../../../../../Utilities';
import Endpoints from '../../../../common/Endpoints';
import LoadingBar from '../../../../common/LoadingBar';
import FormValidator from '../../../../common/FormValidator';
import Header from '../../../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEdit, faFingerprint, faImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../../../common/Alert';

const UNSAVED_CHANGES_TITLE_VALIDATION = "Unsaved Changes";
const UNSAVED_CHANGES_MESSAGE_VALIDATION = "You have unsaved changes which will be lost if you add or edit a SKU."

const NO_BARCODE_TITLE_VALIDATION = "No-Barcode Product";
const NO_BARCODE_MESSAGE_VALIDATION = "You are required to upload a product image.";

const NO_BARCODE_SKU_TITLE_VALIDATION = "No-Barcode Product SKU Variants";
const NO_BARCODE_SKU_MESSAGE_VALIDATION = "You are required to upload a product image for each SKU variant.";

const NO_BARCODE_DELETE_IMAGE = "Unable to remove image from a no-barcode product.";

const UniqueIdentifiers = ({ selazarUniqueID, ean, eanValidation, isbn, handleInputChange }) =>
    <React.Fragment>
        {selazarUniqueID !== null && <FormLabel>Selazar Unique ID: {selazarUniqueID}</FormLabel>}
        <FormGroup>
            <FormLabel className="mb-0" htmlFor="eanField">EAN <span className="form-label-optional">- optional</span></FormLabel>
            <div className="form-input-description">
                <p>This is the number that is below the barcode displayed on either the packaging or the product itself.</p>
            </div>
            <FormControl id="eanField" type="text" parent="item" name="ean" value={ean} onChange={handleInputChange} />
            <span className="text-danger">{eanValidation.ean.message}</span>
        </FormGroup>
        <FormGroup>
            <FormLabel htmlFor="isbnField">ISBN <span className="form-label-optional">- optional</span></FormLabel>
            <FormControl id="isbnField" type="text" parent="item" name="isbn" value={isbn} onChange={handleInputChange} />
        </FormGroup>
    </React.Fragment>

const Skus = ({ stockKeepingUnits, id, pageIndex, showSaveWarning }) =>
    <FormGroup className="mb-5">
        <div className="mb-0">
            <FormLabel htmlFor="addSku">SKUs <span className="form-label-optional">- optional</span></FormLabel>
        </div>
        {showSaveWarning && <WarningAlert warningTitle={UNSAVED_CHANGES_TITLE_VALIDATION} warningMessage={UNSAVED_CHANGES_MESSAGE_VALIDATION} />}
        {(stockKeepingUnits || []).length > 0 && <div className="identifier-list">
            <ul>
                {stockKeepingUnits.map((f, i) => <li key={i}>
                    <Link to={`/supplier/products/edit/uniqueidentifiers/sku/${id}/${pageIndex}/${i}`}>
                        <FontAwesomeIcon icon={faFingerprint} className="form-fileicon" />{f.code}
                        <FontAwesomeIcon className="form-fileicon-edit float-right" index={i} icon={faEdit} /></Link></li>
                )}
            </ul>
        </div>}
        <div className="w-100 float-right mb-4">
            <Link to={`/supplier/products/edit/uniqueidentifiers/sku/${id}/${pageIndex}/add`}>
                <Button variant="secondary" className="float-right" id="addSku">Add SKU</Button>
            </Link>
        </div>
    </FormGroup>

const NoBarcode = ({ noScan, handleInputChange, productWithSkus, showBarcodeWarning }) =>
    <FormGroup className="mb-med">
        <FormLabel className="mb-0">No-Barcode Product</FormLabel>
        <div className="form-input-description mb-3">
            <p>We scan the product's physical barcode (EAN or SUID) throughout pick and pack for better quality control. If your product cannot have a physical barcode, please select below. This product will be manually confirmed using its image through pick and pack.</p>
        </div>
        <FormGroup className="custom-control custom-checkbox">
            <input id="noScan" className="custom-control-input" type="checkbox" name="noScan" checked={noScan} onChange={handleInputChange} />
            <FormLabel className="custom-control-label" htmlFor="noScan">This is a no-barcode product</FormLabel>
        </FormGroup>
        {showBarcodeWarning &&
            <WarningAlert
                warningTitle={productWithSkus ? NO_BARCODE_SKU_TITLE_VALIDATION : NO_BARCODE_TITLE_VALIDATION}
                warningMessage={productWithSkus ? NO_BARCODE_SKU_MESSAGE_VALIDATION : NO_BARCODE_MESSAGE_VALIDATION}
            />
        }
    </FormGroup>

const ImageUpload = ({ images = [], dropzoneEnabled, onDrop, deleteImage, noScan }) =>
    <FormGroup>
        <FormLabel className="mb-0">Product Image</FormLabel>
        <div className="form-input-description mb-3">
            <p>A product image is required for no-barcode products.</p>
        </div>
        {dropzoneEnabled &&
            <ReactDropzone accept="image/*" onDrop={onDrop} maxSize={999999} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="file-upload text-center">
                        <input {...getInputProps()} />
                        <p className="file-upload-primarytext my-2">Drag and drop your image</p>
                        <p className="file-upload-secondarytext my-2">or</p>
                        <Button variant="secondary">Browse</Button>
                    </div>
                )}
            </ReactDropzone>
        }
        {images.length > 0 && <div className="form-image-list mt-3">
            {images.length === 1 && noScan
                ? <React.Fragment>
                    <WarningAlert warningTitle={NO_BARCODE_TITLE_VALIDATION} warningMessage={NO_BARCODE_DELETE_IMAGE} />
                    <ul>
                        <li key={0}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{images[0].fileName}</li>
                    </ul>
                </React.Fragment>
                : <ul>
                    {images.map((image, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{image.fileName}<FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" id={image.id} index={i} onClick={() => deleteImage(image.id, i)} /></li>)}
                </ul>
            }
        </div>}
    </FormGroup>

class EditUniqueIdentifiers extends Component {

    constructor(props) {
        super(props);

        this.eanValidator = new FormValidator([
            {
                field: 'ean',
                method: 'isLength',
                validWhen: true,
                args: [{ min: 0, max: 14 }],
                message: 'EAN cannot exceed 14 characters'
            }
        ]);

        this.state = {
            loading: true,
            warning: null,
            companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            pageIndex: this.props.match.params.pageIndex || 1,
            categories: [],
            companyItem: {},
            eanValidation: this.eanValidator.valid(),
            currentSku: {
                id: defaultGuid,
                code: "",
                description: "",
                itemImageID: "",
                companyItemID: "",
                quantity: 0
            },
            dropzoneEnabled: true,
            currentSkuIndex: null,
            noPackagingNeeded: true,
            showBarcodeWarning: false,
            showSaveWarning: false,
            showDeleteError: false,
            showImageSuccess: false,
            showImageError: false,
            noScanServerValue: false
        };
    }

    async componentDidMount() {
        await this.getCompanyItem();
        this.setState({ loading: false });
    }

    getCompanyItem = () => {
        const { companyItemID } = this.state;
        return GET(Endpoints.STOCK.GET.BY_ID + companyItemID)
            .then(response => response.json())
            .then(data => {
                const companyItem = extractData(data);
                const warning = companyItem ? null : "No product available.";
                this.setState({ companyItem: companyItem, noScanServerValue: companyItem.noScan, warning: warning, dropzoneEnabled: (companyItem.item.itemImages.length === 0) });
            });
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.getAttribute('parent')) {
            const parent = e.target.getAttribute('parent');
            const parentValue = this.state.companyItem[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        if (name === "noScan") {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    noScan: !prevState.companyItem.noScan
                }
            }));
        } else {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    ...{ [name]: value }
                }
            }));
        }

        this.setState({ showSaveWarning: true });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            alert("File could not be uploaded. Ensure file size is under 1MB.");
        } else {
            this.submitImage(acceptedFiles);
        }
    }

    submitImage = (files) => {
        const { companyItem } = this.state;
        if (files) {
            this.setState({ loading: true });

            const formData = new FormData();
            files.forEach((f, i) => formData.append(`files`, files[i], f.name));

            return POST_FILE(Endpoints.STOCK.POST.UPLOAD_IMAGES + companyItem.itemID, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    if (!response.error) {
                        console.log(JSON.stringify(response));
                        let images = companyItem.item.itemImages;
                        images = images.concat(extractData(response));

                        const dropzoneEnabled = (images.length === 0);

                        this.setState(prevState => ({
                            ...prevState,
                            companyItem: {
                                ...prevState.companyItem,
                                ...{
                                    item: {
                                        ...prevState.companyItem.item,
                                        ...{ itemImages: images }
                                    }
                                }
                            },
                            dropzoneEnabled: dropzoneEnabled,
                            loading: false,
                            showImageSuccess: true,
                            showImageError: false,
                            showBarcodeWarning: false
                        }));

                    }
                    else {
                        const dropzoneEnabled = (companyItem.item.itemImages.length === 0);
                        this.setState({ loading: false, showImageSuccess: false, showImageError: true, error: response.message, dropzoneEnabled: dropzoneEnabled });
                    }
                });
        }
    }

    deleteImage = (id, idx) => {
        return DELETE(Endpoints.STOCK.DELETE.ITEM_IMAGE + id).catch(e => console.log(e))
            .then(r => r.json())
            .then(response => {
                if (!response.error) {
                    const images = [
                        ...this.state.companyItem.item.itemImages.slice(0, idx),
                        ...this.state.companyItem.item.itemImages.slice(idx + 1)
                    ];

                    this.setState(prevState => ({
                        ...prevState,
                        companyItem: {
                            ...prevState.companyItem,
                            ...{
                                item: {
                                    ...prevState.companyItem.item,
                                    ...{ itemImages: images }
                                }
                            }
                        },
                        dropzoneEnabled: images.length === 0,
                        showDeleteError: false
                    }));

                    window.location.reload();
                }
                else {
                    this.setState({ loading: false, showDeleteError: true });
                }
            });
    }

    handleSubmit = async () => {
        this.setState({ loading: true });
        const { companyItem } = this.state;
        let valid = true;

        const validation = this.eanValidator.validate(companyItem.item);
        this.setState({ eanValidation: validation });
        valid = validation.isValid;

        if (companyItem.noScan) {
            if (companyItem.stockKeepingUnits.length > 0) {
                const noImageSku = companyItem.stockKeepingUnits.find(s => s.itemImageID === null);
                if (noImageSku !== undefined) {
                    valid = false;
                    this.setState({ showBarcodeWarning: true });
                } else this.setState({ showBarcodeWarning: false });
            } else {
                if (companyItem.item.itemImages.length > 0) {
                    const noImage = companyItem.item.itemImages.find(i => i.itemImageID === null);
                    if (noImage !== undefined) {
                        valid = false;
                        this.setState({ showBarcodeWarning: true });
                    } else this.setState({ showBarcodeWarning: false });
                } else {
                    valid = false;
                    this.setState({ showBarcodeWarning: true });
                }
            }
        } else this.setState({ showBarcodeWarning: false });

        if (valid) {

            if (companyItem.item.selazarUniqueID === null && (!companyItem.item.ean || companyItem.item.ean.length === 0)) {
                companyItem.item.ean = null;
            }

            return await PUT(Endpoints.STOCK.PUT.UPDATE, companyItem)
                .then(response => response.json()).then((result) => {
                    if (!result.error) {
                        this.getCompanyItem();
                        this.setState({ loading: false, success: true, showImageSuccess: false, warning: null, showSaveWarning: false });
                    } else {
                        this.setState({ loading: false, success: false, showImageSuccess: false, warning: result.message });
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        const { companyItem, loading, warning, success, eanValidation, pageIndex, dropzoneEnabled, showSaveWarning, showBarcodeWarning, showDeleteError, showImageSuccess, showImageError, noScanServerValue } = this.state;

        return (
            <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Products" headerClass="mb-0" />
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Link to={`/supplier/products/edit/${companyItem.id}/${pageIndex}`} className="link-button">
                                <Button variant="link" className="button-nav-return p-0 mb-3"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        {loading ? <LoadingBar /> :
                            <React.Fragment>
                                <Col sm={12} md={6}>
                                    <h5>Unique Identifiers</h5>
                                    {warning && <ErrorAlert errorMessage={warning} />}
                                    {success && <SuccessAlert successMessage="Product successfully updated." />}
                                    {showDeleteError && <ErrorAlert errorMessage="There was a problem deleting the image. Please try again." />}
                                    {showImageSuccess && <SuccessAlert successMessage="Product image successfully uploaded." />}
                                    {showImageError && <ErrorAlert errorMessage="There was a problem uploading the image. Please try again." />}
                                    <p className="my-3"><strong>Enter existing unique product identifiers here:</strong></p>
                                    <div className="mt-2">
                                        <UniqueIdentifiers selazarUniqueID={companyItem.item.selazarUniqueID} ean={companyItem.item.ean} eanValidation={eanValidation} isbn={companyItem.item.isbn} handleInputChange={this.handleInputChange} />
                                        <Skus id={companyItem.id} stockKeepingUnits={companyItem.stockKeepingUnits} pageIndex={pageIndex} showSaveWarning={showSaveWarning} />
                                        <NoBarcode noScan={companyItem.noScan} handleInputChange={this.handleInputChange} productWithSkus={companyItem.stockKeepingUnits.length > 0} showBarcodeWarning={showBarcodeWarning} />
                                        {!companyItem.stockKeepingUnits.length > 0 && <ImageUpload images={companyItem.item.itemImages} dropzoneEnabled={dropzoneEnabled} onDrop={this.onDrop} deleteImage={this.deleteImage} noScan={noScanServerValue} />}
                                        <div className="w-100 float-left mt-4">
                                            <div className="float-right">
                                                <Link to={`/supplier/products/edit/${this.props.match.params.id}/${this.props.match.params.pageIndex}`} className="link-button">
                                                    <Button variant="link" className="mr-3">Cancel</Button>
                                                </Link>
                                                <Button variant="primary" className="float-right" onClick={this.handleSubmit}>Save</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </React.Fragment>}
                    </Row>
                </Container>
            </React.Fragment >
        )
    }
}

export default EditUniqueIdentifiers;