import React from 'react';
import { extractData, POST, GET, PUT } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import Header from '../../common/Header';
import { PaymentsSubNavList, PaymentsRestrictedSubNavList } from '../../common/Constants';
import Payments from './Payments';
import AddCard from './AddCard';
import NoCard from './NoCard';

const ADYEN_SCRIPT = process.env.REACT_APP_ADYEN_SCRIPT;

class NewCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showForm: false,
            loading: true,
            showRemoveCardConfirmation: false,
            disableCardSuccess: false,
            success: false,
            adyenForm: null,
            directDebitEnabled: false,
            form: {
                shopperEmail: "",
                holderName: "",
                holderAddressLine1: "",
                holderAddressLine2: "",
                holderAddressTownCity: "",
                holderAddressCountry: "",
                holderAddressPostalCode: "",
                number: "",
                month: "",
                year: "",
                cvc: ""
            },
            validity: {
                holderName: true,
                number: true,
                month: true,
                year: true,
                cvc: true
            },
            latestDetails: null
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await Promise.all([
            this.checkCardValidity(),
            this.getLatestCardDetails(),
            this.isDirectDebitEnabled()]);
        this.setState({ loading: false });
    }

    getBarclaysEncryptionScript() {
        const script = document.createElement("script");
        script.src = ADYEN_SCRIPT;
        script.async = true;
        script.onload = () => {
            console.info('Loaded external script.');
            this.setState({ adyenForm: this.createAdyenForm() });
        };
        document.body.appendChild(script);
    }

    checkCardValidity() {
        return GET(Endpoints.FINANCE.CARD.HAS_VALID_DETAILS)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            });
    }

    getLatestCardDetails() {
        return GET(Endpoints.FINANCE.CARD.LATEST)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result);
                    this.setState({ latestDetails: { name: data.holderName, cardSummary: data.cardSummary, active: data.current } });
                }
            }, err => {
                console.error(err);
            });
    }

    disableCurrentCard() {
        return PUT(Endpoints.FINANCE.CARD.DISABLE_CURRENT)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    this.getLatestCardDetails();
                }
            }, err => {
                console.error(err);
            });
    }

    isDirectDebitEnabled() {
        return GET(Endpoints.FINANCE.DIRECT_DEBIT.ENABLED)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) { return res.json(); }
                else { throw new Error('Error fetching data'); }
            })
            .then(result => {
                if (result) {
                    const enabled = extractData(result);
                    this.setState({ directDebitEnabled: enabled});
                }
            }, err => { console.error(err); });
    }

    createAdyenForm = () => {
        var form = document.getElementById('adyen-encrypted-form');
        var options = {
            name: "adyenEncryptedData"
        };
        console.info('Bound to adyen form.');
        return window.adyen.createEncryptedForm(form, options);
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });
        const { form, adyenForm } = this.state;

        const card = {
            holderName: form.holderName,
            number: form.number,
            month: form.month,
            year: form.year,
            cvc: form.cvc
        };

        const validity = this.state.adyenForm.validate(card);
        if (!validity.valid) {
            this.setState({ validity: validity });
            return;
        }

        const formData = {
            holderEmail: form.shopperEmail,
            holderName: form.holderName,
            holderAddressLine1: form.holderAddressLine1,
            holderAddressLine2: form.holderAddressLine2,
            holderAddressTownCity: form.holderAddressTownCity,
            holderAddressCountry: form.holderAddressCountry,
            holderAddressPostalCode: form.holderAddressPostalCode,
            adyenEncryptedData: adyenForm.encrypt(card)
        };

        POST(Endpoints.FINANCE.CARD.ADD_CARD, formData)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    if (data.authorised === true) {
                        this.getLatestCardDetails();
                        this.setState({ loading: false, success: true, disableCardSuccess: false, showForm: false });
                    }
                    else {
                        this.setState({ loading: false, furtherAuth: data });
                    }
                }
                else {
                    alert("An error occurred: " + result.message);
                    this.setState({ loading: false });
                }
            })
            .catch(e => {
                alert("There was an error saving the card details.");
                this.setState({ loading: false });
                console.log(e);
            });
    }

    onChange = (e) => {
        const { id, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,
                ...{ [id]: value }
            }
        }));
    };

    validationState = (valid) => !valid ? "error" : null;

    handleRemoveShowHide = () => this.setState((prevState) => ({ showRemoveCardConfirmation: !prevState.showRemoveCardConfirmation }));

    handleRemoveAction = async () => {
        this.setState((prevState) => ({ loading: true, disableCardSuccess: true, success: false, showRemoveCardConfirmation: !prevState.showRemoveCardConfirmation }));
        await Promise.all([
            this.disableCurrentCard(),
            this.getBarclaysEncryptionScript(),
            this.getLatestCardDetails(),
            this.isDirectDebitEnabled()]);
        this.setState({ loading: false });
    }

    onCancelForm = async () => {
        this.setState({ loading: true, showForm: false });
        await Promise.all([
            this.getLatestCardDetails(),
            this.isDirectDebitEnabled]);
        this.setState({ loading: false });
    }

    onLoadForm = async () => {
        this.setState({ loading: true });
        await Promise.all([
            this.getBarclaysEncryptionScript(),
            this.isDirectDebitEnabled()]);
        this.setState({
            loading: false, 
            form: {
                shopperEmail: "",
                holderName: "",
                holderAddressLine1: "",
                holderAddressLine2: "",
                holderAddressTownCity: "",
                holderAddressCountry: "",
                holderAddressPostalCode: "",
                number: "",
                month: "",
                year: "",
                cvc: ""
            }, 
            showForm: true
        });
    }

    render() {
        const { loading, latestDetails, success, showRemoveCardConfirmation, showForm, furtherAuth, validity, form, disableCardSuccess, directDebitEnabled } = this.state;
        return (
            <React.Fragment>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Header title="Payments" subNavList={directDebitEnabled ? PaymentsSubNavList : PaymentsRestrictedSubNavList} activeKey="Card Payment" />
                        {(latestDetails && !showForm)
                            ? <Payments
                                success={success}
                                showRemoveCardConfirmation={showRemoveCardConfirmation}
                                handleRemoveShowHide={this.handleRemoveShowHide}
                                handleRemoveAction={this.handleRemoveAction}
                                onLoadForm={this.onLoadForm}
                                latestDetails={latestDetails}
                                disableCardSuccess={disableCardSuccess} />
                            : showForm
                                ? <AddCard
                                    furtherAuth={furtherAuth}
                                    validity={validity}
                                    onSubmit={this.onSubmit}
                                    onChange={this.onChange}
                                    form={form}
                                    validationState={this.validationState}
                                    onCancelForm={this.onCancelForm} />
                                : <NoCard onLoadForm={this.onLoadForm} />}
                </React.Fragment>}
            </React.Fragment>
        );
    }
}

export default NewCard;