
import React, { Component } from 'react';
import { Container, Row, Col, Modal, FormGroup, FormLabel, FormControl, Button, Form, Card } from 'react-bootstrap';

import StockListFilter from './BulkOrderStockListFilter';
import StockItem from '../../../common/StockItemCard';
import { ErrorAlert } from '../../../common/Alert';
import Pagination from '../../../common/Tables/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxes, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const Header = ({ totalAddedCount, getAllItems }) => (
    <Row className="my-4">
        <Col sm={4}><h5 className="mb-4">Item Selection</h5></Col>
        <Col sm={8}>
            <Button className="float-right" variant="count" onClick={getAllItems} disabled={totalAddedCount === 0}>
                <FontAwesomeIcon className="mr-1" icon={faBoxes} />
                <span className="d-inline-block ml-2">Bulk Order Items</span>
                <span className="highlighted d-inline-block ml-2">{totalAddedCount}</span>
            </Button>
        </Col>
    </Row>
)

const AddToBulkModal = ({ currentlySelected, popupErrors, showModal, handleClose, handleQuantityInputChange, addToBulkOrder }) => {
    const errorMessage = popupErrors && popupErrors.length > 0 ? popupErrors.map((e, i) => e && e.length ? <p key={i} className="mb-0">{e}</p> : null) : null;

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Form onSubmit={e => { e.preventDefault(); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Add to Bulk Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup>
                        {popupErrors && popupErrors.length > 0 && <Row><Col><ErrorAlert errorMessage={errorMessage}></ErrorAlert></Col></Row>}
                        <FormLabel>How many {currentlySelected.name}(s) would you like to add to your bulk order?</FormLabel>
                        <FormGroup>
                            <Row>
                                <Col md={3}>
                                    <FormControl id="bulkQuantity" value={currentlySelected.requestedQuantity} onChange={handleQuantityInputChange} max={currentlySelected.availableQuantity} type="number" min="0" step="1" />
                                </Col>
                            </Row>
                        </FormGroup>
                    </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="link" className="button-nav-link mr-3" onClick={handleClose}>Cancel</Button>
                    <Button variant="primary" onClick={addToBulkOrder}>Add to Bulk Order Items</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

class BulkOrderItemSelection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            currentlySelected: {
                id: "",
                name: "",
                sku: "",
                availableQuantity: "",
                requestedQuantity: 0
            }
        };
    }

    handleClose = () => this.setState({ showModal: false, popupErrors: "" });

    handleClick = (identifier, companyItemID, name, availableQuantity, sku) => {
        const { selectedItems } = this.props;
        const arrayIdentifier = selectedItems.items.findIndex(i => i.identifier === identifier);
        const requestedQuantity = (selectedItems.items[arrayIdentifier] !== undefined) ? selectedItems.items[arrayIdentifier].quantity : 0
        this.setState({
            showModal: true, currentlySelected: { identifier: identifier, companyItemID: companyItemID, name: name, availableQuantity: availableQuantity, requestedQuantity: requestedQuantity, sku: sku }, popupErrors: ""
        });
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    handleQuantityInputChange = (e) => {
        const numeric = /^\d?/;
        const { value } = e.target;       

        if (value.match(numeric)) {
            this.setState(prevState => ({
                ...prevState,
                currentlySelected: {
                    ...prevState.currentlySelected,
                    ...{ requestedQuantity: value }
                }
            }));
        }
    }

    addToBulkOrder = async () => {
        const { currentlySelected } = this.state;
        const { updateCounter, handleSelectedItemsRequest, selectedItems, validateItems } = this.props;
        let si = selectedItems;

        const availableQuantity = parseInt(currentlySelected.availableQuantity);
        const requestedQuantity = parseInt(currentlySelected.requestedQuantity);

        if (requestedQuantity === 0 || isNaN(requestedQuantity)) {
            const updatedItem = { identifier: currentlySelected.identifier, companyItemID: currentlySelected.companyItemID, name: currentlySelected.name, quantity: 0, sku: currentlySelected.sku, unassigned: 0, assigned: 0 };
            const exists = si.items.findIndex(i => i.identifier === updatedItem.identifier) === -1 ? false : true;

            if (exists) si.items[si.items.findIndex(i => i.identifier === updatedItem.identifier)] = updatedItem;
            else si.items.push(updatedItem);

            this.handleClose();
        }
        else {
            if (requestedQuantity < 0) {
                await this.promisedSetState({ popupErrors: ["You cannot enter a negative number"] });
            }
            else if (currentlySelected.requestedQuantity % 1 !== 0) {
                await this.promisedSetState({ popupErrors: ["Invalid quantity"] });
            }
            else if (requestedQuantity > availableQuantity) {
                await this.promisedSetState({ popupErrors: ["You have entered an amount higher than the available quantity"] });
            }
            else {

                const quantity = parseInt(requestedQuantity.toString().replace(/^0+/, ''));
                const updatedItem = { identifier: currentlySelected.identifier, companyItemID: currentlySelected.companyItemID, name: currentlySelected.name, quantity: quantity, sku: currentlySelected.sku, unassigned: 0, assigned: 0 };
                const exists = si.items.findIndex(i => i.identifier === updatedItem.identifier) === -1 ? false : true;

                if (exists) si.items[si.items.findIndex(i => i.identifier === updatedItem.identifier)] = updatedItem;
                else si.items.push(updatedItem);

                this.handleClose();
                validateItems();
            }
        }

        await handleSelectedItemsRequest(si);
        updateCounter();
    }

    getAllItems = async () => {
        const { toggleSummary, fetchAllStockItems } = this.props;
        await fetchAllStockItems();
        toggleSummary(true);
    }

    buildSummary() {
        const { currentlySelected, popupErrors, showModal } = this.state;
        const { selectedItems, toggleSummary, totalAddedCount, allItems } = this.props;

        return (
            <Container fluid>
                <React.Fragment>
                    <Row className="my-4">
                        <Col sm={4}>
                            <h5 className="mb-4">Bulk Order Items</h5>
                            <Button onClick={() => toggleSummary(false)} variant="link" className="button-nav-return p-0"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Consignment Items</Button>
                            <h6 className="mt-3">Bulk Order Item Total: {totalAddedCount}</h6>
                        </Col>
                    </Row>

                    {totalAddedCount === 0 ? <Row className="my-4"><Col sm={4}><p><strong>No products to show.</strong></p></Col></Row> :
                        <Row>
                            {allItems.length ? allItems.map((item, i) => {
                                const identifier = (item.sku !== undefined && item.sku !== '' && item.sku !== null) ? item.companyItemID + "_" + item.sku : item.companyItemID;
                                const addToBulkPickButton = <Button className="mt-auto" variant="primary" onClick={() => this.handleClick(identifier, item.companyItemID, item.name, item.shelfStock + item.palletStock, item.sku)}>Edit Bulk Order Amounts</Button>;
                                const arrayIdentifier = selectedItems.items.findIndex(i => i.identifier === identifier);
                                if (selectedItems.items[arrayIdentifier] && selectedItems.items[arrayIdentifier].quantity !== 0) {
                                    const amountRequested = parseInt(selectedItems.items[arrayIdentifier].quantity);
                                    const remainingStock = (item.shelfStock + item.palletStock) - amountRequested;

                                    return (
                                        <Col md={4} sm={12} key={identifier} className="px-2">
                                            <Card className="p-3 mb-3 card-list item-summary">
                                                <p className="card-list-title"><strong>{item.name}</strong></p>
                                                <p className="card-list-subtitle mb-0">{item.sku}</p>
                                                <p className="card-list-subtitle">{item.category}</p>
                                                <div className="card-list-info">
                                                    <p><span className="info-title-bulk">Quantity Added</span>{amountRequested}</p>
                                                    <p><span className="info-title-bulk">Remaining Stock</span>{remainingStock}</p>
                                                </div>
                                                {addToBulkPickButton}
                                            </Card>
                                        </Col>)
                                }
                            }) : <p><strong>No products to show.</strong></p>}
                        </Row>}
                        <AddToBulkModal currentlySelected={currentlySelected} popupErrors={popupErrors} showModal={showModal} handleClose={this.handleClose} handleQuantityInputChange={this.handleQuantityInputChange} addToBulkOrder={this.addToBulkOrder} />
                </React.Fragment>
            </Container>)
    }

    render() {
        const { currentlySelected, popupErrors, showModal } = this.state;
        const { selectedItems, displaySummary, totalAddedCount, onPrevious, onNext, onPageSelect, items, warning, pageIndex, pageCount, onSearch, onFilterChange, onReset, filter } = this.props;
        return (
            <React.Fragment>
                {displaySummary ? this.buildSummary() :
                    <React.Fragment>
                        <Container fluid>
                            <React.Fragment>
                                <Header totalAddedCount={totalAddedCount} getAllItems={this.getAllItems} />
                                <Row className="my-4">
                                    <Col>
                                        <StockListFilter onSearch={onSearch} onFilterChange={onFilterChange} onReset={onReset} filter={filter} />
                                    </Col>
                                </Row>
                                {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                                <Row>
                                    {items.length ? items.map((item, i) => {
                                        const identifier = (item.sku !== undefined && item.sku !== '' && item.sku !== null) ? item.companyItemID + "_" + item.sku : item.companyItemID;
                                        const arrayIdentifier = selectedItems.items.findIndex(i => i.identifier === identifier);
                                        const itemCount = (selectedItems === null || selectedItems === undefined || selectedItems.items[arrayIdentifier] === null || selectedItems.items[arrayIdentifier] === undefined) ? "" : selectedItems.items[arrayIdentifier].quantity;
                                        const itemsPicked = itemCount !== "" && parseInt(itemCount) !== 0;
                                        const buttonText = (itemsPicked) ? "Successfully Added" : "Add to Bulk Order";
                                        const buttonVariant = (itemsPicked) ? "primary" : "secondary";
                                        const addToBulkPickButton = <Button className="mt-3" variant={buttonVariant} onClick={() => this.handleClick(identifier, item.companyItemID, item.name, item.shelfStock + item.palletStock, item.sku)}>{buttonText}</Button>;

                                        return (
                                            <Col md={4} sm={12} key={item.companyItemID + "_" + i} className="px-2">
                                                <StockItem name={item.name} category={item.category} ean={item.ean} sku={item.sku}
                                                    suid={item.selazarUniqueID} totalStock={item.totalStock}
                                                    status={item.status} button={addToBulkPickButton} />
                                            </Col>)
                                    }) : <p><strong>No products to show.</strong></p>}
                                </Row>
                                {items.length ? <Pagination onNext={onNext} onPrevious={onPrevious} onPageSelect={onPageSelect} pageIndex={pageIndex} pageCount={pageCount} /> : ""}
                                <AddToBulkModal currentlySelected={currentlySelected} popupErrors={popupErrors} showModal={showModal} handleClose={this.handleClose} handleQuantityInputChange={this.handleQuantityInputChange} addToBulkOrder={this.addToBulkOrder} />
                            </React.Fragment>
                        </Container>
                    </React.Fragment>}
            </React.Fragment>
        )
    }
}

export default BulkOrderItemSelection;