import React from 'react';
import { RegularCollectionMarker } from './RegularCollectionMarker';
import SelazarMap from '../../common/SelazarMap';
import CommonEndpoints from '../../common/Endpoints';
import PropTypes from 'prop-types';

const RegularCollectionMap = (props) => {    
    let { regularCollections } = props;    

    let isMarkerShown = regularCollections !== null && regularCollections !== undefined && regularCollections.length > 0;
    let centreCoords  = isMarkerShown ? { lat: regularCollections[0].companyAddress.address.latitude, lng: regularCollections[0].companyAddress.address.longitude } : { lat: 0, lng: 0 };

    let markers = regularCollections.map((l, i) => {        
        return (
            <RegularCollectionMarker key={i} regularCollection={l} />
        );
    });

    let settings = {
        centre: centreCoords
    };

    return (
        <SelazarMap settings={settings}
            googleMapURL={CommonEndpoints.MAPS_URL}
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "400px", min_height: "100%", width: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            isMarkerShown={isMarkerShown}
        >
            {markers}
        </SelazarMap>
    );
};

RegularCollectionMap.propTypes = {
    regularCollections: PropTypes.array.isRequired
};

RegularCollectionMap.defaultProps = {
    regularCollections: []
};

RegularCollectionMap.displayName = RegularCollectionMap.name;
export default RegularCollectionMap;