import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InventorySubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faFile, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { GET, PUT, extractData } from '../../../../Consumer';
import { isNullOrEmptyGuid, toLocalTimeString } from '../../../../Utilities';
import SelazarLinkContainer from '../../../common/SelazarLinkContainer';
import Confirm from '../../../common/Confirm';
import { ErrorAlert } from '../../../common/Alert';

import './StockConsignment.scss';

const AFFIX_LABELS_TITLE = "Affix Labels";
const CONSIGNMENT_ERROR_MESSAGE = "There was a problem retrieving the consignment. Please try again.";

const getAffixLabelsText = (showStockItem, showStockBox) => {
    return (
        <React.Fragment>
            <span>By clicking 'Confirm' you are acknowledging that you have affixed all of the necessary labels to your stock consignment. The labels you should have affixed are:</span>
            <span className="modal-text-label">Stock Consignment Label</span>
            {showStockBox && <span className="modal-text-label">Stock Box Labels</span>}
            {showStockItem && <span className="modal-text-label">Product Labels</span>}
        </React.Fragment>
    )
}

const renderStatus = (item) => {
    switch (item.status) {
        case "Processed":
            return <span className="text-status-green">{item.status}</span>;
        case "Not Received":
            return <span className="text-status-grey">{item.status}</span>;
        case "Processing":
        case "Received":
        case "Quality Check in Progress":
        case "Quality Check Completed":
        case "Check-in in Progress":
        case "Check-in Completed":
            return <span className="text-status-yellow">{item.status}</span>;
        case "On Hold":
            return <span className="text-status-red">{item.status}</span>;
        default:
            return <span className="text-status-grey">Unknown</span>;
    }
}

const renderLabelsAffixed = (affixed) => affixed ? <span className="text-status-green" >Affixed</span> : <span className="text-status-grey">Not Affixed</span>;
const itemLabelsRequired = (stockItem) => {
    if (stockItem.lines) {
        for (const line of stockItem.lines) {
            if (line.selazarUniqueID) {
                return true;
            }
        }
    }

    return false;
}

const stockBoxLabelsRequired = (stockItem) => {
    if (stockItem.lines) {
        for (const line of stockItem.lines) {
            if (line.gtin === null || line.gtin === undefined || line.gtin === '') {
                return true;
            }
        }
    }

    return false;
}

const Status = ({ stockItem }) => (
    <div className="section-consignment mt-3 mb-4">
        <p className="title"><strong>Status</strong></p>
        <p className="mb-0 mt-2">Processing Status:</p>
        {renderStatus(stockItem)}
        <p className="mb-0 mt-2">Label Status:</p>
        {renderLabelsAffixed(stockItem.labelsAffixed)}
    </div>
);

const CreatedDetails = ({ createdDate }) => (
    <div className="section-consignment mb-4">
        <p className="title"><strong>Created Details</strong></p>
        <p>{createdDate ? toLocalTimeString(createdDate) : "N/A"}</p>
    </div>
);

const Items = ({ id, total = 0 }) => (
    <div className="section-consignment mt-3 mb-4">
        <h6>Items</h6>
        <p className="title"><strong>Item Total</strong></p>
        <p>{total}</p>
        {total > 0 && <SelazarLinkContainer to={`/supplier/stockconsignments/consignmentitems/${id}`} exact>
            <Button variant="link">View Items</Button>
        </SelazarLinkContainer>}
    </div>
);

const ShippingDetails = ({ shippingReference }) => (
    <div className="section-consignment mt-3 mb-4">
        <h6>Shipping Details</h6>
        <p className="title"><strong>Shipping Reference</strong></p>
        <p>{shippingReference ? shippingReference : "N/A"}</p>
    </div>
);

const ConsignmentDetails = ({ isPallet, boxHeight, boxWidth, boxDepth, boxWeight, supplierPrintedLabels }) => (
    <div className="section-consignment mt-3 mb-4">
        <h6>Consignment Details</h6>
        <p className="title"><strong>Is this a pallet?</strong></p>
        <p>{isPallet ? "Yes" : "No"}</p>
        <p className="title"><strong>Dimensions (cm)</strong></p>
        <p>{boxHeight && boxWidth && boxDepth ? `${boxHeight} x ${boxWidth} x ${boxDepth}` : "N/A"}</p>
        <p className="title"><strong>Weight (kg)</strong></p>
        <p>{boxWeight ? boxWeight : "N/A"}</p>
        <p className="title"><strong>Do you want to print your labels</strong></p>
        <p>{supplierPrintedLabels ? "Yes" : "No"}</p>
    </div>
);

class StockConsignment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: null,
            id: props.match.params.id || null,
            showLabelModal: false,
            stockItem: {}
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchStockItem()]);
        this.setState({ loading: false });
    }

    fetchStockItem = async () => {
        const { id } = this.state;
        if (!isNullOrEmptyGuid(id)) {
            return GET(Endpoints.STOCK.GET.BY_ID_WITH_ITEMS + id)
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    const data = extractData(result);
                    this.setState({ stockItem: data ? data : {}, error: data ? null : CONSIGNMENT_ERROR_MESSAGE });
                }).catch(error => {
                    console.error(error);
                    this.setState({ error: CONSIGNMENT_ERROR_MESSAGE });
                });
        }
    }

    goToURL = (url) => {
        window.open(url, '_blank');
    }

    getLabels = (option) => {
        this.setState({ loading: true });
        const { stockItem } = this.state;
        if (stockItem) {
            return GET(`${Endpoints.STOCK.POST.DOWNLOAD_LABELS}${stockItem.id}`)
                .catch(error => console.log(error))
                .then(response => response.json())
                .then(result => {
                    if (!result.error) {
                        const data = extractData(result);
                        switch (option) {
                            case "consignment":
                                this.goToURL(data.consignmentLabelUrl);
                                this.setState({ loading: false });
                                break;
                            case "box":
                                this.goToURL(data.boxLabelsUrl);
                                this.setState({ loading: false });
                                break;
                            case "item":
                                if (data.itemLabelsUrl) {
                                    this.goToURL(data.itemLabelsUrl);
                                    this.setState({ loading: false });
                                }
                                break;
                            default:
                                console.log(`Unknown option: ${option}`);
                                this.setState({ loading: false });
                        }
                    }
                });
        }
    }

    handleShowHideLabelModal = () => this.setState((prevState) => ({ showLabelModal: !prevState.showLabelModal }));

    affixLabels = async () => {
        const { stockItem } = this.state;
        return PUT(Endpoints.STOCK.PUT.AFFIX_LABELS + stockItem.id)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.handleShowHideLabelModal();
                    this.setState({ loading: true });
                    this.fetchStockItem();
                    this.setState({ loading: false });
                }
            });
    }

    render() {
        const { loading, stockItem, showLabelModal, error } = this.state;

        const showStockItem = stockItem ? itemLabelsRequired(stockItem) : false;
        const showStockBox = stockItem ? stockItem.pickFromPallet ? false : stockBoxLabelsRequired(stockItem) : false;

        return (
            <React.Fragment>
                <Header title="Inventory" subNavList={InventorySubNavList} activeKey="Inventory" headerClass="mb-0" />
                <Link to='/supplier/stockconsignments' className="link-button">
                    <Button variant="link" className="button-nav-return"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Inventory</Button>
                </Link>
                {loading ? <LoadingBar /> :
                    <Container className="mt-3" fluid>
                        <Row>
                            {error
                                ? <Col sm={12} md={6}>
                                    <h5>Consignment Overview</h5>
                                    <ErrorAlert errorMessage={error} />
                                </Col>
                                : <React.Fragment>
                                    <Col sm={12} md={6}>
                                        <h5>Consignment Overview</h5>
                                        {stockItem.url && <div className="file-list consignment">
                                            <ul className>
                                                <li className="file-list-file" onClick={() => this.goToURL(stockItem.url)}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{stockItem.fileName}<div className="float-right"><FontAwesomeIcon className="form-fileicon" icon={faCheckCircle} /></div></li>
                                            </ul>
                                        </div>}
                                        <ConsignmentDetails isPallet={stockItem.isPallet} boxHeight={stockItem.boxHeight} boxWidth={stockItem.boxWidth} boxDepth={stockItem.boxDepth} boxWeight={stockItem.boxWeight} supplierPrintedLabels={stockItem.supplierPrintedLabels} />
                                        <ShippingDetails shippingReference={stockItem.shippingReference} />
                                        <Items id={stockItem.id} total={stockItem.lines.length} />
                                    </Col>
                                    <Col sm={12} md={6}>
                                        <CreatedDetails createdDate={stockItem.createdDate} />
                                        <div className="section-consignment mt-3 mb-4">
                                            <p className="title"><strong>Label Download</strong></p>
                                            <Button variant="secondary" onClick={() => this.getLabels("consignment")}>Stock Consignment</Button>
                                            {showStockBox && <Button variant="secondary" onClick={() => this.getLabels("box")}>Stock Box</Button>}
                                            {showStockItem && <Button variant="secondary" onClick={() => this.getLabels("item")}>Product</Button>}
                                        </div>
                                        <div className="section-consignment mt-3 mb-4">
                                            <p className="title"><strong>Label Status</strong></p>
                                            <p>Have the labels been affixed</p>
                                            {stockItem.labelsAffixed ?
                                                <React.Fragment><div className="toggle toggle-left disabled">No</div><div className="toggle-selected toggle-right disabled">Yes</div></React.Fragment> :
                                                <React.Fragment><div className="toggle-selected toggle-left">No</div><div className="toggle toggle-right" onClick={this.handleShowHideLabelModal}>Yes</div></React.Fragment>}
                                        </div>
                                        <Status stockItem={stockItem} />
                                    </Col>
                                </React.Fragment>}
                        </Row>
                        {showLabelModal && <Confirm title={AFFIX_LABELS_TITLE} text={getAffixLabelsText(showStockItem, showStockBox)} buttonText="Confirm" buttonVariant="primary" handleConfirmClose={this.handleShowHideLabelModal} handleConfirmAction={this.affixLabels} />}
                    </Container>}
            </React.Fragment>
        );
    }
}

export default StockConsignment;