import React, { Component } from 'react';
import { Card, Table, Row, Col, Container } from 'react-bootstrap';
import { RetailerOrderSubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import UnprocessedOrderRow from './UnprocessedOrderRow';
import { GET, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { ErrorAlert } from '../../common/Alert';
import Pagination from '../../common/Tables/Pagination';

class UnsuccessfulOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 1,
            pageIndex: 1,
            orders: [],
            loading: true,
            warning: "",
            showCancellationConfirmationModal: false,
            currentItemID: '',
            currentItemSource: '',
            showError: false,
            showSuccess: false
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchUnsuccessfulOrders()]);
        this.setState({ loading: false });
    }

    fetchUnsuccessfulOrders = async () => {
        const { pageIndex } = this.state;
        let url = new URL(Endpoints.RETAILER.GET.UNSUCCESSFUL);
        url.searchParams.append("pageIndex", pageIndex)
        return GET(url)
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = null;
                this.setState({ orders: data.orders ? data.orders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            })
            .catch(e => this.setState({ warning: e.message }));
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchUnsuccessfulOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchUnsuccessfulOrders);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchUnsuccessfulOrders);
    }

    renderUnsuccessfulOrderTable() {
        const { orders, pageIndex, pageCount } = this.state;
        return (
            <Card>
                <div className="table-responsive">
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th>Identifier</th>
                                <th>Source</th>
                                <th>Date Created</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.length ? orders.map(order => <UnprocessedOrderRow key={order.id} order={order} />) : <p className="ml-3 my-3"><strong>No unsuccessful orders available.</strong></p>}
                        </tbody>
                    </Table>
                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                </div>
            </Card>
        );
    }

    render() {
        const { loading, warning } = this.state;

        return (
            <React.Fragment>
                <Header title="Orders" subNavList={RetailerOrderSubNavList} activeKey="Unsuccessful Orders" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                        {this.renderUnsuccessfulOrderTable()}
                    </Container>}
            </React.Fragment>
        )
    }
}

export default UnsuccessfulOrders;