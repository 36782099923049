import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Form, FormLabel, FormControl, FormGroup, Card, Accordion } from 'react-bootstrap';
import { extractData, GET } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';

class OrderListFilter extends React.Component {
    displayName = OrderListFilter.name;

    constructor(props) {
        super(props);

        this.state = { statusOptions: [] };
        this.fetchStatuses = this.fetchStatuses.bind(this);
    }

    componentDidMount() {
        this.fetchStatuses();
    }

    fetchStatuses() {
        return GET(Endpoints.ALL_STATUSES)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                let statuses = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ statusOptions: statuses });
            }
        );
    }

    render() {
        let { id, status, datefrom, dateto, onSearch, onFilterChange } = this.props;
        let statusOptions = this.state.statusOptions || [];
        let statusOptionElements = statusOptions.map((s) => <option key={s.id} value={s.id}>{s.name}</option>);

        return (<Card id="collapsible-panel-example-2">
            <Card.Heading style={{ textAlign: "center" }}><Card.Title toggle>Toggle Filter</Card.Title></Card.Heading>
            <Accordion.Collapse>
                <Card.Body>
                    <Form horizontal onSubmit={onSearch}>
                        <FormGroup>
                            <Col componentClass={FormLabel} sm={2}>ID</Col>
                            <Col sm={10}><FormControl type="text" id="id" name="id" value={id} onChange={onFilterChange} /></Col>
                        </FormGroup>
                        <FormGroup>
                            <Col componentClass={FormLabel} sm={2}>Status</Col>
                            <Col sm={10}>
                                <FormControl componentClass="select" id="status" name="status" value={status} onChange={onFilterChange} placeholder="">
                                    <option />
                                    {statusOptionElements}
                                </FormControl>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col componentClass={FormLabel} sm={2}>Date from</Col>
                            <Col sm={4}><FormControl type="date" id="datefrom" name="datefrom" value={datefrom} onChange={onFilterChange} /></Col>
                            <Col componentClass={FormLabel} sm={2}>to</Col>
                            <Col sm={4}><FormControl type="date" id="dateto" name="dateto" value={dateto} onChange={onFilterChange} /></Col>
                        </FormGroup>
                        <FormGroup>
                            <Col smOffset={2} sm={10}>
                                <Button bsClass="btn btn-primary" type="submit">Apply filter</Button>
                            </Col>
                        </FormGroup>
                    </Form>
                </Card.Body>
            </Accordion.Collapse>
        </Card>);
    }
}

OrderListFilter.propTypes = {
    datefrom: PropTypes.string,
    dateto: PropTypes.string,
    id: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    status: PropTypes.string
};

export default OrderListFilter;