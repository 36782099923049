import React from 'react';
import { toLocalTimeString } from '../../../Utilities';
import { Button } from 'react-bootstrap';

const renderStatus = (statusName) => {
    switch (statusName) {
        case "Awaiting Collection":
        case "Collection Booked":
        case "Shipped":
            return <span className="text-status-green">{statusName}</span>;
        case "Received":
            return <span className="text-status-yellow">{statusName}</span>;
        case "Awaiting Fulfilment":
        case "Fulfilment In Progress":
        case "Picking In Progress":
        case "Packing In Progress":
            return <span className="text-status-blue">{statusName}</span>;
        default:
            return <span className="text-status-grey">{statusName}</span>;
    }
}

const OrderRow = ({ order, handleViewOrderClick }) => {
    return (
        <tr>
            <td>{order.customerReference}</td>
            <td>{order.parcelReferences.length > 0 ? order.parcelReferences.join(', ') : "N/A"}</td>
            <td>{order.customerOrganisationPersonName}</td>
            <td>{toLocalTimeString(order.createdDate)}</td>
            <td>{renderStatus(order.status.name)}</td>
            <td className="text-center py-2">
                <Button className="link-button" variant="secondary" size="sm" onClick={handleViewOrderClick}>View Order</Button>
            </td>
        </tr>
    );
};

export default OrderRow;