import React, { Component } from 'react';
import { Container, Row, Card, Image, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../../common/Footer';

class RegistrationResult extends Component {

    render() {
        return (
            <Container className="h-100">
                <Row className="justify-content-center row-form-adjustedheight">
                    <Col md={6} className="align-self-center">
                        <Card className="p-5">
                            <Image src='Images/logo.png' className="mx-auto mb-5 image-card" />
                            <h5 className="text-center mb-2">Verify Your Email to Complete Registration</h5>
                            <p className="mb-0">We have sent a verification email, follow the link in the email to verify your account. You are unable to login until your email has been verified.</p>
                            <Image src='Images/email.png' className="mx-auto mb-3 image-card" />
                            <Link to='/' className="link-button">
                                <Button variant="primary" size="lg" block>Login</Button>
                            </Link>
                            <p className="text-center mt-4">Have you not received a verification email? Contact us on <a href="mailto:support@selazar.com">support@selazar.com</a></p> 
                        </Card>
                    </Col>
                </Row>
                <Footer />
            </Container>
        );
    }
}
export default RegistrationResult;