import React, { Component } from 'react';
import Header from '../../../common/Header';
import { PUT, extractData, GET, DELETE } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import { Link } from 'react-router-dom';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { ProductsSubNavList } from '../../../common/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import FormValidator from '../../../common/FormValidator';
import BundleDetails from './bundlewizard/BundleDetails';
import ProductSelection from './bundlewizard/ProductSelection';
import { SuccessAlert, ErrorAlert, WarningAlert } from '../../../common/Alert';
import LoadingBar from '../../../common/LoadingBar';
import Confirm from '../../../common/Confirm';
import './Bundles.scss';

const DELETE_MODAL_TITLE = 'Confirm Deletion';
const DELETE_MODAL_TEXT = 'Are you sure you want to delete ';
const DELETE_MODAL_CANCEL = 'No, do not delete this bundle';
const DELETE_MODAL_OK = 'Delete Bundle';
const NO_PRODUCTS = "Select the products for this bundle before you continue";
const INVALID_QUANTITY = "You need to add more than one item to create a bundle";
const MISSING_BUNDLE_WARNING_TITLE = "Bundle Information";
const MISSING_BUNDLE_WARNING = "We are currently unable to find a matching product bundle. Please go back and try again.";

const MINIMUM_PRICE = 0;
const MAXIMUM_PRICE = 50000;

class Bundle extends Component {
    constructor(props) {
        super(props);

        this.bundleDetailsValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a name for your bundle'
            },
            {
                field: 'skuCode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Enter a bundle SKU'
            },
            {
                field: 'price',
                method: 'isFloat',
                validWhen: true,
                optional: true,
                args: [{ min: MINIMUM_PRICE, max: MAXIMUM_PRICE }],
                message: `Enter a value between '${MINIMUM_PRICE}' and '${MAXIMUM_PRICE}'`
            }
        ]);

        this.state = {
            loading: true,
            items: [],
            unfilteredItems: [],
            pageIndex: 1,
            pageCount: 1,
            currentPage: [],
            bundleDetailsValidation: this.bundleDetailsValidator.valid(),
            itemSelectionValidation: { isValid: true },
            message: '',
            success: false,
            error: false,
            displaySummary: false,
            showDeleteModal: false,
            showEditBundleDetails: false,
            showEditProductSelection: false,
            productBundleID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            allowEdit: (this.props.location.state && this.props.location.state.allowEdit),
            bundleDetails:  { },
            selectedItems: { productBundleCompanyItems: [] },
            filter: {
                searchText: "",
                sortBy: "",
                pagingRequired: true
            }
        };
    }

    fetchProductBundle = async () => {
        const { productBundleID } = this.state;
        
        await GET(Endpoints.STOCK.GET.PRODUCT_BUNDLE + productBundleID)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const productBundle = extractData(result);
                const warningTitle = MISSING_BUNDLE_WARNING_TITLE;
                const warning = productBundle ? null : MISSING_BUNDLE_WARNING;
                const selectedItems = productBundle ? { productBundleCompanyItems: productBundle.productBundleCompanyItems } : null;
                this.setState({ 
                    bundleDetails: productBundle, 
                    selectedItems: selectedItems, 
                    warning: warning, 
                    warningTitle: warningTitle 
                });
            });
    }

    async componentDidMount() {
        await this.fetchProductBundle();
        this.setState({ loading: false });
    }

    renderEditButtons = () => (
            <div className="mt-4">
                <div className="float-right">
                    <Button variant="link" className="button-nav-link mr-3" onClick={this.undoChanges}>Cancel</Button>
                    <Button variant="primary" onClick={this.handleSubmit}>Save Changes</Button>
                </div>
            </div>
        );

    renderBreadcrumbs = (isDetails) => {
        return(
            <div className="mb-3" >
                <Button variant="link" className="breadcrumb-link p-0" onClick={this.handleCancel}>
                    Bundles
                </Button>
                &nbsp;<FontAwesomeIcon className="mx-1" icon={faAngleRight} />&nbsp;
                <Button variant="link" className="breadcrumb-link p-0" onClick={this.undoChanges}>
                    Bundle Details
                </Button>
                &nbsp;<FontAwesomeIcon className="mx-1" icon={faAngleRight} />&nbsp;
                {isDetails ? <span>Edit Bundle Details</span> : <span>Edit Bundle Products</span>}
            </div>
        );
    }

    validateBundleDetails = async (bundleDetails) => {
        const validation = this.bundleDetailsValidator.validate(bundleDetails);
        this.setState({ bundleDetailsValidation: validation });
        return validation;
    }

    validateItemSelection = async (selectedItems) => {
        let validation = { isValid: true };
        const total = selectedItems.productBundleCompanyItems.flatMap(pb => pb.quantity).reduce((a, b) => a + b, 0);

        if (selectedItems.length === 0) {
            validation.isValid = false;
            this.setState({ itemSelectionValidation: validation, message: NO_PRODUCTS });
        }
        
        const invalid = selectedItems.productBundleCompanyItems.findIndex(i => i.quantity == 0) > -1;
        if (total <= 1 || invalid) {
            validation.isValid = false;
            this.setState({ itemSelectionValidation: validation, message: INVALID_QUANTITY });
        }

        this.setState({ itemSelectionValidation: validation });
        return validation;
    }

    handleBundleDetailsInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            bundleDetails: {
                ...prevState.bundleDetails,
                ...{ [name]: value }
            },
        }));
    }

    handleProductSelectionInputChange = (e) => {
        const { value, type } = e.target;
        const companyItemID = e.target.id.split(':').pop();
        const sku = e.target.name || null;
        const { items, bundleDetails } = this.state;
        let { productBundleCompanyItems } = this.state.selectedItems;

        const index = productBundleCompanyItems.findIndex(pb => pb.companyItemID === companyItemID && pb.skuCode === sku);
        if (index > -1) {
            if (type === "checkbox") productBundleCompanyItems.splice(index, 1);
            else productBundleCompanyItems[index].quantity = parseInt(value);
        } else {
            const product = items.find(i => i.companyItemID === companyItemID && i.sku === sku)
            productBundleCompanyItems.push({ companyItemID: product.companyItemID, skuCode: product.sku, quantity: 0, productBundleID: bundleDetails.id });
        }

        this.setState(prevState => ({
            ...prevState,
            selectedItems: {
                productBundleCompanyItems: productBundleCompanyItems
            }
        }));
    }

    onReset = async (e) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ searchText: "", sortBy: "" },
            }
        }), await this.fetchStockItems);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchStockItems);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchStockItems);
    }

    onFilterChange = async (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    fetchStockItems = async () => {
        this.setState({ loading: true });
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.STOCK.GET.BY);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No products to show." : null;
                this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            });
    }

    fetchStockItemsUnfiltered = async () => {
        this.setState({ loading: true });
        let url = new URL(Endpoints.STOCK.GET.BY);
        url.searchParams.append("pagingRequired", false)

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No products to show." : null;
                this.setState({ unfilteredItems: data.items ? data.items : [], loading: false, warning: warning });
            });
    }

    showEditBundleDetails = () =>{
        this.setState({ showEditBundleDetails: true, error: false  });
    }

    showEditProductSelection = async () =>{
        await this.fetchStockItems();
        this.setState({ showEditProductSelection: true, error: false  });
    }

    undoChanges = async () => {        
        this.setState({ loading: true });
        await this.fetchProductBundle();
        this.setState({ showEditProductSelection: false, showEditBundleDetails: false, error: false, loading: false, success: false });
    }

    toggleSummary = async (show) => {
        await this.fetchStockItemsUnfiltered();
        this.setState({ displaySummary: show });
    }

    validate = async () => {
        const { bundleDetails, selectedItems, showEditBundleDetails, showEditProductSelection } = this.state;
        let validate = { isValid: true };
        this.setState({ loading: true });

        if (showEditBundleDetails) {
            validate = await this.validateBundleDetails(bundleDetails);
        }
        else if (showEditProductSelection) {
            validate = await this.validateItemSelection(selectedItems);
        }
        
        return validate;
    };

    handleSubmit = async () => {
        const { bundleDetails, selectedItems } = this.state;

        const productBundle = Object.assign(bundleDetails, selectedItems);

        const validation = await this.validate();

        if(validation.isValid) {
            return await PUT(Endpoints.STOCK.PUT.PRODUCT_BUNDLE, productBundle)
                .then(r => r.json())
                .then(result => {
                    const data = extractData(result)
                    if (data) {
                        this.setState({ loading: false, success: true, error: false, bundleDetails: data, selectedItems: { productBundleCompanyItems: data.productBundleCompanyItems}, showEditBundleDetails: false, showEditProductSelection: false })
                    } else this.setState({ success: false, error: true, message: result.message, loading: false });
                });
        }
    }

    handleShowDeleteModal = () => this.setState({ showDeleteModal: true });

    handleDeleteModalClose = () => this.setState({ showDeleteModal: false });

    handleDeleteModalConfirm = () => this.handleDelete();

    handleDelete = async () => {
        const { bundleDetails } = this.state;
        this.setState({ loading: true });

        return await DELETE(Endpoints.STOCK.DELETE.PRODUCT_BUNDLE + bundleDetails.id)
            .then(r => r.json())
            .then(result => {
                const data = extractData(result)
                if (data) {
                    this.handleCancel();
                } else this.setState({ success: false, error: true, message: result.message, showDeleteModal: false, loading: false  });
            });
    }

    handleCancel = () => {        
        this.props.history.push({ pathname: `/supplier/products/bundles` });
    }

    render() {
        const { allowEdit, warning, warningTitle, bundleDetails, selectedItems, items, unfilteredItems, showEditBundleDetails, showEditProductSelection, bundleDetailsValidation, itemSelectionValidation, success, pageIndex, pageCount, displaySummary, filter, error, message, showDeleteModal, loading } = this.state;
        const { hideDetails, fromOrderDetails  } = this.props;
        const EditButtons = this.renderEditButtons();

        if(showEditBundleDetails){
            return(
                <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Bundles" />
                <Container fluid>
                    {this.renderBreadcrumbs(true)}
                    {error &&  <Row><Col sm={12} md={6}><ErrorAlert errorMessage={message} /></Col></Row>}
                    <Row>
                        <Col sm={12} md={6}>
                            <BundleDetails bundleDetails={bundleDetails} bundleDetailsValidation={bundleDetailsValidation} handleBundleDetailsInputChange={this.handleBundleDetailsInputChange} isEdit={true}/>    
                            {EditButtons}       
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
            );
        }

        if(showEditProductSelection){
            return(
                <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Bundles" />
                <Container fluid>
                    {this.renderBreadcrumbs(false)}
                    {error &&  <Row><Col sm={12} md={6}><ErrorAlert errorMessage={message} /></Col></Row>}
                    <ProductSelection items={items} unfilteredItems={unfilteredItems} selectedItems={selectedItems} handleProductSelectionInputChange={this.handleProductSelectionInputChange} onNext={this.onNext} onPrevious={this.onPrevious}
                        onPageSelect={this.onPageSelect} onSearch={this.onSearch} onFilterChange={this.onFilterChange} onReset={this.onReset} pageIndex={pageIndex} pageCount={pageCount}
                        displaySummary={displaySummary} toggleSummary={this.toggleSummary} itemSelectionValidation={itemSelectionValidation} message={message} isEdit={true} {...filter} />
                    {displaySummary ?
                    <div className="mt-4" /> :
                    EditButtons
                    }  
                </Container>
            </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Bundles" />
                <Container fluid>
                    <div className="mb-3" >
                        <Link to="/supplier/products/bundles">
                            <Button variant="link" className="breadcrumb-link p-0" onClick={hideDetails}>
                                Bundles
                                {/* {fromOrderDetails ? "Order Details" : "Bundles" } */}
                            </Button>
                        </Link>
                        &nbsp;<FontAwesomeIcon className="mx-1" icon={faAngleRight} />&nbsp;
                            <span>Bundle Details</span>
                    </div>
                    {warning ? <WarningAlert warningTitle={warningTitle} warningMessage={warning} /> :
                        <React.Fragment>
                        <h5>{bundleDetails.name}</h5>
                        {success &&  <Row><Col sm={12} md={6}><SuccessAlert successMessage={`You have successfully updated ${bundleDetails.name}.`} /></Col></Row>}
                        {error &&  <Row><Col sm={12} md={6}><ErrorAlert errorMessage={message} /></Col></Row>}
                        {loading ? 
                        <LoadingBar /> :                        
                            <React.Fragment>
                                <div className="mt-3">
                                    <Confirm title={DELETE_MODAL_TITLE} text={`${DELETE_MODAL_TEXT} ${bundleDetails.name}?`} buttonText={DELETE_MODAL_OK} handleConfirmClose={this.handleDeleteModalClose} handleConfirmAction={this.handleDeleteModalConfirm} show={showDeleteModal} cancelText={DELETE_MODAL_CANCEL} size="lg" buttonVariant="danger"/>
                                    <h6>Bundle Details</h6>
                                    <dl>
                                        <dt className="mb-0">Bundle Name</dt>
                                        <dd className="mb-1">{bundleDetails.name}</dd>
                                        <dt className="mb-0">Bundle Description</dt>
                                        <dd className="mb-1">{bundleDetails.description ? bundleDetails.description : "N/A" }</dd>
                                        <dt className="mb-0">Bundle SKU</dt>
                                        <dd className="mb-1">{bundleDetails.skuCode ? bundleDetails.skuCode : "N/A"}</dd>
                                        <dt className="mb-0">Bundle Price (£)</dt>
                                        <dd className="mb-1">{bundleDetails.price ? bundleDetails.price.toFixed(2) : "N/A"}</dd>
                                    </dl>
                                    {allowEdit && <Button variant="link" className="p-0" onClick={this.showEditBundleDetails}>Edit Bundle Details</Button>}
                                </div>
                                <div className="mt-4 mb-4">
                                    <h6>Product Selection</h6>
                                    {selectedItems.productBundleCompanyItems.map((productBundleCompanyItem, i) =>
                                        <React.Fragment key={i}>
                                            <dl>
                                                <dt className="mb-0">Product {i + 1}</dt>
                                                <dd className="mb-0">{productBundleCompanyItem.companyItem.item.name}</dd>
                                                <dd className="mb-0">EAN: {productBundleCompanyItem.companyItem.item.ean ? productBundleCompanyItem.companyItem.item.ean : "N/A"}</dd>
                                                <dd className="mb-0">SKU: {productBundleCompanyItem.skuCode ? productBundleCompanyItem.skuCode : "N/A"}</dd>
                                                <dd className="mb-0">SUID: {productBundleCompanyItem.companyItem.item.selazarUniqueID ? productBundleCompanyItem.companyItem.item.selazarUniqueID : "N/A"}</dd>
                                                <dd className="mb-0">Quantity: {productBundleCompanyItem.quantity}</dd>
                                            </dl>
                                        </React.Fragment>
                                    )}
                                    {allowEdit && <Button variant="link" className="p-0" onClick={this.showEditProductSelection}>Edit Product Selection</Button>}
                                </div>
                                {allowEdit && <Button variant="danger" onClick={this.handleShowDeleteModal}>Delete Bundle</Button>}
                            </React.Fragment>
                        }
                        </React.Fragment>         
                    }
                </Container>
            </React.Fragment>
        )
    }
}

export default Bundle;