import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Endpoints from "../../common/Endpoints";
import { checkPermission } from '../../../Utilities';
import { DELETE, PUT } from '../../../Consumer';
import { Col, Row, ButtonGroup, ButtonToolbar } from 'react-bootstrap';

class ServiceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyService: this.props.companyService
        };
        this.reload = this.reload.bind(this);

    }

    deactivateCompanyService() {
        const { companyService } = this.state;

        let endpoint = companyService.active ? Endpoints.FREEZE_SERVICE : Endpoints.DEFROST_SERVICE;

        PUT(endpoint + companyService.id)
            .then(response => response.json())
            .then(this.reload())
            .catch(error => alert(error));
    }

    reload() {
        return this.props.reload();
    }

    deleteService() {
        const { companyService } = this.state;

        DELETE(Endpoints.DELETE_SERVICE + companyService.id)
            .then(response => response.json())
            .then(this.reload())
            .catch(error => alert(error));
    }

    renderGlyph(value) {
        if (value) {
            return <span>ok</span>;
        }
        else
        {
            return <span>remove</span>;
        }
    }

    renderActive(value) {
        if (value) {
            return <span className="label label-success" >Active</span>;
        }
        else {
            return <span className="label label-danger" >Inactive</span>;
        }
    }

    render() {
        const { companyService } = this.state;

        let message = (companyService.active) ? "Are you sure you wish to deactivate this service?" : "Are you sure you wish to activate this service?";

        let editButton = checkPermission("EditCompanyService", <Link className="btn btn-warning card-button" from={'/courier/services'} to={{ pathname: '/courier/services/edit', state: { companyServiceID: companyService.id } }}></Link>);
        let deactivateButton = checkPermission("EditCompanyService", <button className="btn btn-default card-button" onClick={(e) => { if (window.confirm(message)) this.deactivateCompanyService(e); }}></button>);
        let deleteButton = checkPermission("DeleteCompanyService",  <button className="btn btn-danger card-button" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this service?')) this.deleteService(e); }}></button>);

        return (
            <div className="card">                
                <h4>{companyService.service.name}</h4>
                <span>{this.renderActive(companyService.active)}</span>                    

                <div className="card-body">
                    <div className="card-text">
                        <dl>
                            <dt>Description:</dt><dd>{companyService.service.description}</dd>
                            <dt>Service code:</dt><dd>{companyService.service.serviceCode}</dd>
                            <dt>To / From:</dt><dd>{companyService.service.fromServiceRegion.name} / {companyService.service.toServiceRegion.name}</dd>
                            <dt>Price (£):</dt>
                            <dd>{companyService.currentPrice.price}</dd>
                            <dt>Service Time:</dt> <dd>{companyService.service.serviceTimeName}</dd>
                            <dt>Max Weight (kg):</dt> <dd>{companyService.service.maxWeight}</dd>
                            <dt>Max Size (cm):</dt> <dd>{companyService.service.maxHeight} x {companyService.service.maxWidth} x {companyService.service.maxDepth} (H x W x D)</dd>
                            <dt>Insurance Amount (£):</dt><dd>{companyService.service.insuranceAmount}</dd>                            
                        </dl>

                        <Row className="">
                            <Col md={4}>
                                <label>Batteries:</label> 
                            </Col>
                            <Col md={2}>
                                {this.renderGlyph(companyService.service.batteries)}
                            </Col>
                            <Col md={4}>
                                <label>Liquids:</label>
                            </Col>
                            <Col md={2}>
                                {this.renderGlyph(companyService.service.liquid)}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label>Signed:</label>
                            </Col>
                            <Col md={2}>
                                {this.renderGlyph(companyService.service.signed)}
                            </Col>
                            <Col md={4}>
                                <label>Tracked:</label>
                            </Col>
                            <Col md={2}>
                                {this.renderGlyph(companyService.service.tracked)}
                            </Col>
                        </Row>
                    </div>
                    <ButtonToolbar>
                        <ButtonGroup bsSize="sm">
                            {editButton}
                            {deactivateButton}
                            {deleteButton}
                        </ButtonGroup>
                    </ButtonToolbar>

                </div>
            </div>
        );
    }
}

export default ServiceCard;