import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, FormLabel, Form, FormGroup, FormCheck, Card, Accordion, Row } from 'react-bootstrap';
class IssueFilter extends React.Component {
    displayName = IssueFilter.name;

    constructor(props) {
        super(props);

        this.clear = this.clear.bind(this);
    }

    loading(loading) {
        return this.props.onLoading(loading);
    }

    clear() {
        this.loading(true);
        return this.props.onClear();
    }

    render() {
        let { title,unresolved,resolved,read, onSearch, onFilterChange, loading } = this.props;


        return (<Card id="collapsible-panel-example-2">
            <Card.Heading style={{ textAlign: "center" }}><Card.Title toggle>Toggle Filter</Card.Title></Card.Heading>
            <Accordion.Collapse>
                <Card.Body>
                    <Form onSubmit={onSearch}>
                        <FormGroup>
                            <Row className="margin-bottom">
                                <Col md={2}>
                                    <FormLabel htmlFor="titleField">Title</FormLabel>
                                </Col>
                                <Col md={4}>
                                    <input id="titleField" type="text" name="title" value={title} onChange={onFilterChange} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row className="margin-bottom">
                                <Col md={1}>
                                    <FormLabel htmlFor="unresolvedField">Unresolved</FormLabel>
                                </Col>
                                <Col md={3}>
                                    <FormCheck id="unresolvedField" name="unresolved" checked={unresolved} onChange={onFilterChange} />
                                </Col>
                                <Col md={1}>
                                    <FormLabel htmlFor="resolvedField">Resolved</FormLabel>
                                </Col>
                                <Col md={3}>
                                    <FormCheck id="resolvedField" name="resolved" checked={resolved} onChange={onFilterChange} />
                                </Col>
                                <Col md={1}>
                                    <FormLabel htmlFor="readField">Read</FormLabel>
                                </Col>
                                <Col md={3}>
                                    <FormCheck id="readField" name="read" checked={read} onChange={onFilterChange} />
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <div className="card-button-row">
                                <Button bsClass="btn btn-primary card-button" type="submit" disabled={loading}>Apply filter</Button>
                                <Button bsClass="btn btn-default card-button" onClick={this.clear} disabled={loading}>Clear filter</Button>
                            </div>
                        </FormGroup>
                    </Form>
                </Card.Body>
            </Accordion.Collapse>
        </Card>);
    }
}

IssueFilter.propTypes = {
    read: PropTypes.bool,
    resolved: PropTypes.bool,
    title: PropTypes.string,
    unresolved: PropTypes.bool
};

export default IssueFilter;