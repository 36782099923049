import React from 'react';
import { Card, ListGroup, Image } from 'react-bootstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './DashboardCards.scss'

const setStatusClass = (status) => status === "INCREASE" ? "card-status-increase" : status === "DECREASE" ? "card-status-decrease" : "card-status-nochange";
const setStatusText = (status) => status === "INCREASE" ? "Increased" : status === "DECREASE" ? "Decreased" : "No change";
const setStatusIcon = (status) => status === "INCREASE" ? faChevronUp : status === "DECREASE" ? faChevronDown : faChevronRight;

const DefaultCard = ({ title, count, text, status, statusText }) => (
    <Card className="default-card">
        <Card.Header>{title}</Card.Header>
        <Card.Body className="pt-2">
            <Card.Text className="card-text-count">{count}</Card.Text>
            <Card.Text>{text}</Card.Text>
            {status && <Card.Text className={`${setStatusClass(status)} mt-3`}><FontAwesomeIcon icon={setStatusIcon(status)} className="mr-2" />{setStatusText(status)} {statusText}</Card.Text>}
        </Card.Body>
    </Card>
);

const ListCard = ({ title, items = [] }) => (
    <Card className="list-card">
        <Card.Header>{title}</Card.Header>
        <ListGroup className="h-100">
            {items.length ?
                items.map((item, i) => <ListGroup.Item key={item.pickAndPackCompanyItemID}><span className="mr-2">{i + 1}.</span> {item.name}</ListGroup.Item>) :
                <div className="my-auto pb-5">
                    <Image src='Images/search-result.png' className="mx-auto d-block" width="140px" />
                    <p className="text-noresults text-center">No data is available at the minute</p>
                </div>}
        </ListGroup>
    </Card>
);

const OrderListCard = ({ title, orders = [] }) => (
    <Card className="order-list-card">
        <Card.Header>{title}</Card.Header>
        <ListGroup className="h-100">
            {orders.length ?
                orders.map(order =>
                    <ListGroup.Item key={order.id}>
                        <p className="title">{order.id}</p>
                        <p className="subtitle">Customer Name</p><p className="subtitle">Internal Reference</p>
                        <p className="data">{order.customerOrganisationPersonName}</p><p className="data">{order.internalReference}</p>
                        <span className="text-status-red">Processing Failed</span>
                    </ListGroup.Item>) :
                <div className="my-auto pb-5">
                    <Image src='Images/search-result.png' className="mx-auto d-block" width="140px" />
                    <p className="text-noresults text-center">No data is available at the minute</p>
                </div>}
        </ListGroup>
    </Card>
);

const pieChartOptions = {
    legend: {
        position: 'left',
        labels: {
            boxWidth: 12
        }
    }
}

const PieChartCard = ({ title, data }) => (
    <Card className="piechart-card">
        <Card.Header>{title}</Card.Header>
        {data ?
            <div className="p-2">
                <Doughnut data={data} options={pieChartOptions} />
            </div> :
            <div className="p-2 mt-4">
                <Image src='Images/search-result.png' className="mx-auto d-block" width="140px" />
                <p className="text-noresults text-center">No data is available at the minute</p>
            </div>}
    </Card>
);

const barOptions = {
    legend: {
        display: false
    },
    scales: {
        xAxes: [
            {
                display: false,
            }
        ],
        yAxes: [
            {
                display: false,
            }
        ],
    }
}

const BarCard = ({ title, data }) => (
    <Card className="bar-card">
        <Card.Header>{title}</Card.Header>
        {data ?
            <div className="p-2">
                <Bar data={data} options={barOptions} height={93} />
            </div> :
            <div className="p-2 mt-4">
                <Image src='Images/search-result.png' className="mx-auto d-block" width="140px" />
                <p className="text-noresults text-center">No data is available at the minute</p>
            </div>}
    </Card>
);

export {
    DefaultCard,
    ListCard,
    OrderListCard,
    PieChartCard,
    BarCard
}