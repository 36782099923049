import React, { Component } from 'react';
import Endpoints from '../common/Endpoints';
import { GET } from '../../Consumer';
import { Col, Container, Row, Card } from 'react-bootstrap';

const code = "instance";

class PickAndPackStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ordersAwaitingFulfilment: null,
            ordersAwaitingCollection: null,
            authorised: false
        }
    }

    componentDidMount() {
        this.getOrdersAwaitingFulfilment();
        this.getOrdersAwaitingCollection();
    }

    getOrdersAwaitingFulfilment = () => {
        return GET(Endpoints.FULFILMENT.GET.NUMBER_OF_ORDERS + '/' + code)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    this.setState({
                        authorised: false
                    });
                }
            })
            .then(result => {
                if (!result.error) {
                    this.setState({
                        ordersAwaitingFulfilment: result.data,
                    });
                }
            })
            .catch((error) => console.log(error));
    }

    getOrdersAwaitingCollection = () => {
        return GET(Endpoints.FULFILMENT.GET.NUMBER_OF_COLLECTIONS + '/' + code)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    this.setState({
                        authorised: false
                    });
                }
            })
            .then(result => {
                if (!result.error) {
                    this.setState({
                        ordersAwaitingCollection: result.data,
                        authorised: true
                    });
                }
            })
            .catch((error) => console.log(error));
    }

    render() {
        const { ordersAwaitingFulfilment, ordersAwaitingCollection, authorised } = this.state;
        return (
            authorised &&
            <Container className="h-100">
                <Row className="justify-content-center row-form-adjustedheight">
                    <Col md={6} className="align-self-center">
                        <Card className="p-5">
                            <h1 className="mb-4 text-center">Tracking</h1>
                            <h5 className="mb-5 text-center">Order Statuses From This Morning</h5>
                            <p className="mb-2 mx-4">Orders Awaiting Pick and Pack: <span className="float-right"><strong>{ordersAwaitingFulfilment}</strong></span></p>
                            <p className="mb-2 mx-4">Orders Completed: <span className="float-right"><strong>{ordersAwaitingCollection}</strong></span></p>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )

    };
}

export default PickAndPackStats;