import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

class Shipping extends Component {

    renderLabelOptions = (data, labelChoice, handleInputChange) => {
        return (<React.Fragment>
            <p className="title">Which labels do you want Selazar to affix?</p>
            {(data.requiresGTIN !== 'true') && <FormGroup className="custom-control custom-checkbox mb-0">
                <input className="custom-control-input" type="checkbox" id="stockBoxLabelField" name="stockBox" checked={labelChoice.stockBox} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="stockBoxLabelField">Stock box labels(s)</FormLabel>
            </FormGroup>}
            <FormGroup className="custom-control custom-checkbox">
                <input className="custom-control-input" type="checkbox" id="productLabelField" name="product" checked={labelChoice.product} onChange={handleInputChange} />
                <FormLabel className="custom-control-label" htmlFor="productLabelField">Product label(s)</FormLabel>
            </FormGroup>
        </React.Fragment>);
    }

    render() {
        const { data, handleInputChange, labelChoice, supplierLabelValidation } = this.props;
        const labelOption = (data.supplierPrintedLabels === 'false') ? this.renderLabelOptions(data, labelChoice, handleInputChange) : "";

        return (
            <React.Fragment>
                <h5 className="mt-3">Shipping & Labelling</h5>
                <h6>Shipping</h6>
                <FormGroup>
                    <FormLabel htmlFor="shippingReferenceField">Shipping Reference <span className="form-label-optional">- optional</span></FormLabel>
                    <FormControl id="shippingReferenceField" type="text" name="shippingReference" value={data.shippingReference} onChange={handleInputChange} />
                </FormGroup>

                <h6 className="mt-4">Labelling</h6>
                <p className="title">Would you like Selazar to affix any necessary stock consignment labels for you upon receipt?</p>
                <div className="form-input-description">
                    <p>Please be aware that by selecting this labelling service the consignment will attract additional processing costs.</p>
                </div>
                <FormGroup className="custom-control custom-radio mb-0">
                    <input className="custom-control-input" type='radio' id='radio-3' name='supplierPrintedLabels' value={'true'}
                        checked={data.supplierPrintedLabels === 'true'} onChange={handleInputChange} />
                    <FormLabel className="custom-control-label" htmlFor="radio-3">No, I will affix all labels</FormLabel>
                </FormGroup>
                <FormGroup className="custom-control custom-radio">
                    <input className="custom-control-input" type='radio' id='radio-4' name='supplierPrintedLabels' value={'false'}
                        checked={data.supplierPrintedLabels === 'false'} onChange={handleInputChange} />
                    <FormLabel className="custom-control-label" htmlFor="radio-4">Yes, affix labels for me</FormLabel>
                </FormGroup>
                {labelOption}
                <span className="text-danger">{supplierLabelValidation.supplierPrintedLabels.message}</span>
            </React.Fragment>
        )
    }
}

export default Shipping;