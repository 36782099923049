import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, Modal, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import ReactDropzone from 'react-dropzone';
import { ProductsSubNavList } from '../../../common/Constants';
import { GET, POST, POST_FILE, DELETE, extractData } from '../../../../Consumer';
import { defaultGuid } from '../../../../Utilities';
import update from 'immutability-helper';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import FormValidator from '../../../common/FormValidator';
import Header from '../../../common/Header';
import Stepper from '../../../common/Wizard/Stepper';
import StepperButtons from '../../../common/Wizard/StepperButtons';
import ProductOverview from './add/ProductOverview';
import ProductDetails from './add/ProductDetails';
import Pricing from './add/Pricing';
import UniqueIdentifiers from './add/UniqueIdentifiers';
import PackagingPreferences from './add/PackagingPreferences';
import ConfirmProduct from './add/ConfirmProduct';
import Success from './add/Success';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const PFP_MAX_WEIGHT = 5;
const PFP_MAX_VOLUME = 20250;

const steps = ["Product Overview", "Product Details", "Sales & Pricing", "Unique Identifiers", "Packaging Preferences", "Confirm Product"];
const cancelLink = "/supplier/products"

const getItemVolume = (depth, width, height) => parseFloat(depth) * parseFloat(width) * parseFloat(height);

class AddProduct extends Component {
    constructor(props) {
        super(props);

        this.productOverviewValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Name is required'
            },
            {
                field: 'description',
                method: 'isEmpty',
                validWhen: false,
                message: 'Description is required'
            },
            {
                field: 'brand',
                method: 'isEmpty',
                validWhen: false,
                message: 'Brand is required'
            },
            {
                field: 'categoryID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Category is required'
            }
        ]);

        this.productDetailsValidator = new FormValidator([
            {
                field: 'height',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Height must be greater than 0'
            },
            {
                field: 'width',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Width must be greater than 0'
            },
            {
                field: 'depth',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Depth must be greater than 0'
            },
            {
                field: 'weight',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Weight must be greater than 0'
            },
            {
                field: 'countryCodeOfManufacture',
                method: 'isEmpty',
                validWhen: false,
                message: 'Country of Manufacture is required'
            },
            {
                field: 'commodityCode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Commodity Code is required'
            }
        ]);

        this.priceValidator = new FormValidator([
            {
                field: 'price',
                method: 'isFloat',
                validWhen: true,
                args: [{ min: 0.01 }],
                message: 'Price must be greater than 0'
            }
        ]);

        this.expectedWeeklySalesValidator = new FormValidator([
            {
                field: 'expectedWeeklySales',
                method: 'isInt',
                validWhen: true,
                args: [{ min: 0 }],
                message: 'Expected Weekly Sales is required'
            },
        ]);

        this.eanValidator = new FormValidator([
            {
                field: 'ean',
                method: 'isLength',
                validWhen: true,
                args: [{ min: 0, max: 14 }],
                message: 'EAN cannot exceed 14 characters'
            }
        ]);

        this.skuValidator = new FormValidator([
            {
                field: 'code',
                method: 'isEmpty',
                validWhen: false,
                message: 'Code is required'
            },
            {
                field: 'description',
                method: 'isEmpty',
                validWhen: false,
                message: 'Description is required'
            },
        ]);

        const user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            files: [],
            skuFiles: [],
            loading: true,
            activeStep: 0,
            categories: [],
            companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            error: false,
            companyItem: {
                id: defaultGuid,
                editable: false,
                active: false,
                companyID: user.companyID,
                itemID: defaultGuid,
                expectedWeeklySales: "0",
                isPrePacked: false,
                requiresBoxPackaging: false,
                requiresBubbleWrapPackaging: false,
                requiresPaperPackaging: false,
                requiresEcoPackaging: false,
                isFragile: false,
                pickFromPallet: false,
                stockKeepingUnits: [],
                item: {
                    id: defaultGuid,
                    name: '',
                    description: '',
                    brand: '',
                    height: 0.00,
                    width: 0.00,
                    depth: 0.00,
                    weight: 0.00,
                    categoryID: '',
                    ean: '',
                    selazarUniqueID: null,
                    jan: null,
                    upc: null,
                    isbn: null,
                    mpn: null,
                    countryCodeOfManufacture: '',
                    commodityCode: '',
                    itemImages: []
                },
                currentPrice:
                {
                    id: defaultGuid,
                    companyItemID: defaultGuid,
                    price: 0.00,
                    active: true
                },
                noScan: false,
            },
            productOverviewValidation: this.productOverviewValidator.valid(),
            productDetailsValidation: this.productDetailsValidator.valid(),
            priceValidation: this.priceValidator.valid(),
            expectedWeeklySalesValidation: this.expectedWeeklySalesValidator.valid(),
            eanValidation: this.eanValidator.valid(),
            skuValidation: this.skuValidator.valid(),
            message: '',
            show: false,
            currentSku: {
                id: defaultGuid,
                code: "",
                description: "",
                itemImageID: "",
                companyItemID: "",
                quantity: 0
            },
            dropzoneEnabled: true,
            skuDropzoneEnabled: true,
            currentSkuIndex: null,
            noPackagingNeeded: true,
            showBarcodeWarning: false
        };

    }

    async componentDidMount() {
        await this.getCategories();
        this.setState({ loading: false });
    }

    getCategories = () => {
        return GET(Endpoints.CATEGORY.GET.ALL)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const categories = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ categories: categories });
            });
    }

    updateNextStep = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1
        }));
    }

    handleNext = async () => {
        const { activeStep, companyItem, files } = this.state;

        if (activeStep === 0) {
            const validation = this.productOverviewValidator.validate(companyItem.item);
            this.setState({ productOverviewValidation: validation });
            validation.isValid && this.updateNextStep();
        } else if (activeStep === 1) {
            const validation = this.productDetailsValidator.validate(companyItem.item);
            this.setState({ productDetailsValidation: validation });
            validation.isValid && this.updateNextStep();
        } else if (activeStep === 2) {
            const priceValidation = this.priceValidator.validate(companyItem.currentPrice);
            this.setState({ priceValidation: priceValidation });

            const expectedWeeklySalesValidation = this.expectedWeeklySalesValidator.validate(companyItem);
            this.setState({ expectedWeeklySalesValidation: expectedWeeklySalesValidation });

            (priceValidation.isValid && expectedWeeklySalesValidation.isValid) && this.updateNextStep();
        } else if (activeStep === 3) {
            const eanValidation = this.eanValidator.validate(companyItem.item);
            this.setState({ eanValidation: eanValidation });
            let validIdentifiers = eanValidation.isValid;

            if (companyItem.noScan) {
                if (companyItem.stockKeepingUnits.length > 0) {
                    const noImageSku = companyItem.stockKeepingUnits.find(s => s.itemImageID === null || s.itemImageID === "");
                    if (noImageSku !== undefined) {
                        validIdentifiers = false;
                        this.setState({ showBarcodeWarning: true });
                    } else this.setState({ showBarcodeWarning: false });
                } else {
                    if (files.length > 0) {
                        this.setState({ showBarcodeWarning: false });
                    } else {
                        validIdentifiers = false;
                        this.setState({ showBarcodeWarning: true });
                    }
                }
            }

            validIdentifiers && this.updateNextStep();
        } else if (activeStep === 4) {
            this.updateNextStep();
        } else {
            this.setState({ loading: true });
            await this.handleSubmit();
            const { error } = this.state;

            !error && this.updateNextStep();
        }
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }));
    };

    handleInputChange = (e) => {
        let { name, value } = e.target;
        const { companyItem } = this.state;

        if (name === "weight") {
            if (value >= PFP_MAX_WEIGHT) {
                this.setPickFromPallet(true);
            } else {
                const itemVolume = getItemVolume(companyItem.item.depth, companyItem.item.width, companyItem.item.height);
                if (itemVolume < PFP_MAX_VOLUME) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (name === "height") {
            const itemVolume = getItemVolume(companyItem.item.depth, companyItem.item.width, value);
            if (itemVolume >= PFP_MAX_VOLUME) {
                this.setPickFromPallet(true);
            } else {
                if (companyItem.item.weight < PFP_MAX_WEIGHT) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (name === "width") {
            const itemVolume = getItemVolume(companyItem.item.depth, value, companyItem.item.height);
            if (itemVolume >= PFP_MAX_VOLUME) {
                this.setPickFromPallet(true);
            } else {
                if (companyItem.item.weight < PFP_MAX_WEIGHT) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (name === "depth") {
            const itemVolume = getItemVolume(value, companyItem.item.width, companyItem.item.height);
            if (itemVolume >= PFP_MAX_VOLUME) {
                this.setPickFromPallet(true);
            } else {
                if (companyItem.item.weight < PFP_MAX_WEIGHT) {
                    this.setPickFromPallet(false);
                }
            }
        }

        if (e.target.getAttribute('parent')) {
            const parent = e.target.getAttribute('parent');
            const parentValue = this.state.companyItem[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        if (name === "noScan") {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    noScan: !prevState.companyItem.noScan
                }
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                companyItem: {
                    ...prevState.companyItem,
                    ...{ [name]: value }
                }
            }));
        }
    };

    setPickFromPallet = (pickFromPallet) => {
        this.setState(prevState => ({
            companyItem: {
                ...prevState.companyItem,
                pickFromPallet: pickFromPallet,
            }
        }));
    };

    handleEditStep = (step) => {
        this.setState({ activeStep: step });
    };

    handlePackagingInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "isPrePacked") {
            if (value === "true") {
                this.setState(prevState => ({
                    noPackagingNeeded: true,
                    companyItem: {
                        ...prevState.companyItem,
                        isPrePacked: true,
                        requiresPaperPackaging: false,
                        requiresEcoPackaging: false,
                        requiresBoxPackaging: false,
                        requiresBubbleWrapPackaging: false
                    }
                }));
            } else {
                this.setState(prevState => ({
                    companyItem: {
                        ...prevState.companyItem,
                        isPrePacked: false
                    }
                }));
            }
        }

        if (name === "requiresBoxPackaging") {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    requiresBoxPackaging: value === "true" ? true : false,
                }
            }));
        }

        if (name === "requiresBubbleWrapPackaging" && value === "true") {
            this.setState(prevState => ({
                noPackagingNeeded: false,
                companyItem: {
                    ...prevState.companyItem,
                    requiresBubbleWrapPackaging: true,
                    requiresPaperPackaging: false,
                    requiresEcoPackaging: false
                }
            }));
        }

        if (name === "requiresPaperPackaging" && value === "true") {
            this.setState(prevState => ({
                noPackagingNeeded: false,
                companyItem: {
                    ...prevState.companyItem,
                    requiresPaperPackaging: true,
                    requiresBubbleWrapPackaging: false,
                    requiresEcoPackaging: false
                }
            }));
        }

        if (name === "requiresEcoPackaging" && value === "true") {
            this.setState(prevState => ({
                noPackagingNeeded: false,
                companyItem: {
                    ...prevState.companyItem,
                    requiresEcoPackaging: true,
                    requiresBubbleWrapPackaging: false,
                    requiresPaperPackaging: false
                }
            }));
        }

        if (name === "noPackagingNeeded" && value === "true") {
            this.setState(prevState => ({
                noPackagingNeeded: true,
                companyItem: {
                    ...prevState.companyItem,
                    requiresEcoPackaging: false,
                    requiresBubbleWrapPackaging: false,
                    requiresPaperPackaging: false
                }
            }));
        }

        if (name === "isFragile") {
            this.setState(prevState => ({
                companyItem: {
                    ...prevState.companyItem,
                    isFragile: value === "true" ? true : false,
                }
            }));
        }
    }

    formatInput = (e) => {
        let { name, value } = e.target;

        value = value.trim();

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.companyItem[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            companyItem: {
                ...prevState.companyItem,
                ...{ [name]: value }
            }
        }));
    }

    handleInputChangeSKU = (e) => {
        let { name, value } = e.target;
        let { currentSku } = this.state;

        if (name === 'code') {
            if (!currentSku.quantity > 0) {
                this.setStateOfInputChange(name, value)
            }
        }
        else {
            this.setStateOfInputChange(name, value)
        }
    }

    setStateOfInputChange = (name, value) => {
        this.setState(prevState => ({
            ...prevState,
            currentSku: {
                ...prevState.currentSku,
                ...{ [name]: value }
            }
        }));

    }

    handleShowForNewSKU = () => {
        const { currentSku } = this.state;
        this.setState({ show: true, skuDropzoneEnabled: true });
        currentSku.quantity = 0;
    }

    handleShowForExistingSKU = (idx) => {
        const { companyItem } = this.state;
        const sku = companyItem.stockKeepingUnits[idx];
        const skuDropzoneEnabled = sku.itemImageID ? false : true;
        this.setState({ currentSkuIndex: idx, currentSku: sku, skuDropzoneEnabled: skuDropzoneEnabled, show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
        this.clearCurrentSku();
    }

    removeFile = (idx) => {
        let files = [
            ...this.state.files.slice(0, idx),
            ...this.state.files.slice(idx + 1)
        ];

        let dropzoneEnabled = (files.length === 0);

        this.setState({ files: files, dropzoneEnabled: dropzoneEnabled });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        let dropzoneEnabled = (acceptedFiles.length === 0);

        if (rejectedFiles.length > 0) alert("File could not be uploaded. Ensure file size is under 1MB.");
        if (acceptedFiles.length > 0) this.setState({ showBarcodeWarning: false });

        this.setState(prevState => ({
            files: prevState.files.concat(acceptedFiles),
            dropzoneEnabled: dropzoneEnabled
        }));
    }

    onDropSku = (acceptedFiles, rejectedFiles) => {
        let skuDropzoneEnabled = (acceptedFiles.length === 0);
        if (rejectedFiles.length > 0) {
            alert("File could not be uploaded. Ensure file size is under 1MB.");
        }

        this.setState({ skuFiles: acceptedFiles, skuDropzoneEnabled: skuDropzoneEnabled });
    }

    removeFileSku = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let idx = e.target.getAttribute('index');
        let skuFiles = [
            ...this.state.skuFiles.slice(0, idx),
            ...this.state.skuFiles.slice(idx + 1)
        ];

        let skuDropzoneEnabled = (skuFiles.length === 0);

        this.setState({ skuFiles: skuFiles, skuDropzoneEnabled: skuDropzoneEnabled });
    }

    handleSkuSubmit = () => {
        let { companyItem, currentSku, currentSkuIndex } = this.state;

        if (typeof currentSkuIndex === 'number') {
            companyItem = update(companyItem, { stockKeepingUnits: { [currentSkuIndex]: { $set: currentSku } } })
        } else {
            currentSku.companyItemID = companyItem.id;
            companyItem.stockKeepingUnits.push(currentSku);
        }

        const noImageSku = companyItem.stockKeepingUnits.find(s => s.itemImageID === null || s.itemImageID === "");
        if (noImageSku === undefined) this.setState({ showBarcodeWarning: false });

        this.clearCurrentSku();
        this.setState({ show: false, companyItem: companyItem, loading: false });
    }

    clearCurrentSku = () => {
        const currentSku = {
            id: defaultGuid,
            code: "",
            description: "",
            itemImageID: "",
            companyItemID: "",
            quantity: 0
        };

        this.setState({ currentSku: currentSku, currentSkuIndex: null, skuDropzoneEnabled: true });
    }

    uploadSkuImage = () => {
        const skuValidation = this.skuValidator.validate(this.state.currentSku);
        this.setState({ skuValidation: skuValidation, loading: skuValidation.isValid });
        this.skuSubmitted = true;

        if (skuValidation.isValid) {
            if (this.state.skuFiles.length) {
                this.setState({ loading: true });

                let formData = new FormData();
                this.state.skuFiles.forEach((f, i) => formData.append(`files`, this.state.skuFiles[i], f.name));

                return POST_FILE(Endpoints.STOCK.POST.UPLOAD_IMAGE, formData).catch(e => console.log(e))
                    .then(r => r.json())
                    .then(response => {
                        if (!response.error) {
                            const itemImage = extractData(response);

                            const skuDropzoneEnabled = itemImage.id ? true : false;

                            this.setState(prevState => ({
                                ...prevState,
                                currentSku: {
                                    ...prevState.currentSku,
                                    ...{
                                        itemImageID: itemImage.id,
                                        itemImage: itemImage
                                    }
                                },
                                skuDropzoneEnabled: skuDropzoneEnabled,
                                loading: false,
                                error: false,
                                message: "SKU Image saved successfully.",
                                skuFiles: []
                            }), this.handleSkuSubmit);

                        }
                        else {
                            const skuDropzoneEnabled = (this.state.currentSku.itemImageID) ? true : false;
                            this.setState({ loading: false, error: true, message: response.message, skuFiles: [], skuDropzoneEnabled: skuDropzoneEnabled }, this.handleSkuSubmit);
                        }
                    });
            } else {
                const skuDropzoneEnabled = (this.state.currentSku.itemImageID) ? true : false;
                this.setState({ loading: false, error: false, skuFiles: [], skuDropzoneEnabled: skuDropzoneEnabled }, this.handleSkuSubmit);
            }
        }
        else {
            const skuDropzoneEnabled = this.state.currentSku.itemImageID ? false : true;
            this.setState({ loading: false, error: false, skuFiles: [], skuDropzoneEnabled: skuDropzoneEnabled });
        }
    }

    deleteImageSku = (id) => {
        const { currentSku } = this.state;

        return DELETE(Endpoints.STOCK.DELETE.IMAGE_FOR_SKU + id + '/' + currentSku.id).catch(e => console.log(e))
            .then(r => r.json())
            .then(response => {
                if (!response.error) {
                    this.setState(prevState => ({
                        ...prevState,
                        currentSku: {
                            ...prevState.currentSku,
                            ...{
                                itemImageID: "",
                                itemImage: null
                            }
                        },
                        skuDropzoneEnabled: true,
                        loading: false,
                        error: false,
                        message: "SKU Image deleted successfully.",
                        skuFiles: []
                    }));
                }
                else {
                    this.setState({ loading: false, error: true, files: [] });
                }
            });
    }

    renderSkuModal = () => {
        const { currentSku, currentSkuIndex, show } = this.state;
        const removeButton = typeof currentSkuIndex === 'number' ? <Button variant="danger" onClick={this.removeSKU}>Remove</Button> : null;
        const skuValidation = this.skuSubmitted ? this.skuValidator.validate(currentSku) : this.state.skuValidation;

        return (
            <Form onSubmit={this.handleSkuSubmit}>
                <Modal centered show={show} onHide={this.handleClose} backdrop="static" animation={false}>
                    <Modal.Header closeButton className="px-4">
                        <Modal.Title>Add/Edit SKU</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="px-4">
                        <FormGroup>
                            <FormLabel htmlFor="skuCodeField">SKU Code</FormLabel>
                            <FormControl id="skuCodeField" index={currentSkuIndex} type="text" name="code" value={currentSku.code} onChange={this.handleInputChangeSKU} />
                            <span className="text-danger">{skuValidation.code.message}</span>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel htmlFor="descriptionField">Description</FormLabel>
                            <FormControl id="descriptionField" index={currentSkuIndex} type="text" name="description" value={currentSku.description} onChange={this.handleInputChangeSKU} />
                            <span className="text-danger">{skuValidation.description.message}</span>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>SKU Image <span className="form-label-optional">- optional</span></FormLabel>
                            {this.state.skuDropzoneEnabled &&
                                <ReactDropzone accept="image/*" onDrop={this.onDropSku} maxSize={999999} multiple={false}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className="file-upload text-center">
                                            <input {...getInputProps()} />
                                            <p className="file-upload-primarytext my-2">Drag and drop your image</p>
                                            <p className="file-upload-secondarytext my-2">or</p>
                                            <Button variant="secondary">Browse</Button>
                                        </div>
                                    )}
                                </ReactDropzone>}

                            {this.state.skuFiles.length > 0 && <div className="form-image-list">
                                <ul>
                                    {this.state.skuFiles.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFileImage} className="form-fileicon" />{f.name} - {f.size / 1000} KB <FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" index={i} onClick={this.removeFileSku} /></li>)}
                                </ul>
                            </div>}
                            {(currentSku.itemImage && !this.state.skuDropzoneEnabled) && <div className="file-list mt-4">
                                <ul>
                                    <li><FontAwesomeIcon icon={faFileImage} className="form-fileicon" />{currentSku.itemImage.fileName}<FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" onClick={() => this.deleteImageSku(currentSku.itemImage.id)} /></li>
                                </ul>
                            </div>}
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer className="px-4">
                        <Button variant="link" onClick={this.handleClose}>Cancel</Button>
                        {removeButton}
                        <Button variant="primary" onClick={this.uploadSkuImage}>OK</Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        );
    }

    removeSKU = () => {
        let { companyItem } = this.state;
        const { currentSkuIndex } = this.state;

        if (typeof currentSkuIndex === 'number') {
            companyItem = update(companyItem, { stockKeepingUnits: { $splice: [[currentSkuIndex, 1]] } });
        }
        this.clearCurrentSku();
        this.setState({ show: false, companyItem: companyItem });
    }

    uploadImages = (companyItem) => {
        if (this.state.files.length) {
            this.setState({ loading: true });

            let formData = new FormData();
            this.state.files.forEach((f, i) => formData.append(`files`, this.state.files[i], f.name));

            return POST_FILE(Endpoints.STOCK.POST.UPLOAD_IMAGES + companyItem.itemID, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    if (!response.error) {
                        let images = this.state.companyItem.item.itemImages;
                        images = images.concat(extractData(response));

                        const dropzoneEnabled = (images.length === 0);

                        this.setState(prevState => ({
                            ...prevState,
                            companyItem: {
                                ...prevState.companyItem,
                                ...{
                                    item: {
                                        ...prevState.companyItem.item,
                                        ...{ itemImages: images }
                                    }
                                }
                            },
                            dropzoneEnabled: dropzoneEnabled,
                            loading: false,
                            error: false,
                            message: "Item saved successfully.",
                            files: []
                        }));

                    }
                    else {
                        const dropzoneEnabled = (this.state.companyItem.item.itemImages.length === 0);
                        this.setState({ loading: false, error: true, message: response.message, files: [], dropzoneEnabled: dropzoneEnabled });
                    }
                });
        } else {
            const dropzoneEnabled = (this.state.companyItem.item.itemImages.length === 0);
            this.setState({ loading: false, error: false, message: "Item saved successfully.", files: [], dropzoneEnabled: dropzoneEnabled }, this.getCompanyItem);
        }
    }

    handleSubmit = async () => {
        const { companyItem } = this.state;

        this.submitted = true;

        if (companyItem.item.ean === "null") {
            companyItem.item.ean = null;
        }

        return await POST(Endpoints.STOCK.POST.CREATE, companyItem)
            .then(response => response.json()).then((result) => {
                if (!result.error) {
                    this.setState({ loading: false, error: false, message: "", companyItem: extractData(result) });
                    this.uploadImages(extractData(result));
                } else {
                    let dropzoneEnabled = (this.state.files.length === 0);
                    this.setState({ loading: false, error: true, message: result.message, dropzoneEnabled: dropzoneEnabled });
                }
            });
    }

    render() {
        const { loading, activeStep, companyItem, categories, productOverviewValidation, productDetailsValidation, priceValidation, expectedWeeklySalesValidation, eanValidation, noPackagingNeeded, showBarcodeWarning, files, dropzoneEnabled, error, message } = this.state;

        return (
            <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Add Product" />
                <Container fluid>
                    <Stepper steps={steps} activeStep={activeStep} />
                    {loading ? <LoadingBar /> :
                        <Row className="mt-5">
                            <Col sm={12} md={6} className="mt-3">
                                {activeStep === 0 ?
                                    <React.Fragment>
                                        <ProductOverview companyItem={companyItem} categories={categories} productOverviewValidation={productOverviewValidation} handleInputChange={this.handleInputChange} />
                                        <StepperButtons cancelLink={cancelLink} handleNext={this.handleNext} showNext={true} />
                                    </React.Fragment>
                                    : activeStep === 1 ?
                                        <React.Fragment>
                                            <ProductDetails companyItem={companyItem} handleInputChange={this.handleInputChange} productDetailsValidation={productDetailsValidation} formatInput={this.formatInput} />
                                            <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                        </React.Fragment>
                                        : activeStep === 2 ?
                                            <React.Fragment>
                                                <Pricing companyItem={companyItem} priceValidation={priceValidation} expectedWeeklySalesValidation={expectedWeeklySalesValidation} handleInputChange={this.handleInputChange} />
                                                <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                            </React.Fragment>
                                            : activeStep === 3 ?
                                                <React.Fragment>
                                                    <UniqueIdentifiers companyItem={companyItem} handleInputChange={this.handleInputChange} handleShowForNewSKU={this.handleShowForNewSKU} handleShowForExistingSKU={this.handleShowForExistingSKU} eanValidation={eanValidation} showBarcodeWarning={showBarcodeWarning} images={files} dropzoneEnabled={dropzoneEnabled} onDrop={this.onDrop} deleteImage={this.removeFile} />
                                                    <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                                </React.Fragment>
                                                : activeStep === 4 ?
                                                    <React.Fragment>
                                                        <PackagingPreferences companyItem={companyItem} handlePackagingInputChange={this.handlePackagingInputChange} noPackagingNeeded={noPackagingNeeded} />
                                                        <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                                    </React.Fragment>
                                                    : activeStep === 5 ?
                                                        <React.Fragment>
                                                            <ConfirmProduct companyItem={companyItem} categories={categories} images={files} handleEditStep={this.handleEditStep} message={error ? message : null} />
                                                            <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} nextButtonText="Confirm Product" />
                                                        </React.Fragment>
                                                        : <Success name={companyItem.item.name} />
                                }
                            </Col>
                        </Row>}
                </Container>
                {this.renderSkuModal()}
            </React.Fragment>)
    }
}

export default AddProduct;