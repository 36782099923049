import React, { Component } from 'react';
import { Container, Row, Col, Card, Image, Button } from 'react-bootstrap';
import { GET, POST, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import { Link } from 'react-router-dom';
import Footer from '../../common/Footer';

const LoginButton = (
    <Link to='/' className="link-button">
        <Button variant="primary" size="lg" block>Login</Button>
    </Link>
);

const SuccessConfirmEmail = (
    <React.Fragment>
        <h5 className="text-center">Your Email Address Has Been Confirmed</h5>
        <p className="text-center mb-0">You can login and get started with Selazar!</p>
        <Image src='Images/verify-email.png' className="mx-auto mb-4 image-card" />
        {LoginButton}
    </React.Fragment>
);

const ErrorConfirmEmail = (
    <React.Fragment>
        <h5 className="text-center">An Error has Occurred</h5>
        <p className="text-center">Your email address could not be confirmed.</p>
        {LoginButton}
    </React.Fragment>
);

class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmEmailRequestID: this.props.match.params.id || null,
            loading: true,
            error: false
        };
    }

    componentDidMount() {
        this.getConfirmEmailRequest();
    }

    getConfirmEmailRequest = () => {
        const { confirmEmailRequestID } = this.state;

        return GET(Endpoints.REGISTRATION.GET.CONFIRM_EMAIL_REQUEST + confirmEmailRequestID)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ confirmEmailRequest: extractData(result) }, this.confirmEmailRequest);
                } else {
                    this.setState({ loading: false, error: true });
                }
            }
            );
    }

    confirmEmailRequest = () => {
        const { confirmEmailRequestID } = this.state;

        return POST(Endpoints.REGISTRATION.POST.EMAIL.CONFIRM + confirmEmailRequestID)
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ confirmEmailRequest: extractData(result), loading: false });
                } else {
                    this.setState({ loading: false, error: true });
                }
            }
        );
    }

    render() {
        const { loading, error } = this.state;

        return (<React.Fragment>
            {loading ?
                <LoadingBar /> :
                <Container className="h-100">
                    <Row className="justify-content-center row-form-adjustedheight">
                        <Col md={6} className="align-self-center">
                            <Card className="p-5">
                                <Image src='Images/logo.png' className="mx-auto mb-5 image-logo" />
                                {error ? ErrorConfirmEmail : SuccessConfirmEmail}
                            </Card>
                        </Col>
                    </Row>
                    <Footer />
                </Container>}
        </React.Fragment>
        );
    }
}
export default ConfirmEmail;