import React from 'react';
import WorkArea from '../common/WorkArea';
import SelazarIssuesList from './SelazarIssuesList';

const SelazarIssues = () => {    

    const viewRaisedBy = { eventKey: "/viewRaisedBy", text: "Issues Raised", component: <SelazarIssuesList key={"viewRaisedByComponent"} raisedBy={true}/> };
    const viewAction = { eventKey: "/viewAction", text: "Issues to Action", component: <SelazarIssuesList key={"viewActionComponent"} raisedBy={false}/> };
    const viewAssignedToMe = { eventKey: "/viewAssignedToMe", text: "Assigned to Me", component: <SelazarIssuesList key={"viewAssignedToMeComponent"} raisedBy={false} assignedToMe={true}/> };


    const tabs = [];
    viewRaisedBy && tabs.push(viewRaisedBy);
    viewAction && tabs.push(viewAction);
    viewAssignedToMe && tabs.push(viewAssignedToMe);

    const activeKey = tabs.length ? tabs[0].eventKey : "";

    return (
        <WorkArea tabs={tabs} activeKey={activeKey} />
    );
};

SelazarIssues.displayName = SelazarIssues.name;
export default SelazarIssues;