import React, { Component } from 'react';
import Endpoints from './common/Endpoints';
import { POST, extractData } from '../Consumer';
import { Alert, FormGroup, FormLabel, FormControl, Button, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export class SelazarLogin extends Component {
    displayName = SelazarLogin.name

    constructor(props) {
        super(props);
        this.state = { email: '', password: '', loginError: '' };
        this.validateForm = this.validateForm.bind(this);
        this.tryLogin = this.tryLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = (e) => {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.tryLogin();
    }

    tryLogin() {
        let url = Endpoints.SELAZAR_LOGIN;

        return POST(url, { email: this.state.email, password: this.state.password })
            .catch(error => console.log(error))
            .then(response => response.json())
            .then(result => {
                if(result.error){
                    if(result.message){
                        throw new Error(result.message);
                    }else{
                        throw new Error("Login failed");
                    }
                }else{
                    this.props.onLogin(extractData(result));
                }        
            })
            .catch(e => this.setState({ loginError: e.message, email: '', password: '' }));
    }

    render() {
        return (
            <Col md={4} mdPush={4}>
                <h1>Selazar Internal</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.state.loginError && <Alert bsStyle="danger">{this.state.loginError}</Alert>}
                    <FormGroup bsSize="lg">
                        <FormLabel>Username</FormLabel>
                        <FormControl autoFocus type="email" id="email" value={this.state.email} onChange={this.handleChange} />
                    </FormGroup>
                    <FormGroup bsSize="lg">
                        <FormLabel>Password</FormLabel>
                        <FormControl type="password" id="password" value={this.state.password} onChange={this.handleChange} />
                        <Link to='/account/password/forgot'>Forgot Password?</Link>
                    </FormGroup>
                 
                    <Button
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                    >
                        Login
                    </Button>
                  
                </form>
            </Col>
        );
    }
}
