import React, { Component } from 'react';
import { Card, Table, Row, Col, Container } from 'react-bootstrap';
import { RetailerOrderSubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import UnprocessedOrderRow from './UnprocessedOrderRow';
import { GET, PUT, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import { SuccessAlert, ErrorAlert } from '../../common/Alert';
import { isNullOrEmptyGuid } from '../../../Utilities';
import Pagination from '../../common/Tables/Pagination';
import Confirm from '../../common/Confirm';

const SHOPIFY_ORDER_SOURCE = "Shopify";
const WOOCOMMERCE_ORDER_SOURCE = "WooCommerce";
const CANCEL_ORDER = 'Cancel Order';

class PendingOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 1,
            pageIndex: 1,
            orders: [],
            loading: true,
            showCancellationConfirmationModal: false,
            currentItemID: '',
            currentItemSource: '',
            showError: false,
            showSuccess: false
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchPendingOrders()]);
        this.setState({ loading: false });
    }

    fetchPendingOrders = async () => {
        const { pageIndex } = this.state;
        let url = new URL(Endpoints.RETAILER.GET.PENDING);
        url.searchParams.append("pageIndex", pageIndex)
        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                this.setState({ orders: data.orders ? data.orders : [], pageCount: data.pageCount ? data.pageCount : 1, loading: false });
            });
    }

    showCancellationConfirmationModal = (id, identifier, source, show) => {
        this.setState({ currentItemID: id, identifier: identifier, currentItemSource: source });
        this.setState({ showCancellationConfirmationModal: show });
    }

    handleCancellationConfirmationClose = () => {
        this.setState({ showCancellationConfirmationModal: false });
    }

    cancelOrder = (id, source) => {
        let cancelEndpoint = Endpoints.RETAILER.PUT.CANCEL_ORDER;
        if (source === SHOPIFY_ORDER_SOURCE) {
            cancelEndpoint = Endpoints.RETAILER.PUT.CANCEL_SHOPIFY_ORDER;
        }
        if (source === WOOCOMMERCE_ORDER_SOURCE) {
            cancelEndpoint = Endpoints.RETAILER.PUT.CANCEL_WOOCOMMERCE_ORDER;
        }

        if (!isNullOrEmptyGuid(id)) {
            PUT(cancelEndpoint + "/" + id)
                .catch(error => console.log(error))
                .then(response => {
                    if (response.ok) return response.json();
                })
                .then(result => {
                    const data = extractData(result);
                    if (data === true) {
                        this.setState({ showSuccess: true, showError: false });
                        this.fetchPendingOrders();
                    }
                    else { this.setState({ showSuccess: false, showError: true }); }
                    this.handleCancellationConfirmationClose();
                }
                );
        }
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchPendingOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchPendingOrders);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchPendingOrders);
    }

    renderPendingOrderTable() {
        const { orders, pageIndex, pageCount } = this.state;
        return (
            <Card>
                <div className="table-responsive">
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th>Identifier</th>
                                <th>Source</th>
                                <th>Date Created</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.length ? orders.map(order => <UnprocessedOrderRow key={order.id} order={order} showCancellationConfirmationModal={() => { this.showCancellationConfirmationModal(order.id, order.identifier, order.source, true); }} />) : <p className="ml-3 my-3"><strong>No pending orders available.</strong></p>}
                        </tbody>
                    </Table>
                    <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                </div>
            </Card>
        );
    }

    render() {
        const { loading, identifier, currentItemID, currentItemSource, showError, showSuccess } = this.state;

        return (
            <React.Fragment>
                <Header title="Orders" subNavList={RetailerOrderSubNavList} activeKey="Pending Orders" />
                {loading ? <LoadingBar /> :
                    <Container fluid>
                        {showError && <Row><Col sm={12} md={6}><ErrorAlert errorMessage={`Order ${identifier} has not been cancelled.`} /></Col></Row>}
                        {showSuccess && <Row><Col sm={12} md={6}><SuccessAlert successMessage={`Order ${identifier} has been cancelled.`} /></Col></Row>}
                        {this.renderPendingOrderTable()}
                    </Container>}
                <Confirm title={CANCEL_ORDER} text={"Are you sure you want to cancel order " + identifier + "? You cannot undo this action."} buttonText={CANCEL_ORDER} buttonVariant={"danger"} handleConfirmClose={this.handleCancellationConfirmationClose} handleConfirmAction={() => { this.cancelOrder(currentItemID, currentItemSource); }} show={this.state.showCancellationConfirmationModal} />
            </React.Fragment>
        )
    }
}

export default PendingOrders;