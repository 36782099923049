import React, { Component } from 'react';
import { Modal, Form, FormGroup, FormLabel, FormControl, Button, Col } from 'react-bootstrap';
import LoadingBar from "../common/LoadingBar";
import Endpoints from "../common/Endpoints";
import { defaultGuid } from '../../Utilities';
import { GET, POST, extractData } from '../../Consumer';
import FormValidator from '../common/FormValidator';

class ResolveSelazarIssue extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'description',
                method: 'isEmpty',
                validWhen: false,
                message: 'Description is required'
            },
            {
                field: 'resolutionTypeID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Resolution type is required'
            }
        ]
        );

        let {issueID} = this.props.location.state;

        this.state = {
            resolution: {
                id: defaultGuid,
                description: "",
                dateResolved: null,
                issueID: issueID,
                resolutionTypeID: ""
            },
            resolutionTypes: [],
            validation: this.validator.valid(),
            loading: true,
            message: "",
            show: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.getResolutionTypes = this.getResolutionTypes.bind(this);

    }

    componentDidMount() {
        Promise.all([this.getResolutionTypes()]);
    }

    handleClose = () => {
        this.setState({ show: false });
        this.props.history.push('/issues');
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    getResolutionTypes() {
        let { resolution } = this.state;

        return GET(Endpoints.RESOLUTION_TYPES.GET.BY_ISSUE + resolution.issueID)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ resolutionTypes: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    handleInputChange(e) {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.resolution[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            resolution: {
                ...prevState.resolution,
                ...{ [name]: value }
            }
        }));


    }

    handleSubmit(event) {
        event.preventDefault();
        const { resolution } = this.state;

        const validation = this.validator.validate(resolution);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (validation.isValid) {
            POST(Endpoints.RESOLUTIONS.POST.RESOLVE, resolution)
                    .then(response => response.json())  
                    .then(result => {
                        if (!result.error) {
                            var data = extractData(result)
                            if(data === true){
                                this.setState({ loading: false, message: result.message });
                                this.props.history.push('/issues');
                            }
                            else{
                                this.setState({ loading: false, message: data });
                                this.handleShow();
                            }
                        }
                        else {
                            this.setState({ loading: false,  message: result.message });
                        }
                    });   
        }
    }

    renderForm(resolution) {
        let validation = this.submitted ?
            this.validator.validate(resolution) :
            this.state.validation;

        let { loading, message } = this.state;
        const { resolutionTypes } = this.state;

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
  
                    <FormGroup>
                        <FormLabel htmlFor="descriptionField">Description</FormLabel>
                        <FormControl disabled={loading} id="descriptionField" componentClass="textarea" rows="5" maxLength={300} name="description" value={resolution.description} onChange={this.handleInputChange} />
                        <span className="text-danger">{validation.description.message}</span>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel htmlFor="resolutionTypeField">Resolution Action</FormLabel>
                        <FormControl disabled={loading} id="resolutionTypeField" name="resolutionTypeID" componentClass="select" value={resolution.resolutionTypeID} onChange={this.handleInputChange}>
                            <option key={0} value={0}>Please Select...</option>;
                            {resolutionTypes.map(resolutionType => {
                                return <option key={resolutionType.id} value={resolutionType.id}>{resolutionType.name}</option>;
                            })}
                        </FormControl>
                        <span className="text-danger">{validation.resolutionTypeID.message}</span>
                    </FormGroup>

                    <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resolve Issue</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {message}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose}>Continue</Button>
                    </Modal.Footer>
                </Modal>

                    <div className="margin-bottom">
                        <input className="btn btn-primary" type="submit" disabled={loading} value="Submit" />
                    </div>
                </Form>
            </div>
        );
    }

    render() {
        const { resolution, loading } = this.state;
        let contents = this.renderForm(resolution);

        return (
            <div>
                {loading && <LoadingBar />}
                <h1>Resolve Issue</h1>
                <Col md={6}>{contents}</Col>
            </div>
        );
    }
}

export default ResolveSelazarIssue;