import React, { Component } from 'react';
import { BulkOrderSubNavList } from '../../../common/Constants';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Header from '../../../common/Header';
import { extractData, GET } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { ErrorAlert } from '../../../common/Alert';
import SelazarLinkContainer from '../../../common/SelazarLinkContainer';

const NO_CARD_DETAILS_MESSAGE = "No card details found. Please add card details to your account to submit orders.";

class CreateBulkOrder extends Component {    
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            permitted: false,
        };
    }

    componentDidMount() {
        this.validateAccount();
    }

    validateAccount() {
        return GET(Endpoints.FINANCE.CARD.HAS_VALID_DETAILS)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    console.log(res.message);
                    this.setState({ loading: false });
                }
                else extractData(res) ? this.setState({ loading: false, permitted: true }) : this.setState({ loading: false });
            })
            .catch(e => console.log(e));
    }

    render = () => {
        const { loading, permitted } = this.state;

        return (<React.Fragment>
            <Header title="Bulk Order" subNavList={BulkOrderSubNavList} activeKey="Create Bulk Order" />
            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <h4>Bulk Order Information</h4>
                        <br />
                        <h5>What is a Bulk Order?</h5>
                        <p>Selazar’s core pick and pack process is designed for B2C orders where there are a handful of items to be picked and packed. In some cases though, you may need to send stock to businesses rather than consumers and these orders include large amounts of stock. This B2B order type is what we would consider a Bulk Order, and these can be created using the Bulk Order Wizard. This Bulk Order Wizard is designed for orders with lots of products that you want sent to a single location. You can choose your own product-to-box configuration or choose for us to do it for you. Need to split SKUs into different boxes? That’s no problem. Need to send it yourself on a pallet? No problem. It is all part of the Wizard.</p>
                        <br />
                        <h5>Instructions for Bulk Order</h5>
                        <p>The Bulk Order Wizard will take you through creating a Bulk Order with ease. Choose the destination, products, packaging, and shipping service time, and we’ll take it from there. There are several options to select on the way and the order is not locked into our system until you complete the full process, so you can explore the settings and find what is best for your order requirements.</p>
                        <p>All boxes will be packed and shipped using our existing Selazar intelligence algorithms. In the case where there are a lot of boxes you don’t want shipped by a courier, we can palletise them for you instead. Just select the self-shipping option.</p>
                        <p>The self-shipping option mentioned allows you to ship an order yourself in case you have special requirements. This is helpful if you want to send a lot of boxes on a pallet and need specialised shipping options. We can help you with the shipping of these or you can arrange the pickup yourself but, in each case, you need to contact Customer Services on 020 39507860 to arrange this. We do not accept ad-hoc, unscheduled collections from the warehouse.</p>
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        {loading ? <LoadingBar /> :
                            permitted ?
                                <SelazarLinkContainer to={{ pathname: `/retailer/bulkorder/create` }}>
                                    <Button variant="primary" className="float-right link-button" type="submit">Create Bulk Order</Button>
                                </SelazarLinkContainer>
                            : <ErrorAlert errorMessage={NO_CARD_DETAILS_MESSAGE} />}
                    </Col>
                </Row>
            </Container>

        </React.Fragment>);
    }
}

export default CreateBulkOrder;