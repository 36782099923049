import React, { Component } from 'react';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';

class ItemSelection extends Component {

    render() {
        const { data, handleInputChange, loading, itemValidation, companyItems, skuCodes, skuCodeEmptyMessage } = this.props;

        return (
            <React.Fragment>
                <h5>Item Selection</h5>
                <FormGroup>
                    <FormLabel htmlFor="itemField">Select Item</FormLabel>
                    <FormControl disabled={loading} id="itemField" name="companyItemID" as="select" value={data.companyItemID} onChange={handleInputChange}>
                        <option key={''} value={''}>Please Select...</option>;
                                        {companyItems.map(companyItem => {
                            return <option key={companyItem.companyItemID} value={companyItem.companyItemID}>{companyItem.name}</option>;
                        })}
                    </FormControl>
                    <span className="text-danger">{itemValidation.companyItemID.message}</span>
                </FormGroup>
                {skuCodes.length > 0 &&
                    <FormGroup>
                        <FormLabel htmlFor="skuCodeField">Select SKU</FormLabel>
                        <FormControl disabled={loading} id="skuCodeField" name="skuCode" as="select" value={data.skuCode} onChange={handleInputChange}>
                            <option key={''} value={''}>Please Select...</option>;
                                        {skuCodes.map(sku => {
                                return <option key={sku.code} value={sku.code}>{sku.code}</option>;
                            })}
                        </FormControl>
                        <span className="text-danger">{skuCodeEmptyMessage}</span>
                    </FormGroup>
                }
            </React.Fragment>
        )
    }
}

export default ItemSelection;