import React, { Component } from 'react';
import { Col, Button, Image, Container, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Footer from '../../common/Footer';

const LoginButton = (
    <Link to='/' className="link-button">
        <Button variant="primary" size="lg" block>Login</Button>
    </Link>
);

const ResetRequested = (email) => {
    return (
        <React.Fragment>
            <h5 className="text-center">Password Reset</h5>
            <p className="text-center mb-0">We have emailed a password reset link to {email}, follow the link in the email to reset your password</p>
            <Image src='Images/email.png' className="mx-auto mb-3 image-card" />
            {LoginButton}
            <p className="text-center mt-4 mb-0">Can't see the email? Be sure to check your spam folder. Having issues? Or no longer have access to your email address? Contact us <a href="mailto:support@selazar.com">support@selazar.com</a>.</p>
        </React.Fragment>
    );
}

const ResetSuccess = (
    <React.Fragment>
        <h5 className="text-center">Password Reset Successfully</h5>
        <p className="text-center">We have successfully reset your password, you can now login using your new password.</p>
        <Image src='Images/unlocked.png' className="mx-auto mb-3 image-card" />
        {LoginButton}
    </React.Fragment>
);

class ResetResult extends Component {

    render() {
        return (
            <Container className="h-100">
                <Row className="justify-content-center row-form-adjustedheight">
                    <Col md={6} className="align-self-center">
                        <Card className="p-5">
                            <Image src='Images/logo.png' className="mx-auto mb-3 image-logo" />
                            {this.props.location.state.changed ? ResetSuccess : ResetRequested(this.props.location.state.email)}
                        </Card>
                    </Col>
                </Row>
                <Footer />
            </Container>
        );
    }
}
export default ResetResult;