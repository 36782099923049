import React, { Component } from 'react';
import { Col, Container, Row, Table, Card, Button } from 'react-bootstrap';
import { extractData, GET, DELETE, PUT } from '../../../Consumer';
import { checkPermission, toLocalTimeString } from '../../../Utilities';
import CommonEndpoints from '../../common/Endpoints';
import LoadingBar from '../../common/LoadingBar';
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faUserPlus, faUserLock, faUserTimes, faTrashAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Actions from '../../common/Tables/Actions';
import Confirm from '../../common/Confirm';
import Pagination from '../../common/Tables/Pagination';
import UserFilter from './UserFilter';

class Users extends Component {
    displayName = Users.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            totalCount: 0,
            pageIndex: 1,
            pageCount: 1,
            users: [],
            showDeleteUserConfirmation: false,
            showToggleUserConfirmation: false,
            selectedUser: null,
            toggleMessage: "",
            deleteMessage: "",
            filter: {
                name: "",
                sortBy: ""
            }
        };
        this.fetchUsers = this.fetchUsers.bind(this);
        this.handleToggleShowHide = this.handleToggleShowHide.bind(this);
        this.handleDeleteShowHide = this.handleDeleteShowHide.bind(this);
        this.handleToggleAction = this.handleToggleAction.bind(this);
        this.handleDeleteAction = this.handleDeleteAction.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.onPageSelect = this.onPageSelect.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers() {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(CommonEndpoints.GET_FILTERED_USERS);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result) || [];
                    const warning = result.error ? result.message : null;
                    this.setState({ users: data.users, totalCount: data.total, pageCount: data.pageCount, loading: false, warning: warning });
                }
            }, err => {
                console.error(err);
                this.setState({ loading: false, warning: err.toString() });
            });
    }

    deleteUser(user) {
        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to delete user' });
            return;
        }

        if (window.confirm(`Are you sure you want to delete ${user.forename} ${user.surname}?`)) {
            this.setState({ loading: true });

            return DELETE(CommonEndpoints.DELETE_USER + user.id)
                .then(r => r.json())
                .then(({ data, error, message }) => {
                    if (data === true) {
                        this.fetchUsers();
                    }
                    if (error) {
                        this.setState({ warning: message });
                    }
                });
        }
    }

    toggleUser(user) {
        if (Object.keys(user).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to toggle user' });
            return;
        }

        let message = `Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${user.forename} ${user.surname}?`;

        if (window.confirm(message)) {
            this.setState({ loading: true });

            return PUT(CommonEndpoints.TOGGLE_USER + user.id)
                .then(r => r.json())
                .then(({ data, error, message }) => {
                    if (data) {
                        this.fetchUsers();
                    }
                    if (error) {
                        this.setState({ warning: message });
                    }
                });
        }
    }

    getActions(user) {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        const currentUserID = currentUser.id;
        const isSameUser = currentUserID === user.id;
        const rowActions = [];
        const editUser = checkPermission('EditUser', <SelazarLinkContainer to={`/company/users/editdetails/${user.id}`}><Button variant="link"><FontAwesomeIcon icon={faEdit} />Edit Details</Button></SelazarLinkContainer>);
        rowActions.push(editUser);

        const editPermissions = checkPermission('EditPermission', <SelazarLinkContainer to={{ pathname: '/users/permissions', state: { user: user } }}><Button variant="link"><FontAwesomeIcon icon={faUserLock} />Permissions</Button></SelazarLinkContainer>);
        editPermissions && rowActions.push(editPermissions);

        const toggleUser = !isSameUser ? checkPermission('ToggleUser', <Button variant="link" onClick={() => this.handleToggleShowHide(user)}><FontAwesomeIcon icon={faUserTimes} />{user.active ? "Deactivate" : "Activate"}</Button>) : null;
        toggleUser && rowActions.push(toggleUser);

        const deleteUser = !isSameUser ? checkPermission('DeleteUser', <Button variant="link" onClick={() => this.handleDeleteShowHide(user)}><FontAwesomeIcon icon={faTrashAlt} />Delete</Button>) : null;
        deleteUser && rowActions.push(deleteUser);

        return rowActions;
    }

    handleToggleShowHide = (user) => {

        user ?
            this.setState((prevState) => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation, selectedUser: user, toggleMessage: `Are you sure you want to ${user.active ? 'deactivate' : 'activate'} ${user.forename} ${user.surname}?` })) :
            this.setState((prevState) => ({ showToggleUserConfirmation: !prevState.showToggleUserConfirmation }));
    }

    handleDeleteShowHide = (user) => {
        user ?
            this.setState((prevState) => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation, selectedUser: user, deleteMessage: `Are you sure you want to delete ${user.forename} ${user.surname}?` })) :
            this.setState((prevState) => ({ showDeleteUserConfirmation: !prevState.showDeleteUserConfirmation }));
    }

    handleToggleAction() {
        const { selectedUser } = this.state;
        if (Object.keys(selectedUser).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to toggle user' });
            return;
        }

        this.setState({ loading: true, showToggleUserConfirmation: false });

        return PUT(CommonEndpoints.TOGGLE_USER + selectedUser.id)
            .then(r => r.json())
            .then(({ data, error, message }) => {
                if (data) {
                    this.fetchUsers();
                }
                if (error) {
                    this.setState({ warning: message });
                }
            });
    }

    handleDeleteAction() {
        const { selectedUser } = this.state;
        if (Object.keys(selectedUser).indexOf('id') === -1) {
            this.setState({ warning: 'Unable to delete user' });
            return;
        }

        this.setState({ loading: true, showDeleteUserConfirmation: false });

        return DELETE(CommonEndpoints.DELETE_USER + selectedUser.id)
            .then(r => r.json())
            .then(({ data, error, message }) => {
                if (data === true) {
                    this.fetchUsers();
                    this.setState({ loading: false });
                }
                if (error) {
                    this.setState({ warning: message });
                }
            });
    }

    onNext() {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, this.fetchUsers);
        }
    }

    onPrevious() {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, this.fetchUsers);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchUsers);
    }

    onFilterChange(e) {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    onClear() {
        this.setState({
            users: [],
            loading: true,
            filter: {
                name: "",
                sortBy: ""
            }
        }, this.fetchUsers);
    }

    onSearch(e) {
        e.preventDefault();
        this.setState({ users: [], loading: true }, this.fetchUsers);
    }

    render() {
        const { loading, users, selectedUser, showToggleUserConfirmation, showDeleteUserConfirmation, toggleMessage, deleteMessage, pageIndex, pageCount, warning } = this.state;
        const addNewUser = checkPermission('AddUser',
            (
                <SelazarLinkContainer to='/company/users/new' activeClassName={null}>
                    <Button variant="primary" className="link-button float-right px-4">Add New User <FontAwesomeIcon icon={faUserPlus} className="ml-2" /></Button>
                </SelazarLinkContainer>
            )
        );

        return (
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <h2 className="d-inline-block">Users</h2>
                        {loading ? null : addNewUser}
                    </Col>
                </Row>
                {this.state.warning && <Row>
                    <Col md="6">
                        <Card className="card-danger my-3">
                            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                            <Card.Body>
                                {warning}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>}
                <Row className="my-3">
                    <Col>
                        <UserFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} loading={this.state.loading} />
                    </Col>
                </Row>
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Row className="mt-4">
                            <Col md={12}>
                                <Card>
                                    <div className="table-responsive">
                                        <Table striped bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>Full Name</th>
                                                    <th>Last Login</th>
                                                    <th>Date Created</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.length ? users.map((u) => {
                                                    return (
                                                        <tr key={u.id}>
                                                            <td>{u.email}</td>
                                                            <td>{u.forename} {u.surname}</td>
                                                            <td>{u.lastLogin ? toLocalTimeString(u.lastLogin) : "No Logins"}</td>
                                                            <td>{u.createdDate ? toLocalTimeString(u.createdDate) : "N/A"}</td>
                                                            <td>{u.active ? <span className="text-status-green">Active</span> : <span className="text-status-red">Deactivated</span>}</td>
                                                            <td className="text-center py-2">
                                                                {checkPermission("ViewUser", <SelazarLinkContainer to={`/company/users/account/${u.id}`} exact>
                                                                    <Button className="link-button" variant="secondary" size="sm">View User</Button>
                                                                </SelazarLinkContainer>)}
                                                            </td>
                                                            <td><Actions title="Edit User" actions={this.getActions(u)} /></td>
                                                        </tr>);
                                                }) : <tr><td colSpan={7}>No users to show.</td></tr>}
                                            </tbody>
                                        </Table>
                                        <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        {showToggleUserConfirmation && <Confirm title={selectedUser.active ? "Confirm Deactivation" : "Confirm Activation"} text={toggleMessage} buttonText={selectedUser.active ? "Deactivate" : "Activate"} buttonVariant={selectedUser.active ? "danger" : "secondary"} handleConfirmClose={this.handleToggleShowHide} handleConfirmAction={this.handleToggleAction} />}
                        {showDeleteUserConfirmation && <Confirm title={"Confirm Deletion"} text={deleteMessage} buttonText={"Delete"} buttonVariant="danger" handleConfirmClose={this.handleDeleteShowHide} handleConfirmAction={this.handleDeleteAction} />}
                    </React.Fragment>}
            </Container>
        );
    }
}

export default Users;