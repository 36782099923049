import React, { Component } from 'react';
import { Col, Button, Row, Card, Image, Container } from 'react-bootstrap';
import { GET, POST, PUT } from '../../../Consumer';
import CommonEndpoints from '../../common/Endpoints';
import { toLocalDateString } from '../../../Utilities';
import EditIntegration from './EditIntegration';
import SelectIntegration from './SelectIntegration';

import './Integrations.scss';
import ShopifyDetails from './details/ShopifyDetails';
import WooCommerceDetails from './details/WooCommerceDetails';
import EditWooCommerceIntegration from './EditWooCommerceIntegration';
import ShopifyShippingConfiguration from './ShopifyShippingConfiguration';
import EditShopifyShippingConfiguration from './EditShopifyShippingConfiguration';

const initState = {
    step: 0,
    isError: false,
    isSuccess: false,
    loading: false,
    showDetails: false,
    showEdit: false,
    showShippingRates: false,
    showEditShippingRates: false,
    showShippingRateEditSuccess: false,
    integrations: [],
    integrationSource: '',
    integration: {
        shopName: '',
        storeUrl: '',
        username: '',
        password: '',
        connected: true,
        inventoryManagement: true
    }
}

const SHOPIFY_INTEGRATION_TYPE = 'Shopify';
const WOOCOMMERCE_INTEGRATION_TYPE = 'WooCommerce';

class Integrations extends Component {
    constructor(props) {
        super(props);

        this.state = initState;

        this.getAllIntegrations = this.getAllIntegrations.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.reset = this.reset.bind(this);
        this.setIntegrationSource = this.setIntegrationSource.bind(this);
        this.setStep = this.setStep.bind(this);
        this.hideDetails = this.hideDetails.bind(this);
        this.showEdit = this.showEdit.bind(this);
    }

    componentDidMount() {
        this.getAllIntegrations();
    }

    getAllIntegrations() {
        this.setState({
            loading: true
        }, () => {
            GET(CommonEndpoints.INTEGRATIONS.GET.ALL)
                .catch(error => console.log(error))
                .then(res => res.json())
                .then(e => {
                    if (!e.error) {
                        this.setState({
                            integrations: e.data,
                            loading: false,
                            step: 0
                        });
                    } else {
                        this.setState({
                            isError: true,
                            loading: false,
                            step: 0
                        });
                    }
                })
        });
    }

    setStep(direction) {
        let nextStep = this.state.step;

        switch (direction) {
            case 'home':
            case 'finish':
                this.setState(initState);
                this.getAllIntegrations();
                break;
            case 'back': nextStep = nextStep - 1;
                if (nextStep === 1) {
                    this.reset();
                } else {
                    this.setState({ step: nextStep });
                }
                break;
            default: nextStep = nextStep + 1;
                this.setState({ step: nextStep });
        }
    }

    reset() {
        this.setState(prevState => ({
            ...prevState,
            integration: {
                shopName: '',
                storeUrl: '',
                username: '',
                password: '',
            },
            integrationSource: '',
            step: 1
        }));
    }

    setIntegrationSource(source) {
        if (source) {
            this.setState({ integrationSource: source });
        }
    }

    handleInputChange(e) {
        let { name, value, type } = e.target;

        if (type === "radio") {
            const { checked } = e.target;
            value = checked;

            switch (name) {
                case "connected":
                case "disconnected":
                    if (value === true) {
                        this.setState(prevState => ({
                            integration: {
                                ...prevState.integration,
                                inventoryManagement: !this.connected, // set to previous connected value
                                connected: name === "connected" ? value : !value
                            }
                        }));
                    }
                    break;
                case "inventoryManaged":
                case "inventoryUnmanaged":
                    if (value === true) {
                        this.setState(prevState => ({
                            integration: {
                                ...prevState.integration,
                                inventoryManagement: name === "inventoryManaged" ? value : !value
                            }
                        }));
                    }
                    break;

            }
        }

        this.setState(prevState => ({
            ...prevState,
            integration: {
                ...prevState.integration,
                ...{ [name]: value }
            }
        }));
    }

    handleSubmit(integration) {

        let url = ""

        switch (integration.integrationType) {
            case SHOPIFY_INTEGRATION_TYPE:
                url = CommonEndpoints.INTEGRATIONS.SHOPIFY.POST.CREATE;
                break;
            case WOOCOMMERCE_INTEGRATION_TYPE:
                url = CommonEndpoints.INTEGRATIONS.WOOCOMMERCE.POST.CREATE;
                break;
            default:
                console.log("Integration Type: " + integration.integrationType + " not recognised.");
                break;
        }


        POST(url, integration)
            .catch(error => console.log(error))
            .then(res => {
                if (res.ok) { return res.json(); }
            })
            .then(e => {
                if (e && !e.error) {
                    this.setState({ isSuccess: true, integration: e.data });
                } else {
                    this.setState({ isError: true });
                }
            })
    }

    handleEdit(integration) {
        const { integration: { id } } = this.state;

        // Ensure existing dis-connected integrations aren't inventory managed
        if (!integration.connected) integration.inventoryManagement = false;

        let url = ""
        switch (integration.integrationType) {
            case SHOPIFY_INTEGRATION_TYPE:
                url = CommonEndpoints.INTEGRATIONS.SHOPIFY.PUT.UPDATE;
                break;
            case WOOCOMMERCE_INTEGRATION_TYPE:
                url = CommonEndpoints.INTEGRATIONS.WOOCOMMERCE.PUT.UPDATE;
                break;
            default:
                console.log("Integration Type: " + integration.integrationType + " not recognised.");
                break;
        }

        PUT(`${url}${id}`, integration)
            .catch(error => console.log(error))
            .then(res => {
                if (res.ok) { return res.json(); }
            })
            .then(e => {
                if (e && !e.error) {
                    this.setState({ isSuccess: true });
                } else {
                    this.setState({ isError: true });
                }
            })
    }

    hideDetails(e) {
        this.setState(initState);
        this.getAllIntegrations();
    }

    onCardClick(integration) {
        this.setState({ showDetails: true, integration: integration });
    }

    showEdit(e) {
        let { integration, integration: { metadata } } = this.state;
        const credentials = JSON.parse(metadata);
        integration = Object.assign(integration, credentials);

        this.setState({ showEdit: true, showDetails: false, showShippingRates: false, showEditShippingRates: false, integration: integration });
    }

    showShippingRates = (showSuccess) => {
        let { integration, integration: { metadata } } = this.state;
        const credentials = JSON.parse(metadata);
        integration = Object.assign(integration, credentials);

        this.setState({ showEdit: false, showDetails: false, showShippingRates: true, showEditShippingRates: false, integration: integration, showShippingRateEditSuccess: showSuccess });
    }

    showEditShippingRates = (e) => {
        let { integration, integration: { metadata } } = this.state;
        const credentials = JSON.parse(metadata);
        integration = Object.assign(integration, credentials);

        this.setState({ showEdit: false, showDetails: false, showShippingRates: false, showEditShippingRates: true, integration: integration });
    }

    renderDetails(integration) {
        const { integrationType } = integration;

        if (integrationType === SHOPIFY_INTEGRATION_TYPE) {
            return (
                <ShopifyDetails integration={integration} backToIntegrationsClick={this.hideDetails} editIntegrationClick={this.showEdit} showShippingRates={this.showShippingRates} showEditShippingRates={this.showEditShippingRates} />
            );
        }
        if (integrationType === WOOCOMMERCE_INTEGRATION_TYPE) {
            return (
                <WooCommerceDetails integration={integration} backToIntegrationsClick={this.hideDetails} editIntegrationClick={this.showEdit} />
            );
        }
    }

    renderEdit(integration) {
        const { integrationType } = integration;
        const { isError, isSuccess } = this.state;

        if (integrationType === SHOPIFY_INTEGRATION_TYPE) {
            return (<EditIntegration
                handleInputChange={this.handleInputChange}
                handleSubmit={this.handleEdit}
                integration={integration}
                isError={isError}
                isSuccess={isSuccess}
                setStep={this.setStep}
                showBack={false}
                showEditShippingRates={this.showEditShippingRates}
            />
            );
        }

        if (integrationType === WOOCOMMERCE_INTEGRATION_TYPE) {
            return (<EditWooCommerceIntegration
                handleInputChange={this.handleInputChange}
                handleSubmit={this.handleEdit}
                integration={integration}
                isError={isError}
                isSuccess={isSuccess}
                setStep={this.setStep}
                showBack={false}
            />
            );
        }
    }

    renderIntegrationType = (integrationType) => {
        switch (integrationType) {
            case SHOPIFY_INTEGRATION_TYPE:
                return (<React.Fragment>
                    <div>
                        <Image src='Images/shopify-logo.png' className="mx-auto mb-2 integration-image" />
                    </div>
                    <div className="mt-2">
                        <strong>Shopify</strong>
                    </div>
                </React.Fragment>);
            case WOOCOMMERCE_INTEGRATION_TYPE:
                return (<React.Fragment>
                    <div className="integration-image-container">
                        <Image src='Images/woocommerce-logo.png' className="mx-auto mb-2 integration-image" />
                    </div>
                    <div className="mt-2">
                        <strong>WooCommerce</strong>
                    </div>
                </React.Fragment>);
            default:
                return;
        }
    }

    render() {
        const { integration, integrations, integrationSource, isError, isSuccess, loading, step, showDetails, showEdit, showShippingRates, showEditShippingRates, showShippingRateEditSuccess } = this.state;

        if (showDetails === true) {
            return this.renderDetails(integration);
        }

        if (showShippingRates) {
            return (
                <ShopifyShippingConfiguration integration={integration} backToIntegrationsClick={this.hideDetails} showEditShippingRates={this.showEditShippingRates} showSuccess={showShippingRateEditSuccess} />
            );
        }

        if (showEditShippingRates) {
            return (
                <EditShopifyShippingConfiguration integration={integration} backToIntegrationsClick={this.hideDetails} showShippingRates={this.showShippingRates} />
            );
        }

        if (showEdit === true) {
            return (
                <Container fluid>
                    <h2>Integrations</h2>
                    <Row>
                        <Col sm="12" md="6" className="mt-4">{this.renderEdit(integration)}</Col>
                    </Row>
                </Container>
            );
        }

        return (
            !loading && <Container fluid>
                <h2>Integrations</h2>
                <Row>
                    {step === 0 &&
                        integrations.length === 0 ? <Col sm="12" md="4" className="mt-4">
                            <Card>
                                <Card.Body>
                                    <p><strong>You currently have no integrations set up</strong></p>
                                    <div className="text-center">
                                        <Button className="link-button" variant="secondary" size="sm" onClick={this.setStep}>
                                            Set up integration
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        : step === 0 && <React.Fragment>
                            {integrations.map((integration) => {
                                return (
                                    <Col sm="12" md="6" lg="4" className="mt-4">
                                        <Card key={integration.id} className="link-button link-pointer integration-card-wide" onClick={() => this.onCardClick(integration)}>
                                            <Card.Body>

                                                {this.renderIntegrationType(integration.integrationType)}
                                                <div className="mt-1"><strong>Store Name</strong> {integration.shopName}</div>
                                                <div className="mt-1"><strong>Date of Integration</strong> {toLocalDateString(integration.dateOfIntegration)}</div>
                                                <div className="mt-3">
                                                    {integration.connected ? <span className="text-status-green">Connected</span>
                                                        : <span className="text-status-red">Not Connected</span>}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            })}
                            <Col sm="12" md="6" lg="4" className="mt-4">
                                <Card className="integration-card-wide integration-setup-container">
                                    <Card.Body>
                                        <p><strong>Want to add another integration?</strong></p>
                                        <div className="text-center">
                                            <Button className="link-button" variant="secondary" size="sm" onClick={this.setStep}>
                                                Set up integration
                                                </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </React.Fragment>
                    }

                    {step === 1 &&
                        <Col sm="12" md="6" className="mt-4">
                            <SelectIntegration
                                integrationSource={integrationSource}
                                setIntegrationSource={this.setIntegrationSource}
                                setStep={this.setStep} />
                        </Col>}

                    {step === 2 && integrationSource === 'Shopify' &&
                        <Col sm="12" md="6" className="mt-4">
                            <EditIntegration
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                integration={integration}
                                isError={isError}
                                isSuccess={isSuccess}
                                setStep={this.setStep}
                                showBack={true}
                                showEditShippingRates={this.showEditShippingRates} />
                        </Col>}

                    {step === 2 && integrationSource === 'WooCommerce' &&
                        <Col sm="12" md="6" className="mt-4">
                            <EditWooCommerceIntegration
                                handleInputChange={this.handleInputChange}
                                handleSubmit={this.handleSubmit}
                                integration={integration}
                                isError={isError}
                                isSuccess={isSuccess}
                                setStep={this.setStep}
                                showBack={true} />
                        </Col>}
                </Row>
            </Container>
        );
    }
}

export default Integrations;