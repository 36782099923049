import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import './StockItemCard.scss';

const getStockStatus = (status) => status === "GOOD" ?
    <span className="text-status-green stock-status">Stock Good</span> : status === "OK" ?
        <span className="text-status-yellow stock-status">Stock Ok</span> : status === "LOW" ?
            <span className="text-status-red stock-status">Stock Low</span> : <span className="text-status-grey stock-status">Unknown Stock Levels</span>

class StockItemCard extends Component {
    
    render() {
        const { name, category, ean, sku, suid, totalStock, status, button = null } = this.props;
        
        return (
                <Card className="p-3 mb-3 card-list">
                    <p className="card-list-title"><strong>{name}</strong></p>
                    <p className="card-list-subtitle">{category}</p>
                    <div className="card-list-info">
                        <p><span className="info-title">EAN</span>{ean}</p>
                        <p><span className="info-title">SKU</span>{sku}</p>
                        <p><span className="info-title">SUID</span>{suid}</p>
                        <p><span className="info-title">Total Stock</span>{totalStock}{getStockStatus(status)}</p>
                    </div>
                    {button ? button : null}
                </Card>
        )
    }
}

export default StockItemCard;