import React, { Component, } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Col, Container, Row, Button } from 'react-bootstrap';
import { CountryList } from '../../common/Constants';

const CURRENT_YEAR = new Date().getFullYear();
const MONTH_OPTIONS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const YEAR_OPTIONS = Array((CURRENT_YEAR + 10) - CURRENT_YEAR + 1).fill().map((_, idx) => CURRENT_YEAR + idx);


const ThreeDRedirect = ({ furtherAuth = {} }) => {
    return (
        <form method="POST" action={furtherAuth.issuerUrl} id="3dform">
            <input type="hidden" name="PaReq" value={furtherAuth.paRequest} />
            <input type="hidden" name="MD" value={furtherAuth.md} />
            <input type="hidden" name="TermUrl" value={furtherAuth.confirmUrl} />
            <h1>Processing your 3D Secure Transaction</h1>
            <p>Please click continue to continue the processing of your 3D Secure transaction.</p>
            <input type="submit" class="btn btn-primary" value="Continue" />
        </form>
    );
};

class AddCard extends Component {
    render() {
        const { furtherAuth, validity, onSubmit, onChange, form, validationState, onCancelForm } = this.props;
        
        return (
            <React.Fragment>
                {furtherAuth ?
                    <ThreeDRedirect furtherAuth={furtherAuth} /> :
                    <Container fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form onSubmit={onSubmit} id="adyen-encrypted-form">
                                    <h2>Add New Payment Method</h2>
                                    <h5 className="mt-4">Cardholder Details</h5>
                                    <FormGroup validationState={validationState(validity.holderName)}>
                                        <FormLabel htmlFor="holderName">Name as Display on Card</FormLabel>
                                        <FormControl type="text" size="50" maxLength="50" id="holderName" data-encrypted-name="holderName" onChange={onChange} required value={form.holderName} />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="shopperEmail">Email</FormLabel>
                                        <FormControl type="email" id="shopperEmail" onChange={onChange} required value={form.shopperEmail} size="40" />
                                    </FormGroup>
                                    <h5 className="mt-4">Cardholder Address</h5>
                                    <FormGroup>
                                        <FormLabel htmlFor="holderAddressLine1">Address Line 1</FormLabel>
                                        <FormControl type="text" size="50" maxLength="50" id="holderAddressLine1" required onChange={onChange} value={form.holderAddressLine1} />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="holderAddressLine2">Address Line 2  <span className="form-label-optional">- optional</span></FormLabel>
                                        <FormControl type="text" size="50" maxLength="50" id="holderAddressLine2" onChange={onChange} value={form.holderAddressLine2} />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="holderAddressTownCity">Town</FormLabel>
                                        <FormControl type="text" size="50" maxLength="50" id="holderAddressTownCity" required onChange={onChange} value={form.holderAddressTownCity} />
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="holderAddressCountry">Country</FormLabel>
                                        <FormControl as="select" type="text" id="holderAddressCountry" required onChange={onChange} value={form.holderAddressCountry}>
                                            <option></option>
                                            {CountryList.map((v, i) => <option key={i} value={v.code}>{v.name}</option>)}
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel htmlFor="holderAddressPostalCode">Postcode</FormLabel>
                                        <FormControl type="text" size="20" maxLength="20" id="holderAddressPostalCode" required onChange={onChange} value={form.holderAddressPostalCode} />
                                    </FormGroup>

                                    <h5 className="mt-4">Card Details</h5>
                                    <FormGroup validationState={validationState(validity.number)}>
                                        <FormLabel sm={2} htmlFor="number">Card Number</FormLabel>
                                        <FormControl type="text" size="20" maxLength="20" id="number" data-encrypted-name="number" onChange={onChange} value={form.number} />
                                    </FormGroup>
                                    <FormGroup validationState={validationState(validity.month)}>
                                        <FormLabel htmlFor="month">Expiry Month</FormLabel>
                                        <FormControl as="select" data-encrypted-name="expiryMonth" id="month" onChange={onChange} value={form.month}>
                                            <option></option>
                                            {MONTH_OPTIONS.map((v, i) => <option key={i} value={v}>{v}</option>)}
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup validationState={validationState(validity.year)}>
                                        <FormLabel htmlFor="year">Expiry Year</FormLabel>
                                        <FormControl as="select" type="text" data-encrypted-name="expiryYear" id="year" onChange={onChange} value={form.year}>
                                            <option></option>
                                            {YEAR_OPTIONS.map((v, i) => <option key={i} value={v}>{v}</option>)}
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup as={Row} validationState={validationState(validity.cvc)}>
                                        <Col xs={12} md={3}>
                                            <FormLabel htmlFor="cvc">CVC</FormLabel>
                                            <FormControl type="text" maxLength="4" data-encrypted-name="cvc" id="cvc" onChange={onChange} value={form.cvc} />
                                        </Col>
                                    </FormGroup>
                                    <input type="hidden" value={new Date().toISOString()} data-encrypted-name="generationtime" />
                                    <Button variant="primary" className="float-right mt-4" type="submit">Add Card</Button>
                                    <Button variant="link" className="float-right mt-4 mr-2" onClick={onCancelForm}>Cancel</Button>
                            </Form>
                            </Col>
                        </Row>
                    </Container>
                }
            </React.Fragment>
        )
    }
}

export default AddCard;