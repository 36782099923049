import React from 'react';
import SelazarMap from '../../common/SelazarMap';
import { OrderMarker } from './OrderMarker';
import CommonEndpoints from '../../common/Endpoints';
import PropTypes from 'prop-types';

const OrderMap = (props) => {    
    let { orders } = props;

    let isMarkerShown = orders !== null && orders !== undefined && orders.length > 0;
    let centreCoords  = isMarkerShown ? { lat: orders[0].latitude, lng: orders[0].longitude } : { lat: 0, lng: 0 };

    let markers = orders.map((s, i) => {
        return (
            <OrderMarker key={i} order={s} />
        );
    });

    let settings = {
        centre: centreCoords
    };

    return (
        <SelazarMap settings={settings}
            googleMapURL={CommonEndpoints.MAPS_URL}
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "400px", min_height: "100%", width: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            isMarkerShown={isMarkerShown}
        >
            {markers}
        </SelazarMap>
    );
};

OrderMap.propTypes = {
    orders: PropTypes.array.isRequired
};

OrderMap.defaultProps = {
    orders: []
};

OrderMap.displayName = OrderMap.name;
export default OrderMap;