import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { checkPermission } from '../../Utilities';
import MenuItem from '../common/MenuItem';

import '../NavMenu.scss';

export class SelazarNavMenu extends Component {
    displayName = SelazarNavMenu.name

    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    logout() {
        this.props.onLogout();
    }

    render() {

        let issues = checkPermission("ViewIssue", <MenuItem link="/issues" title="Issues"/>);


        return (
            <Nav>
                 <div className="profile-title">
                    Selazar Internal
                </div>
                <Nav>
                    <MenuItem link='/' title='Dashboard' />
                </Nav>
                {issues}
            </Nav>
        );
    }
}
