import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Endpoints from "../common/Endpoints";
import { defaultGuid, hasPermission, checkPermission } from '../../Utilities';
import { PUT, GET, extractData } from '../../Consumer';
import { Form, FormGroup, FormControl, FormLabel, Modal, Button } from 'react-bootstrap';

class SelazarIssueTableRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            assigned_user: "Unassigned",
            raised_user: "Not found",
            show: false,
            issue: this.props.issue,
            assignTo: this.props.issue.assignedTo,
            current_user: JSON.parse(localStorage.getItem('user')),
            loading: true,
            raisedByCompany: { name: "Not found" },
            issueActorCompanies: [],
            actors:'',
            notifiers:''
        };

        this.renderAssignment = this.renderAssignment.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        let { issue } = this.props;
        Promise.all([this.fetchAssignedUser(issue.assignedTo),this.fetchRaisedUser(issue.raisedByUserID), this.fetchRaisedByCompany(issue.raisedByID), this.renderActorCompanies(issue.id),this.renderNotifyCompanies(issue.id),  this.fetchUsers()]);
    }

    fetchRaisedUser = (userID) => {
        if (userID) {
            if (userID !== defaultGuid) {
            GET(Endpoints.GET_USER + userID)
                .then(raw => raw.json())
                .then(result => {
                    let data = extractData(result);
                    if (data) {
                        let name = data.forename + " " + data.surname;
                        this.setState({ raised_user: name });
                    }
            });
        }
    }
    }

    fetchAssignedUser = (userID) => {
        var name = "Unassigned";

        if (userID) {
            if (userID !== defaultGuid) {
            GET(Endpoints.GET_USER + userID)
                .then(raw => raw.json())
                .then(result => {
                    let data = extractData(result);
                    if (data) {
                        name = data.forename + " " + data.surname;           
                    }
                    this.setState({ assigned_user: name });
            });
        }
    }
    }

    fetchRaisedByCompany(id) {
        GET(Endpoints.COMPANY.GET.COMPANY_BY_ID + id)
            .then(raw => raw.json())
            .then(result => {
                this.setState({ raisedByCompany: extractData(result) });
            });
    }

    renderEntityID = (id) => {
        if(id && id !== defaultGuid){
            return "[ID: "+id +"]";
        }
    }

    renderAssignment() {
        let { assigned_user, users, assignTo, current_user, issue } = this.state;
        let assignPermission = hasPermission("AssignIssue");
        let reassignPermission = hasPermission("ReassignIssue");

        let assignment;

        if ((assignPermission || reassignPermission) && this.isActor(issue)) {
            assignment = (<FormGroup>
                <FormLabel htmlFor="assignField">Assigned To:</FormLabel>

                <FormControl id="assignField" name="assignTo" componentClass="select" value={assignTo} onChange={this.onChange}>
                    <option key={null} value={null}>Unassigned</option>;
                                    <option key={current_user.id} value={current_user.id}>Assign to Me</option>;
                            {users.map(u => {
                        return <option key={u.id} value={u.id}>{u.forename} {u.surname}</option>;
                    })}
                </FormControl>

            </FormGroup>);
        }
        else {
            assignment = <div><dt>Assigned To:</dt><dd>{assigned_user}</dd></div>;
        }


        return assignment;
    }

    getCompanyName(id){
        GET(Endpoints.COMPANY.GET.COMPANY_BY_ID + id)
        .then(raw => raw.json())
        .then(result => {
            let data = extractData(result);
            if (data) {
            return data.forename + " " + data.surname;
            }
        });
       
    }

    renderActorCompanies = (id) => {
        GET(Endpoints.SELAZAR.ISSUES.GET.ACTOR_COMPANIES + id)
        .then(raw => raw.json())
        .then(result => {
            let data = extractData(result);
            if (data) {
                this.setState({actors: data.join(', ')});
            }else{
                this.setState({actors: "Not found"});
            }
        });
        
    }

    markAsRead = (issue) => {
        if (this.isActor(issue)) {
            return PUT(Endpoints.ISSUES.PUT.MARK_AS_READ + issue.id)
                .then(raw => raw.json());
        }
    }

    handleShow = () => {
        let { issue } = this.props;
   
        if (!issue.dateRead) {
            this.markAsRead(issue);
        }

        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    
    renderNotifyCompanies = (id) => {
        GET(Endpoints.SELAZAR.ISSUES.GET.NOTIFY_COMPANIES + id)
        .then(raw => raw.json())
        .then(result => {
            let data = extractData(result);
            if (data) {
                this.setState({notifiers: data.join(', ')});
            }else{
                this.setState({notifiers: "Not found"});
            }
        });
    }

    fetchUsers = () => {
        let { current_user } = this.state;
        GET(Endpoints.SELAZAR.USERS.GET.ALL)
            .then(raw => raw.json())
            .then(result => {
                let data = extractData(result);
                if (data) {
                    var removeIndex = data.map(function (item) { return item.id; })
                        .indexOf(current_user.id);

                    ~removeIndex && data.splice(removeIndex, 1);

                    this.setState({ users: data, loading: false });
                }
            });
    }

    renderResolution = (resolution) => {
        if(resolution){
            return resolution.description;
        } else {
            return "Not resolved";
        }
    }

    isActor = (issue) => 
    {   
        let user = JSON.parse(localStorage.getItem('user'));
        for( let i = 0; i < issue.issueActorCompanies.length; i++)  
        {
            if(issue.issueActorCompanies[i].companyID === user.companyID)
            {
                return true;
            }
        }
        return false;
    }

    renderInteractionSource = (interactionSource) => {
        if(interactionSource){
            return interactionSource.name;
        } else {
            return "Not found";
        }
    }

    onChange(e) {
        let { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }


    onSubmit(e) {
        e.preventDefault();
        const { assignTo, issue, current_user } = this.state;

        if (assignTo === current_user.id) {
            PUT(Endpoints.ISSUES.PUT.ASSIGN_TO_ME + issue.id)
                .then(response => response.json())
                .then(result => {
                    let data = extractData(result);
                    if (data) {
                        this.setState({ assignTo: current_user.id });
                    }
                    this.handleClose();
                }
                );
        } else {
            PUT(Endpoints.ISSUES.PUT.ASSIGN_TO_USER + issue.id + '/' + assignTo)
                .then(response => response.json())
                .then(result => {
                    let data = extractData(result);
                    if (data) {
                        this.setState({ assignTo: assignTo });
                    }
                    this.handleClose();
                }
                );
        }
    }

    render() {

        let { assigned_user, raised_user, issue, raisedByCompany, notifiers, actors } = this.state;

        let resolved = issue.dateResolved ? <div><dt>Resolved On:</dt> <dd>{issue.resolvedOn}</dd><dt>Resolution:</dt> <dd>{issue.resolution.description}</dd></div> : "";
        let resolvedButton = !issue.dateResolved && this.isActor(issue) ? <Link className="btn btn-warning map-button" from={'/issues/list'} to={{ pathname: '/issues/resolve', state: { issueID: issue.id } }}>Resolve (Wrench)</Link> : "";

        resolvedButton = checkPermission("ActionIssue", resolvedButton);
        let saveButton = this.isActor(issue) ?  <Button className="btn btn-primary" type="submit">Save</Button> : "";
        return (
            <React.Fragment>
            <tr key={`${issue.id}`} onClick={this.handleShow} className="table-row"> 
                <td>{issue.title}</td>
                <td>{issue.raisedOn}</td>
                <td>{raisedByCompany.name}</td>
                <td>{raised_user}</td>
                <td>{issue.issueType.name}</td>
                <td>{issue.issueType.areaOfConcernType.name}</td>
                <td>{issue.entityID}</td>
                <td>{this.renderInteractionSource(issue.interactionSource)}</td>
                <td>{actors}</td>
                <td>{notifiers}</td>
                <td>{assigned_user}</td>
                <td>{issue.resolvedOn ? issue.resolvedOn: "Not resolved"}</td>
                <td>{this.renderResolution(issue.resolution)}</td> 
            </tr>  
            <Modal show={this.state.show} onHide={this.handleClose}>
                    <Form onSubmit={this.onSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>{issue.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <dl>
                                <dt>Issue Type:</dt><dd>{issue.issueType.name}</dd>
                                <dt>Area of Concern:</dt><dd>{issue.issueType.areaOfConcernType.name} [ID: {issue.entityID}]</dd>
                                <dt>Raised On:</dt><dd>{issue.raisedOn}</dd>
                                <dt>Raised By:</dt><dd>{raisedByCompany.name}</dd>
                                {resolved}
                            </dl>

                            {this.renderAssignment()}

                            {resolvedButton}
                        </Modal.Body>
                        <Modal.Footer>
                            {saveButton}
                            <Button onClick={this.handleClose}>Close</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

            </React.Fragment>
        );
    }
}

export default SelazarIssueTableRow;