import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const StepperButtons = ({ cancelLink, handleBack, showBack = false, handleNext, showNext = false, nextButtonText = "Next Step" }) => 
    <div className="mt-4">
        {showBack && <div className="float-left">
            <Button variant="secondary" onClick={handleBack}>Back</Button>
        </div>}
        <div className="float-right">
            <Link to={cancelLink} className="link-button">
                <Button variant="link" className="button-nav-link mr-3">Cancel</Button>
            </Link>
            {showNext && <Button variant="primary" onClick={handleNext}>{nextButtonText}</Button>}
        </div>
    </div>


export default StepperButtons;
