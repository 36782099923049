import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../../common/Header';
import { BulkOrderSubNavList } from '../../../common/Constants';
import Breadcrumbs from '../../../common/Breadcrumbs';

import './BulkOrderDetails.scss';

const breadcrumbs = (headerKey, bulkOrderID) => [
    { text: "Bulk Orders", link: headerKey === "Pending Orders" ? "/retailer/bulkorder" : headerKey === "Orders in Process" ? "/retailer/bulkorder/inprocessorders" : headerKey === "Processed Orders" ? "/retailer/bulkorder/processedorders" : "/retailer/bulkorder/unsuccessfulorders" },
    { text: "Bulk Order Details", link: `/retailer/bulkorder/bulkorderdetails/${bulkOrderID}`, state: { headerKey: headerKey } },
    { text: "Split Details", link: null }
];

const createBoxBreakdown = (box, i) => (
    <div key={`box_${i}`} className="mb-3">
        <p className="title">Box {i + 1}</p>
        {box.items.map(item => item.quantity > 0 ?
            item.sku
                ? <React.Fragment key={`box_${i}_item_${item.companyItemID}`}><p className="mb-1">{`${item.name}: ${item.quantity}`}</p><p className="small">{item.sku}</p></React.Fragment>
                : <p key={`box_${i}_item_${item.companyItemID}`} className="mb-1">{`${item.name}: ${item.quantity}`}</p>
            : null)
        }
    </div>
);

class BulkOrderSplitDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            purchaseOrderNumber: props.location.state.purchaseOrderNumber || "",
            boxSplit: props.location.state.boxSplit || {},
            headerKey: props.location.state.headerKey || "",
            bulkOrderID: props.location.state.bulkOrderID || ""
        };
    }

    render() {
        const { purchaseOrderNumber, boxSplit, headerKey, bulkOrderID } = this.state;

        return (
            <React.Fragment>
                <Header title="Bulk Order" subNavList={BulkOrderSubNavList} activeKey={headerKey} headerClass="mb-2" />
                <Container fluid>
                    <Breadcrumbs breadcrumbs={breadcrumbs(headerKey, bulkOrderID)} />
                    <Row className="mb-4">
                        <Col sm={12} md={6}>
                            {purchaseOrderNumber &&
                                <React.Fragment>
                                    <h5>Split Details</h5>
                                    <section className="mt-3 mb-3">
                                        <p className="title">Purchase Order Number</p>
                                        <p>{purchaseOrderNumber}</p>
                                    </section>
                                </React.Fragment>}
                            {boxSplit &&
                                <React.Fragment>
                                    <h5>Box Breakdown</h5>
                                    <section className="mt-3">
                                        {boxSplit.boxes.map((box, i) => createBoxBreakdown(box, i))}
                                    </section>
                                </React.Fragment>}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default BulkOrderSplitDetails;