import React from 'react';
import { Button, Col, FormLabel, Form, FormControl, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class BulkOrderStockListFilter extends React.Component {
    render() {
        const { onFilterChange, onReset, onSearch, filter  } = this.props;
        const sortOptions = [ 
            { id: "1", name: "Recently Added" }, 
            { id: "2", name: "A-Z" }, 
            { id: "3", name: "Z-A" }, 
            { id: "4", name: "Stock High to Low" }, 
            { id: "5", name: "Stock Low to High" }, 
        ];
        return (<Form onSubmit={onSearch}>
            <FormGroup>
                <Row>
                    <Col md={3}>
                        <InputGroup>
                            <FormControl type="text" id="name" name="name" value={filter.name} onChange={onFilterChange} placeholder="Search by name" />
                            <InputGroup.Append>
                                <InputGroup.Text className="input-filter-icon">
                                    <FontAwesomeIcon icon={faSearch} />
                                </InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col md={0} className="mt-1 ml-3">
                        <FormLabel htmlFor="sortBy" className="float-right mt-1">Sort by</FormLabel>
                    </Col>
                    <Col md={3}>
                        <FormControl id="sortBy" name="sortBy" as="select" value={filter.sortBy} onChange={onFilterChange}>
                            <option key="" value="">Please Select...</option>;
                            {sortOptions.map((option) => <option key={option.id} value={option.id}>{option.name}</option>)}
                        </FormControl>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                <Button variant="link" className="float-right" name="filterReset" onClick={onReset}>Clear filter</Button>
            </FormGroup>
        </Form>);
    }
}

export default BulkOrderStockListFilter;