import React, { Component } from 'react';
import { Form, FormGroup, FormControl, Button, Card, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTrashAlt, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import CommonEndpoints from '../../common/Endpoints';
import { GET, POST, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Confirm from '../../common/Confirm';
import ShopifyShippingSummaryBox from './ShopifyShippingSummaryBox'

import './Integrations.scss';

const SFP = 'Self Fulfilled Package';
const SFP_VALUE = 'SFP';
const UK = 'UK';
const EU = 'EU';
const ROW = 'RestOfWorld';
const DEFAULT_MODAL_TITLE = 'Default Shipping Alert';
const DEFAULT_MODAL_TEXT = 'You have not assigned all default shipping options for each region which could result in delivery charges costing more than what your customers paid for. To prevent additional charges we recommend that you assign all your Shopify shipping options to your Selazar delivery preferences.';
const UNASSIGNED_MODAL_TEXT = 'You have one or more unassigned Shopify shipping options. We will use the default service times per region you have chosen for any order using an unassigned shipping option.';
const DEFAULT_MODAL_CANCEL = 'Edit shipping configuration';
const DEFAULT_MODAL_OK = 'Ok';
const SERVICE_TIMES_ORDERED = ["TwentyFourPreTenThirty", "TwentyFourPreNoon", "TwentyFour", "FortyEight", "SeventyTwo", "OneToTwo", "TwoToThree", "ThreeToFive", "FiveToTen"];

class EditShopifyShippingConfiguration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            integration: null,
            shopifyShippingRates: null,
            serviceTimeOptions: [],
            currentPreferences: [],
            selectedShippingRates: [],
            ukDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: UK,
                serviceTime: null
            },
            euDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: EU,
                serviceTime: null
            },
            rowDefault: {
                shippingRateID: null,
                shippingRateName: null,
                shippingRatePrice: null,
                countryRegion: ROW,
                serviceTime: null
            },
            validationError: false,
            rateRemoved: false,
            isSuccess: false,
            isError: false,
            showModal: false
        }
    }

    async componentDidMount() {
        await Promise.all([
            this.getRetailerIntegration(),
            this.fetchServiceTimes(),
            this.fetchCurrentPreferences(),
            this.getShippingRates()
        ]);
        this.setState({ loading: false }, this.removeDeleteRates());
    }

    getRetailerIntegration = async () => {
        const { integration } = this.props;
        const url = new URL(CommonEndpoints.INTEGRATIONS.GET.INTEGRATION_BY_ID + integration.id);

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const shopifyMetadata = JSON.parse(data.metadata);

                if (shopifyMetadata.shopifyShippingRatePreferences) {
                    const existingShippingRates = shopifyMetadata.shopifyShippingRatePreferences.filter(s => s.shippingRateID != null) || null;

                    this.setState({ integration: data, selectedShippingRates: existingShippingRates });
                } else {
                    this.setState({ integration: data })
                }
            });
    }

    fetchServiceTimes = async () => {
        return await GET(CommonEndpoints.RETAILER.GET.SERVICES_TIMES_OPTIONS)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                this.setState({ serviceTimeOptions: extractData(results) });
            });
    }

    fetchCurrentPreferences = async () => {
        return await GET(CommonEndpoints.RETAILER.GET.COURIER_REGIONAL_PREFERENCES)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const currentPreferences = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ currentPreferences: currentPreferences });
            });
    }

    getShippingRates = async () => {
        const { integration } = this.props;
        const url = new URL(CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_PREFIX + integration.id + CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_SUFFIX);

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                this.setState({ shopifyShippingRates: data });
            });
    }

    addOption = (countryRegion, serviceTime) => {
        const { selectedShippingRates } = this.state;
        selectedShippingRates.push({ shippingRateID: null, shippingRateName: null, shippingRatePrice: null, countryRegion: countryRegion, serviceTime: serviceTime })
        this.setState({ selectedShippingRates: selectedShippingRates });
    }

    setDefaults = (shopifyShippingRatePreferences) => {
        const { serviceTimeOptions, currentPreferences } = this.state;

        if (shopifyShippingRatePreferences) {
            let uk = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion == UK);
            let eu = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion == EU);
            let row = shopifyShippingRatePreferences.find(s => s.shippingRateID === null && s.countryRegion == ROW);

            if (uk) {
                let availableUKServiceTimes;
                const currentUKRegionPreference = currentPreferences.find(c => c.countryRegion === UK);
                if (currentUKRegionPreference) availableUKServiceTimes = currentUKRegionPreference.preferences.flatMap(p => p.ServiceTimes);

                const ukServiceTimesList = serviceTimeOptions.find(s => s.countryRegion === UK).availableServiceTimeOptions.filter(s => s.companyName != SFP).flatMap(m => m.serviceTimes),
                    ukServiceTimes = Object.assign({}, ...ukServiceTimesList);
                let uniqueUkServiceTimes = Object.keys(ukServiceTimes);
                if (availableUKServiceTimes) uniqueUkServiceTimes = uniqueUkServiceTimes.filter(u => availableUKServiceTimes.includes(u));

                if (!uniqueUkServiceTimes.includes(uk.serviceTime)) uk.serviceTime = null;

                this.setState({ ukDefault: uk })
            }
            if (eu) {
                let availableEUServiceTimes;
                const currentEURegionPreference = currentPreferences.find(c => c.countryRegion === EU);
                if (currentEURegionPreference) availableEUServiceTimes = currentEURegionPreference.preferences.flatMap(p => p.ServiceTimes);

                const euServiceTimesList = serviceTimeOptions.find(s => s.countryRegion === EU).availableServiceTimeOptions.filter(s => s.companyName != SFP).flatMap(m => m.serviceTimes),
                    euServiceTimes = Object.assign({}, ...euServiceTimesList);
                let uniqueEUServiceTimes = Object.keys(euServiceTimes);
                if (availableEUServiceTimes) uniqueEUServiceTimes = uniqueEUServiceTimes.filter(u => availableEUServiceTimes.includes(u));

                if (!uniqueEUServiceTimes.includes(eu.serviceTime)) eu.serviceTime = null;

                this.setState({ euDefault: eu });
            }
            if (row) {
                let availableROWServiceTimes;
                const currentROWRegionPreference = currentPreferences.find(c => c.countryRegion === ROW);
                if (currentROWRegionPreference) availableROWServiceTimes = currentROWRegionPreference.preferences.flatMap(p => p.ServiceTimes);

                const rowServiceTimesList = serviceTimeOptions.find(s => s.countryRegion === ROW).availableServiceTimeOptions.filter(s => s.companyName != SFP).flatMap(m => m.serviceTimes),
                    rowServiceTimes = Object.assign({}, ...rowServiceTimesList);
                let uniqueROWServiceTimes = Object.keys(rowServiceTimes);
                if (availableROWServiceTimes) uniqueROWServiceTimes = uniqueROWServiceTimes.filter(u => availableROWServiceTimes.includes(u));

                if (!uniqueROWServiceTimes.includes(row.serviceTime)) row.serviceTime = null;

                this.setState({ rowDefault: row });
            }
        }
    }

    removeDeleteRates = () => {
        const { selectedShippingRates, shopifyShippingRates, integration } = this.state;
        if (shopifyShippingRates) {
            const shopifyMetadata = JSON.parse(integration.metadata);
            this.setDefaults(shopifyMetadata.shopifyShippingRatePreferences);
            const activeRates = selectedShippingRates.filter(s => shopifyShippingRates.flatMap(sr => sr.shippingRateID).includes(s.shippingRateID));
            this.setState({ selectedShippingRates: activeRates, rateRemoved: selectedShippingRates.length > activeRates.length });
        }
    }

    removeOption = (shippingRate) => {
        const { selectedShippingRates } = this.state;
        const index = selectedShippingRates.findIndex(s => s.shippingRateID === shippingRate.shippingRateID && s.countryRegion === shippingRate.countryRegion && s.serviceTime === shippingRate.serviceTime);

        let updatedSelectedShippingRates = [
            ...this.state.selectedShippingRates.slice(0, index),
            ...this.state.selectedShippingRates.slice(index + 1)
        ];

        this.setState({ selectedShippingRates: updatedSelectedShippingRates });
    }

    validateSelections = () => {
        const { selectedShippingRates } = this.state;
        const invalidSelection = selectedShippingRates.findIndex(s => s.shippingRateID === null || s.shippingRateID === "") > -1;

        if (invalidSelection) {
            this.setState({ validationError: true });
            return false;
        } else {
            this.setState({ validationError: false });
            return true;
        }
    }

    handleInputChange = (e) => {
        let { name, value } = e.target;
        const { shopifyShippingRates, selectedShippingRates } = this.state;

        if (name.includes("default")) {
            if (name.includes(UK)) {
                this.setState(prevState => ({
                    ...prevState,
                    ukDefault: {
                        ...prevState.ukDefault,
                        ...{ serviceTime: value }
                    }
                }));
            } else if (name.includes(EU)) {
                this.setState(prevState => ({
                    ...prevState,
                    euDefault: {
                        ...prevState.euDefault,
                        ...{ serviceTime: value }
                    }
                }));
            } else if (name.includes(ROW)) {
                this.setState(prevState => ({
                    ...prevState,
                    rowDefault: {
                        ...prevState.rowDefault,
                        ...{ serviceTime: value }
                    }
                }));
            }
        } else {
            const shippingRate = JSON.parse(name);

            let toUpdate = selectedShippingRates.find(s => s.shippingRateID === shippingRate.shippingRateID && s.countryRegion === shippingRate.countryRegion && s.serviceTime === shippingRate.serviceTime);
            const index = selectedShippingRates.findIndex(s => s.shippingRateID === shippingRate.shippingRateID && s.countryRegion === shippingRate.countryRegion && s.serviceTime === shippingRate.serviceTime);
            const shopifyRate = shopifyShippingRates.find(s => s.shippingRateID == value)

            toUpdate.shippingRateID = shopifyRate ? parseFloat(value) : null;
            selectedShippingRates[index] = toUpdate;

            this.setState({ selectedShippingRates: selectedShippingRates });
        }
    }

    handleSubmit = () => {
        const { integration, selectedShippingRates, shopifyShippingRates, ukDefault, euDefault, rowDefault } = this.state;
        const { showShippingRates } = this.props;
        const url = new URL(CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_PREFIX + integration.id + CommonEndpoints.INTEGRATIONS.SHOPIFY.GET.SHIPPING_RATES_SUFFIX);

        this.setState({ loading: true });

        selectedShippingRates.forEach(shippingRate => {
            const shopifyRate = shopifyShippingRates.find(s => s.shippingRateID == shippingRate.shippingRateID);
            shippingRate.shippingRateName = shopifyRate.name;
            shippingRate.shippingRatePrice = shopifyRate.price
        });

        if (ukDefault.serviceTime) selectedShippingRates.push(ukDefault);
        if (euDefault.serviceTime) selectedShippingRates.push(euDefault);
        if (rowDefault.serviceTime) selectedShippingRates.push(rowDefault);

        POST(url, selectedShippingRates)
            .catch(error => console.log(error))
            .then(res => {
                if (res.ok) { return res.json(); }
            })
            .then(result => {
                if (result && !result.error) {
                    const data = extractData(result) || [];
                    const shopifyMetadata = JSON.parse(data.metadata);
                    const existingShippingRates = shopifyMetadata.shopifyShippingRatePreferences.filter(s => s.shippingRateID != null) || null;
                    this.setDefaults(shopifyMetadata.shopifyShippingRatePreferences);
                    this.setState({ integration: data, selectedShippingRates: existingShippingRates, isSuccess: true, rateRemoved: false, loading: false }, () => showShippingRates(true));

                } else {
                    const existingShippingRates = selectedShippingRates.filter(s => s.shippingRateID != null) || null;
                    this.setDefaults(selectedShippingRates);
                    this.setState({ isError: true, selectedShippingRates: existingShippingRates, loading: false });
                }
            })
    }

    getShippingRateClass = (shippingRateID) => {
        const { selectedShippingRates } = this.state;
        const selected = selectedShippingRates.findIndex(s => s.shippingRateID == shippingRateID) > -1;
        return selected ? "shipping-summary-positive" : "";
    }

    showValidationError = (shippingRateID) => {
        const { validationError } = this.state;
        return validationError && (shippingRateID === null || shippingRateID === "");
    }

    handleDefaultModalClose = () => this.setState({ showModal: false });

    handleDefaultModalConfirm = () => this.handleSubmit();

    handleModalText = () => {
        const { ukDefault, euDefault, rowDefault, shopifyShippingRates, selectedShippingRates, currentPreferences } = this.state;
        const unassigned = shopifyShippingRates.length - selectedShippingRates.filter(s => s.shippingRateID != null).length !== 0;

        let euSFP = false;
        const euPreference = currentPreferences.find(c => c.countryRegion === EU);
        if (euPreference) euSFP = euPreference.preferences.flatMap(p => p.ServiceTimes).findIndex(s => s == SFP_VALUE) > -1;

        let rowSFP = false;
        const rowPreference = currentPreferences.find(c => c.countryRegion === ROW);
        if (rowPreference) rowSFP = rowPreference.preferences.flatMap(p => p.ServiceTimes).findIndex(s => s == SFP_VALUE) > -1;

        if ((!ukDefault.serviceTime || !euDefault.serviceTime || !rowDefault.serviceTime) && !unassigned) return DEFAULT_MODAL_TEXT;
        else if ((ukDefault.serviceTime && (euDefault.serviceTime || euSFP) && (rowDefault.serviceTime || rowSFP)) && unassigned) return UNASSIGNED_MODAL_TEXT;
        else return `${DEFAULT_MODAL_TEXT} ${UNASSIGNED_MODAL_TEXT}`;
    }

    handleShowModal = (e) => {
        e.preventDefault();
        const { ukDefault, euDefault, rowDefault, shopifyShippingRates, selectedShippingRates } = this.state;

        const unassigned = shopifyShippingRates.length - selectedShippingRates.filter(s => s.shippingRateID != null).length !== 0;

        if (this.validateSelections()) {
            if (!ukDefault.serviceTime || !euDefault.serviceTime || !rowDefault.serviceTime || unassigned) this.setState({ showModal: true });
            else this.handleSubmit();
        }
    }

    sortServiceTimes = (serviceTimes) => {
        serviceTimes.sort( function (a, b) {
            if (SERVICE_TIMES_ORDERED.indexOf(a) > SERVICE_TIMES_ORDERED.indexOf(b)) return 1;
            else return -1;
          });  
          return serviceTimes;
    }

    render() {
        const { loading, serviceTimeOptions, currentPreferences, integration, shopifyShippingRates, selectedShippingRates, ukDefault, euDefault, rowDefault, isError, rateRemoved, showModal } = this.state;
        const { backToIntegrationsClick } = this.props;

        return (
            loading ? (<LoadingBar />) : <Container fluid>
                <a className="link-button link-pointer" onClick={backToIntegrationsClick}>
                    <FontAwesomeIcon icon={faAngleLeft} />  Return to Integrations
                </a>
                <h2 className="mt-4">Integrations</h2>
                <Row>
                    <Col sm="12" md="6" className="mt-4">
                        <h6>Shopify Shipping Configuration</h6>
                        <p>Please enter the following information about your Shopify account to allow us to set up your integration.</p>

                        <Confirm title={DEFAULT_MODAL_TITLE} text={this.handleModalText()} buttonText={DEFAULT_MODAL_OK} handleConfirmClose={this.handleDefaultModalClose} handleConfirmAction={this.handleDefaultModalConfirm} show={showModal} cancelText={DEFAULT_MODAL_CANCEL} size="lg" />
                        {isError &&
                            <Card className="card-danger mb-3">
                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                                    There seems to be a problem
                            </Card.Title>
                                <Card.Body>
                                    We could not update Shopify shipping configuration.
                            </Card.Body>
                            </Card>}

                        {rateRemoved &&
                            <Card className="card-danger mb-3">
                                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />
                                    A Shopify shipping rate has been removed
                            </Card.Title>
                                <Card.Body>
                                    You do not need to do anything, we have automatically removed this shipping rate.
                            </Card.Body>
                            </Card>}

                        <p className="title">Store Name</p>
                        <p>{integration.shopName}</p>
                        <div className="form-input-description mb-5">
                            <p>We will automatically send all orders out on the fastest service we have. This can cost more than what your customers have paid for, therefore it is important that you configure your shipping settings on Shopify with Selazars shipping settings as soon as possible.</p>
                        </div>

                        <Form onSubmit={this.handleShowModal}>
                            {serviceTimeOptions.map((st, wi) => {
                                const serviceTimesList = st.availableServiceTimeOptions.filter(s => s.companyName != SFP).flatMap(m => m.serviceTimes),
                                    serviceTimes = Object.assign({}, ...serviceTimesList);

                                let availableServiceTimes;
                                let isSFP = false;
                                const currentRegionPreference = currentPreferences.find(c => c.countryRegion === st.countryRegion);
                                if (currentRegionPreference) { 
                                    availableServiceTimes = currentRegionPreference.preferences.flatMap(p => p.ServiceTimes);
                                    isSFP = currentRegionPreference.preferences.flatMap(p => p.ServiceTimes).findIndex(s => s == SFP_VALUE) > -1;
                                }

                                let uniqueServiceTimes = Object.keys(serviceTimes);

                                if (availableServiceTimes) uniqueServiceTimes = uniqueServiceTimes.filter(u => availableServiceTimes.includes(u));

                                uniqueServiceTimes = this.sortServiceTimes(uniqueServiceTimes);

                                const selectedShippingRateIDs = selectedShippingRates.filter(s => s.shippingRateID !== "" && s.shippingRateID !== null).flatMap(s => s.shippingRateID);

                                let defaultValue = {};
                                switch (st.countryRegion) {
                                    case UK:
                                        defaultValue = ukDefault;
                                        break;
                                    case EU:
                                        defaultValue = euDefault;
                                        break;
                                    case ROW:
                                        defaultValue = rowDefault;
                                        break;
                                }

                                return (
                                    !isSFP && 
                                    <React.Fragment key={`world-area-${wi}`}>
                                        <h5>Default {st.countryRegionName} Shipping</h5>
                                        <FormControl id={`default-${st.countryRegion}`} className="mb-2 default-dropdown" as="select" name={`default-${st.countryRegion}`} value={defaultValue.serviceTime} onChange={this.handleInputChange}>
                                            <option key="" value="">Select shopify shipping option</option>;
                                                {uniqueServiceTimes.map((serviceTime, i) => <option key={i} value={serviceTime}>{serviceTimes[serviceTime]}</option>)}
                                        </FormControl>
                                        <h5>{st.countryRegionName}</h5>
                                        <FormGroup>
                                            {uniqueServiceTimes.map((serviceTime, i) => {
                                                const shippingRates = selectedShippingRates.filter(s => s.countryRegion == st.countryRegion && s.serviceTime == serviceTime);
                                                return (
                                                    <React.Fragment>
                                                        <p className="title">{serviceTimes[serviceTime]}</p>
                                                        {shippingRates.map((shippingRate, i) =>
                                                            <FormGroup>
                                                                <FormControl className="shipping-rate-dropdown" id={`${wi}-${serviceTime}-${i}`} as="select" name={JSON.stringify(shippingRate)} value={shippingRate.shippingRateID} onChange={this.handleInputChange}>
                                                                    <option key="" value="">Select shopify shipping option</option>;
                                                                        {shopifyShippingRates.filter(r => r.shippingRateID === shippingRate.shippingRateID || !selectedShippingRateIDs.includes(r.shippingRateID)).map((v, i) =>
                                                                        <option key={i} value={v.shippingRateID}>{v.name}-{v.shippingZoneName}-£{v.price.toFixed(2)}</option>)}
                                                                </FormControl>
                                                                <Button variant="link" className="float-right shipping-rate-remove" name="removeOption" onClick={() => this.removeOption(shippingRate)}><FontAwesomeIcon icon={faTrashAlt} className="mr-1" /> Remove</Button>
                                                                {this.showValidationError(shippingRate.shippingRateID) && <span className="text-danger">Please choose a valid option.</span>}
                                                            </FormGroup>)}
                                                        <Button variant="link" name="addOption" onClick={() => this.addOption(st.countryRegion, serviceTime)}>Add Shopify Configuration</Button>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </FormGroup>
                                    </React.Fragment>
                                )
                            })}
                            <div className="float-right">
                                <Button variant="link" className="button-nav-link mr-3" onClick={backToIntegrationsClick}>Cancel</Button>
                                <Button variant="primary" type='submit'>Save Configuration</Button>
                            </div>
                        </Form>
                    </Col>
                    <Col>
                        <ShopifyShippingSummaryBox shippingRates={shopifyShippingRates} selectedShippingRates={selectedShippingRates} getShippingRateClass={this.getShippingRateClass} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default EditShopifyShippingConfiguration;