import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { BulkOrderSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';
import BulkOrderTable from './BulkOrderTable';
import { GET, PUT, extractData } from '../../../../Consumer';
import LoadingBar from '../../../common/LoadingBar';
import Endpoints from '../../../common/Endpoints';
import { SuccessAlert, ErrorAlert } from '../../../common/Alert';
import BulkOrderListFilter from './BulkOrderListFilter';
import Confirm from './../../../common/Confirm';

const HEADER_KEY = "Pending Orders";
const CANCEL_ORDER = 'Cancel Order';

class PendingBulkOrders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageCount: 1,
            pageIndex: 1,
            bulkOrders: [],
            loading: true,
            filter: {
                purchaseOrderNumber: "",
                dateFrom: undefined,
                dateTo: undefined,
                sortBy: ""
            },
            currentBulkOrderId: "",
            currentPurchaseOrderNumber: "",
            showCancellationConfirmationModal: false,
            warning: null,
            cancelWarning: null,
            success: false
        };
    }

    async componentDidMount() {
        await this.fetchPendingBulkOrders();
        this.setState({ loading: false });
    }

    fetchPendingBulkOrders = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.RETAILER.GET.BULK_ORDER_PENDING);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.bulkOrders === 0 ? "No pending bulk orders found" : null;
                this.setState({ bulkOrders: data.bulkOrders ? data.bulkOrders : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            });
    }

    cancelBulkOrder = async (id) => {
        console.log(id);
        return PUT(Endpoints.RETAILER.PUT.BULK_ORDER_CANCEL + id)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) { 
                    return response.json(); 
                }
                else {
                    this.setState({ cancelWarning: "Unable to cancel bulk order", showCancellationConfirmationModal: false });
                }
            })
            .then(result => {
                const data = extractData(result) || null;                
                const cancelWarning = data ? null :  "Unable to cancel bulk order";
                this.setState({ success: data ? true : false, cancelWarning: cancelWarning, showCancellationConfirmationModal: false });
            });
    }

    onCancelBulkOrder = async (id) => {
        this.setState({ loading: true });
        await this.cancelBulkOrder(id);
        await this.fetchPendingBulkOrders();
        this.setState({ loading: false });
    }

    handleCancelClick = (id, purchaseOrderNumber) => {
        this.setState({ currentBulkOrderId: id, currentPurchaseOrderNumber: purchaseOrderNumber });
        this.setState({ showCancellationConfirmationModal: true });
    }

    handleCancellationConfirmationClose = () => this.setState({ showCancellationConfirmationModal: false });

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true, cancelWarning: null, success: false }, this.fetchPendingBulkOrders);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true, cancelWarning: null, success: false }, this.fetchPendingBulkOrders);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true, cancelWarning: null, success: false }, this.fetchPendingBulkOrders);
    }

    onClear = async (e) => {
        e.preventDefault();
        this.setState({
            filter: { purchaseOrderNumber: "", dateFrom: null, dateTo: null, sortBy: "" },
            loading: true,
            cancelWarning: null, 
            success: false
        }, await this.fetchPendingBulkOrders);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true, cancelWarning: null, success: false }, await this.fetchPendingBulkOrders);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    handleViewBulkOrderClick = (id) => {
        this.props.history.push({ pathname: `/retailer/bulkorder/bulkorderdetails/${id}`, state: { headerKey: HEADER_KEY } });
    }

    render() {
        const { bulkOrders, loading, pageIndex, pageCount, currentBulkOrderId, currentPurchaseOrderNumber, showCancellationConfirmationModal, success, warning, cancelWarning } = this.state;

        return (
            <React.Fragment>
                <Header title="Bulk Order" subNavList={BulkOrderSubNavList} activeKey={HEADER_KEY} />
                {loading ? <LoadingBar /> :
                    <React.Fragment>
                        <Container fluid>
                            {success && <Row><Col sm={12} md={6} className="px-2"><SuccessAlert successMessage={`Successfully cancelled bulk order ${currentPurchaseOrderNumber}.`} /></Col></Row>}
                            {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                            {cancelWarning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={cancelWarning} /></Col></Row>}
                            <Row className="my-4">
                                <Col>
                                    <BulkOrderListFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onClear={this.onClear} {...this.state.filter} />
                                </Col>
                            </Row>
                            <BulkOrderTable bulkOrders={bulkOrders} handleViewBulkOrderClick={this.handleViewBulkOrderClick} showCancel={true} handleCancelClick={this.handleCancelClick} onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                        </Container>
                        <Confirm title={CANCEL_ORDER} text={`Are you sure you want to cancel order ${currentPurchaseOrderNumber}? You cannot undo this action.`} buttonText={CANCEL_ORDER} buttonVariant={"danger"} handleConfirmClose={this.handleCancellationConfirmationClose} handleConfirmAction={() => { this.onCancelBulkOrder(currentBulkOrderId); }} show={showCancellationConfirmationModal} cancelText="No, do not cancel this order" />
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default PendingBulkOrders;