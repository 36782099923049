import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { GET, extractData } from '../../../Consumer';
import Endpoints from '../../common/Endpoints';
import { findIndexByAttribute, updateItemPropInArray  } from '../../../Utilities';
import { RegularCollectionCard } from './RegularCollectionCard';
import RegularCollectionMap from './RegularCollectionMap';
import LoadingBar from './../../common/LoadingBar';

class RegularCollections extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            regularCollections: [],
            loading: true
        };

        this.fetchAllData            = this.fetchAllData.bind(this);
        this.fetchRegularCollections = this.fetchRegularCollections.bind(this);
        this.fetchLocations          = this.fetchLocations.bind(this);        
        this.renderCards             = this.renderCards.bind(this);
    }

    componentDidMount() {
        return this.fetchAllData();
    }

    fetchAllData() {
        this.setState({ loading: true });

        return Promise.all([
            this.fetchRegularCollections().then(this.fetchLocations)
        ]).then(() => this.setState({ loading: false }));
    }

    fetchLocations() {        
        let ids = this.state.regularCollections.map(rc => rc.senderAddressID);
        let promises = [];
        for (var i in ids) {
            promises.push(
                GET(`${Endpoints.GET_LOCATION}${ids[i]}/true`)
                .then(raw => raw.json())
                .then(result => {                    
                    let data = extractData(result);
                    if (data) {
                        let regularCollectionIndex  = findIndexByAttribute('senderAddressID', data.id, this.state.regularCollections);
                        let regCollections          = updateItemPropInArray(regularCollectionIndex, 'companyAddress', data, this.state.regularCollections);
                        this.setState({ regularCollections: regCollections });
                    }                    
                })
            );
        }

        return Promise.all(promises);
    }

    fetchRegularCollections() {
        return GET(Endpoints.REGULAR_COLLECTIONS.GET.FOR_CURRENT_COMPANY)
            .then(raw => raw.json())
            .then(result => {
                let data = extractData(result);
                this.setState({ regularCollections: data });
            });        
    }

    renderCards(collections) {

        let containsItems = collections.length > 0;

        if (containsItems) {
            return (
                <Row className="margin-bottom">
                    {collections.map(collection => {
                        return <Col sm={6} md={3} key={collection.id}><RegularCollectionCard regularCollection={collection} reload={this.fetchAllData} /></Col>;
                    })}
                </Row>
            );
        }
        else {
            return (
                <Row className="margin-bottom">
                    <p>No Locations</p>
                </Row>
            );
        }
    }

    render() {        
        let map = this.state.loading ? null : <div className="margin-bottom"><RegularCollectionMap regularCollections={this.state.regularCollections} /></div>;
        let contents = this.state.loading
            ? <LoadingBar/>
            : this.renderCards(this.state.regularCollections);
        return (
            <div>
                <h2>Regular Collections</h2>
                {map}
                {contents}
            </div>
        );
    }
};

export default RegularCollections;