import React from 'react';
import { Form, FormControl, FormLabel, FormGroup } from 'react-bootstrap';
import { CountryList } from '../../../common/Constants';

class BulkOrderRecipientDetails extends React.Component {

    render() {
        const { orderInformation, contactDetails, destinationAddress, contactDetailsValidation, orderInformationValidation, destinationAddressValidation, handleOrderInformationInputChange, handleContactDetailsInputChange, handleDestinationAddressInputChange } = this.props;
        return (
            <Form>
                <fieldset>
                    <h5 className="mt-2 mb-3">Recipient Details</h5>
                    <div className="form-input-description mb-3">
                        <p>The following information is regarding the recipient of the bulk order.</p>
                    </div>
                    <h6>Order Information</h6>
                    <FormGroup>
                        <FormLabel htmlFor="purchaseOrderNumber">Purchase Order Number</FormLabel>
                        <FormControl id="purchaseOrderNumber" maxLength="35" type="text" name="purchaseOrderNumber" value={orderInformation.purchaseOrderNumber} pattern="/^[a-zA-Z0-9_-]*$/" onChange={handleOrderInformationInputChange} required />    
                        <span className="text-danger">{orderInformationValidation.purchaseOrderNumber.message}</span>
                    </FormGroup>
                    <h6>Contact Details</h6>
                    <FormGroup>
                        <FormLabel htmlFor="name">Name</FormLabel>
                        <FormControl id="name" maxLength="35" type="text" name="name" value={contactDetails.name} onChange={handleContactDetailsInputChange} required />
                        <span className="text-danger">{contactDetailsValidation.name.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="emailAddress">Email Address</FormLabel>
                        <FormControl id="emailAddress" type="email" maxLength="50" name="emailAddress" value={contactDetails.emailAddress} onChange={handleContactDetailsInputChange} required />
                        <span className="text-danger">{contactDetailsValidation.emailAddress.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="contactNumber">Contact Number</FormLabel>
                        <FormControl id="contactNumber" type="tel" maxLength="15" name="contactNumber" value={contactDetails.contactNumber} onChange={handleContactDetailsInputChange} required />
                        <span className="text-danger">{contactDetailsValidation.contactNumber.message}</span>
                    </FormGroup>
                    <h6>Address</h6>
                    <FormGroup>
                        <FormLabel htmlFor="addressLine1">Address line 1</FormLabel>
                        <FormControl id="addressLine1" type="text" size="35" maxLength="35" name="addressLine1" value={destinationAddress.addressLine1} onChange={handleDestinationAddressInputChange} required />
                        <span className="text-danger">{destinationAddressValidation.addressLine1.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="addressLine2">Address line 2 <span className="form-label-optional">- optional</span></FormLabel>
                        <FormControl id="addressLine2" type="text" size="35" maxLength="35" name="addressLine2" value={destinationAddress.addressLine2} onChange={handleDestinationAddressInputChange} />
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="town">Town</FormLabel>
                        <FormControl id="town" type="text" size="30" maxLength="30" name="town" value={destinationAddress.town} onChange={handleDestinationAddressInputChange} required />
                        <span className="text-danger">{destinationAddressValidation.town.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="county">County</FormLabel>
                        <FormControl id="county" type="text" size="50" maxLength="50" name="county" value={destinationAddress.county} onChange={handleDestinationAddressInputChange} required />
                        <span className="text-danger">{destinationAddressValidation.county.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="country">Country</FormLabel>
                        <FormControl as="select" type="text" id="country" name="country" value={destinationAddress.country} onChange={handleDestinationAddressInputChange} required>
                            <option></option>
                            {CountryList.map((v, i) => <option key={i} value={v.code}>{v.name}</option>)}
                        </FormControl>
                        <span className="text-danger">{destinationAddressValidation.country.message}</span>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel htmlFor="postcode">Postcode</FormLabel>
                        <FormControl type="text" size="9" maxLength="9" id="postcode" name="postcode" value={destinationAddress.postcode} onChange={handleDestinationAddressInputChange} required />
                        <span className="text-danger">{destinationAddressValidation.postcode.message}</span>
                    </FormGroup>
                </fieldset>
            </Form>
        );
    }
}

export default BulkOrderRecipientDetails;