import React from 'react';
import { FormGroup, Button } from 'react-bootstrap';
import Endpoints from '../../common/Endpoints';

const OrderExport = (props) => {
    let endpoint = Endpoints.EXPORT_CSV;
    let { filter } = props;


    let download = () => {
        let { id, status, datefrom, dateto } = filter || {};

        let user = JSON.parse(localStorage.getItem('user'));

        let url = endpoint + user.companyID;

        if (id || status || datefrom || dateto) {
            url = new URL(url);
            Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        }

        window.location.assign(url);
    };

    return (
        <FormGroup style={{ marginTop: '15px' }}>
            <Button name="export" id="export" type="button" onClick={download}>Export Orders as CSV</Button>
        </FormGroup>
    );
};

OrderExport.displayName = OrderExport.name;
export default OrderExport;