import React, { Component } from 'react';
import { BulkOrderSubNavList } from '../../../common/Constants'
import { Container, Row, Col } from 'react-bootstrap';
import { POST, extractData, GET } from '../../../../Consumer';
import Endpoints from '../../../common/Endpoints';
import Header from '../../../common/Header';
import Stepper from '../../../common/Wizard/Stepper';
import StepperButtons from '../../../common/Wizard/StepperButtons';
import LoadingBar from '../../../common/LoadingBar';
import BulkOrderRecipientDetails from './BulkOrderRecipientDetails';
import BulkPickOptions from './BulkOrderOptions';
import BulkOrderItemSelection from './BulkOrderItemSelection';
import FormValidator from '../../../common/FormValidator';
import { ErrorAlert } from '../../../common/Alert';
import BoxSplit from './BulkOrderBoxSplit';
import BulkOrderSummaryBox from './BulkOrderSummaryBox';
import BulkOrderShippingResults from './BulkOrderShippingResults';
import BulkOrderConfirmation from './BulkOrderConfirmation';
import BulkOrderSuccess from './BulkOrderSuccess';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import './BulkOrderWizard.scss';

const steps = ["Recipient Details", "Bulk Order Options", "Item Selection", "Split", "Shipping", "Confirm"];
const cancelLink = "/retailer/bulkorder/info";
const MANDATORY_FIELD_VALIDATION_MESSAGE = "You have not filled in all mandatory fields";
const PAPER_PACKAGING_PREFERENCE = "PAPER";
const ECO_PACKAGING_PREFERENCE = "ECO";
const BUBBLE_WRAP_PACKAGING_PREFERENCE = "BUBBLE_WRAP";

const itemSelectionError = (errorMessages) => {
    const message = errorMessages.length > 0 ? errorMessages.map((e, i) =>
        <React.Fragment key={i}>
            <p className="mb-0"><b>{e.name}</b></p>
            <p className="mb-0">{e.message}</p>
            <br />
        </React.Fragment>)
        : null;

    return (
        <Card className="card-danger mb-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>
                <p>The following products do not have enough stock to fulfill this bulk order:</p>
                {message}
                <p>Please change item selection, or try again in 15 minutes.</p>
            </Card.Body>
        </Card>
    )
}

class BulkOrderWizard extends Component {
    constructor(props) {
        super(props);

        this.orderInformationValidator = new FormValidator([
            {
                field: 'purchaseOrderNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'Purchase Order Number is required'
            }
        ]);
        this.contactDetailsValidator = new FormValidator([
            {
                field: 'name',
                method: 'isEmpty',
                validWhen: false,
                message: 'Name is required'
            },
            {
                field: 'emailAddress',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email Address is required'
            },
            {
                field: 'contactNumber',
                method: 'isEmpty',
                validWhen: false,
                message: 'Contact Number is required'
            }
        ]);
        this.destinationAddressValidator = new FormValidator([
            {
                field: 'addressLine1',
                method: 'isEmpty',
                validWhen: false,
                message: 'Address Line 1 is required'
            },
            {
                field: 'town',
                method: 'isEmpty',
                validWhen: false,
                message: 'Town is required'
            },
            {
                field: 'county',
                method: 'isEmpty',
                validWhen: false,
                message: 'County is required'
            },
            {
                field: 'country',
                method: 'isEmpty',
                validWhen: false,
                message: 'Country is required'
            },
            {
                field: 'postcode',
                method: 'isEmpty',
                validWhen: false,
                message: 'Postcode is required'
            },
        ]);

        this.optionsValidator = new FormValidator([
            {
                field: 'ownBoxSplit',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            },
            {
                field: 'separateSKUs',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            },
            {
                field: 'shippingRequired',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            },
            {
                field: 'packagingRequired',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            },
            {
                field: 'packingPreference',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            },
            {
                field: 'fragileItems',
                method: 'isEmpty',
                validWhen: false,
                message: 'Please select an option'
            }
        ]);

        this.boxSplitValidator = new FormValidator([
        ]);

        this.shippingServiceValidator = new FormValidator([
            {
                field: 'selected',
                method: 'isBoolean',
                validWhen: true,
                message: 'Select shipping option before continuing'
            },
        ]);

        this.state = {
            loading: true,
            items: [],
            allItems: [],
            pageIndex: 1,
            pageCount: 1,
            currentPage: [],
            warning: '',
            orderInformationValidation: this.orderInformationValidator.valid(),
            contactDetailsValidation: this.contactDetailsValidator.valid(),
            destinationAddressValidation: this.destinationAddressValidator.valid(),
            optionsValidation: this.optionsValidator.valid(),
            itemsSelectionValidation: "",
            boxSplitValidation: this.boxSplitValidator.valid(),
            shippingServiceValidation: this.shippingServiceValidator.valid(),
            errors: [],
            itemSelectionErrors: [],
            success: false,
            showConfirmError: false,
            activeStep: 0,
            orderInformation: {
                purchaseOrderNumber: "",
            },
            contactDetails: {
                name: "",
                emailAddress: "",
                contactNumber: "",
            },
            destinationAddress: {
                addressLine1: '',
                addressLine2: '',
                town: '',
                county: '',
                country: '',
                postcode: '',
            },
            options: {
                ownBoxSplit: 'false',
                separateSKUs: 'false',
                shippingRequired: 'true',
                packagingRequired: 'true',
                packingPreference: 'paper',
                fragileItems: 'false',
                shippingServiceTime: 'noPreference'
            },
            boxSplit: {
                numberOfBoxes: 1,
                previousBoxTotal: 1,
                showTotalBoxQuestion: true,
                showServerValidation: false,
                showManualSplitSummary: false,
                all: { "boxes": [] }
            },
            selectedItems: { "items": [] },
            displaySummary: false,
            totalAddedCount: 0,
            shippingService: {
                id: "",
                name: "",
                service: "",
                cost: "",
                selected: false
            },
            filter: {
                name: "",
                sortBy: "",
                isPrePacked: false,
                pagingRequired: true
            },
            shippingResult: {},
            showDifferentServiceMessage: false,
            originalServiceTimePreference: "",
            noCourierError: false,
            courierCalculation: {}
        };
    }

    validateRecipientDetails = async (orderInformation, contactDetails, destinationAddress) => {
        let orderInformationValidation = this.orderInformationValidator.validate(orderInformation);
        let contactDetailsValidation = this.contactDetailsValidator.validate(contactDetails);
        let destinationAddressValidation = this.destinationAddressValidator.validate(destinationAddress);

        this.setState({
            orderInformationValidation: orderInformationValidation,
            contactDetailsValidation: contactDetailsValidation,
            destinationAddressValidation: destinationAddressValidation
        });

        if (!orderInformationValidation.isValid) {
            return await this.setValidationState(orderInformationValidation, MANDATORY_FIELD_VALIDATION_MESSAGE);
        } else if (!contactDetailsValidation.isValid) {
            return await this.setValidationState(contactDetailsValidation, MANDATORY_FIELD_VALIDATION_MESSAGE);
        } else {

            orderInformation.purchaseOrderNumber = orderInformation.purchaseOrderNumber.trim();
            contactDetails.name = contactDetails.name.trim();
            contactDetails.contactNumber = contactDetails.contactNumber.trim();
            destinationAddress.addressLine1 = destinationAddress.addressLine1.trim();
            destinationAddress.addressLine2 = destinationAddress.addressLine2.trim();
            destinationAddress.town = destinationAddress.town.trim();
            destinationAddress.county = destinationAddress.county.trim();
            destinationAddress.postcode = destinationAddress.postcode.trim();

            return await this.setValidationState(destinationAddressValidation, MANDATORY_FIELD_VALIDATION_MESSAGE);
        }
    }

    validateOptions = async (options) => {
        let validation = this.optionsValidator.validate(options);

        if (options.ownBoxSplit === 'true' && options.separateSKUs === 'true') {
            validation.isValid = false;
            validation.separateSKUs.message = "You cannot have separate SKUs if you have specified your own box split";
            return await this.setValidationState(validation, "You cannot have separate SKUs if you have specified your own box split")
        }
        else return await this.setValidationState(validation, MANDATORY_FIELD_VALIDATION_MESSAGE)
    }

    validateShippingService = async (shippingService) => {
        let validation = this.shippingServiceValidator.validate(shippingService);
        if (!shippingService.selected) {
            validation.isValid = false;
            validation.selected.isInvalid = true;
            validation.selected.message = "Select shipping option before continuing";
            this.setState({ shippingResult: { destinationAddress: '', bulkOrderBoxes: '', totalCost: '' } });
        }
        else this.setState({ shippingResult: { destinationAddress: this.state.destinationAddress, bulkOrderBoxes: this.state.courierCalculation.packagingRequired, totalCost: this.state.shippingService.cost } });
        return await this.setValidationState(validation, "Please select that the shipping option is suitable before proceeding");
    }

    clearBoxSplit = async () => {
        this.setState(prevState => ({
            ...prevState,
            boxSplit: {
                ...prevState.boxSplit,
                ...{ boxes: [] },
                ...{ numberOfBoxes: 1 },
            }
        }));
    }

    validateItems = async () => {
        const { selectedItems } = this.state;
        let validation = { isValid: true };
        const noItemsSelectedMessage = "You have not selected any items to add to the order";
        let itemSelected = false;

        if (selectedItems.items === undefined) {
            validation.isValid = false;
            return await this.setValidationState(validation, noItemsSelectedMessage);
        }
        else {
            Object.entries(selectedItems.items).forEach(([key, value]) => { if (parseInt(`${value.quantity}`) !== 0) itemSelected = true });
            if (itemSelected === false) {
                validation.isValid = false;
                return await this.setValidationState(validation, noItemsSelectedMessage)
            }
        }
        validation.isValid = true;
        return await this.setValidationState(validation, '')
    }

    validateBoxSplit = async (boxSplit) => {

        const { totalAddedCount, options, selectedItems, showPackagingOptions } = this.state;
        let validation = { isValid: true };
        const noOfBoxes = boxSplit.numberOfBoxes;

        if (options.ownBoxSplit === 'true') {
            if (showPackagingOptions) {
                if (noOfBoxes === '' || noOfBoxes === 0) {
                    validation.isValid = false;
                    return await this.setValidationState(validation, "Total number of boxes is required");
                }
                else if (noOfBoxes <= 0 || noOfBoxes % 1 !== 0) {
                    validation.isValid = false;
                    return await this.setValidationState(validation, "Invalid number of boxes");
                }
                else if (noOfBoxes > totalAddedCount) {
                    validation.isValid = false;
                    return await this.setValidationState(validation, "There are more boxes requested than items picked");
                } else {
                    validation.isValid = true;
                    return await this.setValidationState(validation, "");
                }
            }
            else {
                let unassignedIssues = 0;
                let assignedIssues = 0;
                let unassignedBoxes = 0;

                Object.entries(selectedItems.items).map(([key, value]) => {
                    if (value.unassigned !== 0 && value.assigned > 0) assignedIssues++;
                    if (value.unassigned > 0) unassignedIssues++;
                });

                for (let i = 0; i < noOfBoxes; i++) {
                    if (i < noOfBoxes) {
                        if (boxSplit.all.boxes[i] === undefined) unassignedBoxes++;
                        else if (boxSplit.all.boxes[i].items.every(i => i.quantity === 0 || i.quantity === '')) unassignedBoxes++;
                    }
                }

                if (unassignedIssues > 0) {
                    validation.isValid = false;
                    return await this.setValidationState(validation, "There are remaining items requiring assignment.");
                }
                else if (assignedIssues > 0) {
                    validation.isValid = false;
                    return await this.setValidationState(validation, "There are more items assigned than selected for bulk order.");
                }
                else if (unassignedBoxes > 0) {
                    validation.isValid = false;
                    return await this.setValidationState(validation, "There are boxes without assigned items.");
                } else {
                    validation.isValid = true;
                    return await this.setValidationState(validation, "");
                }
            }
        } else {
            if (boxSplit.all.boxes.length === 0) {
                validation.isValid = false;
                return await this.setValidationState(validation, "No order or items submitted, please go back and try again.");
            } else {
                validation.isValid = true;
                return await this.setValidationState(validation, "");
            }
        }
    }

    handleToggleShowHide = (showOptions) => {
        if (!showOptions) {
            this.setState({ showPackagingOptions: false });
            this.setState(prevState => ({
                ...prevState,
                options: {
                    ...prevState.options,
                    ...{ packingPreference: 'paper' }
                }
            }));
        }
    };

    handleBoxQuantityRequest = async (validQuantity) => {
        const show = (validQuantity) ? false : true;
        this.setState(prevState => ({
            ...prevState,
            boxSplit: {
                ...prevState.boxSplit,
                ...{ showTotalBoxQuestion: show },
            }
        }));
    };

    handleSelectedItemsRequest = async (selectedItems) => {
        this.setState({ selectedItems: selectedItems });
    };

    setValidationState = async (validation, text) => {
        if (validation.isValid) {
            validation.isValid = true;
            this.setState({ errors: [] });
        }
        else {
            validation.isValid = false;
            this.setState({ errors: [text] });
        }

        return validation;
    }

    async componentDidMount() {
        this.setState({ loading: false });
    }

    handleNext = async () => {
        const { activeStep, orderInformation, contactDetails, destinationAddress, options, selectedItems, boxSplit, shippingService } = this.state;
        let validate = null;
        let bypassValidation = false;
        this.setState({ loading: true });

        if (activeStep === 0) {
            validate = await this.validateRecipientDetails(orderInformation, contactDetails, destinationAddress);
        }
        else if (activeStep === 1) {
            validate = await this.validateOptions(options);
            this.setState({ optionsValidation: validate });
            await this.fetchStockItems();
        }
        else if (activeStep === 2) {
            validate = await this.validateItems(selectedItems.items);

            const boxes = this.state.boxSplit.all.boxes;
            if (boxes !== undefined && boxes.length > 0) {
                boxes.forEach((b) => {
                    b.items.forEach((i) => {
                        const exists = selectedItems.items.findIndex(si => si.identifier === i.identifier && si.quantity < 1) === -1 ? false : true;
                        if (exists) b.items.splice([b.items.findIndex(item => item.identifier === i.identifier)]);
                    });
                    if (b.items.length < 1) boxes.splice(b);
                });
            }

            this.setState(prevState => ({
                ...prevState,
                selectedItems: {
                    ...prevState.selectedItems,
                    ...{ items: selectedItems.items.filter(i => i.quantity > 0) }
                },
                boxes: boxes,
                itemsSelectionValidation: validate
            }));

            if (validate !== null && validate.isValid) {
                await this.validateSelectedStock(selectedItems, validate);
                validate = this.state.itemsSelectionValidation;
            }
        }
        else if (activeStep === 3) {
            validate = await this.validateBoxSplit(boxSplit);

            boxSplit.all.boxes.forEach((b => {
                if (b.items.length < 1) boxSplit.all.boxes.splice(b);
            }));

            this.setState(prevState => ({
                ...prevState,
                boxSplit: boxSplit,
                boxSplitValidation: validate
            }));

            if (validate !== null && validate.isValid) {
                if (options.ownBoxSplit === "true") {
                    await this.validateManualSplit(boxSplit.all, validate);
                    validate = this.state.boxSplitValidation;
                }
            }
        }
        else if (activeStep === 4) {
            if (options.shippingRequired === "false") {
                bypassValidation = true;
            } else {
                validate = await this.validateShippingService(shippingService);
                this.setState({ shippingServiceValidation: validate });
            }
        } else if (activeStep === 5) {
            await this.handleSubmit();
            bypassValidation = this.state.success;
        }

        if ((validate !== null && validate.isValid) || bypassValidation) {

            if (activeStep === 3 && options.ownBoxSplit === "true" && !boxSplit.showManualSplitSummary) {
                this.setState(prevState => ({
                    ...prevState,
                    boxSplit: {
                        ...prevState.boxSplit,
                        showTotalBoxQuestion: false,
                        showManualSplitSummary: true
                    },
                }));
            } else {
                this.setState(state => ({
                    activeStep: state.activeStep + 1
                }));
            }

            if (activeStep === 2) {
                if (options.shippingRequired === "false" && options.packagingRequired === "false") {
                    validate = await this.validateBoxSplit(boxSplit);
                    await this.validateNoPackaging(selectedItems, validate);
                } else if (options.separateSKUs === "true") {
                    validate = await this.validateBoxSplit(boxSplit);
                    await this.validateSkuSplit(selectedItems, validate, false);
                } else if (options.ownBoxSplit === "false" && options.separateSKUs === "false") {
                    validate = await this.validateBoxSplit(boxSplit);
                    await this.validateSkuSplit(selectedItems, validate, true);
                }
            }
        }
        this.setState({ loading: false });
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
            errors: [],
            itemSelectionErrors: [],
            showConfirmError: false
        }));
    };

    handleBackBoxSplit = () => {
        const { boxSplit } = this.state;
        if (boxSplit.showTotalBoxQuestion) {
            this.setState(state => ({
                activeStep: state.activeStep - 1,
                errors: [],
                itemSelectionErrors: []
            }));
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                boxSplit: {
                    ...prevState.boxSplit,
                    ...{ showTotalBoxQuestion: true, showManualSplitSummary: false }
                },
                errors: [],
                itemSelectionErrors: []
            }));
        }
    };

    handleContactDetailsInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "contactNumber" && value !== "") value = value.replace(/[^0-9.]/gi, '');
        
        this.setState(prevState => ({
            ...prevState,
            contactDetails: {
                ...prevState.contactDetails,
                ...{ [name]: value }
            },
        }));
    }

    handleOrderInformationInputChange = (e) => {
        let { name, value, type } = e.target;

        if (name === "purchaseOrderNumber" && value !== "") {
            value = value.replace(/[`~!¬@#£$%^&*|=?;:'",.<>\{\} ]/gi, '');
        }

        this.setState(prevState => ({
            ...prevState,
            orderInformation: {
                ...{ [name]: value }
            },
        }));
    }

    handleDestinationAddressInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            destinationAddress: {
                ...prevState.destinationAddress,
                ...{ [name]: value }
            },
        }));
    }

    handleOptionInputChange = (e) => {
        const { name, value } = e.target;

        console.log(name);

        // if packaging required has changed selected items need cleared as not to include non-packed in pre-packed only items
        if (name === "packagingRequired") {
            this.setState({ selectedItems: { "items": [] }, totalAddedCount: 0 });
        }

        if (name === "packagingRequired" && value === "false") {
            this.setState(prevState => ({
                ...prevState,
                options: {
                    ...prevState.options,
                    ...{
                        ownBoxSplit: 'false',
                        separateSKUs: 'false',
                    }
                },
            }));
        }

        if (name === "shippingRequired" && value === "true") {
            this.setState(prevState => ({
                ...prevState,
                options: {
                    ...prevState.options,
                    ...{ packagingRequired: "true" }
                },
            }));
        }

        if (name === "ownBoxSplit" || name === "separateSKUs") {
            this.setState(prevState => ({
                ...prevState,
                boxSplit: {
                    ...prevState.boxSplit,
                    all: { "boxes": [] }
                }
            }));
        }

        this.setState(prevState => ({
            ...prevState,            
            options: {
                ...prevState.options,
                ...{ [name]: value }
            },
        }));
    }

    handleBoxSplitInputChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            ...prevState,
            boxSplit: {
                ...prevState.boxSplit,
                ...{ [name]: value }
            },
        }));
    }

    handleBoxTotalChange = (e) => {
        const { name, value, defaultValue } = e.target;
        this.setState(prevState => ({
            ...prevState,
            boxSplit: {
                ...prevState.boxSplit,
                showServerValidation: false,
                ...{ [name]: value },
                ...{ previousBoxTotal: defaultValue }
            },
        }));
    }

    toggleSummary = async (show) => this.setState({ displaySummary: show });

    updateCounter = () => {
        const { selectedItems } = this.state;
        let { totalAddedCount } = this.state;
        totalAddedCount = 0;
        Object.entries(selectedItems.items).forEach(([key, value]) => { if (parseInt(`${value.quantity}`) !== 0) totalAddedCount = totalAddedCount + parseInt(`${value.quantity}`) });
        this.setState({ totalAddedCount: totalAddedCount });
    }

    calculateAssignments = async () => {
        const { boxSplit, selectedItems } = this.state;

        let originalNumberOfBoxesRequested = boxSplit.previousBoxTotal;
        if (parseInt(boxSplit.numberOfBoxes) < parseInt(originalNumberOfBoxesRequested)) {
            const difference = originalNumberOfBoxesRequested - boxSplit.numberOfBoxes;

            if (difference > 0) {
                const all = boxSplit.all;
                for (let i = 0; i < all.boxes.length; i++) {
                    if (i > boxSplit.numberOfBoxes - 1) {
                        boxSplit.all.boxes[i].items = []
                        boxSplit.all.boxes[i].companyPackagingId = null
                    }
                }

                this.setState(prevState => ({
                    ...prevState,
                    boxSplit: {
                        ...prevState.boxSplit,
                        ...{ all: all }
                    }
                }));
            }
        }

        for (let i = 0; i < boxSplit.numberOfBoxes; i++) {
            Object.entries(selectedItems.items).map(([key, value]) => {
                const identifier = (value.sku !== undefined && value.sku !== '' && value.sku !== null) ? value.companyItemID + "_" + value.sku : value.companyItemID;
                let selectedQuantity = 0;

                if (value.quantity !== 0) {
                    const box = boxSplit.all.boxes[i];
                    if (box !== undefined) {
                        const item = box.items.filter(item => item.identifier === identifier)[0];
                        if (item !== undefined) selectedQuantity = item.quantity;
                    }
                    this.recalculateAssignments(selectedQuantity, i, value.identifier, value.companyItemID, value.name, value.sku)
                }
                else {
                    const box = boxSplit.all.boxes[i];
                    if (box !== undefined) {
                        const item = box.items.filter(item => item.identifier === identifier)[0];
                        if (item !== undefined) selectedQuantity = 0;
                    }
                    this.recalculateAssignments(selectedQuantity, i, value.identifier, value.companyItemID, value.name, value.sku)
                }
            })
        }
    }

    recalculateAssignments = async (e, boxNumber, identifier, companyItemID, name, sku) => {
        const { boxSplit, selectedItems } = this.state;
        let requestedQuantity = e;

        if (requestedQuantity === "" || requestedQuantity === undefined || requestedQuantity < 0) requestedQuantity = 0;

        var numericValue = parseInt(requestedQuantity);
        if (numericValue === undefined) requestedQuantity = 0;

        let all = boxSplit.all;

        if (all.boxes[boxNumber] !== undefined) {
            const box = all.boxes[boxNumber];
            let existingBox = box.items.filter(item => item.identifier === identifier)[0];

            if (existingBox !== undefined) existingBox.quantity = requestedQuantity;
            else {
                const item = { identifier: identifier, quantity: requestedQuantity, companyItemID: companyItemID, name: name, sku: sku };
                all.boxes[boxNumber].items = all.boxes[boxNumber].items.concat(item);
            }
        }
        else {
            const item = { identifier: identifier, quantity: requestedQuantity, companyItemID: companyItemID, name: name, sku: sku };

            var boxItems =
            {
                "items": [],
                "companyPackagingID": null
            }

            boxItems.items = boxItems.items.concat(item);
            all.boxes[boxNumber] = boxItems;
        }

        let selectedItemsCopy = selectedItems;
        const exists = selectedItemsCopy.items.findIndex(i => i.identifier === identifier) === -1 ? false : true;

        if (exists) {
            var selectedItem = selectedItemsCopy.items[selectedItemsCopy.items.findIndex(i => i.identifier === identifier)];
            let totalAssignedCounter = 0;
            all.boxes.map(function (box) {
                let selectedItem = box.items.filter(item => item.identifier === identifier)[0];
                if (selectedItem !== undefined && selectedItem.quantity !== '') {
                    totalAssignedCounter += parseInt(selectedItem.quantity);
                }
            })

            selectedItem.assigned = totalAssignedCounter;
            selectedItem.unassigned = parseInt(selectedItem.quantity) - totalAssignedCounter;
        }

        this.setState(prevState => ({
            ...prevState,
            boxSplit: {
                ...prevState.boxSplit,
                ...{ all: all }
            },
            selectedItems: selectedItemsCopy
        }));
    };

    toggleShippingService = () => {
        this.setState(prevState => ({
            ...prevState,
            shippingService: {
                ...prevState.shippingService,
                ...{ selected: !prevState.shippingService.selected }
            },
        }));
    }

    validateManualSplit = async (boxSplitAll, validate) => {
        return POST(Endpoints.RETAILER.POST.BULK_ORDER_MANUAL_SPLIT_VALIDATE, boxSplitAll)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                if (data) {
                    let errors = [];

                    let invalidBoxes = false;
                    const emptyPackaging = data.boxes.filter(items => items.companyPackagingID === null && !items.isStockBox);

                    if (emptyPackaging.length > 0) emptyPackaging.forEach(item => { item.items.forEach(i => { if (i.quantity > 0) invalidBoxes = true; }) })

                    if (invalidBoxes === true) {
                        validate.isValid = false;
                        errors = ["The following boxes have too many products. Please return to the previous stage to increase box numbers and try again."]
                    }
                    this.setState(prevState => ({
                        ...prevState,
                        errors: errors,
                        boxSplitValidation: validate,
                        boxSplit: {
                            ...prevState.boxSplit,
                            showServerValidation: true,
                            ...{ all: data }
                        },
                    }));
                } else {
                    validate.isValid = false;
                    this.setState(prevState => ({
                        ...prevState,
                        errors: ["There was a problem validating the box split. Please try again."],
                        boxSplitValidation: validate,
                        boxSplit: {
                            ...prevState.boxSplit,
                            showServerValidation: false,
                        },
                    }));
                }
            });
    }

    validateSkuSplit = async (selectedItems, validate, isAutoSplit) => {
        validate.isValid = true;

        return POST(Endpoints.RETAILER.POST.BULK_ORDER_SKU_SPLIT_VALIDATE + isAutoSplit, selectedItems)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    if (data) {
                        this.setState(prevState => ({
                            ...prevState,
                            errors: [],
                            boxSplitValidation: validate,
                            boxSplit: {
                                ...prevState.boxSplit,
                                ...{ all: data }
                            }
                        }));
                    } else {
                        validate.isValid = false;
                        this.setState(prevState => ({
                            ...prevState,
                            boxSplit: {
                                ...prevState.boxSplit,
                                all: { "boxes": [] }
                            },
                            errors: ["There was a problem validating the split. Please try again."],
                            boxSplitValidation: validate
                        }));
                    }
                } else {
                    validate.isValid = false;
                    this.setState(prevState => ({
                        ...prevState,
                        errors: [result.message],
                        boxSplitValidation: validate
                    }));
                }
            });
    }

    validateNoPackaging = async (selectedItems, validate) => {
        validate.isValid = true;
        return POST(Endpoints.RETAILER.POST.BULK_ORDER_NO_PACKAGING_VALIDATE, selectedItems)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                if (!result.error) {
                    const data = extractData(result);
                    if (data) {
                        this.setState(prevState => ({
                            ...prevState,
                            errors: [],
                            boxSplitValidation: validate,
                            boxSplit: {
                                ...prevState.boxSplit,
                                ...{ all: data }
                            }
                        }));
                    } else {
                        validate.isValid = false;
                        this.setState(prevState => ({
                            ...prevState,
                            boxSplit: {
                                ...prevState.boxSplit,
                                all: { "boxes": [] }
                            },
                            errors: ["There was a problem validating the split. Please try again."],
                            boxSplitValidation: validate
                        }));
                    }
                } else {
                    validate.isValid = false;
                    this.setState(prevState => ({
                        ...prevState,
                        errors: [result.message],
                        boxSplitValidation: validate
                    }));
                }
            });
    }

    fetchShippingOption = async () => {
        this.setState({ loading: true });
        this.setState({ showDifferentServiceMessage: false });

        var submissionDetails = {
            boxes: this.state.boxSplit.all.boxes,
            serviceTimePreference: this.state.options.shippingServiceTime,
            destinationAddress: this.state.destinationAddress,
            contactDetails: this.state.contactDetails,
            orderInformation: this.state.orderInformation
        };
        let validate = this.shippingServiceValidator.validate(this.state.shippingService);

        return POST(Endpoints.INTERNAL.POST.BULK_ORDER_FIND_SHIPPING_OPTIONS, submissionDetails)
            .catch(error => console.log(error))
            .then(response => {
                if (response !== undefined && response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                if (data) {
                    if (data.noShippingResults) {
                        validate.isValid = false;
                        this.setState(prevState => ({
                            ...prevState,
                            noCourierError: true,
                            shippingServiceValidation: validate,
                            shippingService: { id: '', name: '', service: '', cost: '', selected: false },
                            loading: false
                        }));
                    }
                    else if (data.errorThrown) {
                        validate.isValid = false;
                        this.setState(prevState => ({
                            ...prevState,
                            errors: ["There was a problem retrieving couriers. Please try again."],
                            shippingServiceValidation: validate,
                            shippingService: { id: '', name: '', service: '', cost: '', selected: false },
                            loading: false,
                            noCourierError: false,
                            courierCalculation: {},
                            shippingResult: {}
                        }));
                    }
                    else {
                        this.setState(prevState => ({
                            ...prevState,
                            errors: [],
                            shippingServiceValidation: validate,
                            courierCalculation: data,
                            loading: false,
                            noCourierError: false,
                            shippingService: { id: data.cheapestCourierDetails.courierID, name: data.cheapestCourierDetails.courierName, service: data.cheapestCourierDetails.serviceTimeDetails.textValue + " delivery", cost: data.cheapestCourierDetails.cost.toFixed(2), selected: false },
                            shippingResult: {}
                        }));

                        if ((this.state.options.shippingServiceTime !== data.cheapestCourierDetails.serviceTimeDetails.enumValue)
                            && (this.state.options.shippingServiceTime !== "noPreference")) this.setState({ showDifferentServiceMessage: true, originalServiceTimePreference: data.originalServiceTimePreference });
                    }

                } else {
                    validate.isValid = false;
                    this.setState(prevState => ({
                        ...prevState,
                        errors: ["There was a problem retrieving couriers. Please try again."],
                        shippingServiceValidation: validate,
                        noCourierError: false,
                        shippingService: { id: '', name: '', service: '', cost: '', selected: false },
                        loading: false,
                        courierCalculation: {},
                        shippingResult: {}
                    }));
                }
            });
    }

    handleBackShippingResults = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
            errors: [],
            showDifferentServiceMessage: false,
            noCourierError: false,
            shippingService: {
                id: "",
                name: "",
                service: "",
                cost: "",
                selected: false
            },
        }));
    };

    onReset = async (e) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            filter: {
                ...prevState.filter,
                ...{ name: "", sortBy: "" },
            }
        }), await this.fetchStockItems);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchStockItems);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchStockItems);
    }

    onFilterChange = async (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    fetchStockItems = async () => {

        this.setState({ loading: true });
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(Endpoints.STOCK.GET.BY);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No products to show." : null;
                this.setState({ items: data.items ? data.items : [], pageCount: data.pageCount ? data.pageCount : 0, loading: false, warning: warning });
            });
    }

    fetchAllStockItems = async () => {
        this.setState({ loading: true });
        let filter = { ...this.state.filter };
        filter.pagingRequired = false;
        let url = new URL(Endpoints.STOCK.GET.BY);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                this.setState({ allItems: data.items ? data.items : [], loading: false, });
            });
    }

    validateSelectedStock = async (selectedItems, validate) => {
        return POST(Endpoints.RETAILER.POST.BULK_ORDER_SELECTED_ITEMS_VALIDATE, selectedItems)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                if (data) {
                    let errors = [];
                    if (data.invalidItems.length > 0) {
                        validate.isValid = false;
                        Object.entries(data.invalidItems).forEach(([key, value]) => {
                            if (value.quantity > 0) {
                                const totalQuantity = value.availableShelfCount + value.availablePalletCount;
                                const name = value.sku ? value.name + `(s) [${value.sku}]` : value.name + "(s)";
                                const reserved = value.pendingPalletCount > 0 ? `${value.pendingPalletCount} are currently unavailable due to in progress replenishment.` : '';
                                const errorItem = { name: name, message: `${totalQuantity} items are available, ${value.quantity} selected for bulk order. ${reserved}` };
                                errors.push(errorItem);
                            }
                        });
                    }
                    else validate.isValid = true;

                    this.setState(prevState => ({
                        ...prevState,
                        itemSelectionErrors: errors,
                        itemsSelectionValidation: validate
                    }));
                }
                else {
                    validate.isValid = false;
                    this.setState(prevState => ({
                        ...prevState,
                        errors: ["There was a problem validating the items. Please try again."],
                        itemsSelectionValidation: validate
                    }));
                }
            });
    }

    handleSubmit = async () => {

        const { orderInformation, contactDetails, destinationAddress, options, selectedItems, boxSplit, shippingService, shippingResult } = this.state;
        
        const bulkOrderModel = {
            purchaseOrderNumber: orderInformation.purchaseOrderNumber,
            contactDetails: contactDetails,
            destinationAddress: destinationAddress,
            preferences: {
                isBoxSplit: options.ownBoxSplit === "true" ? true : false,
                requireSeperateSKUs: options.separateSKUs === "true" ? true : false,
                requireShipping: options.shippingRequired === "true" ? true : false,
                requirePackaging: options.packagingRequired === "true" ? true : false,
                isFragile: options.fragileItems === "true" ? true : false,
                packagingPreference: options.packingPreference === "paper" ? PAPER_PACKAGING_PREFERENCE : options.packingPreference === "eco" ? ECO_PACKAGING_PREFERENCE : BUBBLE_WRAP_PACKAGING_PREFERENCE
            },
            stockSelection: selectedItems.items,
            shippingResult: shippingResult,
            boxSplits: [{ boxes: boxSplit.all.boxes }]
        };

        return POST(Endpoints.RETAILER.POST.BULK_ORDER_SUBMIT, bulkOrderModel)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result);
                if (data) {
                    let errors = [];
                    if (data.invalidItems !== undefined && data.invalidItems.length > 0) {
                        Object.entries(data.invalidItems).forEach(([key, value]) => {
                            if (value.quantity > 0) {
                                const totalQuantity = value.availableShelfCount + value.availablePalletCount;
                                const name = value.sku ? value.name + `(s) [${value.sku}]` : value.name + "(s)";
                                const errorItem = { name: name, message: `${totalQuantity} items in stock, ${value.quantity} selected for bulk order` };
                                errors.push(errorItem);
                            }
                        });
                        this.setState({ showConfirmError: false, success: false, itemSelectionErrors: errors });
                    }
                    else this.setState({ showConfirmError: false, success: true, itemSelectionErrors: [] });
                } else this.setState({ showConfirmError: true, success: false, itemSelectionErrors: [] });
            });
    }

    render() {
        const { loading, errors, itemSelectionErrors, showConfirmError, activeStep, orderInformationValidation, contactDetailsValidation, destinationAddressValidation, optionsValidation, orderInformation, contactDetails, destinationAddress,
            options, selectedItems, displaySummary, boxSplit, boxSplitValidation, totalAddedCount, shippingService, shippingServiceValidation, items,
            allItems, pageCount, pageIndex, filter, showDifferentServiceMessage, originalServiceTimePreference, noCourierError } = this.state;
        const errorMessage = errors.length > 0 ? errors.map((e, i) =>
            e && e.length ? <React.Fragment key={i}><p className="mb-0">{e}</p><br /></React.Fragment> : null) : null;

        return (<React.Fragment>
            <Header title="Bulk Order" subNavList={BulkOrderSubNavList} activeKey="Create Bulk Order" />
            <Container fluid>
                <Stepper steps={steps} activeStep={activeStep} />
                {loading ? <LoadingBar /> :
                    <div className="pt-4">
                        {errors.length > 0 && <Row><Col sm={12} md={6}><ErrorAlert errorMessage={errorMessage}></ErrorAlert></Col></Row>}
                        {itemSelectionErrors.length > 0 ? <Row><Col sm={12} md={6}>{itemSelectionError(itemSelectionErrors)}</Col></Row> : ''}
                        {activeStep === 0 ?
                            <Row>
                                <Col sm={12} md={6}>
                                    <BulkOrderRecipientDetails orderInformation={orderInformation} contactDetails={contactDetails} destinationAddress={destinationAddress} orderInformationValidation={orderInformationValidation} contactDetailsValidation={contactDetailsValidation} destinationAddressValidation={destinationAddressValidation} handleOrderInformationInputChange={this.handleOrderInformationInputChange} handleContactDetailsInputChange={this.handleContactDetailsInputChange} handleDestinationAddressInputChange={this.handleDestinationAddressInputChange} />
                                    <StepperButtons cancelLink={cancelLink} handleNext={this.handleNext} showNext={true} />
                                </Col>
                            </Row> : activeStep === 1 ?
                                <Row>
                                    <Col sm={12} md={6}>
                                        <BulkPickOptions options={options} validation={optionsValidation} handleInputChange={this.handleOptionInputChange} handleToggleShowHide={this.handleToggleShowHide} />
                                        <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />
                                    </Col>
                                </Row> : activeStep === 2 ?
                                    <React.Fragment>
                                        <BulkOrderItemSelection handleSelectedItemsRequest={this.handleSelectedItemsRequest} updateCounter={this.updateCounter}
                                            totalAddedCount={totalAddedCount} options={options} handleInputChange={this.handleInputChange}
                                            items={items} allItems={allItems} filter={filter}
                                            selectedItems={selectedItems} toggleSummary={this.toggleSummary} displaySummary={displaySummary}
                                            fetchAllStockItems={this.fetchAllStockItems} fetchStockItems={this.fetchStockItems} onFilterChange={this.onFilterChange}
                                            onPageSelect={this.onPageSelect} onPrevious={this.onPrevious} onNext={this.onNext} onSearch={this.onSearch}
                                            onReset={this.onReset} pageCount={pageCount} pageIndex={pageIndex} validateItems={this.validateItems} />
                                        {displaySummary ? null : <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} />}
                                    </React.Fragment> : activeStep === 3 ?
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <BoxSplit calculateAssignments={this.calculateAssignments} recalculateAssignments={this.recalculateAssignments} selectedItems={selectedItems} handleBoxQuantityRequest={this.handleBoxQuantityRequest} handleBack={this.handleBackBoxSplit} totalAddedCount={totalAddedCount} options={options} boxSplit={boxSplit} validation={boxSplitValidation} handleBoxTotalChange={this.handleBoxTotalChange} clearBoxSplit={this.clearBoxSplit} />
                                                {boxSplit.showTotalBoxQuestion && options.ownBoxSplit === 'true' ? null : <StepperButtons cancelLink={cancelLink} handleBack={this.handleBackBoxSplit} showBack={true} handleNext={this.handleNext} showNext={true} nextButtonText={options.ownBoxSplit === "true" && !boxSplit.showManualSplitSummary ? "Show Split Summary" : "Next Step"} />}
                                            </Col>
                                            {!boxSplit.showTotalBoxQuestion && !boxSplit.showManualSplitSummary ? <Col><BulkOrderSummaryBox selectedItems={selectedItems} /></Col> : null}
                                        </Row> : activeStep === 4 ?
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <BulkOrderShippingResults shippingRequired={options.shippingRequired} destinationAddress={destinationAddress} shippingServiceTime={options.shippingServiceTime} handleInputChange={this.handleOptionInputChange} fetchShippingOption={this.fetchShippingOption} shippingService={shippingService} toggleShippingService={this.toggleShippingService} validation={shippingServiceValidation} showDifferentServiceMessage={showDifferentServiceMessage} originalServiceTimePreference={originalServiceTimePreference} noCourierError={noCourierError} />
                                                    <StepperButtons cancelLink={cancelLink} handleBack={this.handleBackShippingResults} showBack={true} handleNext={this.handleNext} showNext={true} />
                                                </Col>
                                            </Row> : activeStep === 5 ?
                                                <Col sm={12} md={6}>
                                                    <BulkOrderConfirmation orderInformation={orderInformation} contactDetails={contactDetails} destinationAddress={destinationAddress} options={options} boxSplit={boxSplit} shippingService={shippingService} showConfirmError={showConfirmError} />
                                                    <StepperButtons cancelLink={cancelLink} handleBack={this.handleBack} showBack={true} handleNext={this.handleNext} showNext={true} nextButtonText="Confirm and Process Bulk Order" />
                                                </Col> : activeStep === 6 ?
                                                    <Col sm={12} md={6}>
                                                        <BulkOrderSuccess />
                                                    </Col> : null}
                    </div>}
            </Container>

        </React.Fragment>);
    }
}

export default BulkOrderWizard;