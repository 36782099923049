import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { GET, extractData } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import CommonEndpoints from '../../common/Endpoints';
import StockListFilter from './StockListFilter';
import { ProductsSubNavList } from '../../common/Constants';
import Header from '../../common/Header';
import StockItem from '../../common/StockItemCard';
import { ErrorAlert } from '../../common/Alert';
import Pagination from '../../common/Tables/Pagination';
import { format } from 'date-fns'

class StockList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            totalCount: 0,
            pageIndex: this.props.match.params.pageIndex || 1,
            pageCount: 1,
            items: [],
            currentPage: [],
            loading: true,
            warning: '',
            filter: {
                name: "",
                category: "",
                pagingRequired: true
            },
            companyName: "",
            exportError: false,
            message: ""
        };
    }

    async componentDidMount() {
        await Promise.all([
            this.fetchStockItems(),
            this.getCompanyName()
        ]);
        this.setState({ loading: false });
    }

    onReset = async (e) => {
        e.preventDefault();

        this.setState({
            filter: { name: "", category: "" },
            exportError: false,
            loading: true
        }, await this.fetchStockItems);
    }

    onExport = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.exportStockItems);
    }

    onSearch = async (e) => {
        e.preventDefault();
        this.setState({ loading: true }, await this.fetchStockItems);
    }

    onNext = async () => {
        const { pageIndex, pageCount } = this.state;
        if (pageIndex < pageCount) {
            this.setState({ pageIndex: parseInt(pageIndex) + 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPrevious = async () => {
        const { pageIndex } = this.state;
        if (pageIndex > 1) {
            this.setState({ pageIndex: parseInt(pageIndex) - 1, loading: true }, await this.fetchStockItems);
        }
    }

    onPageSelect = (e) => {
        const { value } = e.target;
        this.setState({ pageIndex: value, loading: true }, this.fetchStockItems);
    }

    onFilterChange = (e) => {
        const { name, value } = e.target;

        this.setState(prevState => ({
            ...prevState,
            pageIndex: 1,
            filter: {
                ...prevState.filter,
                ...{ [name]: value }
            }
        }));
    }

    getCompanyName = async () => {
        const user = JSON.parse(localStorage.getItem('user'));

        return await GET(CommonEndpoints.COMPANY.GET.COMPANY_BY_ID + user.companyID)
            .then(response => response.json())
            .then(result => {
                this.setState({ companyName: extractData(result).name });
            });
    }

    exportStockItems = async () => {
        const { pageIndex, companyName } = this.state;
        let filter = { ...this.state.filter };
        filter.pagingRequired = false;
        let url = new URL(CommonEndpoints.STOCK.GET.EXPORT_CSV);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.blob();
            })
            .then(result => {     
                    if(result){
                        const date = new Date();
                        const downloadLink = document.createElement("a");
                        const blob = new Blob(["\ufeff", result]);
                        const url = URL.createObjectURL(blob);
                        downloadLink.href = url;
                        downloadLink.download = companyName + "StockExport_" + format(date, 'DDMMYYYY') + ".csv";
                    
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        this.setState({ loading: false, exportError: false}, this.fetchStockItems);
                    } else this.setState({ loading: false, exportError: true, message: "Stock data for export could not be found." }, this.fetchStockItems);
            });
        }

    fetchStockItems = async () => {
        const { pageIndex } = this.state;
        const filter = { ...this.state.filter };
        let url = new URL(CommonEndpoints.STOCK.GET.BY);

        Object.keys(filter).forEach(k => url.searchParams.append(k, filter[k]));
        url.searchParams.append("pageIndex", pageIndex)

        return await GET(url)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(result => {
                const data = extractData(result) || [];
                const warning = data.items === 0 ? "No stock found" : null;
                this.setState({ items: data.items ? data.items : [], totalCount: data.total ? data.total : 0, pageCount: data.pageCount ? data.pageCount : 0, pageIndex: pageIndex > data.pageCount ? 1 : pageIndex, loading: false, warning: warning });
            });
    }

    render() {
        const { items, warning, pageIndex, pageCount, exportError, message } = this.state;
        return (
            <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Products" />
                <Container fluid>
                    <Row className="my-4">
                        <Col>
                            <StockListFilter onSearch={this.onSearch} onFilterChange={this.onFilterChange} onReset={this.onReset} onExport={this.onExport} exportError={exportError} message={message} {...this.state.filter} />
                        </Col>
                    </Row>
                    {this.state.loading ? <LoadingBar /> :
                        <React.Fragment>
                            {warning && <Row><Col sm={12} md={6} className="px-2"><ErrorAlert errorMessage={warning} /></Col></Row>}
                            <Row>
                                {items.length ? items.map(item =>
                                    <Col md={4} sm={12} key={item.companyItemID} className="px-2">
                                        <Link className="link-button" to={`/supplier/products/edit/${item.companyItemID}/${pageIndex}`}>
                                            <StockItem name={item.name} category={item.category} ean={item.ean} sku={item.sku} suid={item.selazarUniqueID} totalStock={item.totalStock} status={item.status} showButton={false}/>
                                        </Link>
                                    </Col>) : <p><strong>No products to show.</strong></p>}
                            </Row>
                            <Pagination onNext={this.onNext} onPrevious={this.onPrevious} onPageSelect={this.onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                        </React.Fragment>}
                </Container>
            </React.Fragment>
        )
    }
}

export default StockList;