import React, { Component } from 'react';
import { Button, Col, Form, FormGroup, Row, Container, Card } from 'react-bootstrap';
import ReactDropzone from 'react-dropzone';
import { POST_FILE, extractData, GET } from '../../../Consumer';
import LoadingBar from '../../common/LoadingBar';
import Endpoints from '../../common/Endpoints';
import ItemUploadPdf from '../Selazar-Item-Upload-Instructions.pdf';
import ItemUploadExample from '../ItemUploadExample.csv';
import { ProductsSubNavList } from '../../common/Constants'
import Header from '../../common/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faDownload, faFileUpload, faTrashAlt, faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

class UploadCompanyItemCsv extends Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            loading: true,
            error: false,
            permitted: false,
            results: [],
            message: '',
        };
        this.onDrop = this.onDrop.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.renderUploadResult = this.renderUploadResult.bind(this);
        this.renderUploadForm = this.renderUploadForm.bind(this);
        this.renderForbidden = this.renderForbidden.bind(this);
    }

    componentDidMount() {
        this.validateAccount();
    }

    validateAccount() {
        return GET(Endpoints.FINANCE.CARD.HAS_VALID_DETAILS)
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    console.log(res.message);
                    this.setState({ loading: false });
                }
                else {
                    const existing = extractData(res);
                    existing ? this.setState({ loading: false, permitted: true }) : this.setState({ loading: false });
                }
            })
            .catch(e => console.log(e));
    }

    removeFile(e) {
        e.preventDefault();
        e.stopPropagation();

        const idx = e.target.getAttribute('index');
        const files = [
            ...this.state.files.slice(0, idx),
            ...this.state.files.slice(idx + 1)
        ];

        this.setState({ files: files });
    }

    renderUploadResult(result) {
        if (result.isError) {
            const errors = result.message ? result.message.split(',') : [];

            if (result.isMalformed) {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>Formatting error, {result.fileName} error detected with formatting</Card.Body>
                </Card>);
            } else if (result.message && result.isError && errors.length > 0) {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>
                        <p>Upload Error</p>
                        {errors.map((e, i) => <p key={i}>{e}</p>)}
                    </Card.Body>
                </Card>);
            } else if (result.message && result.isError) {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>Upload error, {result.message}</Card.Body>
                </Card>);
            } else {
                return (<Card className="card-danger mb-3">
                    <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                    <Card.Body>
                        <p>Upload error</p>
                        <p>{result.fileName}: {result.successfulUploads} Products uploaded Successfully.</p>
                        <p>{result.errorUploads} Products could not be uploaded.</p>
                        {result.errorLines.length && <React.Fragment>
                            <p>Errors on lines:</p>
                            <ul>
                                {result.errorLines.map((e, i) => <li key={i}>{e}</li>)}
                            </ul>
                        </React.Fragment>}
                    </Card.Body>
                </Card>);
            }
        } else {
            return (<Card className="card-success mb-3">
                <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
                <Card.Body>{result.fileName} uploaded successfully.</Card.Body>
            </Card>);
        }
    }

    renderPickFromPalletResult = (result) => {
        if (result.isError) return null;
        const fileName = result.data.fileName;
        const lines = result.data.lines.filter(l => l.pickFromPallet === true);
        if (!lines.length) return null;

        return (
            <Card className="card-warning mb-3">
                <Card.Title><FontAwesomeIcon icon={faExclamationTriangle} />Product Size Information</Card.Title>
                <Card.Body>
                    <p>We have detected that you have added the following large product(s) to {fileName}:</p>
                    <ul>
                        {lines.map((l, i) => <li key={`${l.name}-${i}`}><span>{l.name} {l.skuCode ? ` - SKU: ${l.skuCode}` : ""}</span></li>)}
                    </ul>
                    <p>Any large products will be kept on pallets and picked directly from them, providing you the most cost-effective storage.</p>
                </Card.Body>
            </Card>
        );
    }

    onDrop(acceptedFiles, rejectedFiles) {

        if (rejectedFiles.length > 0) {
            this.setState({ results: [], error: true, message: "File could not be uploaded. Please ensure the file type is CSV and the file size is under 1MB." });
        }

        this.setState(prevState => ({
            files: prevState.files.concat(acceptedFiles)
        }));
    }

    renderForbidden() {
        return (<Card className="card-danger mb-3">
            <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
            <Card.Body>No card details found, please add card details to your account to upload products.</Card.Body>
        </Card>);
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.state.files.length) {
            this.setState({ loading: true });

            let formData = new FormData();
            this.state.files.forEach((f, i) => formData.append(`files`, this.state.files[i], f.name));

            return POST_FILE(Endpoints.STOCK.POST.UPLOAD_ITEM_CSV, formData).catch(e => console.log(e))
                .then(r => r.json())
                .then(response => {
                    if (!response.error) {
                        this.setState({ loading: false, error: false, files: [], message: 'Item records successfully uploaded.', results: extractData(response) });
                    } else {
                        this.setState({ loading: false, error: true, files: [], message: 'An error occurred, please try again later.' });
                    }
                });
        }

        !this.state.files.length && this.setState({ results: [], error: true, message: "No file selected, Ensure a file is selected for upload." });
    }

    renderUploadForm(uploadResult, pickFromPalletResult) {
        const { error, message } = this.state;

        return (<Form onSubmit={this.onSubmit}>
            <FormGroup>
                <ReactDropzone accept=".csv" onDrop={this.onDrop} maxSize={999999}>
                    {({ getRootProps, getInputProps }) => (
                        <React.Fragment>
                            <div {...getRootProps({ className: "file-upload text-center" })}>
                                <input {...getInputProps()} />
                                <FontAwesomeIcon icon={faFileUpload} className="file-upload-icon" />
                                <p className="file-upload-primarytext my-2">Drag and drop your CSV file(s)</p>
                                <p className="file-upload-secondarytext my-2">or</p>
                                <Button variant="secondary">Browse</Button>
                            </div>
                            <div className="file-list mt-4">
                                <ul>
                                    {this.state.files.map((f, i) => <li key={i}><FontAwesomeIcon icon={faFile} className="form-fileicon" />{f.name} - {f.size} bytes<FontAwesomeIcon icon={faTrashAlt} className="form-fileicon-action float-right" index={i} onClick={this.removeFile} /></li>)}
                                </ul>
                            </div>
                        </React.Fragment>
                    )}
                </ReactDropzone>
            </FormGroup>
            {uploadResult}
            {pickFromPalletResult}
            {error && message && <Card className="card-danger mb-3">
                <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
                <Card.Body>{message}</Card.Body>
            </Card>}
            <FormGroup className="float-right mt-2">
                <Button variant="primary" type="submit">Upload File(s)</Button>
            </FormGroup>
        </Form>);
    }

    render() {
        const { results, loading, permitted } = this.state;
        const uploadResult = results.map(r => this.renderUploadResult(r));
        const pickFromPalletResult = results.map(r => this.renderPickFromPalletResult(r));

        return (<React.Fragment>
            <Header title="Products" subNavList={ProductsSubNavList} activeKey="Upload Product CSV" />
            <Container fluid>
                <Row>
                    <Col sm={12} md={6}>
                        <h4>CSV Example and Documentation</h4>
                        <p><strong>Before you upload please read the following information.</strong></p>
                        <p>Please use the following CSV example and Documentation to learn how to bulk upload Products.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <div className="file-list">
                            <ul className>
                                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Product CSV example<span className="text-status-new ml-2" >NEW!</span><a href={ItemUploadExample} target='_blank' rel="noopener noreferrer" className="float-right" alt='Item CSV example - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
                                <li className="file-list-file"><FontAwesomeIcon icon={faFile} className="form-fileicon" />Product CSV instructions<span className="text-status-new ml-2" >NEW!</span><a href={ItemUploadPdf} target='_blank' rel="noopener noreferrer" className="float-right" alt='Item CSV instructions - opens in a new window'><FontAwesomeIcon className="form-fileicon-download" icon={faDownload} /></a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <p>Each row in the CSV represents a specific SKU of an product. The EAN is used as the primary identifier for your products, you can upload multiple SKUs using the same EAN. </p>
                        <p>If you wish to upload images for you products this must be done from the Edit Product page after the upload is complete.</p>
                        <p>If your product does not have a barcode leave the EAN field blank and we will generate a Selazar Unique ID for you. If in doubt or you have any questions about this please contact us via support@selazar.com.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        {loading ? <LoadingBar /> :
                            permitted ? this.renderUploadForm(uploadResult, pickFromPalletResult) : this.renderForbidden()
                        }
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
        );
    }
}

export default UploadCompanyItemCsv;