import React from 'react';
import { Form, Card, Table, FormControl, FormGroup, FormLabel, InputGroup, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBoxOpen, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../../common/Tables/Pagination';
import { ErrorAlert } from '../../../../common/Alert';
import './BundleWizard.scss';

const MAX_QUANTITY = 10;

const TableHeader = () => {
    return (
        <thead>
            <tr>
                <th>Product Name</th>
                <th>EAN</th>
                <th>SKU</th>
                <th>SUID</th>
                <th>Add to Bundle</th>
                <th>Quantity</th>
            </tr>
        </thead>
    );
}

const ProductRow = ({ item, selectedItems, onChange, index }) => {
    const product = selectedItems.productBundleCompanyItems.find(pb => pb.companyItemID === item.companyItemID && pb.skuCode === item.sku) || false;
    const checkBoxID = `chk${index}:${item.companyItemID}`;
    const dropdownID = `drp${index}:${item.companyItemID}`;
    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.ean}</td>
            <td>{item.sku}</td>
            <td>{item.selazarUniqueID}</td>
            <td>
                <FormGroup className="custom-control custom-checkbox mt-1">
                    <input id={checkBoxID} className="custom-control-input" type="checkbox" name={item.sku} checked={product} onChange={onChange} />
                    <FormLabel className="custom-control-label" htmlFor={checkBoxID}/>
                </FormGroup>
            </td>
            <td>
                {product &&
                    <FormControl id={dropdownID} name={item.sku} as="select" value={product.quantity} onChange={onChange}>
                        {[...Array(MAX_QUANTITY + 1)].map((x, i) =>
                            <option key={i} value={i}>{i}</option>
                        )}
                    </FormControl>}
            </td>
        </tr>
    );
};

const ProductFilter = ({ onSearch, onFilterChange, onReset, searchText, sortBy }) => {
    return (
        <Row className="my-4">
            <Col>
                <Form onSubmit={onSearch}>
                    <FormGroup>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <FormControl type="text" id="searchText" className="input-filter" name="searchText" value={searchText} onChange={onFilterChange} placeholder="Search by ID or product name" />
                                    <InputGroup.Append>
                                        <InputGroup.Text className="input-filter-icon">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                            <Col md={3}>
                                <FormControl id="sortBy" name="sortBy" as="select" value={sortBy} onChange={onFilterChange}>
                                    <option key="" value="">Please Select...</option>;
                                    <option value="RecentlyAdded">Recently Added</option>
                                    <option value="AToZ">A to Z</option>
                                    <option value="ZToA">Z to A</option>
                                </FormControl>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Button variant="primary" className="float-right" type="submit">Apply filter</Button>
                        <Button variant="link" className="float-right" name="filterReset" onClick={onReset}>Clear filter</Button>
                    </FormGroup>
                </Form>
            </Col>
        </Row>
    );
};

class ProductSelection extends React.Component {

    render() {
        const { items, unfilteredItems, selectedItems, handleProductSelectionInputChange, onNext, onSearch, onFilterChange, onReset, onPrevious, onPageSelect, pageIndex, pageCount, searchText, sortBy, displaySummary, toggleSummary, itemSelectionValidation, message, isEdit } = this.props;
        const total = selectedItems.productBundleCompanyItems.flatMap(pb => pb.quantity).reduce((a, b) => a + b, 0);
        let selectedProducts = [];

        if(unfilteredItems){
            selectedItems.productBundleCompanyItems.map(pb => {
                const item = unfilteredItems.find(i => i.companyItemID === pb.companyItemID && i.sku === pb.skuCode)
                selectedProducts.push(item);
            });
        } else {
            selectedItems.productBundleCompanyItems.map(pb => {
                const item = items.find(i => i.companyItemID === pb.companyItemID && i.sku === pb.skuCode)
                selectedProducts.push(item);
            });
        }

        return (
            <Form>
                <fieldset>
                    {displaySummary ?
                        <React.Fragment>
                            <Row className="my-4">
                                <Col sm={4}><h6 className="mb-4">Selected Bundle Products</h6></Col>
                                <Col sm={8}>
                                    <Button className="float-right" variant="count" onClick={() => toggleSummary(true)}>
                                        <FontAwesomeIcon className="mr-1" icon={faBoxOpen} />
                                        <span className="d-inline-block ml-2">Bundle Items</span>
                                        <span className="highlighted d-inline-block ml-2">{total}</span>
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="my-4">
                                <Col>
                                    <Button onClick={() => toggleSummary(false)} variant="link" className="button-nav-return p-0"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Product Selection</Button>
                                </Col>
                            </Row>
                            <Card>
                                <div className="table-responsive">
                                    <Table striped bordered size="sm">
                                        <TableHeader/>
                                        <tbody>
                                            {selectedProducts.length ? selectedProducts.map((item, i) => <ProductRow key={i} item={item} selectedItems={selectedItems} onChange={handleProductSelectionInputChange} index={i} />) : <p className="mx-1 my-1 ml-3"><strong>You have not added any products to this bundle.</strong></p>}
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        </React.Fragment> :
                        <React.Fragment>
                            <Row className="my-4">
                                <Col sm={4}>{isEdit ? <h6 className="mb-4">Edit Bundle Products</h6> : <h6 className="mb-4">Product Selection</h6>}</Col>
                                <Col sm={8}>
                                    <Button className="float-right" variant="count" onClick={() => toggleSummary(true)}>
                                        <FontAwesomeIcon className="mr-1" icon={faBoxOpen} />
                                        <span className="d-inline-block ml-2">Bundle Items</span>
                                        <span className="highlighted d-inline-block ml-2">{total}</span>
                                    </Button>
                                </Col>
                            </Row>
                            {!itemSelectionValidation.isValid && <Row><Col sm={12} md={6}><ErrorAlert errorMessage={message}></ErrorAlert></Col></Row>}
                            <ProductFilter onSearch={onSearch} onFilterChange={onFilterChange} onReset={onReset} searchText={searchText} sortBy={sortBy} />
                            <Card>
                                <div className="table-responsive">
                                    <Table striped bordered size="sm">
                                        <TableHeader/>
                                        <tbody>
                                            {items.length ? items.map((item, i) => <ProductRow key={i} item={item} selectedItems={selectedItems} onChange={handleProductSelectionInputChange} index={i} />) : <p className="mx-1 my-1"><strong>No products available.</strong></p>}
                                        </tbody>
                                    </Table>
                                    <Pagination onNext={onNext} onPrevious={onPrevious} onPageSelect={onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
                                </div>
                            </Card>
                        </React.Fragment>}
                </fieldset>
            </Form>
        );
    }
}

export default ProductSelection;