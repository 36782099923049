import React, { Component } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Marker } from 'react-google-maps';
import { Link } from 'react-router-dom';
import CommonEndpoints from '../../common/Endpoints';
import SelazarMap from '../../common/SelazarMap';
import PropTypes from 'prop-types';
import { checkPermission } from '../../../Utilities';
import { DELETE, PUT } from '../../../Consumer';

export class LocationCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyAddress: this.props.location
        };

        this.toggleCompanyAddress = this.toggleCompanyAddress.bind(this);
    }

    toggleCompanyAddress(e) {
        let { location } = this.props;
        if (window.confirm(`Are you sure you want to ${location.active ? 'deactivate' : 'activate'} this company address?`)) {
            return PUT(`${CommonEndpoints.TOGGLE_COMPANY_ADDRESS}/${location.id}`)
                .then(response => response.json())
                .then(this.props.reload);
        }
    }

    deleteLocation() {
        const { companyAddress } = this.state;

        DELETE(CommonEndpoints.DELETE_LOCATION + companyAddress.id)
            .then(response => response.json())
            .then(this.props.reload)
            .catch(error => alert(error));
    }

    render() {
        const { companyAddress } = this.state;

        let isMarkerShown = true;
        let centreCoords = { lat: companyAddress.address.latitude, lng: companyAddress.address.longitude };

        let toggleButton = checkPermission("ToggleCompanyAddress", <Button className='btn btn-warning' title={companyAddress.active ? 'Deactivate' : 'Activate'} onClick={this.toggleCompanyAddress}><span className={companyAddress.active ? 'glyphicon glyphicon-remove-circle' : 'glyphicon glyphicon-ok-circle'} /></Button> )
        let editButton = checkPermission("EditCompanyAddress", <Link className="btn btn-default map-button" from={'/company/locations'} to={{ pathname: '/company/locations/edit', state: { companyAddressID: companyAddress.id } }}><span className="glyphicon glyphicon-pencil" /></Link>);
        let deleteButton = checkPermission("DeleteCompanyAddress", <button className="btn btn-danger" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this location?')) this.deleteLocation(e); }}><span className="glyphicon glyphicon-trash" /></button>);

        let settings = {
            centre: centreCoords,
            zoom: 15
        };

        return (
            <div className="map-card">
                <div className="map-card-top">
                    {
                        centreCoords.lat && centreCoords.lng ?
                        <SelazarMap settings={settings}
                            googleMapURL={CommonEndpoints.MAPS_URL}
                            loadingElement={<div style={{ height: "100%" }} />}
                            containerElement={<div style={{ height: "200px", min_height: "100%", width: "100%" }} />}
                            mapElement={<div style={{ height: "100%" }} />}
                            isMarkerShown={isMarkerShown}
                        >
                            <Marker position={settings.centre} />
                        </SelazarMap> :
                        <div style={{ height:'200px', min_height:'100%', width:'100%', textAlign:'center', verticalAlign:'center' }}>
                            <p><span className="glyphicon glyphicon-warning-sign"></span> Missing map information</p>
                        </div> 
                    }
                    
                </div>

                <div className="map-card-body">
                    <div className="map-card-text">                        
                        <h2>{companyAddress.addressType.type}{companyAddress.active ? null : ' - Inactive'}</h2>
                        <p>
                            {companyAddress.address.line1}<br/>  
                            {companyAddress.address.line2}<br/>  
                            {companyAddress.address.town}<br/>   
                            {companyAddress.address.postcode}
                        </p>
                    </div>
                    <div className="map-button-row">                        
                        <ButtonGroup>
                            {editButton}
                            {toggleButton}
                            {deleteButton}
                        </ButtonGroup>
                    </div>
               
                </div>
            </div>
        );
    }

}

LocationCard.propTypes = {
    reload: PropTypes.func.isRequired
};

export default LocationCard;