import React, { Component } from 'react';
import { GET, extractData } from '../../../../Consumer';
import { defaultGuid } from '../../../../Utilities';
import { Link } from 'react-router-dom';
import Endpoints from '../../../common/Endpoints';
import LoadingBar from '../../../common/LoadingBar';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { ErrorAlert } from '../../../common/Alert';
import { CountryList } from '../../../common/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faImage } from '@fortawesome/free-solid-svg-icons';
import { ProductsSubNavList } from '../../../common/Constants'
import Header from '../../../common/Header';

import './EditProduct.scss'

const PRODUCT_ERROR_MESSAGE = "There was a problem retrieving the product. Please try again.";

const ProductOverview = ({ id, name, description, brand, category = {}, pageIndex }) => (
    <div className="section-item mt-3">
        <h6>Product Overview</h6>
        <p className="title">Product Name</p>
        <p>{name}</p>
        <p className="title">Product Description</p>
        <p>{description}</p>
        <p className="title">Brand</p>
        <p>{brand}</p>
        <p className="title">Category</p>
        <p>{category ? category.name : "N/A"}</p>
        <Link to={`/supplier/products/edit/overview/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0">Edit Product Overview</Button>
        </Link>
    </div>
);

const ProductDetails = ({ id, dimensions, weight, country = {}, commodityCode, pageIndex }) => (
    <div className="section-item">
        <h6>Product Details</h6>
        <p className="title">Dimensions (cm)</p>
        <p>{dimensions}</p>
        <p className="title">Weight (kg)</p>
        <p>{weight}</p>
        <p className="title">Country of Manufacturing</p>
        <p>{country ? country.name : "N/A"}</p>
        <p className="title">Commodity Code</p>
        <p>{commodityCode}</p>
        <Link to={`/supplier/products/edit/details/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0" >Edit Product Details</Button>
        </Link>
    </div>
);

const Pricing = ({ id, price, expectedWeeklySales, pageIndex }) => (
    <div className="section-item">
        <h6>{"Sales & Pricing"}</h6>
        <p className="title">Product Price</p>
        <p>{`£${price}`}</p>
        <p className="title">Expected Weekly Sales</p>
        <p>{expectedWeeklySales}</p>
        <Link to={`/supplier/products/edit/pricing/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0" >{"Edit Sales & Pricing"}</Button>
        </Link>
    </div>
);

const UniqueIdentifiers = ({ id, ean, skus = [], suid, noScan, itemImages = [], pageIndex }) => (
    <div className="section-item">
        <h6>Unique Identifiers</h6>
        <p className="title">EAN</p>
        <p>{ean ? ean : "No EAN entered"}</p>
        <p className="title">SKUs</p>
        <div className="mb-3">
            {skus.length > 0 ? skus.map((f, i) => <p className="mb-0" key={`sku-${i}`}>{f.code}</p>) : <p className="mb-0">No SKUs entered</p>}
        </div>
        <p className="title">SUID</p>
        <p>{suid ? suid : "No SUID generated"}</p>
        <p className="title">No-Barcode Product</p>
        <p>{noScan ? "This is a no-barcode product" : "This is not a no-barcode product"}</p>
        <p className="title">Product Image</p>
        {skus.length > 0 ?
            skus.filter(s => s.itemImage !== null).length > 0 ?
                <div className="form-image-list mt-2">
                    <ul>
                        {skus.filter(s => s.itemImage !== null).map((sku, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{sku.itemImage.fileName}</li>)}
                    </ul>
                </div> : <p>You do not have an image, you can upload one at anytime.</p>
            : itemImages.length > 0 ? <div className="form-image-list mt-2">
                <ul>
                    {itemImages.map((image, i) => <li key={i}><FontAwesomeIcon icon={faImage} className="form-fileicon" />{image.fileName}</li>)}
                </ul>
            </div> : <p>You do not have an image, you can upload one at anytime.</p>}
        <Link to={`/supplier/products/edit/uniqueidentifiers/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0">Edit Unique Identifiers</Button>
        </Link>
    </div>
);

const PackagingPreferences = ({ id, isPrePacked, requiresBoxPackaging, requiresBubbleWrapPackaging, requiresPaperPackaging, requiresEcoPackaging, isFragile, pageIndex }) => (
    <div className="section-item">
        <h6>Packaging Preferences</h6>
        <p className="title">Is this product pre-packed?</p>
        <p>{isPrePacked ? "Yes, it is pre-packed" : "No, it is not pre-packed"}</p>
        <p className="title">Is this product box packed?</p>
        <p>{requiresBoxPackaging ? "Yes, it is box packed" : "No, it is not box packed"}</p>
        <p className="title">Packaging Preference</p>
        <p>
            {requiresBubbleWrapPackaging ? "Bubble wrap packaging"
                : requiresPaperPackaging ? "Paper packaging"
                    : requiresEcoPackaging ? "Eco Packaging"
                        : "No packaging preference"}
        </p>
        <p className="title">Is this product fragile</p>
        <p>{isFragile ? "Yes, it is fragile" : "No, it is not fragile"}</p>
        <Link to={`/supplier/products/edit/packagingpreferences/${id}/${pageIndex}`} className="link-button">
            <Button variant="link" className="p-0">Edit Packaging Preferences</Button>
        </Link>
    </div>
);

const AdditionalInformation = ({ pickFromPallet }) => (
    <div className="section-item">
        <h6>Additional Information</h6>
        {pickFromPallet &&
            <React.Fragment>
                <p className="title">Pick from Pallet</p>
                <p>We have detected you are uploading a large product. These products will be kept on pallets and picked directly from them, providing you with more cost-effective storage.</p>
            </React.Fragment>
        }
    </div>
);

class EditProduct extends Component {

    constructor(props) {
        super(props);

        const user = JSON.parse(localStorage.getItem('user'));

        this.state = {
            loading: true,
            error: null,
            companyItemID: (this.props.match && this.props.match.params) ? this.props.match.params.id : null,
            pageIndex: this.props.match.params.pageIndex || 1,
            categories: [],
            companyItem: {
                id: defaultGuid,
                editable: false,
                active: false,
                companyID: user.companyID,
                itemID: defaultGuid,
                expectedWeeklySales: 0,
                isPrePacked: false,
                requiresBoxPackaging: false,
                requiresBubbleWrapPackaging: false,
                requiresPaperPackaging: false,
                requiresEcoPackaging: false,
                isFragile: false,
                pickFromPallet: false,
                stockKeepingUnits: [],
                item: {
                    id: defaultGuid,
                    name: '',
                    description: '',
                    brand: '',
                    height: 0.00,
                    width: 0.00,
                    depth: 0.00,
                    weight: 0.00,
                    categoryID: '',
                    ean: '',
                    selazarUniqueID: null,
                    jan: null,
                    upc: null,
                    isbn: null,
                    mpn: null,
                    countryCodeOfManufacture: '',
                    commodityCode: '',
                    itemImages: []
                },
                currentPrice:
                {
                    id: defaultGuid,
                    companyItemID: defaultGuid,
                    price: 0.00,
                    active: true
                },
                noScan: false,
                dropzoneEnabled: true
            },
        };
    }

    async componentDidMount() {
        Promise.all([
            this.getCompanyItem(),
            this.getCategories()]);
        this.setState({ loading: false });
    }

    getCompanyItem = () => {
        const { companyItemID } = this.state;
        return GET(Endpoints.STOCK.GET.BY_ID + companyItemID)
            .then(response => response.json())
            .then(data => {
                const companyItem = extractData(data);
                this.setState({ companyItem: companyItem, error: companyItem ? null : PRODUCT_ERROR_MESSAGE });
            }).catch(error => {
                console.error(error);
                this.setState({ companyItem: null, error: PRODUCT_ERROR_MESSAGE });
            });
    }

    getCategories = () => {
        return GET(Endpoints.CATEGORY.GET.ALL)
            .catch(error => console.log(error))
            .then(response => {
                if (response.ok) return response.json();
            })
            .then(results => {
                const categories = Array.isArray(extractData(results)) ? extractData(results) : [];
                this.setState({ categories: categories });
            });
    }

    render() {
        const { companyItem, categories, loading, error, pageIndex } = this.state;
        const category = companyItem !== null ? categories.find(c => c.id === companyItem.item.categoryID) : null;
        const country = companyItem !== null ? CountryList.find(c => c.code === companyItem.item.countryCodeOfManufacture) : null;
        return (
            <React.Fragment>
                <Header title="Products" subNavList={ProductsSubNavList} activeKey="Products" headerClass="mb-0" />
                <Container fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            <Link to={`/supplier/products/list/${pageIndex}`} className="link-button">
                                <Button variant="link" className="button-nav-return p-0"><FontAwesomeIcon icon={faChevronLeft} className="mr-1" /> Return to Products</Button>
                            </Link>
                            {loading ? <LoadingBar /> :
                                <React.Fragment>
                                    {error
                                        ? <div className="mt-3">
                                            <ErrorAlert errorMessage={error} />
                                        </div>
                                        : <React.Fragment>
                                            <ProductOverview id={companyItem.id} name={companyItem.item.name} description={companyItem.item.description} brand={companyItem.item.brand} category={category} pageIndex={pageIndex} />
                                            <ProductDetails id={companyItem.id} dimensions={`${companyItem.item.height} x ${companyItem.item.width} x ${companyItem.item.depth}`} weight={companyItem.item.weight} country={country} commodityCode={companyItem.item.commodityCode} pageIndex={pageIndex} />
                                            <Pricing id={companyItem.id} price={companyItem.currentPrice.price} expectedWeeklySales={companyItem.expectedWeeklySales} pageIndex={pageIndex} />
                                            <UniqueIdentifiers id={companyItem.id} ean={companyItem.item.ean} skus={companyItem.stockKeepingUnits} suid={companyItem.item.selazarUniqueID} noScan={companyItem.noScan} itemImages={companyItem.item.itemImages} pageIndex={pageIndex} />
                                            <PackagingPreferences id={companyItem.id} isPrePacked={companyItem.isPrePacked} requiresBoxPackaging={companyItem.requiresBoxPackaging} requiresBubbleWrapPackaging={companyItem.requiresBubbleWrapPackaging} requiresPaperPackaging={companyItem.requiresPaperPackaging} requiresEcoPackaging={companyItem.requiresEcoPackaging} isFragile={companyItem.isFragile} pageIndex={pageIndex} />
                                            {companyItem.pickFromPallet && <AdditionalInformation pickFromPallet={companyItem.pickFromPallet} />}
                                        </React.Fragment>}
                                </React.Fragment>}
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}


export default EditProduct;