import React from 'react';
import { Container, Row, Col, Form, FormControl, FormLabel, FormGroup, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoadingBar from '../../common/LoadingBar';
import { GET, extractData, PUT } from '../../../Consumer';
import CommonEndpoints from '../../common/Endpoints';
import FormValidator from '../../common/FormValidator';
import { isNullOrEmptyGuid } from '../../../Utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ErrorAlert } from '../../common/Alert';

const FETCH_ERROR_MESSAGE = "There was a problem retrieving the user. Please try again.";

const ErrorUpdate = ({ errors }) => (
    <Card className="card-danger my-3">
        <Card.Title><FontAwesomeIcon icon={faExclamationCircle} />There seems to be a problem</Card.Title>
        <Card.Body>
            {errors.map((e, i) => e && e.length ? <p key={i} className="mb-0">{e}</p> : null)}
        </Card.Body>
    </Card>
);

const SuccessfulUpdate = ({ userId, userName, returnToAccount }) => (
    <React.Fragment>
        <Card className="card-success mb-3">
            <Card.Title><FontAwesomeIcon icon={faCheckCircle} />Success</Card.Title>
            <Card.Body>{userName} account has been updated successfully</Card.Body>
        </Card>
        <Link to={returnToAccount ? `/company/users/account/${userId}` : '/company/users'} className="float-right">
            <Button variant="primary">{returnToAccount ? "Continue to Account" : "Continue to Users"}</Button>
        </Link>
    </React.Fragment>
);

class EditDetails extends React.Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'forename',
                method: 'isEmpty',
                validWhen: false,
                message: 'Forename is required'
            },
            {
                field: 'surname',
                method: 'isEmpty',
                validWhen: false,
                message: 'Surname is required'
            },
            {
                field: 'email',
                method: 'isEmpty',
                validWhen: false,
                message: 'Email is required'
            }],
        );

        this.state = {
            loading: true,
            validation: this.validator.valid(),
            errors: [],
            errorFetch: null,
            success: false,
            returnToAccount: (this.props.location.state && this.props.location.state.returnToAccount) ? this.props.location.state.returnToAccount : false,
            userID: props.match.params.id || null,
            user: {
                id: '',
                forename: '',
                surname: '',
                email: '',
                roles: []
            },
            validationMessages: {
                roles: false,
            }
        };

        this.fetchUser = this.fetchUser.bind(this);
        this.fetchRoleOptions = this.fetchRoleOptions.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validate = this.validate.bind(this);
    };

    async componentDidMount() {
        await Promise.all([
            this.fetchUser(),
            this.fetchRoleOptions()]);
        this.setState({ loading: false });
    }

    validate = (user) => this.validator.validate(user);

    fetchUser() {
        const { userID } = this.state;
        if (!isNullOrEmptyGuid(userID)) {
            return GET(CommonEndpoints.GET_USER_WITH_ROLES + userID)
                .then(response => response.json())
                .then(result => {
                    const data = extractData(result);
                    const user = data ? { ...data, roles: data.userRoles.map((ur) => ur.roleID) } : null;
                    this.setState({ user: user, errorFetch: data ? null : FETCH_ERROR_MESSAGE });
                }).catch(error => {
                    console.error(error);
                    this.setState({ errorFetch: FETCH_ERROR_MESSAGE });
                });
        }
    }

    fetchRoleOptions() {
        const companyID = JSON.parse(localStorage.getItem('user')).companyID;
        return GET(CommonEndpoints.GET_ROLE_OPTIONS_FOR_COMPANY + companyID)
            .catch(error => console.log(error))
            .then(res => {
                if (res && res.ok) {
                    return res.json();
                }
                else {
                    throw new Error('Error fetching data');
                }
            })
            .then(result => {
                if (result) {
                    const data = extractData(result) || [];
                    const error = result.error ? result.message : null;
                    this.setState({ roleOptions: data, errors: error ? [error] : [] });
                }
            }, err => {
                this.setState({ loading: false, error: err.toString() ? [err.toString()] : [] });
            });
    }

    handleInputChange(e) {
        const { name, value, type } = e.target;

        if (type === 'checkbox') {
            const currRole = this.state.user.roles.indexOf(name);
            if (currRole > -1) {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: [...prevState.user.roles.slice(0, currRole), ...prevState.user.roles.slice(currRole + 1)] }
                    }
                }));
            }
            else {
                const newRoles = this.state.user.roles;
                newRoles.push(name);
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...{ roles: newRoles }
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    ...{ [name]: value }
                }
            }));
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { user } = this.state;
        const userID = JSON.parse(localStorage.getItem('user')).id;
        const validation = this.validator.validate(user);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (userID !== user.ID) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                this.setState({ validationMessages: { roles: true } });
            }
        }

        if (validation.isValid) {
            PUT(CommonEndpoints.EDIT_USER_DETAILS, user)
                .then(res => res.json())
                .then(e => {
                    if (e.error) {
                        let arrays = Object.keys(e).map(k => e[k]);
                        let errors = [].concat.apply([], arrays);
                        this.setState({ errors: errors, loading: false });
                    } else {
                        this.setState({ success: true, errors: [], loading: false });
                    }
                });
        } else {
            this.setState({ loading: validation.isValid });
        }
    }

    render() {
        const { loading, success, errors, returnToAccount, user, validationMessages, errorFetch } = this.state;

        const loggedInUser = JSON.parse(localStorage.getItem('user'));

        const validation = this.submitted ?
            this.validate(user) :
            this.state.validation;

        const userID = JSON.parse(localStorage.getItem('user')).id;

        if (errorFetch == null && userID !== user.ID && this.submitted) {
            if (user.roles.length === 0) {
                validation.isValid = false;
                validationMessages.roles = true;
            }
        }

        return (
            <Container fluid>
                <h2>Edit User</h2>
                {loading ? <LoadingBar /> :
                    <Row className="mt-4">
                        <Col sm={12} md={6}>
                            {success ? <SuccessfulUpdate userId={user.id} userName={`${user.forename} ${user.surname}`} returnToAccount={returnToAccount} /> :
                                errorFetch
                                    ? <div className="mt-3">
                                        <h5 className="mb-3">User Details</h5>
                                        <ErrorAlert errorMessage={errorFetch} />
                                    </div>
                                    : <React.Fragment>
                                        {errors.length > 0 && <ErrorUpdate errors={errors} />}
                                        <Form onSubmit={this.handleSubmit}>
                                            <input type="hidden" name="id" value={user.id} />
                                            <fieldset>
                                                <h5 className="mb-3">User Details</h5>
                                                <FormGroup>
                                                    <FormLabel htmlFor="forename">Forename</FormLabel>
                                                    <FormControl id="forename" type="text" name="forename" value={user.forename} onChange={this.handleInputChange} />
                                                    <span className="text-danger">{validation.forename.message}</span>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel htmlFor="surname">Surname</FormLabel>
                                                    <FormControl id="surname" type="text" name="surname" value={user.surname} onChange={this.handleInputChange} />
                                                    <span className="text-danger">{validation.surname.message}</span>
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel htmlFor="email">Email</FormLabel>
                                                    <FormControl id="email" type="email" name="email" value={user.email} onChange={this.handleInputChange} />
                                                    <span className="text-danger">{validation.email.message}</span>
                                                </FormGroup>
                                            </fieldset>
                                            {loggedInUser.id !== user.id && Object.keys(this.state.roleOptions).map((k) =>
                                                <div key={k} className="py-2">
                                                    <h5 className="pb-2">{k} Roles</h5>
                                                    {this.state.roleOptions[k].map((r) =>
                                                        <FormGroup key={r.id} className="custom-control custom-checkbox mb-1">
                                                            <input className="custom-control-input" id={r.id} type="checkbox" name={r.id} onChange={this.handleInputChange} checked={user.roles.indexOf(r.id) > -1} />
                                                            <FormLabel className="custom-control-label" htmlFor={r.id}>{r.name}</FormLabel>
                                                        </FormGroup>
                                                    )}
                                                </div>
                                            )}
                                            {validationMessages.roles && <span className="text-danger">Select a Role</span>}
                                            <FormGroup>
                                                <Button className="btn btn-primary float-right" type="submit">Save Changes</Button>
                                                <Link to={returnToAccount ? `/company/users/account/${user.id}` : '/company/users'} className="link-button">
                                                    <Button variant="link" className="button-nav-link float-right mr-4 mt-1">Cancel</Button>
                                                </Link>
                                            </FormGroup>
                                        </Form>
                                    </React.Fragment>}
                        </Col>
                    </Row>
                }
            </Container>
        );
    }
}

export default EditDetails;