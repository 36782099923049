import React, { Component } from 'react';
import { Tab, Nav, NavItem, Navbar } from 'react-bootstrap';

import './WorkArea.css';

class WorkArea extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabs: this.props.tabs,
            activeKey: this.props.activeKey,
            activeTab: this.props.tabs.find(t => t.eventKey === this.props.activeKey)
        }

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(key) {
        const { tabs } = this.state;
        this.setState({ 
            activeKey: key,
            activeTab: tabs.find(t => t.eventKey === key) 
        });
    }

    render() {
        const { tabs, activeKey, activeTab } = this.state;

        return tabs.length ? (
            <div className="work-area-main">
                <Tab.Container defaultActiveKey={activeKey} onSelect={this.handleSelect}>
                    <React.Fragment>
                        <Navbar fluid>
                            <Nav>
                                {tabs.map(tab => <NavItem key={tab.eventKey + 'nav'} eventKey={tab.eventKey}>{tab.text}</NavItem>)}
                            </Nav>
                        </Navbar>
                        <Tab.Content animation mountOnEnter>
                            <Tab.Pane eventKey={activeTab.eventKey}>{activeTab.component}</Tab.Pane>
                        </Tab.Content>
                    </React.Fragment>
                </Tab.Container>
            </div>
        ) : null;
    }
}

export default WorkArea;