import React, { Component } from 'react';
import { Button, Col, Form, FormControl, FormGroup } from 'react-bootstrap';
import { extractData, PUT } from '../../../Consumer';
import CommonEndpoints from '../../common/Endpoints';

class EditPromotion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            promotion: this.props.promotion,
            errors: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(changeEvent) {
        let { name, value } = changeEvent.target;
        this.setState({ promotion: { ...this.state.promotion, [name]: value } });
    }

    handleSubmit(ev) {
        ev.preventDefault();
        const { promotion } = this.state;
        PUT(CommonEndpoints.UPDATE_PROMOTION, promotion)
            .then(r => r.json()).then(data => {
                if (!data.error) {
                    this.props.closeMe();
                }
                else {
                    let arrays = Object.keys(extractData(data)).map(k => extractData(data)[k]);
                    let errors = [].concat.apply([], arrays);
                    this.setState({ errors: errors });
                }
            });
    }

    renderForm() {
        let promotion = this.state.promotion;
        let dateFrom = promotion.dateFrom.substring(0, 10);
        let dateTo = promotion.dateTo.substring(0, 10);
        return (
            <div>
                {this.state.errors.length > 0 && <div className="alert alert-danger">
                    <span>An error occurred while submitted your changes, please see below for further information:</span>
                    <ul>
                        {this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
                    </ul>
                </div>}
                <Form horizontal onSubmit={this.handleSubmit}>
                    <input type="hidden" name="id" value={promotion.id} />
                    <input type="hidden" name="active" value={promotion.active} />
                    <input type="hidden" name="promotionTypeID" value={promotion.promotionTypeID} />
                    <input type="hidden" name="entityID" value={promotion.entityID} />
                    <input type="hidden" name="supplierID" value={promotion.supplierID} />
                    <FormGroup>
                        <Col sm={2}>Date from</Col>
                        <Col sm={4}><FormControl type="date" id="datefrom" name="datefrom" onChange={this.handleInputChange} value={dateFrom}/></Col>
                        <Col sm={2}>to</Col>
                        <Col sm={4}><FormControl type="date" id="dateto" name="dateto" onChange={this.handleInputChange} value={dateTo} /></Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={2}>Percentage</Col>
                        <Col sm={4}><FormControl type="number" id="percentage" name="percentage" placeholder="Percentage" onChange={this.handleInputChange} value={promotion.percentage}/></Col>
                    </FormGroup>
                    <FormGroup>
                        <Col sm={2}>Description</Col>
                        <Col sm={10}><FormControl type="text" id="description" name="description" placeholder="Description" onChange={this.handleInputChange} value={promotion.description}/></Col>
                    </FormGroup>
                    <FormGroup>
                        <Col smOffset={2} sm={10}>
                            <Button bsClass="btn btn-primary" type="submit">Submit</Button>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        );
    }

    render() {
        let { promotion } = this.state;
        let content = this.renderForm(promotion);

        return (
            <div>
                {content}
            </div>
        );}
}

export default EditPromotion;