import React, { Component } from 'react';
import { Form, FormGroup, FormLabel, FormControl, Col, Container } from 'react-bootstrap';
import LoadingBar from "../../common/LoadingBar";
import Endpoints from "../../common/Endpoints";
import SelazarLinkContainer from '../../common/SelazarLinkContainer';
import { defaultGuid, INTERACTION_SOURCE, PARTY } from '../../../Utilities';
import { GET, POST, extractData } from '../../../Consumer';
import FormValidator from '../../common/FormValidator';

import './CreateRetailerIssue.css';

class CreateRetailerIssue extends Component {
    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            {
                field: 'title',
                method: 'isEmpty',
                validWhen: false,
                message: 'Description is required'
            },
            {
                field: 'issueTypeID',
                method: 'isEmpty',
                validWhen: false,
                message: 'Issue type is required'
            }
        ]
        );

        let user = JSON.parse(localStorage.getItem('user'));

        let { entityID, aoc } = this.props.location.state;

        this.state = {
            issue: {
                id: defaultGuid,
                title: "",
                dateRead: null,
                issueTypeID: "",
                interactionSourceID: INTERACTION_SOURCE.WEB,
                raisedByID: user.companyID,
                raisedByUserID: user.id,
                entityID: this.props.location.state.entityID
            },
            issueTypes: [],
            validation: this.validator.valid(),
            loading: true,
            entityID: entityID,
            aoc: aoc
        };
    }

    componentDidMount() {
        Promise.all([this.getIssueTypes()]);
    }

    getIssueTypes = () => {
        let { aoc } = this.state
        return GET(Endpoints.ISSUE_TYPES.GET.BY_AOC + aoc + '/' + PARTY.RETAILER)
            .then(response => response.json())
            .then(result => {
                if (!result.error) {
                    this.setState({ issueTypes: extractData(result), loading: false });
                }
                else {
                    this.setState({ loading: false });
                }
            });
    }

    handleInputChange = (e) => {
        let { name, value, type } = e.target;

        if (type === "checkbox") {
            let { checked } = e.target;
            value = checked;
        }

        if (e.target.getAttribute('parent')) {
            let parent = e.target.getAttribute('parent');
            let parentValue = this.state.issue[parent];
            parentValue[name] = value;

            name = parent;
            value = parentValue;
        }

        this.setState(prevState => ({
            ...prevState,
            issue: {
                ...prevState.issue,
                ...{ [name]: value }
            }
        }));


    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { issue } = this.state;

        const validation = this.validator.validate(issue);
        this.setState({ validation: validation, loading: validation.isValid });
        this.submitted = true;

        if (validation.isValid) {
            POST(Endpoints.ISSUES.POST.CREATE, issue)
                .then(response => response.json())
                .then(result => {
                    if (!result.error) {
                        this.setState({ loading: false });
                        this.props.history.push('/company/issues');
                    }
                    else {
                        alert("An error occurred: " + result.message);
                        this.setState({ loading: false });
                        this.props.history.push('/company/issues');
                    }
                });
        }
    }

  

    renderForm(issue) {
        let validation = this.submitted ?
            this.validator.validate(issue) :
            this.state.validation;

        let { loading } = this.state;
        const { issueTypes } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>

                <FormGroup>
                    <FormLabel htmlFor="titleField">Description</FormLabel>
                    <FormControl disabled={loading} id="titleField" componentClass="textarea" rows="5" name="title" maxLength={300} value={issue.title} onChange={this.handleInputChange} />
                    <span className="text-danger">{validation.title.message}</span>
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="issueTypeField">Issue Types</FormLabel>
                    <FormControl disabled={loading} id="issueTypeField" name="issueTypeID" componentClass="select" value={issue.issueTypeID} onChange={this.handleInputChange}>
                        <option key={''} value={''}>Please Select...</option>;
                        {issueTypes.map(issueType => {
                            return <option key={issueType.id} value={issueType.id}>{issueType.name}</option>;
                        })}
                    </FormControl>
                    <span className="text-danger">{validation.issueTypeID.message}</span>
                </FormGroup>

                <div className="form-buttons">
                    <SelazarLinkContainer to='/retailer/orders' activeClassName={null}>
                        <a className='btn btn-secondary'>Back to Orders</a>
                    </SelazarLinkContainer> 
                    <input className="btn btn-primary" type="submit" disabled={loading} value="Submit" />
                </div>
            </Form>
        );
    }

    render() {
        const { issue, loading } = this.state;
        const contents = this.renderForm(issue);

        return (
            <React.Fragment>
                {loading && <LoadingBar />}
                <Container fluid className="raise-issue">
                    <Col md={5}>
                        <h1>Raise Issue</h1>
                        {contents}
                    </Col>
                </Container>
            </React.Fragment>
        );
    }
}

export default CreateRetailerIssue;