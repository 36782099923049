import React, { Component } from 'react';
import { Row, Col, Card, ToggleButtonGroup, ToggleButton, Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import EditPromotion from './EditPromotion';
import { DELETE, PUT } from '../../../Consumer';
import { checkPermission } from '../../../Utilities';
import Endpoints from '../../common/Endpoints';

class PromotionCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            details: null,
            errors: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleChange(value) {
        let { promotion } = this.props;
        promotion.active = value;

        PUT(Endpoints.UPDATE_PROMOTION, promotion)
            .then(r => r.json()).then(this.props.refetchMe);
    }

    handleDelete(id) {
        DELETE(Endpoints.DELETE_PROMOTION + id)
            .then(r => r.json())
            .then(e => {
                if (e === true) {
                    this.props.refetchMe();
                    //alert("Promotion Deleted!");
                }
                if (e === false) {
                    let arrays = Object.keys(e).map(k => e[k]);
                    let errors = [].concat.apply([], arrays);
                    this.setState({ errors: errors });
                }
            });    
    }

    handleClose() {
        this.setState({ show: false }, this.props.refetchMe);
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        let { promotion } = this.props;

        let editButton = checkPermission("EditPromotion", <button className="btn btn-warning map-button" type="submit" onClick={this.handleShow}><span className="glyphicon glyphicon-pencil" /></button>);
        let deleteButton = checkPermission("DeletePromotion", <button className="btn btn-danger" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this promotion?')) this.handleDelete(promotion.id); }}><span className="glyphicon glyphicon-trash" /></button>);

        return (
            <div>
                <Col sm={4}>
                    {this.state.errors.length > 0 && <div className="alert alert-danger">
                        <span>An error occurred while submitting your changes, please see below for further information:</span>
                        <ul>
                            {this.state.errors.map((e, i) => <li key={i}>{e}</li>)}
                        </ul>
                    </div>}
                    <Row className="margin-bottom">
                        <Card>
                            <Card.Heading>
                                <Row>
                                    <Col sm={12}>
                                        {promotion.category.name}
                                    </Col>               
                                </Row>
                            </Card.Heading>
                            <Card.Body>
                                <Row>
                                    <Col sm={6}><b>Description:</b></Col>
                                    <Col sm={6}> {promotion.description}</Col>
                                </Row>
                                <Row>
                                    <Col sm={6}><b>Percentage Discount:</b></Col>
                                    <Col sm={6}>{promotion.percentage}% </Col>
                                </Row><Row>
                                    <Col sm={6}><b>Valid From:</b></Col>
                                    <Col sm={6}>{moment(promotion.dateFrom).format('DD/MM/YYYY')} </Col>
                                </Row> <Row>
                                    <Col sm={6}><b>Valid To:</b></Col>
                                    <Col sm={6}>{moment(promotion.dateTo).format('DD/MM/YYYY')}  </Col>
                                </Row><br />
                                <Row>
                                    <Col sm={8}>
                                        <ToggleButtonGroup type="radio" defaultValue={promotion.active} name="Active" value={promotion.active} onChange={this.handleChange} >
                                            <ToggleButton value={true}>
                                                ON
                                            </ToggleButton >
                                            <ToggleButton value={false}>
                                                OFF
                                            </ToggleButton >
                                        </ToggleButtonGroup>
                                    </Col>
                                    <Col sm={4} className="promotion-card-row" >
                                        {editButton}
                                        {deleteButton}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Promotion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EditPromotion promotion={this.props.promotion} key={this.props.id} closeMe={this.handleClose} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default PromotionCard;