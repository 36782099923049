import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import SelazarLinkContainer from './SelazarLinkContainer';

const NotFound = (props) => {
    return (
        <div>
            <h2>Not found!</h2>
            <Container>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <p>Unable to find the resource you were looking for. Please try again.</p>
                            <SelazarLinkContainer to='/' activeClassName={null}>
                                <Button>Back to Home</Button>
                            </SelazarLinkContainer>
                        </Card>                        
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

NotFound.displayName = NotFound.name;
export default NotFound;