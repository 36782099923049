import React from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import { toLocalTimeString } from '../../../../Utilities';
import SelazarLinkContainer from '../../../common/SelazarLinkContainer';
import Pagination from '../../../common/Tables/Pagination';

const renderStatus = (status) => {
    switch (status) {
        case "Shipped":
        case "Awaiting Collection":
            return <span className="text-status-green">{status}</span>;
        case "Awaiting Fulfilment":
            return <span className="text-status-purple">{status}</span>;
        case "Fulfilment In Progress":
            return <span className="text-status-blue">{status}</span>;
        case "Cancelled":
        case "Unprocessed":
            return <span className="text-status-red">{status}</span>;
        default:
            return <span className="text-status-grey">{status}</span>;
    }
}

const BulkOrderTableRow = ({ bulkOrder, handleViewBulkOrderClick, showCancel, handleCancelClick }) => {
    return (
        <tr>
            <td>{bulkOrder.purchaseOrderNumber}</td>
            <td>{bulkOrder.totalOrderItems}</td>
            <td>{toLocalTimeString(bulkOrder.createdDate)}</td>
            <td>{renderStatus(bulkOrder.status)}</td>
            <td>{bulkOrder.requiresShipping ? "Selazar Fulfilled" : "Self Fulfilled"}</td>
            {showCancel && <td className="text-center py-2">
                <Button variant="danger" size="sm" onClick={() => handleCancelClick(bulkOrder.id, bulkOrder.purchaseOrderNumber)}>Cancel</Button>
            </td>}
            <td className="text-center py-2">
                <Button className="link-button" onClick={() => handleViewBulkOrderClick(bulkOrder.id)} variant="secondary" size="sm">View Order</Button>
            </td>
        </tr>
    );
};

const BulkOrderTable = ({ bulkOrders, handleViewBulkOrderClick, showCancel = false, handleCancelClick, onNext, onPrevious, onPageSelect, pageIndex, pageCount }) => {
    return (
        <Card>
            <div className="table-responsive">
                <Table striped bordered size="sm">
                    <thead>
                        <tr>
                            <th>Purchase Order Number</th>
                            <th>Item Total</th>
                            <th>Date Created</th>
                            <th>Status</th>
                            <th>Shipping</th>
                            {showCancel && <th></th>}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {bulkOrders.length ? bulkOrders.map(bulkOrder => <BulkOrderTableRow key={bulkOrder.id} bulkOrder={bulkOrder} handleViewBulkOrderClick={handleViewBulkOrderClick} showCancel={showCancel} handleCancelClick={handleCancelClick} />) : <p className="ml-3 my-3"><strong>No bulk orders available.</strong></p>}
                    </tbody>
                </Table>
                <Pagination onNext={onNext} onPrevious={onPrevious} onPageSelect={onPageSelect} pageIndex={pageIndex} pageCount={pageCount} />
            </div>
        </Card>
    );
};

export default BulkOrderTable;