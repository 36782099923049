import React from 'react';
import { LocationMarker } from './LocationMarker';
import SelazarMap from '../../common/SelazarMap';
import CommonEndpoints from '../../common/Endpoints';
import PropTypes from 'prop-types';

const LocationMap = (props) => {    
    let { companyAddresses } = props;

    let isMarkerShown = companyAddresses !== null && companyAddresses !== undefined && companyAddresses.length > 0;
    let centreCoords  = isMarkerShown ? { lat: companyAddresses[0].address.latitude, lng: companyAddresses[0].address.longitude } : { lat: 0, lng: 0 };

    let markers = companyAddresses.map((l, i) => {
        return (
            <LocationMarker key={i} location={l} />
        );
    });

    let settings = {
        centre: centreCoords
    };

    return (
        <SelazarMap settings={settings}
            googleMapURL={CommonEndpoints.MAPS_URL}
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "400px", min_height: "100%", width: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            isMarkerShown={isMarkerShown}
        >
            {markers}
        </SelazarMap>
    );
};

LocationMap.propTypes = {
    companyAddresses: PropTypes.array.isRequired
};

LocationMap.defaultProps = {
    companyAddresses: []
};

LocationMap.displayName = LocationMap.name;
export default LocationMap;